import { Injectable } from '@angular/core';
import { ApiService, PagedRecords } from '../shared/api.service';

@Injectable()
export class DefaultService {
	getFundInfo(filter: Object, page: number = 1, size: number = 10, table: string): Promise<any> {
		const url = `${table}`;
		return this.apiService.getWithFilter(url, filter, page, size)
			.then(response => response as any);
	}
	findDefList(filter: Object, page: number = 1, size: number = 1000, table: string): Promise<PagedRecords> {
		return this.apiService.get(table)
			.then(response => response as PagedRecords);
	}
	serviceName = 'cop';
	dcfundService = 'cdb';
	holidayService = 'mrd';
	jobServiceName = 'scheduler-common/';
	filter: Object = {};
	countries = '/commons/countrystates/list';
	currencies = '/commons/currencies';
	constructor(private apiService: ApiService) { }

	find(filter: Object, page: number = 1, size: number = 10, table: string): Promise<PagedRecords> {
		const url = `${this.serviceName}/${table}`;
		return this.apiService.getWithFilter(url, filter, page, size)
			.then(response => response as PagedRecords);
	}
	holidayfind(filter: Object, page: number = 1, size: number = 10, table: string): Promise<PagedRecords> {
		const url = `${this.serviceName}/${this.holidayService}/${table}`;
		return this.apiService.getWithFilter(url, filter, page, size)
			.then(response => response as PagedRecords);
	}
	dcfind(filter: Object, page: number = 1, size: number = 10, table: string): Promise<PagedRecords> {
		const url = `${this.serviceName}/${this.dcfundService}/${table}`;
		return this.apiService.getWithFilter(url, filter, page, size)
			.then(response => response as PagedRecords);
	}
	getTrade(filter: Object, page: number = 1, size: number = 10, table: string): Promise<any> {
		const url = `${this.serviceName}/${table}`;
		return this.apiService.getWithFilter(url, filter, page, size)
			.then(response => response as any);
	}
	findOne(id: string, table: string): Promise<Object> {
		const url = `${this.serviceName}/${table}/${id}`;
		return this.apiService.get(url);
	}

	create(data: Object, table: string): Promise<Object> {
		const url = `${this.serviceName}/${table}`;
		return this.apiService.post(url, data);
	}
	holidaycreate(data: Object, table: string): Promise<Object> {
		const url = `${this.serviceName}/${this.holidayService}/${table}`;
		return this.apiService.post(url, data);
	}
	createJob(data: Object, query: string): Promise<Object> {
		const url = `${this.jobServiceName}${query}`;
		return this.apiService.post(url, data);
	}

	updateTd(data: Object, table: string): Promise<Object> {
		const url = `${this.serviceName}/${table}`;
		return this.apiService.post(url, data);
	}



	createDc(data: Object, table: string): Promise<Object> {
		const url = `${table}`;
		return this.apiService.post(url, data);
	}

	putupdateDc(data: Object, table: string): Promise<Object> {
		const url = `${table}/${data['fundCode']}/${data['accountPostingDriver']}/${data['dateRule']}`;
		return this.apiService.post(url, data);
	}

	putDc(data: Object, table: string): Promise<Object> {
		const url = `${table}`;
		return this.apiService.put(url, data);
	}

	putDcPosting(data: Object, table: string): Promise<Object> {
		const url = `${table}`;
		return this.apiService.post(url, data);
	}



	putDcUpdate(data: Object, table: string): Promise<Object> {
		const url = `${table}/${data['accountPostingDriver']}/${data['holidayDateAdjustment']}`;
		return this.apiService.post(url,data);
	}

	createCommons(data: Object, table: string): Promise<Object> {
		const url = 'commons/' + table;
		return this.apiService.post(url, data);
	}

	update(code: string, data: Object, table: string): Promise<Object> {
		const url = `${this.serviceName}/${table}/${code}/update`;
		return this.apiService.put(url, data);
	}
	update_(code: string, data: Object, table: string): Promise<Object> {
		const url = `${this.serviceName}/${table}/${code}`;
		return this.apiService.post(url, data);
	}
	createTrade(data: Object, table: string): Promise<Object> {
		const url = `${table}`;
		return this.apiService.post(url, data);
	}
	updateTrade(data: Object, table: string): Promise<Object> {
		const url = `${table}`;
		return this.apiService.post(url, data);
	}
	rerun(data: Object, table: string): Promise<Object> {
		const url = `${this.serviceName}/${table}`;
		return this.apiService.post(url, data);
	}

	post(data: Object, table: string): Promise<Object> {
		const url = `${table}`;
		return this.apiService.post(url, data);
	}

	put(data: Object, table: string): Promise<Object> {
		if (table.includes('order')) {
			const url = `${table}`;
			return this.apiService.put(url, data);
		} else {
			const url = `${this.serviceName}/${table}`;
			return this.apiService.put(url, data);
		}
	}
	postData(data: Object, table: string): Promise<Object> {
		if (table.includes('order')) {
			const url = `${table}`;
			return this.apiService.post(url, data);
		} else {
			const url = `${this.serviceName}/${table}`;
			return this.apiService.post(url, data);
		}
	}

	put_(data: Object, table: string): Promise<Object> {
		if (table.includes('order')) {
			const url = `${table}`;
			return this.apiService.post(url, data);
		} else {
			const url = `${this.serviceName}/${table}`;
			return this.apiService.post(url, data);
		}
	}

	delete(code: string, table: string): Promise<Object> {
		if (table.includes('order')) {
			const url = `${table}/${code}`;
			return this.apiService.delete(url);
		} else {
			const url = `${this.serviceName}/${table}/${code}`;
			return this.apiService.delete(url);
		}
	}
	delete_(code: string, table: string): Promise<Object> {
		if (table.includes('order')) {
			const url = `${table}/${code}`;
			return this.apiService.post(url);
		} else {
			const url = `${this.serviceName}/${table}/${code}`;
			return this.apiService.post(url);
		}
	}
	deleteroute_(code: string, table: string): Promise<Object> {
		if (table.includes('order')) {
			const url = `${table}/${code}/delete`;
			return this.apiService.post(url);
		} else {
			const url = `${this.serviceName}/${table}/${code}/delete`;
			return this.apiService.post(url);
		}
	}
	deleteTrade(code: string, table: string): Promise<Object> {
		const url = `${table}/${code}`;
		return this.apiService.post(url);
	}
	deleteCConfig(code: Object, table: string): Promise<Object> {
		if (table.includes('order')) {
			const url = `${table}`;
			return this.apiService.post_(url);
		} else {
			const url = `${this.serviceName}/${table}`;
			return this.apiService.post_(url, code);
		}
	}
	dcdelete(code: string, table: string): Promise<Object> {
		// if(table.includes('order')){
		const url = `${table}/${code}`;
		return this.apiService.post(url);

	}
	reconDelete(data: Object, table: string): Promise<Object> {
		const url = `${this.serviceName}/${table}/delete`;
		return this.apiService.post_(url, data);
	}
	holidayDelete(data: Object, table: string): Promise<Object> {
		const url = `${this.serviceName}/${this.holidayService}/${table}/delete`;
		return this.apiService.post_(url, data);
	}

	get(filter: Object, page: number = 1, size: number = 10, table: string): Promise<any> {
		const url = `${table}`;
		return this.apiService.getWithFilter(url, filter, page, size)
			.then(response => response as any);
	}

	deleteTD(filter: Object, page: number = 1, size: number = 10, table: string): Promise<any> {
		const url = `${table}`;
		return this.apiService.getWithFilterTD(url, filter, page, size)
			.then(response => response as any);
	}
	getEXecution(code: Object, table: string): Promise<any> {
		const url = `${table}/${code['basketId']}/${code['fundCode']}`;
		return this.apiService.getEXecutionWithFilter(url)
			.then(response => response as any);
	}
	// =====To be change
	reconSummaries(filter: Object, table: string): Promise<any> {
		const url = `${this.serviceName}/${table}`;
		return this.apiService.getSummaries(url, filter)
			.then(response => response as any);
	}
	checkUploadFile(file) {
		const re = /(\.(svg|img|png|jpg|jpeg))$/i;
		if (re.exec(file.name)) {
			return true;
		}
		return false;
	}
	getFormattedDate(date) {
		const formattedDate = new Date(date);
		return formattedDate.getFullYear() + ('0' + (formattedDate.getMonth() + 1)).slice(-2) + ('0' + formattedDate.getDate()).slice(-2);
	}
	getStartDate(inputDate: Date) {
		const adjustedDate = this.adjustZone(inputDate);
		return JSON.stringify(adjustedDate).replace(/"/g, '');
	}

	getEndDate(inputDate: Date) {
		inputDate.setSeconds(24 * 60 * 60 - 1);
		return JSON.stringify(inputDate).replace(/"/g, '');
	}

	getStartOfTheDay(inputDate: Date) {
		inputDate.setSeconds(0);
		inputDate.setMinutes(0);
		inputDate.setHours(0);
		inputDate.setMilliseconds(0);
		return inputDate;
	}

	adjustZone(inputDate: Date) {
		const timeZone = new Date(inputDate);
		let UTCseconds;
		if (timeZone.getTimezoneOffset() > 0) {
			UTCseconds = (timeZone.getTime() + timeZone.getTimezoneOffset() * 60 * 1000);
		} else {
			UTCseconds = (timeZone.getTime() - timeZone.getTimezoneOffset() * 60 * 1000);
		}
		return new Date(UTCseconds);
	}

	getNewsletterPDFURL(data) {
		return data['path'].split(data['path'].split('data/out/')[1])[0] + encodeURIComponent(data['path'].split('data/out/')[1]);
	}

	mrd_update(code: string, data: Object, table: string): Promise<Object> {
		const url = `${this.serviceName}/${table}/${code}/update`;
		return this.apiService.post(url, data);
	}

	mrd_delete(code: string, table: string): Promise<Object> {
		const url = `${this.serviceName}/${table}/${code}/delete`;
		return this.apiService.post(url);
	}
	mrd_deleteAsset(code: string, table: string): Promise<Object> {
		const url = `${this.serviceName}/${table}/${code}`;
		return this.apiService.post(url);
	}
}