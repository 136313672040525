export const TableSchema = {
	userGroups: {
		class: 'primary-p-table user-groups-head-padding',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(100vh - 370px)',
		fields: [
			{
				field: 'name',
				header: 'Group',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: true,
				width: '20%',
				editType: 'text'
			},
			{
				field: 'theme',
				header: 'Theme',
				filterType: 'dropdown',
				dataType: 'dropdown',
				inlineEdit: true,
				width: '15%'
			},
			{
				field: 'createdAt',
				header: 'Date Created',
				filterType: 'dateRange',
				dataType: 'dateRange',
				inlineEdit: false,
				width: '15%'
			},
			{
				field: 'actions',
				header: 'Actions',
				filterType: 'actions',
				inlineEdit: false,
				width: '',
				isDisableSort: true,
				edit: true,
				buttonType: 'button',
				update: true,
				cancel: true,
				delete: true
			}
		]
	},

	funds: {
		class: 'primary-p-table funds-head-padding',
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 300px)',
		fields: [
			{
				field: 'type',
				header: 'Fund',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '10%'
			},
			{
				field: '_id',
				header: 'Fund ID',
				filterType: 'text',
				dataType: 'link_id',
				inlineEdit: false,
				width: '10%'
			},
			{
				field: 'name',
				header: 'Fund Name',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '30%'
			},
			{
				field: 'tradarCode',
				header: 'Tradar Code',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '15%'
			},
			{
				field: 'ccy',
				header: 'Currency',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '10%'
			},
			{
				field: 'actions',
				header: 'Actions',
				filterType: 'actions',
				buttonType: 'displayIcons',
				edit: true,
				update: false,
				cancel: false,
				delete: false,
				view: false,
				isDisableSort: true
			}
		]
	},

	'transCostConfig': {
		class: 'primary-p-table',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(95vh - 370px)',
		fields: [
			{
				field: 'fundCode',
				header: 'FUND CODE',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '100px',
			},
			{
				field: 'assetGroupUdfDescription',
				header: 'ASSET GROUP',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '100px',
			},
			{
				field: 'exchangeDescription',
				header: 'EXCHANGE',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '100px',
			},
			{
				field: 'costFccyPerTrade',
				header: 'TRANSACTION COST',
				filterType: 'text',
				dataType: 'number',
				inlineEdit: false,
				width: '100px',
			},
			{
				field: 'actions',
				header: 'Actions',
				filterType: 'actions',
				buttonType: 'icon',
				edit: true,
				update: false,
				cancel: false,
				delete: true,
				isDisableSort: true,
				width: '100px'
			}
		]
	},

	holidayCalendar: {
		class: 'primary-p-table holiday-calendar-head-padding',
		paginator: true,
		scrollHeight: 'calc(100vh - 370px)',
		isResizableColumns: true,
		fields: [
			{
				field: 'date',
				header: 'Date',
				filterType: 'date',
				dataType: 'date',
				inlineEdit: false,
				width: '15%'
			},
			{
				field: 'description',
				header: 'Description',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '20%'
			},
			{
				field: 'countryName',
				header: 'Country',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '15%'
			},
			{
				field: 'actions',
				header: 'Actions',
				filterType: 'actions',
				buttonType: 'button',
				edit: true,
				update: false,
				cancel: false,
				delete: true,
				isDisableSort: true
			}
		]
	},

	roles: {
		class: 'primary-p-table roles-p-table',
		paginator: true,
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 370px)',
		fields: [
			{
				field: 'name',
				header: 'Name',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: true,
				width: '40%'
			},
			{
				field: 'actions',
				header: 'Actions',
				filterType: 'actions',
				buttonType: 'button',
				edit: true,
				update: true,
				cancel: true,
				delete: true,
				isDisableSort: true,
				inlineEdit: true
			}
		]
	},

	viewJobs: {
		class: 'no-col-lin',
		paginator: false,
		isResizableColumns: true,
		scrollHeight: '250px',
		fields: [
			{
				field: 'time',
				fieldType: 'string',
				header: 'Time',
				dataType: 'dateRange',
				inlineEdit: true,
				width: '20%',
				editType: 'text'
			},
			{
				field: 'status',
				fieldType: 'string',
				header: 'Status',
				dataType: 'text',
				inlineEdit: true,
				width: '20%'
			},
			{
				field: 'message',
				fieldType: 'string',
				header: 'Message',
				dataType: 'text',
				inlineEdit: false,
				width: '20%'
			}
		]
	},

	// Refference
	refference: {
		class: 'no-col-lin',
		paginator: false,
		fields: [
			{
				field: 'type',
				fieldType: 'string',
				header: 'Type',
				dataType: 'text',
				inlineEdit: true,
				width: '40%',
				editType: 'text',
				isDisableSort: true
			},
			{
				field: 'value',
				fieldType: 'Value',
				header: 'Status',
				dataType: 'text',
				inlineEdit: true,
				width: '40%',
				isDisableSort: true
			},
			{
				field: 'actions',
				header: '',
				type: 'actions',
				buttonType: 'icon',
				edit: false,
				update: false,
				cancel: false,
				delete: true,
				isDisableSort: true
			}
		]
	},

	// Fund-class
	'fund-class': {
		class: 'no-col-lin',
		paginator: false,
		fields: [
			{
				field: 'classIDName',
				fieldType: 'string',
				header: 'Class ID',
				dataType: 'text',
				inlineEdit: true,
				width: '40%',
				editType: 'text',
				isDisableSort: true
			},
			{
				field: 'ccy',
				fieldType: 'string',
				header: 'Currency',
				dataType: 'text',
				inlineEdit: true,
				width: '40%',
				isDisableSort: true
			},
			{
				field: 'actions',
				header: '',
				type: 'actions',
				buttonType: 'icon',
				edit: true,
				update: false,
				cancel: false,
				delete: false,
				isDisableSort: true
			}
		]
	},

	// Quant-Fee
	'quant-fee': {
		class: 'no-col-lin',
		paginator: false,
		fields: [
			{
				field: 'classIDName',
				fieldType: 'string',
				header: 'Class ID',
				dataType: 'text',
				inlineEdit: true,
				width: '40%',
				editType: 'text',
				isDisableSort: true
			},
			{
				field: 'ccy',
				fieldType: 'Currency',
				header: 'Status',
				dataType: 'text',
				inlineEdit: true,
				width: '40%',
				isDisableSort: true
			},
			{
				field: 'actions',
				header: '',
				type: 'actions',
				buttonType: 'icon',
				edit: true,
				update: false,
				cancel: false,
				delete: false,
				isDisableSort: true
			}
		]
	},

	'index-info': {
		class: 'no-col-lin',
		paginator: false,
		fields: [
			{
				field: 'name',
				fieldType: 'string',
				header: 'Index Name',
				dataType: 'text',
				inlineEdit: true,
				width: '20%',
				editType: 'text',
				isDisableSort: true
			},
			{
				field: 'ticker',
				fieldType: 'string',
				header: 'Ticker',
				dataType: 'text',
				inlineEdit: true,
				width: '10%',
				isDisableSort: true
			},
			{
				field: 'ccy',
				fieldType: 'string',
				header: 'Currency',
				dataType: 'text',
				inlineEdit: true,
				width: '20%',
				isDisableSort: true
			},
			{
				field: 'fromDate',
				fieldType: 'string',
				header: 'From Date',
				dataType: 'dateRange',
				inlineEdit: true,
				width: '20%',
				isDisableSort: true
			},
			{
				field: 'toDate',
				fieldType: 'string',
				header: 'To Date',
				dataType: 'dateRange',
				inlineEdit: true,
				width: '20%',
				isDisableSort: true
			},
			{
				field: 'actions',
				header: '',
				type: 'actions',
				buttonType: 'icon',
				edit: true,
				update: false,
				cancel: false,
				delete: false,
				isDisableSort: true
			}
		]
	},

	'bond-rates': {
		class: 'no-col-lin',
		paginator: false,
		fields: [
			{
				field: 'trustFeeBand',
				fieldType: 'string',
				header: 'Trust Fee Bands',
				dataType: 'text',
				inlineEdit: true,
				width: '40%',
				editType: 'text',
				isDisableSort: true
			},
			{
				field: 'rate',
				fieldType: 'string',
				header: 'Rate',
				dataType: 'text',
				inlineEdit: true,
				width: '40%',
				isDisableSort: true
			},
			{
				field: 'actions',
				header: '',
				type: 'actions',
				buttonType: 'icon',
				edit: false,
				update: false,
				cancel: false,
				delete: true,
				isDisableSort: true
			}
		]
	},

	jobs: {
		class: 'primary-p-table jobs-head-padding',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(100vh - 370px)',
		fields: [
			{
				field: 'params',
				header: 'JOB',
				type: 'object',
				objectKey: 'jobType',
				inlineEdit: false,
				width: '35%'
			},
			{
				field: 'status',
				header: 'STATUS',
				dataType: 'text',
				inlineEdit: false,
				width: '20%'
			},
			{
				field: 'createdAt',
				header: 'RUN DATE',
				dataType: 'dateRange',
				inlineEdit: false,
				width: '20%'
			},
			{
				field: 'actions',
				header: 'Actions',
				type: 'actions',
				buttonType: 'button',
				edit: false,
				update: false,
				cancel: true,
				delete: false,
				childObj: true,
				width: '25%',
				isDisableSort: true
			}
		]
	},

	emailConfig: {
		class: 'primary-table email-config',
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 500px)',
		fields: [
			{
				field: 'fundID',
				header: 'FUND NAME',
				dataType: 'list',
				width: '14%',
				inlineEdit: false,
				isDisableSort: true
			},
			{
				field: 'lang',
				header: 'LANGUAGES',
				dataType: 'array',
				width: '14%',
				isDisableSort: true
			},
			{
				field: 'toList',
				header: 'TO',
				dataType: 'text',
				width: '14%',
				isDisableSort: true
			},
			{
				field: 'ccList',
				header: 'CC',
				width: '14%',
				dataType: 'text',
				isDisableSort: true
			},
			{
				field: 'bccList',
				header: 'BCC',
				width: '14%',
				dataType: 'text',
				isDisableSort: true
			},
			{
				field: 'subject',
				header: 'SUBJECT',
				width: '10%',
				dataType: 'text',
				isDisableSort: true
			},
			{
				field: 'body',
				header: 'EMAIL BODY',
				dataType: 'html',
				width: '14%',
				isDisableSort: true
			},
			{
				field: 'actions',
				header: 'ACTIONS',
				dataType: 'actions',
				editIcon: true,
				edit: true,
				buttonType: 'icon',
				isDisableSort: true,
				width: '10%'
			}
		]
	},

	'acc-maintanance': {
		class: 'inline-editable-table-alg',
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 500px)',
		fields: [
			{
				field: 'codeDayBasis',
				header: 'Code',
				dataType: 'text',
				inlineEdit: false,
				width: '23%',
				isDisableSort: true
			},
			{
				field: 'name',
				header: 'Name',
				dataType: 'text',
				inlineEdit: false,
				width: '10%',
				isDisableSort: true
			},
			{
				field: 'ccy',
				header: 'Currency',
				dataType: 'text',
				inlineEdit: false,
				width: '10%',
				isDisableSort: true
			},
			{
				field: 'dayBasis',
				header: 'Day Basis',
				dataType: 'text',
				inlineEdit: false,
				width: '10%',
				isDisableSort: true
			},
			{
				field: 'interestRate',
				header: 'Interest Rate',
				dataType: 'text',
				inlineEdit: false,
				width: '10%',
				isDisableSort: true
			},
			{
				field: 'proportionateAmount',
				header: 'Proportionate Amount',
				dataType: 'text',
				inlineEdit: false,
				width: '15%',
				isDisableSort: true
			},
			{
				field: 'postingAcc',
				header: 'Interest Posting Account',
				dataType: 'text',
				inlineEdit: false,
				width: '15%',
				isDisableSort: true
			},
			{
				field: 'actions',
				header: '',
				buttonType: 'button',
				edit: true,
				update: false,
				cancel: false,
				delete: false,
				type: 'actions',
				isDisableSort: true
			}
		]
	},

	scheduler: {
		class: 'primary-p-table scheduler-head-padding',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(100vh - 370px)',
		fields: [
			{
				field: 'title',
				header: 'NAME',
				filterType: 'text',
				dataType: 'text',
				width: '30%'
			},
			{
				field: 'active',
				header: 'STATUS',
				filterType: 'text',
				dataType: 'text',
				width: '10%'
			},
			{
				field: 'cron',
				header: 'FREQUENCY',
				filterType: 'text',
				dataType: 'text',
				width: '10%'
			},
			{
				field: 'actions',
				header: 'ACTIONS',
				width: '50%',
				filterType: 'actions',
				buttonType: 'button',
				edit: true,
				update: false,
				isDisableSort: true
			}
		]
	},

	fairvalueadjustment: {
		class: 'no-col-lin',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(100vh - 500px)',
		fields: [
			{
				field: 'ticker',
				header: 'Ticker',
				dataType: 'text',
				width: '40%',
				isDisableSort: true
			},
			{
				field: 'priceOfficialClose',
				header: 'Fair Value',
				dataType: 'number',
				inlineEdit: true,
				isDisableSort: true
			},
			{
				field: 'marketStatus',
				header: 'Market Status',
				dataType: 'text',
				isDisableSort: true
			},
			{
				field: 'actions',
				header: '',
				buttonType: 'icon',
				edit: true,
				editIcon: true,
				update: true,
				cancel: false,
				delete: false,
				isDisableSort: true
			}
		]
	},

	fxRate: {
		class: 'primary-p-table fxRate-head-padding',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(100vh - 370px)',
		fields: [
			{
				header: 'Date',
				filterType: 'date',
				dataType: 'date',
				field: 'asOfDate',
				width: '12%'
			},
			{
				field: 'fromCurrency',
				header: 'FROM CURRENCY',
				filterType: 'dropdown',
				dataType: 'text',
				width: '15%'
			},
			{
				field: 'toCurrency',
				header: 'TO CURRENCY',
				filterType: 'dropdown',
				dataType: 'text',
				width: '12%'
			},
			{
				field: 'multiplier',
				header: 'FX RATE',
				dataType: 'text',
				filterType: 'text',
				width: '12%'
				// innerObj: 'object'
			},
			{
				field: 'translation',
				header: 'TRANSLATIONS',
				dataType: 'text',
				filterType: 'text',
				width: '52%'
			}
		]
	},

	opsFxRate: {
		class: 'primary-p-table fxRate-head-padding',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(100vh - 470px)',
		fields: [
			{
				field: 'from_date',
				header: 'Date',
				filterType: 'date',
				dataType: 'date',
				width: '12%'
			},
			{
				field: 'source',
				header: 'PARTY',
				filterType: 'dropdown',
				dataType: 'text',
				width: '15%'
			},
			{
				field: 'from_instrument',
				header: 'FROM CURRENCY',
				filterType: 'dropdown',
				dataType: 'text',
				width: '15%'
			},
			{
				field: 'to_instrument',
				header: 'TO CURRENCY',
				filterType: 'dropdown',
				dataType: 'text',
				width: '12%'
			},
			{
				field: 'value',
				header: 'FX RATE',
				dataType: 'text',
				filterType: 'text',
				width: '12%'
				// innerObj: 'object'
			},
			{
				field: 'translator',
				header: 'TRANSLATIONS',
				dataType: 'text',
				filterType: 'text',
				width: '23%'
			},
			{
				field: 'actions',
				header: 'Actions',
				filterType: 'actions',
				buttonType: 'displayIcons',
				pdccedit: true,
				width: '12%'
			},
		]
	},

	appFeeMaintenance: {
		class: 'inline-editable-table-alg',
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 500px)',
		fields: [
			{
				field: 'tradarCode',
				header: 'Fund Code',
				width: '20%',
				dataType: 'text',
				isDisableSort: true
			},
			{
				field: 'creationFee',
				header: 'Creation Fee',
				dataType: 'text',
				inlineEdit: true,
				width: '20%',
				isDisableSort: true
			},
			{
				field: 'redemptionFee',
				header: 'Redemption Fee',
				dataType: 'text',
				inlineEdit: true,
				width: '30%',
				isDisableSort: true
			},
			{
				field: 'dcBuffer',
				header: 'Buffer(%)',
				dataType: 'text',
				inlineEdit: true,
				width: '20%',
				isDisableSort: true
			},
			{
				field: 'actions',
				header: '',
				dataType: 'actions',
				buttonType: 'icon',
				edit: true,
				editIcon: true,
				update: true,
				cancel: true,
				delete: false,
				isDisableSort: true
			}
		]
	},

	peerfunds: {
		class: 'peer-table-header peer-table',
		scrollHeight: 'calc(100vh - 450px)',
		fields: [
			{
				field: 'ticker',
				header: 'FUND TICKER',
				dataType: 'text',
				width: '30%',
				isDisableSort: true
			},
			{
				field: 'name',
				header: 'NAME',
				dataType: 'text',
				width: '30%',
				isDisableSort: true
			},
			{
				field: 'ccy',
				header: 'BASE CURRENCY',
				dataType: 'text',
				width: '30%',
				isDisableSort: true
			},
			{
				field: 'inceptionDate',
				header: 'INCEPTION DATE',
				dataType: 'date',
				width: '30%',
				isDisableSort: true
			},
			{
				field: 'actions',
				header: 'ACTIONS',
				width: '50%',
				dataType: 'actions',
				buttonType: 'icon',
				edit: true,
				update: false,
				cancel: false,
				delete: true,
				isDisableSort: true
			}
		]
	},

	exportedFilesLog: {
		class: 'inline-editable-table-alg',
		paginator: true,
		scrollHeight: '200px',
		fields: [
			{
				field: 'updatedAt',
				header: 'TIMESTAMP',
				width: '20%',
				dataType: 'timestamp',
				isDisableSort: true
			},
			{
				field: 'fileName',
				header: 'EXPORTED FILE',
				inlineEdit: true,
				dataType: 'text',
				width: '23%',
				isDisableSort: true
			},
			{
				field: 'fileType',
				header: 'FILE TYPE',
				inlineEdit: true,
				dataType: 'text',
				width: '15%',
				isDisableSort: true
			},
			{
				field: 'tradeType',
				header: 'TRADE TYPE',
				inlineEdit: true,
				width: '20%',
				dataType: 'text',
				isDisableSort: true
			},
			{
				field: 'actions',
				header: '',
				buttonType: 'button',
				dataType: 'actions',
				delete: true,
				download: true,
				isDisableSort: true
			}
		]
	},

	portfoliocodes: {
		class: 'primary-p-table',
		paginator: true,
		scrollHeight: 'calc(100vh - 370px)',
		fields: [
			{
				field: 'portfolioCode',
				header: 'PORTFOLIO CODES',
				fieldType: 'Value',
				dataType: 'text',
				inlineEdit: true,
				width: '15%',
				editType: 'text'
			},
			{
				field: 'fundNotation',
				header: 'FUND NOTATION',
				fieldType: 'Value',
				dataType: 'text',
				inlineEdit: true,
				width: '15%'
			},
			{
				field: 'actions',
				header: 'ACTIONS',
				type: 'actions',
				width: '70%',
				buttonType: 'button',
				edit: true,
				update: true,
				cancel: true,
				delete: true,
				isDisableSort: true
			}
		]
	},

	caseApproval: {
		class: 'cc-header-alg innertable-margin',
		isResizableColumns: false,
		paginator: false,
		scrollHeight: 'calc(100vh - 370px)',
		fields: [
			{
				field: 'remark',
				header: 'Remarks',
				dataType: 'text',
				inlineEdit: false,
				width: '30%',
				editType: 'text'
			},
			{
				field: 'date',
				header: 'Date',
				dataType: 'dateRange',
				inlineEdit: true,
				width: '10%'
			},
			{
				field: 'updatedBy',
				header: 'User',
				dataType: 'text',
				inlineEdit: false,
				width: '15%'
			}
		]
	},

	prices: {
		class: 'primary-p-table prices-head-padding',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(100vh - 370px)',
		fields: [
			{
				dataType: 'date',
				filterType: 'date',
				inlineEdit: false,
				width: '10%',
				field: 'asOfDate',
				header: 'DATE'
			},
			{
				field: 'instrumentTicker',
				header: 'INSTRUMENT TICKER',
				dataType: 'text',
				filterType: 'text',
				inlineEdit: false,
				width: '15%'
			},
			{
				filterType: 'dropdown',
				dataType: 'text',
				field: 'currency',
				header: 'CURRENCY',
				inlineEdit: false,
				width: '15%'
			},
			{
				field: 'PX_bidPrice',
				header: 'BID PRICE',
				dataType: 'text',
				filterType: 'text',
				inlineEdit: false,
				width: '12%'
			},
			{
				field: 'PX_askPrice',
				header: 'ASK PRICE',
				dataType: 'text',
				filterType: 'text',
				inlineEdit: false,
				width: '13%'
			},
			{
				field: 'PX_midPrice',
				header: 'MID PRICE',
				dataType: 'text',
				filterType: 'text',
				inlineEdit: false,
				width: '50%'
			}
		]
	},

	'support-docs': {
		class: 'cc-header-alg',
		isResizableColumns: false,
		paginator: false,
		scrollHeight: 'calc(100vh - 370px)',
		fields: [
			{
				field: 'name',
				header: 'DOCUMENTS',
				dataType: 'documentTemplate',
				width: '25%',
				display: true,
				labelIcon: 'assets/images/file.png',
				isDisableSort: true
			},
			{
				field: 'format',
				header: 'TYPE',
				dataType: 'custom-dropdown',
				display: false,
				width: '15%',
				isDisableSort: true
			},
			{
				field: 'certified',
				header: 'CERTIFIED',
				dataType: 'customCheckbox',
				display: false,
				width: '10%',
				isDisableSort: true
			},
			{
				field: 'certified',
				header: 'SCAN',
				dataType: 'scan',
				display: false,
				width: '6%',
				isDisableSort: true
			},
			{
				field: 'Document',
				header: 'DELETE',
				dataType: 'delete',
				display: false,
				width: '6%',
				isDisableSort: true
			}
		]
	},

	'support-audio': {
		class: 'cc-header-alg',
		isResizableColumns: false,
		paginator: false,
		scrollHeight: 'calc(100vh - 370px)',
		fields: [
			{
				field: 'name',
				header: 'DOCUMENTS',
				dataType: 'documentTemplate',
				display: true,
				width: '20%',
				labelIcon: 'assets/images/audio.png',
				class: 'iconAlign',
				isDisableSort: true
			},
			{
				field: 'play',
				header: 'ACTION',
				dataType: 'audio',
				display: true,
				width: '10%',
				isDisableSort: true
			},
			{
				field: 'Audio',
				header: 'DELETE',
				dataType: 'delete',
				display: false,
				width: '15%',
				isDisableSort: true
			}
		]
	},

	'file-upload-logs': {
		class: 'primary-p-table funds-head-padding',
		paginator: true,
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 370px)',
		fields: [
			{
				field: 'fileName',
				header: 'FILE NAME',
				filterType: 'text',
				dataType: 'text',
				width: '25%'
			},
			{
				field: 'uploadStatus',
				header: 'STATUS',
				filterType: 'text',
				dataType: 'status',
				inlineEdit: true,
				width: '10%'
			},
			{
				field: 'uploadedBy',
				header: 'UPLOADED BY',
				dataType: 'text',
				filterType: 'text',
				inlineEdit: true,
				width: '12%'
			},
			{
				field: 'updatedAt',
				header: 'UPLOADED AT',
				dataType: 'date',
				filterType: 'date',
				inlineEdit: true,
				width: '12%'
			},
			{
				field: 'error',
				header: 'REASON',
				dataType: 'text',
				filterType: 'text',
				inlineDropdown: true,
				inlineEdit: true,
				width: '22%'
			},
			{
				field: 'actions',
				header: 'Actions',
				filterType: 'actions',
				buttonType: 'button',
				delete: true,
				isDisableSort: true
			}
		]
	},

	'audit-list': {
		class: 'p-table-secondary',
		isResizableColumns: false,
		paginator: false,
		scrollHeight: '200px',
		fields: [
			{
				field: 'user',
				header: 'USER',
				dataType: 'text',
				width: '20%',
				isDisableSort: true
			},
			{
				field: 'action',
				header: 'ACTIVITY TYPE',
				dataType: 'text',
				width: '20%',
				isDisableSort: true
			},
			{
				field: 'updatedAt',
				header: 'DATE',
				dataType: 'dateRange',
				width: '20%',
				isDisableSort: true
			}
		]
	},

	'audit-full-history': {
		class: 'no-col-lin',
		isResizableColumns: false,
		paginator: false,
		scrollHeight: '200px',
		fields: [
			{
				field: 'propertyName',
				header: 'Property Name',
				dataType: 'text',
				width: '20%',
				isDisableSort: true
			},
			{
				field: 'prevValue',
				header: 'Old Value',
				width: '20%',
				dataType: 'html',
				isDisableSort: true
			},
			{
				field: 'updatedVal',
				header: 'New Value',
				dataType: 'html',
				width: '20%',
				isDisableSort: true
			}
		]
	},
	'flex-system': {
		class: 'primary-p-table funds-head-padding',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(100vh - 370px)',
		fields: [
			{
				field: 'name',
				header: 'FILE NAME',
				filterType: 'text',
				dataType: 'link',
				inlineEdit: false,
				width: '15%',
				filterMatchMode: 'contains'
			},
			{
				field: 'uploadDate',
				header: 'DATE',
				filterType: 'dateRange',
				dataType: 'dateRange',
				inlineEdit: false,
				width: '10%',
				filterMatchMode: 'contains'
			},
			{
				field: 'userName',
				header: 'USER',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '15%',
				filterMatchMode: 'contains'
			},
			{
				field: 'monthAndYear',
				header: 'MONTH & YEAR',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '15%',
				filterMatchMode: 'contains'
			}
		]
	},
	'flex-report-information': {
		class: 'primary-p-table funds-head-padding',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(100vh - 370px)',
		fields: [
			{
				field: 'voucherDate',
				header: 'Voucher Date',
				type: 'date',
				inlineEdit: false,
				width: '250px'
			},
			{
				field: 'voucherNumber',
				header: 'Voucher Number',
				dataType: 'text',
				inlineEdit: false,
				width: '250px'
			},
			{
				field: 'accountCode',
				header: 'Account Code',
				dataType: 'text',
				inlineEdit: false,
				width: '250px'
			},
			{
				field: 'particular1',
				header: 'Particular 1',
				dataType: 'text',
				inlineEdit: false,
				width: '250px'
			},
			{
				field: 'particular2',
				header: 'Particular 2',
				dataType: 'text',
				inlineEdit: false,
				width: '250px'
			},
			{
				field: 'ccy',
				header: 'Currency Code',
				dataType: 'text',
				inlineEdit: false,
				width: '250px'
			},
			{
				field: 'amount',
				header: 'Original Amount',
				dataType: 'text',
				inlineEdit: false,
				width: '250px'
			},
			{
				field: 'transactionType',
				header: 'Debit/Credit',
				dataType: 'text',
				inlineEdit: false,
				width: '250px'
			},
			{
				field: 'analysisCode4',
				header: 'Analysis code 4',
				dataType: 'text',
				inlineEdit: false,
				width: '250px'
			},
			{
				field: 'analysisCode5',
				header: 'Analysis Code 5',
				dataType: 'text',
				inlineEdit: false,
				width: '250px'
			},
		]
	},
	// ops fund
	'ops-funds': {
		class: 'primary-p-table',
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 390px)',
		// paginator: true,
		fields: [
			{
				field: 'tradar_code',
				header: 'FUND CODE',
				filterType: 'colFilter',
				dataType: 'expandable',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'share_class',
				header: 'SHARE CLASS',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'ccy',
				header: 'CCY',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'nav_diff',
				header: 'NAV DIFF',
				filterType: 'colFilter',
				dataType: 'roundValNeg',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'units_diff',
				header: 'UNITS DIFF',
				filterType: 'colFilter',
				dataType: 'hasNegativeValUnits',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'nav/share_diff',
				header: 'NAV/SHARE DIFF',
				filterType: 'colFilter',
				dataType: 'originalValNeg',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'gav_trading_diff',
				header: 'GAV TRADING DIFF',
				filterType: 'colFilter',
				dataType: 'roundValNeg',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'gav_non_trading_diff',
				header: 'GAV NON TRADING DIFF',
				filterType: 'colFilter',
				dataType: 'roundValNeg',
				inlineEdit: false,
				width: '240px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'nav_accrual_diff',
				header: 'NAV ACCRUALS DIFF',
				filterType: 'colFilter',
				dataType: 'roundValNeg',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'unmatched_gav_trading_diff',
				header: 'UNMATCHED GAV TRADING',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '260px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'unmatched_gav_non_trading_diff',
				header: 'UNMATCHED GAV NON TRADING',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '320px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'unmatched_nav_accrual_diff',
				header: 'UNMATCHED NAV ACCRUAL',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '260px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'recon_status',
				header: 'RECONCILE STATUS',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
			},
			{
				field: 'sign_off_status',
				header: 'SIGN OFF STATUS',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
			},
			{
				field: "sign_off_comments",
				header: "Sign-off Comments",
				inlineEdit: false,
				width: "220px",
				editType: "text",
				isDisableSort: true,
				dataType: 'signOff',
				alignRight: false,
			}
		]
	},
	'gav-trading': {
		class: 'primary-p-table',
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 470px)',
		// paginator: true,
		isCheckbox: true,
		fields: [
			{
				field: 'tradar_code',
				header: 'FUND CODE',
				filterType: 'colFilter',
				dataType: 'expandable',
				inlineEdit: false,
				width: '200px',
				color: '#366ccc',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'account_id',
				header: 'ACCOUNT ID',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '200px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'tradar_sec_type',
				header: 'SECURITY TYPE',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '200px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'security_type',
				header: 'SECURITY TYPE CATEGORY',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'tradar_fund_ccy',
				header: 'CCY',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '200px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'isin',
				header: 'ISIN',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '200px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'ticker',
				header: 'TICKER',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '200px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'tradar_description',
				header: 'SEC DESCRIPTION',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '350px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'quantity_diff',
				header: 'QUANTITY DIFF',
				filterType: 'colFilter',
				dataType: 'hasNegRed',
				inlineEdit: false,
				width: '200px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'price_in_local_ccy_diff',
				header: 'PRICE DIFF',
				filterType: 'colFilter',
				dataType: 'originalValNeg',
				inlineEdit: false,
				width: '200px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'local_value',
				header: 'LOCAL VALUE DIFF',
				filterType: 'colFilter',
				dataType: 'roundValNeg',
				inlineEdit: false,
				width: '200px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'fund_value',
				header: 'FUND VALUE DIFF',
				filterType: 'colFilter',
				dataType: 'roundValNeg',
				inlineEdit: false,
				width: '200px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'accr_lc',
				header: 'ACCRUED',
				filterType: 'colFilter',
				dataType: 'roundValNeg',
				inlineEdit: false,
				width: '200px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'accr_fc',
				header: 'ACCRUED FC',
				filterType: 'colFilter',
				dataType: 'roundValNeg',
				inlineEdit: false,
				width: '200px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'match_status',
				header: 'MATCH STATUS',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '200px',
				alignRight: false,
			},
		]
	},
	'gav-non-trading': {
		class: 'primary-p-table',
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 470px)',
		// paginator: true,
		isCheckbox: true,
		fields: [
			{
				field: 'tradar_code',
				header: 'FUND CODE',
				filterType: 'colFilter',
				dataType: 'expandable',
				inlineEdit: false,
				width: '220px',
				color: '#366ccc',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'tradar_description',
				header: 'DESCRIPTION',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '400px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'category',
				header: 'CATEGORY',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'cash_class',
				header: 'CASH CLASS',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'account_id',
				header: 'ACCOUNT ID',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'tradar_fund_ccy',
				header: 'CCY',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'local_value_variance',
				header: 'LOCAL VALUE DIFF',
				filterType: 'colFilter',
				dataType: 'roundValNeg',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'fund_value_variance',
				header: 'FUND VALUE DIFF',
				filterType: 'colFilter',
				dataType: 'roundValNeg',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'match_status',
				header: 'MATCH STATUS',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
			},
		]
	},
	'nav-accrual': {
		class: 'primary-p-table',
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 470px)',
		// paginator: true,
		isCheckbox: true,
		fields: [
			{
				field: 'tradar_code',
				header: 'FUND CODE',
				filterType: 'colFilter',
				dataType: 'expandable',
				inlineEdit: false,
				width: '220px',
				color: '#366ccc',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'tradar_description',
				header: 'DESCRIPTION',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '400px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'category',
				header: 'CATEGORY',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'cash_class',
				header: 'CASH CLASS',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'account_id',
				header: 'ACCOUNT ID',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'tradar_fund_ccy',
				header: 'CCY',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'local_value_variance',
				header: 'LOCAL VALUE DIFF',
				filterType: 'colFilter',
				dataType: 'roundValNeg',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'fund_value_variance',
				header: 'FUND VALUE DIFF',
				filterType: 'colFilter',
				dataType: 'roundValNeg',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'match_status',
				header: 'MATCH STATUS',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
			},
		]
	},
	'opsfund-innertable': {
		class: 'primary-p-table',
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 300px)',
		fields: [
			{
				field: 'category',
				header: '',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '200px'
			},
			{
				field: 'fa',
				header: 'FA',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '200px'
			},
			{
				field: 'pma',
				header: 'PMA',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '250px'
			},
			{
				field: 'variance',
				header: 'VARIANCE',
				filterType: 'text',
				dataType: 'hasNegativeVal',
				inlineEdit: false,
			},
		]
	},
	'gavnontrading_innertable1': {
		class: 'primary-p-table',
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 300px)',
		totals: 'fa',
		fields: [
			{
				field: 'field1',
				header: 'GL CODE',
				filterType: 'text',
				dataType: 'string',
				inlineEdit: false,
				width: '140px'
			},
			{
				field: 'field2',
				header: 'GL Description',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '180px'
			},
			{
				field: 'local_value',
				header: 'LOCAL VALUE',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '160px'
			},
			{
				field: 'fund_value',
				header: 'FUND VALUE',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '160px',
			},
		]
	},
	'secDescInnertable1': {
		class: 'primary-p-table',
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 300px)',
		totals: 'fa',
		fields: [
			{
				field: 'field1',
				header: 'SEC DESCRIPTION',
				filterType: 'text',
				dataType: 'string',
				inlineEdit: false,
				width: '200px'
			},
			{
				field: 'field2',
				header: 'GL Description',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '180px'
			},
			{
				field: 'local_value',
				header: 'LOCAL VALUE',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '160px'
			},
			{
				field: 'fund_value',
				header: 'FUND VALUE',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '160px',
			},
		]
	},
	'gavnontrading_innertable2': {
		class: 'primary-p-table',
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 300px)',
		totals: 'pma',
		fields: [
			{
				field: 'field1',
				header: 'CASH CLASS',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '160px',
				hasBorder: 'borderLeft',
			},
			{
				field: 'field2',
				header: 'ACCOUNT ID',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '160px'
			},
			{
				field: 'local_value',
				header: 'LOCAL VALUE',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '200px'
			},
			{
				field: 'fund_value',
				header: 'FUND VALUE',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '200px',
				hasBorder: 'borderRight'
			},
		]
	},
	'gavnontrading_innertable3': {
		class: 'primary-p-table',
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 300px)',
		totals: 'variance',
		fields: [
			{
				field: 'local_value_variance',
				header: 'LOCAL VALUE VARIANCE',
				filterType: 'text',
				dataType: 'hasNegativeVal',
				inlineEdit: false,
				width: '300px'
			},
			{
				field: 'fund_value_variance',
				header: 'FUND VALUE VARIANCE',
				filterType: 'text',
				dataType: 'hasNegativeVal',
				inlineEdit: false,
			},
		],
	},
	'navaccrual_innertable1': {
		class: 'primary-p-table',
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 300px)',
		totals: 'nav_fa',
		fields: [
			{
				field: 'field1',
				header: 'SEC DESCRIPTION',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '160px'
			},
			{
				field: 'field2',
				header: 'GL Description',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '180px'
			},
			{
				field: 'local_value',
				header: 'LOCAL VALUE',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '160px'
			},
			{
				field: 'fund_value',
				header: 'FUND VALUE',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '160px',
			},
		]
	},
	'navaccrual_innertable2': {
		class: 'primary-p-table',
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 300px)',
		totals: 'nav_pma',
		fields: [
			{
				field: 'field1',
				header: 'CASH CLASS',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '160px',
				hasBorder: 'borderLeft',
			},
			{
				field: 'field2',
				header: 'ACCOUNT ID',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '160px'
			},
			{
				field: 'local_value',
				header: 'LOCAL VALUE',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '200px'
			},
			{
				field: 'fund_value',
				header: 'FUND VALUE',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '200px',
				hasBorder: 'borderRight'
			},
		]
	},
	'navaccrual_innertable3': {
		class: 'primary-p-table',
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 300px)',
		totals: 'nav_variance',
		fields: [
			{
				field: 'local_value_variance',
				header: 'LOCAL VALUE VARIANCE',
				filterType: 'text',
				dataType: 'hasNegativeVal',
				inlineEdit: false,
				width: '300px'
			},
			{
				field: 'fund_value_variance',
				header: 'FUND VALUE VARIANCE',
				filterType: 'text',
				dataType: 'hasNegativeVal',
				inlineEdit: false,
			},
		],
	},
	// recon-configuration
	'recon-hsbc': {
		class: 'primary-p-table',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(95vh - 370px)',
		fields: [
			{
				field: 'recon_level',
				header: 'RECON CATEGORY',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '100px',
			},
			{
				field: 'recon_field',
				header: 'RECON NAME',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '140px'
			},
			{
				field: 'is_active',
				header: 'MATCH CRITERIA',
				filterType: 'dropdown',
				isDisableSort: true,
				dataType: 'boolean',
				inlineEdit: false,
				width: '80px',
			},
			{
				field: 'tol_value',
				header: 'TOLERANCE',
				filterType: 'text',
				isDisableSort: true,
				dataType: 'text',
				inlineEdit: false,
				width: '80px',
			},
		]
	},
	'recon-hsbc1': {
		class: 'primary-p-table',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(95vh - 370px)',
		fields: [
			{
				field: 'recon_level',
				header: 'RECON CATEGORY',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '100px',
			},
			{
				field: 'recon_field',
				header: 'RECON NAME',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '150px'
			},
			{
				field: 'is_active',
				header: 'MATCH CRITERIA',
				isDisableSort: true,
				filterType: 'dropdown',
				dataType: 'boolean',
				inlineEdit: false,
				width: '80px',
			},
			{
				field: 'tol_value',
				header: 'TOLERANCE',
				filterType: 'text',
				isDisableSort: true,
				dataType: 'text',
				inlineEdit: false,
				width: '80px'
			},
			{
				field: 'actions',
				header: 'Actions',
				filterType: 'actions',
				buttonType: 'displayIcons',
				edit: true,
				update: false,
				cancel: false,
				delete: true,
				isDisableSort: true,
				width: '100px'
			}
		]
	},
	// ops-account-mapping
	'listedSecurityType': {
		class: 'primary-p-table',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(95vh - 370px)',
		fields: [
			{
				field: 'code',
				header: 'LISTED SECURITY TYPE',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '50px',
			},
			{
				field: 'actions',
				header: 'Actions',
				filterType: 'actions',
				buttonType: 'icon',
				edit: false,
				update: false,
				cancel: false,
				delete: true,
				isDisableSort: true,
				width: '100px'
			}
		]
	},
	'level&category': {
		class: 'primary-p-table',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(95vh - 430px)',
		fields: [
			{
				field: 'recon_level',
				header: 'LEVEL',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '200px',
			},
			{
				field: 'category',
				header: 'CATEGORY',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '230px'
			},
			{
				field: 'actions',
				header: 'Actions',
				filterType: 'actions',
				buttonType: 'icon',
				edit: true,
				update: false,
				cancel: false,
				delete: true,
				isDisableSort: true,
			}
		]
	},
	'account-mapping': {
		class: 'primary-p-table',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(95vh - 430px)',
		fields: [
			{
				field: 'recon_category',
				header: 'LEVEL & CATEGORY',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '150px'
			},
			{
				field: 'cash_class',
				header: 'CASH CLASS',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '80px',
				filterMatchMode: "contains"
			},
			{
				field: 'account_id',
				header: 'ACCOUNT ID',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '100px',
				filterMatchMode: "contains"

			}
		]
	},
	'hsbc-account-mapping': {
		class: 'primary-p-table',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(95vh - 430px)',
		fields: [
			{
				field: 'recon_category',
				header: 'LEVEL & CATEGORY',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '150px'
			},
			{
				field: 'cash_class',
				header: 'CASH CLASS',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '80px',
				filterMatchMode: "contains"

			},
			{
				field: 'account_id',
				header: 'ACCOUNT ID',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '100px',
				filterMatchMode: "contains"

			},
			{
				field: 'mapping1',
				header: 'G/L CODE',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '100px',
				filterMatchMode: "contains"

			},
		]
	},
	'citi-account-mapping': {
		class: 'primary-p-table',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(95vh - 430px)',
		fields: [
			{
				field: 'recon_category',
				header: 'LEVEL & CATEGORY',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '150px'
			},
			{
				field: 'cash_class',
				header: 'CASH CLASS',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '80px',
				filterMatchMode: "contains"

			},
			{
				field: 'account_id',
				header: 'ACCOUNT ID',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '100px',
				filterMatchMode: "contains"

			},
			{
				field: 'mapping1',
				header: 'SEC DESCRIPTION',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '100px',
				filterMatchMode: "contains"

			},
		]
	},
	'ops-nav2-fund': {
		class: 'primary-p-table',
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 390px)',
		fields: [
			{
				field: 'tradar_code',
				header: 'FUND CODE',
				filterType: 'colFilter',
				dataType: 'expandable',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'share_class',
				header: 'SHARE CLASS',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'ccy',
				header: 'CCY',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'units_diff',
				header: 'UNITS DIFF',
				filterType: 'colFilter',
				dataType: 'hasNegativeValUnits',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'units_diff_in_baskets',
				header: 'UNITS DIFF IN BASKETS',
				filterType: 'colFilter',
				dataType: 'hasNegativeValUnits',
				inlineEdit: false,
				width: '240px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'nav/share_diff',
				header: 'NAV/SHARE DIFF',
				filterType: 'colFilter',
				dataType: 'originalValNeg',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'nav_share_diff_bps',
				header: 'NAV/SHARE DIFF (BPS)',
				filterType: 'colFilter',
				dataType: 'originalValNeg',
				inlineEdit: false,
				width: '240px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'nav_diff',
				header: 'NAV DIFF',
				filterType: 'colFilter',
				dataType: 'roundValNeg',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'normalized_nav_diff',
				header: 'NORMALIZED NAV DIFF',
				filterType: 'colFilter',
				dataType: 'roundValNeg',
				inlineEdit: false,
				width: '240px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'exception_status',
				header: 'EXCEPTION STATUS',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '240px',
				alignRight: false,
			},
			{
				field: 'sign_off_status',
				header: 'SIGN OFF STATUS',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '260px',
				alignRight: false,
			},
			{
				field: "sign_off_comments",
				header: "Sign-off Comments",
				inlineEdit: false,
				width: "220px",
				editType: "text",
				isDisableSort: true,
				dataType: 'signOff',
				alignRight: false,
			}
		]
	},
	'nav2-fund-innertable': {
		class: 'primary-p-table',
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 300px)',
		fields: [
			{
				field: 'category',
				header: '',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '200px'
			},
			{
				field: 'nav2',
				header: 'NAV2',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '200px'
			},
			{
				field: 'nav1',
				header: 'NAV1',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '250px'
			},
			{
				field: 'variance',
				header: 'VARIANCE',
				filterType: 'text',
				dataType: 'hasNegativeVal',
				inlineEdit: false,
			},
		]
	},
	'nav2-composition': {
		class: 'primary-p-table',
		isResizableColumns: true,
		isCheckbox: true,
		scrollHeight: 'calc(100vh - 470px)',
		fields: [
			{
				field: 'tradar_code',
				header: 'FUND CODE',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'position',
				header: 'POSITION CLASS',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'tradar_sec_type',
				header: 'SECURITY TYPE',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'cash_class',
				header: 'CASH CLASS',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'tradar_description',
				header: 'DESCRIPTION',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'ticker',
				header: 'TICKER',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'nav1_price_in_local_ccy',
				header: 'NAV1 PRICE',
				filterType: 'colFilter',
				dataType: 'originalValNeg',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'nav2_price_in_local_ccy',
				header: 'NAV2 PRICE',
				filterType: 'colFilter',
				dataType: 'originalValNeg',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'price_in_local_ccy_diff',
				header: 'PRICE DIFF',
				filterType: 'colFilter',
				dataType: 'originalValNeg',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'nav1_fx_rate',
				header: 'NAV1 FX',
				filterType: 'colFilter',
				dataType: 'hasNegRed',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'nav2_fx_rate',
				header: 'NAV2 FX',
				filterType: 'colFilter',
				dataType: 'hasNegRed',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'fx_rate_diff',
				header: 'FX DIFF',
				filterType: 'colFilter',
				dataType: 'hasNegRed',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'quantity_diff',
				header: 'AMOUNT DIFF',
				filterType: 'colFilter',
				dataType: 'hasNegRed',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'accrued_fc_diff',
				header: 'ACCRUED FC DIFF',
				filterType: 'colFilter',
				dataType: 'roundValNeg',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'value_fc_diff',
				header: 'VALUE FC DIFF',
				filterType: 'colFilter',
				dataType: 'hasNegRed',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'holding_ind',
				header: 'HOLDING IND',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				color: 'negative-value',
				colFilterType: 'text',
			},
			{
				field: 'match_status',
				header: 'MATCH STATUS',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
			},
		]
	},
	'oms-trading-position': {
		class: 'primary-p-table',
		isResizableColumns: true,
		isCheckbox: true,
		scrollHeight: 'calc(100vh - 470px)',
		fields: [
			{
				field: 'tradar_code',
				header: 'FUND CODE',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'tradar_sec_type',
				header: 'SECURITY TYPE',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'tradar_description',
				header: 'DESCRIPTION',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'ticker',
				header: 'TICKER',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'oms_sec_id',
				header: 'OMS SEC ID',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'nav1_oms_position',
				header: 'OMS POS',
				filterType: 'colFilter',
				dataType: 'hasNegRed',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'nav2_oms_position',
				header: 'NAV2 POS',
				filterType: 'colFilter',
				dataType: 'hasNegRed',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'oms_position_diff',
				header: 'POS DIFF',
				filterType: 'colFilter',
				dataType: 'hasNegRed',
				inlineEdit: false,
				width: '220px',
				alignRight: true,
				colFilterType: 'text',
			},
			{
				field: 'oms_position_diff_status',
				header: 'MATCH STATUS',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
			},
		]
	},
	'custodian-code': {
		class: 'primary-p-table',
		isResizableColumns: true,
		isCheckbox: true,
		scrollHeight: 'calc(100vh - 470px)',
		fields: [
			{
				field: 'tradar_code',
				header: 'FUND CODE',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'tradar_sec_type',
				header: 'SECURITY TYPE',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'tradar_description',
				header: 'DESCRIPTION',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'ticker',
				header: 'TICKER',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'asset_group_udf',
				header: 'ASSET GROUP',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'country',
				header: 'COUNTRY',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'exch_id',
				header: 'EXCHANGE ID',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'nav2_account_id',
				header: 'NAV2 ACCOUNT ID',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'nav1_account_id',
				header: 'EXPECTED ACCOUNT ID',
				filterType: 'colFilter',
				dataType: 'text',
				inlineEdit: false,
				width: '240px',
				alignRight: false,
				colFilterType: 'text',
			},
			{
				field: 'account_id_diff_status',
				header: 'MATCH STATUS',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '220px',
				alignRight: false,
			},
		]
	},
	'excluded_security_type': {
		class: 'primary-p-table',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(95vh - 370px)',
		fields: [
			{
				field: 'code',
				header: 'EXCLUDED SECURITY TYPE',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '50px',
			},
			{
				field: 'actions',
				header: 'Actions',
				filterType: 'actions',
				buttonType: 'icon',
				edit: false,
				update: false,
				cancel: false,
				delete: true,
				isDisableSort: true,
				width: '100px'
			}
		]
	},

	'dc-config': {
		class: "primary-p-table",
		isResizableColumns: true,
		paginator: true,
		scrollHeight: "calc(106vh - 460px)",
		frozenWidth: "770px",
		isFrozenColumns: [
			{
				field: 'actions',
				header: '',
				buttonType: 'displayIcons',
				dcEdit: true,
				update: false,
				cancel: false,
				dcDelete: true,
				width: '80px',
				bgcolor: '#bbbdbf',
				fontcolor: '#383839'
			},
			{
				field: 'tradar_code',
				header: 'FUND CODE',
				filterType: 'dropdown',
				dataType: 'text',
				width: '140px',
				position: 'fixed',
				bgcolor: '#bbbdbf',
				fontcolor: '#383839'
			},
			{
				field: 'share_class_name',
				header: 'SHARE CLASS NAME',
				filterType: 'dropdown',
				dataType: 'text',
				width: '250px',
				bgcolor: '#bbbdbf',
				fontcolor: '#383839'
			},
			{
				field: 'cr_ccy',
				header: 'CR CCY',
				dataType: 'text',
				width: '100px',
				bgcolor: '#bbbdbf',
				fontcolor: '#383839'
			},
			{
				field: 'dc_calc_type_desc',
				header: 'DC CALC TYPE',
				filterType: 'dropdown',
				dataType: 'text',
				width: '200px',
				bgcolor: '#bbbdbf',
				fontcolor: '#383839'
			},
		],
		fields: [
			{
				field: 'class_type',
				header: 'SHARE CLASS TYPE',
				filterType: 'space',
				dataType: 'text',
				width: '190px'
			},
			{
				field: 'class_ccy',
				header: 'SHARE CLASS CCY',
				filterType: 'space',
				dataType: 'text',
				width: '180px',
			},
			{
				field: 'underlier_stock_fill_date',
				header: 'UNDERLIER STOCK FILL END DATE',
				filterType: 'space',
				dataType: 'text',
				width: '310px',
			},
			{
				field: 'exec_fx_day_desc',
				header: 'EXECUTION FX DATE',
				filterType: 'space',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 'exec_fx_cash_comp_incl_data',
				header: 'EXECUTION FX CASH COMP. INCL.',
				filterType: 'space',
				dataType: 'text',
				width: '300px',
			},
			{
				field: 'creation_trade_date_desc',
				header: 'CREATION TRADE DATE',
				filterType: 'space',
				dataType: 'text',
				width: '230px',
			},
			{
				field: 'redemption_trade_date_desc',
				header: 'REDEMPTION TRADE DATE',
				filterType: 'space',
				dataType: 'text',
				width: '250px',
			},
			{
				field: 'pre_fund_ind_data',
				header: 'PREFUNDING?',
				filterType: 'space',
				dataType: 'text',
				width: '160px',
			},
			{
				field: 'pre_fund_rate',
				header: 'PREFUND RATE',
				filterType: 'space',
				dataType: 'number',
				width: '160px',
			},
			{
				field: 'pre_fund_base',
				header: 'PREFUND BASE',
				filterType: 'space',
				dataType: 'text',
				width: '170px',
			},
			{
				field: 'creation_fee',
				header: 'CREATION FEE',
				filterType: 'space',
				dataType: 'number',
				width: '160px',
			},
			{
				field: 'creation_fee_eff_date',
				header: 'CREATION FEE EFF. DATE',
				filterType: 'space',
				dataType: 'date',
				width: '240px',
			},
			{
				field: 'redemption_fee',
				header: 'REDEMPTION FEE',
				filterType: 'space',
				dataType: 'number',
				width: '180px',
			},
			{
				field: 'redemption_fee_eff_date',
				header: 'REDEMPTION FEE EFF. DATE',
				filterType: 'space',
				dataType: 'date',
				width: '270px',
			},
			{
				field: 'pd_fee_from_desc',
				header: 'PD FEE FROM',
				filterType: 'space',
				dataType: 'textTooltip',
				width: '280px',
			},
			{
				field: 'ta_order_fee',
				header: 'TA FEE',
				filterType: 'space',
				dataType: 'number',
				width: '110px',
			},
			{
				field: 'ta_order_fee_from_desc',
				header: 'TA FEE FROM',
				filterType: 'space',
				dataType: 'textTooltip',
				width: '280px',
			},
		]
	},
	'enum-config': {
		class: "primary-p-table",
		isResizableColumns: true,
		paginator: true,
		scrollHeight: "calc(108vh - 460px)",
		fields: [
			{
				field: 'category_desc',
				header: 'ENUM TYPE',
				filterType: 'dropdown',
				dataType: 'text',
				width: '220px',
				isDisableSort: true,
				position: 'fixed'
			},
			{
				field: 'code',
				header: 'ENUM CODE',
				filterType: 'dropdown',
				dataType: 'text',
				width: '220px',
				isDisableSort: true,
			},
			{
				field: 'code_desc',
				header: 'CODE DESCRIPTION',
				filterType: 'text',
				dataType: 'text',
				width: '220px',
				isDisableSort: true,
			},
			{
				field: 'actions',
				header: 'ACTIONS',
				buttonType: 'displayIcons',
				enumEdit: true,
				update: false,
				cancel: false,
				enumDelete: true,
				isDisableSort: true,
				width: '120px',
			},
		]
	},
	'ca-config': {
		class: "primary-p-table",
		isResizableColumns: true,
		// paginator: true,
		scrollHeight: "calc(108vh - 460px)",
		fields: [
			{
				field: 'actions',
				header: 'Actions',
				filterType: 'space',
				buttonType: 'displayIcons',
				caView: true,
				caEdit: true,
				update: false,
				cancel: false,
				caDelete: true,
				isDisableSort: true,
				width: '150px'
			},
			{
				field: 'tradar_code',
				header: 'FUND CODE',
				filterType: 'dropdown',
				dataType: 'text',
				width: '140px',
				isDisableSort: true,
			},
			{
				field: 'share_class_name',
				header: 'SHARE CLASS NAME',
				filterType: 'dropdown',
				dataType: 'text',
				width: '250px',
				isDisableSort: true,
			},
			{
				field: 'event_type_desc',
				header: 'EVENT TYPE',
				filterType: 'dropdown',
				dataType: 'text',
				width: '320px',
				isDisableSort: true,
			},
			{
				field: 'event_desc',
				header: 'EVENT NAME',
				filterType: 'dropdown',
				dataType: 'text',
				width: '220px',
				isDisableSort: true,
			},
			{
				field: 'exDate',
				header: 'EX DATE',
				filterType: 'space',
				dataType: 'date',
				width: '220px',
				isDisableSort: true,
			},
			{
				field: 'dividend_ccy',
				header: 'DIVIDEND CURRENCY',
				filterType: 'space',
				dataType: 'text',
				width: '220px',
				isDisableSort: true,
			},
			{
				field: 'dividendAmt',
				header: 'DIVIDEND AMOUNT',
				filterType: 'space',
				dataType: 'number',
				width: '220px',
				isDisableSort: true,
			},
			{
				field: 'unitsMultiplier',
				header: 'UNITS MULTIPLIER',
				filterType: 'space',
				dataType: 'number',
				width: '220px',
				isDisableSort: true,
			},
			{
				field: 'announcementDate',
				header: 'ANNOUNCEMENT DATE',
				filterType: 'space',
				dataType: 'date',
				width: '220px',
				isDisableSort: true,
			},
			{
				field: 'recordDate',
				header: 'RECORD DATE',
				filterType: 'space',
				dataType: 'date',
				width: '220px',
				isDisableSort: true,
			},
			{
				field: 'paymentDate',
				header: 'PAYMENT DATE',
				filterType: 'space',
				dataType: 'date',
				width: '220px',
				isDisableSort: true,
			},
		]
	},
	'Trading-Holiday': {
		class: "primary-p-table",
		isResizableColumns: true,
		paginator: true,
		selectionPageOnly: true,
		scrollHeight: "calc(106vh - 460px)",
		fields: [
			{
				field: "",
				header: "",
				dataType: "multiSelectText",
				width: "80px",
				isDisableSort: true,
			},
			{
				field: 'holiday_code',
				header: 'CALENDAR CODE',
				filterType: 'dropdown',
				dataType: 'text',
				width: '220px',
				isDisableSort: false,
			},
			{
				field: 'holiday_year',
				header: 'YEAR',
				filterType: 'dropdown',
				dataType: 'text',
				width: '220px',
				isDisableSort: false,
			},
			{
				field: 'holiday_date',
				header: 'DATE',
				dataType: 'date',
				width: '220px',
				isDisableSort: false,
			},
			{
				field: 'holiday_cal_desc',
				header: 'DESCRIPTION',
				dataType: 'text',
				width: '320px',
				isDisableSort: false,
			},
			{
				field: 'actions',
				header: '',
				buttonType: 'displayIcons',
				edit: false,
				update: false,
				cancel: false,
				holidayDelete: true,
				isDisableSort: false,
				width: '60px',
			},
		]
	},
	'Ccy-Holiday-Calender': {
		class: "primary-p-table",
		isResizableColumns: true,
		paginator: true,
		scrollHeight: "calc(106vh - 460px)",
		fields: [
			{
				field: 'ccy',
				header: 'CCY',
				filterType: 'dropdown',
				dataType: 'text',
				width: '220px',
				isDisableSort: false,
			},
			{
				field: 'entity',
				header: 'ENTITY',
				filterType: 'dropdown',
				dataType: 'text',
				width: '220px',
				isDisableSort: false,
			},
			{
				field: 'holiday_code_desc',
				header: 'CALENDAR CODE',
				dataType: 'text',
				width: '220px',
				isDisableSort: false,
			},
			{
				field: 'actions',
				header: 'ACTIONS',
				buttonType: 'displayIcons',
				holidayEdit: true,
				update: false,
				cancel: false,
				holidayDelete: true,
				isDisableSort: false,
				width: '80px',
			},
		]
	},
	'settlement-Holiday': {
		class: "primary-p-table",
		isResizableColumns: true,
		paginator: true,
		scrollHeight: "calc(105vh - 460px)",
		fields: [
			{
				field: "",
				header: "",
				dataType: "multiSelectText",
				width: "80px",
				isDisableSort: true,
			},
			{
				field: 'holiday_code',
				header: 'CALENDAR CODE',
				filterType: 'dropdown',
				dataType: 'text',
				width: '220px',
				isDisableSort: false,
			},
			{
				field: 'holiday_year',
				header: 'YEAR',
				filterType: 'dropdown',
				dataType: 'text',
				width: '220px',
				isDisableSort: false,
			},
			{
				field: 'holiday_date',
				header: 'DATE',
				dataType: 'date',
				width: '220px',
				isDisableSort: false,
			},
			{
				field: 'holiday_cal_desc',
				header: 'DESCRIPTION',
				dataType: 'text',
				width: '320px',
				isDisableSort: false,
			},
			{
				field: 'actions',
				header: '',
				buttonType: 'displayIcons',
				edit: false,
				update: false,
				cancel: false,
				holidayDelete: true,
				isDisableSort: false,
				width: '60px',
			},
		]
	},
	'dmp': {
		class: "primary-p-table",
		isResizableColumns: true,
		paginator: true,
		scrollHeight: "calc(104vh - 460px)",
		fields: [
			{
				field: 'trade_date',
				header: 'TRADE DATE',
				filterType: 'dateRange',
				// colFilter: 'date',
				dataType: 'date',
				width: '140px',
				filterMatchMode: 'contains'
				// isDisableSort: true,
			},
			{
				field: 'fund_name',
				header: 'FUND NAME',
				filterType: 'dpmdropdown',
				dataType: 'text',
				width: '140px',
				isDisableSort: true,
			},
			{
				field: 'ticker',
				header: 'TICKER',
				filterType: 'space',
				dataType: 'text',
				width: '140px',
				isDisableSort: true,
			},
			{
				field: 'ccy',
				header: 'CURRENCY',
				filterType: 'space',
				dataType: 'text',
				width: '140px',
				isDisableSort: true,
			},
			{
				field: 'nav',
				header: 'NAV',
				filterType: 'space',
				dataType: 'thousandSeparators',
				width: '140px',
				isDisableSort: true,
			},
			{
				field: 'nav_per_unit',
				header: 'NAV/UNIT',
				filterType: 'space',
				dataType: 'thousandSeparators',
				width: '140px',
				isDisableSort: true,
			},
			{
				field: 'units',
				header: 'UNITS',
				filterType: 'space',
				dataType: 'thousandSeparators',
				width: '140px',
				isDisableSort: true,
			},
		]
	},
	'monthly': {
		class: "primary-p-table",
		isResizableColumns: true,
		paginator: true,
		scrollHeight: "calc(106vh - 460px)",
		fields: [
			{
				field: 'DATE',
				header: 'DATE',
				// filterType: 'dateRange',
				// colFilter: 'date',
				dataType: 'date',
				width: '140px',
				// filterMatchMode: 'contains'
				// isDisableSort: true,
			},
			{
				field: 'FUNDNAME',
				header: 'FUND NAME',
				filterType: 'dropdown',
				dataType: 'text',
				width: '140px',
				isDisableSort: true,
			},
			{
				field: 'FUNDID',
				header: 'FUND ID',
				filterType: 'text',
				dataType: 'text',
				width: '140px',
				isDisableSort: true,
			},
			{
				field: 'TICKER',
				header: 'TICKER',
				filterType: 'text',
				dataType: 'text',
				width: '140px',
				isDisableSort: true,
			},
			{
				field: 'CCY',
				header: 'CCY',
				filterType: 'text',
				dataType: 'text',
				width: '140px',
				isDisableSort: true,
			},
			{
				field: 'AUM',
				header: 'AUM',
				filterType: 'text',
				dataType: 'text',
				width: '140px',
				isDisableSort: true,
			},
		]
	},
	'aum': {
		class: "primary-p-table",
		isResizableColumns: true,
		paginator: true,
		scrollHeight: "calc(106vh - 460px)",
		fields: [
			{
				field: 'HstDate',
				header: 'DATE',
				filterType: 'dateRange',
				// colFilter: 'date',
				dataType: 'date',
				width: '140px',
				// filterMatchMode: 'contains'
				// isDisableSort: true,
			},
			{
				field: 'FundName',
				header: 'FUND NAME',
				filterType: 'dropdown',
				dataType: 'text',
				width: '140px',
				isDisableSort: true,
			},
			{
				field: 'FundID',
				header: 'FUND ID',
				filterType: 'text',
				dataType: 'text',
				width: '140px',
				isDisableSort: true,
			},
			{
				field: 'FundCCY',
				header: 'FUND CCY',
				filterType: 'text',
				dataType: 'text',
				width: '140px',
				isDisableSort: true,
			},
			{
				field: 'Shares',
				header: 'SHARES',
				filterType: 'text',
				dataType: 'text',
				width: '140px',
				isDisableSort: true,
			},
			{
				field: 'AUM',
				header: 'AUM',
				filterType: 'text',
				dataType: 'text',
				width: '140px',
				isDisableSort: true,
			},
		]
	},


	'order-details': {
		class: "primary-p-table",
		isResizableColumns: true,
		// paginator: true,
		scrollHeight: "calc(106vh - 360px)",
		frozenWidth: "1260px",
		isFrozenColumns: [
			{
				field: "",
				header: "",
				dataType: "multiSelectText",
				width: "60px",
				isDisableSort: true,
				bgcolor: '#bbbdbf',
				fontcolor: '#383839'
			},
			{
				field: 'actions',
				header: 'EXPORTS',
				buttonType: 'button',
				tExpo: true,
				eExpo: true,
				width: '150px',
				bgcolor: '#bbbdbf',
				fontcolor: '#383839'
			},
			{
				field: 'orderDate',
				header: 'ORDER DATE',
				filterType: 'space',
				dataType: 'date',
				width: '150px',
				bgcolor: '#bbbdbf',
				fontcolor: '#383839'
			},
			{
				field: 'order_id',
				header: 'ORDER ID',
				filterType: 'dropdown',
				dataType: 'link_id',
				width: '250px',
				position: 'fixed',
				bgcolor: '#bbbdbf',
				fontcolor: '#383839'
			},
			{
				field: 'dealingDate',
				header: 'DEALING DATE',
				filterType: 'space',
				dataType: 'date',
				width: '150px',
				bgcolor: '#bbbdbf',
				fontcolor: '#383839'
			},
			{
				field: 'tradeFillDate',
				header: 'TRADE FILL DAY',
				dataType: 'text',
				filterType: 'dropdown',
				width: '160px',
				bgcolor: '#bbbdbf',
				fontcolor: '#383839'
			},

			{
				field: 'orderType',
				header: 'ORDER TYPE',
				filterType: 'dropdown',
				dataType: 'text',
				width: '180px',
				bgcolor: '#bbbdbf',
				fontcolor: '#383839'
			},
			{
				field: 'fundCode',
				header: 'FUND',
				filterType: 'dropdown',
				dataType: 'text',
				width: '140px',
				bgcolor: '#bbbdbf',
				fontcolor: '#383839'
			},
		],
		fields: [
			{
				field: 'pmaShareClass',
				header: 'SHARE CLASS',
				filterType: 'space',
				dataType: 'textTooltip',
				width: '250px',
				fontSize: '18px'
			},
			{
				field: 'shareClassCcy',
				header: 'SHARE CLASS CCY',
				filterType: 'dropdown',
				dataType: 'text',
				width: '180px',
			},
			{
				field: 'pd',
				header: 'PD',
				filterType: 'space',
				dataType: 'text',
				width: '150px',
			},
			{
				field: 'baskets',
				header: 'BASKETS',
				filterType: 'space',
				dataType: 'hasNegative',
				width: '130px',
			},
			{
				field: 'units',
				header: 'UNITS',
				filterType: 'space',
				dataType: 'hasNegative',
				width: '150px',
			},
			{
				field: 'amount',
				header: 'AMOUNT',
				filterType: 'space',
				dataType: 'hasNegative',
				width: '150px',
			},
			{
				field: 'crCcy',
				header: 'PAY CCY',
				filterType: 'space',
				dataType: 'text',
				width: '120px',
			},
			{
				field: 'dcCalcType',
				header: 'DC CALC TYPE',
				filterType: 'dropdown',
				dataType: 'text',
				width: '160px',
			},
			{
				field: 'crCharges',
				header: 'CR CHARGES(In Bps)',
				filterType: 'space',
				dataType: 'hasNegative',
				width: '200px',
			},
			{
				field: 'cashComponentPcf',
				header: 'CASH COMPONENT PCF',
				filterType: 'space',
				dataType: 'hasNegative',
				width: '240px',
			},
			{
				field: 'adjustmentFactor',
				header: 'ADJUSTMENT FACTOR',
				filterType: 'space',
				dataType: 'hasNegative',
				width: '220px',
			},
			{
				field: 'totalBaskets',
				header: 'TOTAL BASKETS',
				filterType: 'space',
				dataType: 'hasNegative',
				width: '180px',
			},
			{
				field: 'ratio',
				header: 'RATIO',
				filterType: 'space',
				dataType: 'hasNegative',
				width: '110px',
			},
			{
				field: 'pmaBasketID',
				header: 'PMA BASKET ID',
				filterType: 'dropdown',
				dataType: 'textTooltip',
				width: '250px',
			},
			{
				field: 'navPerShare',
				header: 'NAV1 / SHARE',
				dataType: 'hasNegative',
				filterType: 'space',
				width: '180px',
			},
			{
				field: 'crCapitalFccy',
				header: 'CR CAPITAL FCCY',
				dataType: 'hasNegative',
				filterType: 'space',
				width: '180px',
			},
			{
				field: 'preFundNav',
				header: 'PREFUND NAV',
				dataType: 'hasNegative',
				width: '160px',
			},
			{
				field: 'preFundFxRate',
				header: 'PREFUND FX RATE',
				dataType: 'hasNegative',
				filterType: 'space',
				width: '180px',
			},
			{
				field: 'preFundCashPayAmt',
				header: 'PREFUND CASH PAY AMT',
				filterType: 'space',
				dataType: 'hasNegative',
				width: '240px',
			},
			{
				field: 'pdFee',
				header: 'PD FEE (CR CCY)',
				dataType: 'hasNegative',
				width: '180px',
			},
			{
				field: 'pdFeeForm',
				filterType: 'space',
				header: 'PD FEE FROM',
				dataType: 'text',
				width: '180px',
			},
			{
				field: 'crChargesAmt',
				header: 'CR CHARGES',
				filterType: 'space',
				dataType: 'hasNegative',
				width: '160px',
			},
			{
				field: 'crPayFxrate',
				header: 'CR PAY FX RATE',
				dataType: 'hasNegative',
				filterType: 'space',
				width: '180px',
			},
			{
				field: 'crChargesPayAmt',
				header: 'CR CHARGES PAY AMT',
				dataType: 'hasNegative',
				filterType: 'space',
				width: '220px',
			},
			{
				field: 'inKindMv',
				header: 'IN KIND MV',
				dataType: 'hasNegative',
				filterType: 'space',
				width: '220px',
			},
			{
				field: 'ccassConvAgentFee',
				header: 'CCASS CONV AGENT FEE ',
				dataType: 'hasNegative',
				filterType: 'space',
				width: '220px',
			},	{
				field: 'inKindBoardLot',
				header: 'IN KIND BOARD LOT ',
				dataType: 'hasNegative',
				filterType: 'space',
				width: '220px',
			},	{
				field: 'ccassShareCancelFee',
				header: 'CCASS SHARE CANCEL FEE ',
				dataType: 'hasNegative',
				filterType: 'space',
				width: '220px',
			},	{
				field: 'totalCcassFee',
				header: 'TOTAL CCASS FEE ',
				dataType: 'hasNegative',
				filterType: 'space',
				width: '220px',
			},

			{
				field: 't0PayAmtPd',
				header: 'T0 PAY AMT PD',
				dataType: 'hasNegative',
				filterType: 'space',
				width: '170px',
			},
			{
				field: 't0PayAmtFund',
				header: 'T0 PAY AMT FUND',
				dataType: 'hasNegative',
				filterType: 'space',
				width: '180px',
			},
			{
				field: 't0Red2ndPayAmt',
				header: 'T0 RED 2ND PAY AMT',
				dataType: 'hasNegative',
				filterType: 'space',
				width: '200px',
			},
			{
				field: 'dcT0Fccy',
				header: 'D&C T0 FCCY',
				dataType: 'hasNegative',
				filterType: 'space',
				width: '150px',
			},
			{
				field: 't0FundAmt',
				header: 'T0 FUND AMT',
				dataType: 'hasNegative',
				filterType: 'space',
				width: '150px',
			},
			{
				field: 'securityFundAmtT1Fx',
				header: 'SECURITY FUND AMT T1 FX',
				filterType: 'space',
				dataType: 'hasNegative',
				width: '240px',
			},
			{
				field: 'dcT1FxT0',
				header: 'D&C T1 FX T0',
				dataType: 'hasNegative',
				filterType: 'space',
				width: '160px',
			},
			{
				field: 'refundPayAmt',
				header: 'REFUND PAY AMT',
				dataType: 'hasNegative',
				filterType: 'space',
				width: '180px',
			},
			{
				field: 'estimatedFill',
				header: 'ESTIMATED FILL',
				dataType: 'hasNegative',
				filterType: 'space',
				width: '180px',
			},
			{
				field: 'securityPayAmtChangeT1',
				header: 'SECURITY PAY AMT CHANGE T1',
				filterType: 'space',
				dataType: 'hasNegative',
				width: '260px',
			},
			{
				field: 'dcChangeT1Fccy',
				header: 'D&C CHANGE T1 FCCY',
				filterType: 'space',
				dataType: 'hasNegative',
				width: '220px',
			},
			{
				field: 'securityPayAmtChangeT2',
				header: 'SECURITY PAY AMT CHANGE T2',
				filterType: 'space',
				dataType: 'hasNegative',
				width: '280px',
			},
			{
				field: 'dcChangeT2Fccy',
				header: 'D&C CHANGE T2 FCCY',
				filterType: 'space',
				dataType: 'hasNegative',
				width: '220px',
			},
			{
				field: 'finalPayAmtT2',
				header: 'FINAL PAY AMT T2',
				filterType: 'space',
				dataType: 'hasNegative',
				width: '180px',
			},
			{
				field: 't0BlendedPayFundFxrate',
				header: 'T0 BLENDED PAY FUND FX RATE',
				filterType: 'space',
				dataType: 'hasNegative',
				width: '290px',
			},
			{
				field: 't1BlendedPayFundFxrate',
				header: 'T1 BLENDED PAY FUND FX RATE',
				filterType: 'space',
				dataType: 'hasNegative',
				width: '290px',
			},
			{
				field: 't2BlendedPayFundFxrate',
				header: 'T2 BLENDED PAY FUND FX RATE',
				filterType: 'space',
				dataType: 'hasNegative',
				width: '290px',
			},
			{
				field: 'taOrderFeeFccy',
				header: 'TA ORDER FEE FCCY',
				filterType: 'space',
				dataType: 'hasNegative',
				width: '200px',
			},
			{
				field: 'taOrderFeeFrom',
				header: 'TA ORDER FEE FROM',
				filterType: 'space',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 'signOffStatus',
				header: 'SIGN-OFF STATUS',
				filterType: 'space',
				dataType: 'text',
				width: '180px',
			},
			{
				field: 'signOffUser',
				header: 'SIGN-OFF USER',
				filterType: 'space',
				dataType: 'text',
				width: '180px',
			},
			{
				field: 'signOffDate',
				header: 'SIGN-OFF DATE',
				filterType: 'space',
				dataType: 'date',
				width: '180px',
			},
			{
				field: 'signOffComments',
				header: 'SIGN-OFF COMMENTS',
				filterType: 'space',
				dataType: 'textTooltip',
				width: '220px',
			},
		]
	},
	'merged_order': {
		class: 'primary-p-table',
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 390px)',
		fields: [
			{
				field: 'pmaBasketId',
				header: 'PMA BASKET ID',
				filterType: 'dropdown',
				dataType: 'text',
				width: '400px',
				alignRight: false,
			},
			{
				field: 'orderType',
				header: 'ORDER TYPE',
				filterType: 'dropdown',
				dataType: 'text',
				width: '200px',
				alignRight: false,
			},
			{
				field: 'fund',
				header: 'FUND',
				dataType: 'text',
				filterType: 'dropdown',
				width: '150px',
				alignRight: false,
			},
			{
				field: 'shareClass',
				header: 'SHARE CLASS',
				filterType: 'dropdown',
				dataType: 'text',
				width: '250px',
			},
			{
				field: 'totalBaskets',
				header: 'TOTAL BASKETS',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '180px',
			},
			{
				field: 'fundCcy',
				header: 'SHARE CLASS CCY',
				filterType: 'dropdown',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 'crCcy',
				header: 'PAY CCY',
				filterType: 'dropdown',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 'crrdCapitalFccy',
				header: 'CR CAPITAL FCCY',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '200px',
			},
			{
				field: 'securityPayAmtExecutedT0',
				header: 'SECURITY PAY AMT EXECUTED T0',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '310px',
			},
			{
				field: 'securityPayAmtUnfilledT0',
				header: 'SECURITY PAY AMT UNFILLED T0',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '310px',
			},
			{
				field: 'cashComponent',
				header: 'CASH COMPONENT',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '200px',
			},
			{
				field: 'cashComponentPayAmt',
				header: 'CASH COMPONENT PAY AMT',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '280px',
			},
			{
				field: 'totalPayAmtT0',
				header: 'TOTAL PAY AMT T0',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '200px',
			},
			{
				field: 'totalFundAmtT0',
				header: 'TOTAL FUND AMT T0',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '210px',
			},
			{
				field: 'dcTofccy',
				header: 'D&C T0 FCCY',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '170px',
			},
			{
				field: 'totalFundAmtT1Fx',
				header: 'TOTAL FUND AMT T1 FX',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '240px',
			},
			{
				field: 'dcT0T1FxFccy',
				header: 'D&C TO T1 FX FCCY',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '210px',
			},
			{
				field: 'securityPayAmtChangeT1',
				header: 'SECURITY PAY AMT CHANGE T1',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '310px',
			},
			{
				field: 'securityFundAmtChangeT1',
				header: 'SECURITY FUND AMT CHANGE T1',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '310px',
			},
			{
				field: 't1BlendedFxRate',
				header: 'T1 BLENDED PAY FUND FX RATE',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '310px',
			},
			{
				field: 'dcChangeT1Fccy',
				header: 'D&C CHANGE T1 FCCY',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '240px',
			},
			{
				field: 'securityPayAmtChangeT2',
				header: 'SECURITY PAY AMT CHANGE T2',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '310px',
			},
			{
				field: 'securityFundAmtChangeT2',
				header: 'SECURITY FUND AMT CHANGE T2',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '310px',
			},
			{
				field: 't2BlendedFxRate',
				header: 'T2 BLENDED PAY FUND FX RATE',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '310px',
			},
			{
				field: 'dcChangeT2Fccy',
				header: 'D&C CHANGE T2 FCCY',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '220px',
			},
			{
				field: 'finalPayAmtT2',
				header: 'FINAL PAY AMT T2',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '200px',
			},
		]
	},
	'pcf': {
		class: 'primary-p-table',
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 390px)',
		fields: [
			{
				field: 'pmaBasketId',
				header: 'PMA BASKET ID',
				filterType: 'dropdown',
				dataType: 'text',
				width: '500px',
				alignRight: false,
			},
			{
				field: 'pcfSequence',
				header: 'PCF SEQ',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '160px',
				alignRight: false,
			},
			{
				field: 'orderType',
				header: 'ORDER TYPE',
				filterType: 'dropdown',
				dataType: 'text',
				width: '180px',
				alignRight: false,
			},
			{
				field: 'fund',
				header: 'FUND',
				filterType: 'dropdown',
				dataType: 'text',
				width: '200px',
				alignRight: false,
			},
			{
				field: 'baskets',
				header: 'BASKETS',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '140px',
			},
			{
				field: 'pcfAggTicker',
				header: 'IDENTIFIER',
				filterType: 'space',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 'bbgTicker',
				header: 'BBG TICKER',
				filterType: 'space',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 'pcfQuantity',
				header: 'CU QTY',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '130px',
			},
			{
				field: 'underlyerCcy',
				header: 'UNDERLIER CCY',
				filterType: 'space',
				dataType: 'text',
				width: '180px',
			},
			{
				field: 'crCcy',
				header: 'PAY CCY',
				filterType: 'dropdown',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 'fundCcy',
				header: 'FUND CCY',
				filterType: 'dropdown',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 'expectedQty',
				header: 'EXPECTED QTY',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '180px',
			},
			{
				field: 't0InKindQty',
				header: 'T0 INKIND QTY',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '180px',
			},
			{
				field: 't0InKindConsideration',
				header: 'T0 INKIND CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '270px',
			},
			{
				field: 'underlyerFundFxrate',
				header: 'UNDERLIER FUND FX RATE',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '260px',
			},
			{
				field: 't0InKindFundConsideration',
				header: 'To INKIND FUND CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '320px',
			},
			{
				field: 't0ExecQty',
				header: 'T0 EXEC QTY',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '160px',
			},
			{
				field: 't0ExecQtyCcy',
				header: 'T0 EXEC CCY',
				filterType: 'space',
				dataType: 'text',
				width: '160px',
			},
			{
				field: 't0ExecConsideration',
				header: 'T0 EXEC CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '260px',
			},
			{
				field: 't0PayFxRate',
				header: 'T0 PAY FX RATE',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '180px',
			},
			{
				field: 't0PayConsideration',
				header: 'T0 PAY CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '240px',
			},
			{
				field: 'payFundFxType',
				header: 'PAY FUND FX TYPE',
				filterType: 'space',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 't0PayFundFxRate',
				header: 'T0 PAY FUND FX RATE',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '220px',
			},
			{
				field: 't0FundConsiderationt1Fx',
				header: 'T0 FUND CONSIDERATION T1 FX',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '300px',
			},
			{
				field: 't0FundConsideration',
				header: 'T0 FUND CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '260px',
			},
			{
				field: 't0UnfilledQty',
				header: 'T0 UNFILLED QTY',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '200px',
			},
			{
				field: 't0Price',
				header: 'T0 PX',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '120px',
			},
			{
				field: 't0UnfilledMvConsideration',
				header: 'T0 UNFILLED MV CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '320px',
			},
			{
				field: 't0UnfilledPayFxRate',
				header: 'T0 UNFILLED PAY FX RATE',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '270px',
			},
			{
				field: 't0UnfilledPayMvConsideration',
				header: 'T0 UNFILLED PAY MV CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '350px',
			},
			{
				field: 't0UnfilledFundMvConsideration',
				header: 'T0 UNFILLED FUND MV CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '370px',
			},
			{
				field: 'cndLot',
				header: 'CN Odd Lot',
				filterType: 'space',
				dataType: 'text',
				width: '160px',
			},
			{
				field: 't1ExecQty',
				header: 'T1 EXEC QTY',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '160px',
			},
			{
				field: 't1ExecCcy',
				header: 'T1 EXEC CCY',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '160px',
			},
			{
				field: 't1ExecConsideration',
				header: 'T1 EXEC CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '240px',
			},
			{
				field: 't1PayFx',
				header: 'T1 PAY FX',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '140px',
			},
			{
				field: 't1PayFxRate',
				header: 'T1 PAY FX RATE',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '170px',
			},
			{
				field: 't1PayConsideration',
				header: 'T1 PAY CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '240px',
			},
			{
				field: 't1PayFundFxRate',
				header: 'T1 PAY FUND FX RATE',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '240px',
			},
			{
				field: 't1FundConsideration',
				header: 'T1 FUND CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '300px',
			},
			{
				field: 't1UnfilledQty',
				header: 'T1 UNFILLED QTY CHG',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '240px',
			},
			{
				field: 't1UnfilledMvConsideration',
				header: 'T1 UNFILLED MV CONSIDERATION CHG',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '370px',
			},
			{
				field: 't1UnfilledPayMvConsideration',
				header: 'T1 UNFILLED PAY MV CONSIDERATION CHG',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '390px',
			},
			{
				field: 't1UnfilledFundMvConsideration',
				header: 'T1 UNFILLED FUND MV CONSIDERATION CHG',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '400px',
			},
			{
				field: 't2ExecQty',
				header: 'T2 EXEC QTY',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '140px',
			},
			{
				field: 't2ExecCcy',
				header: 'T2 EXEC CCY',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '140px',
			},
			{
				field: 't2ExecConsideration',
				header: 'T2 EXEC CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '240px',
			},
			{
				field: 't2PayFx',
				header: 'T2 PAY FX',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '140px',
			},
			{
				field: 't2PayFxRate',
				header: 'T2 PAY FX RATE',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '140px',
			},
			{
				field: 't2PayConsideration',
				header: 'T2 PAY CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '240px',
			},
			{
				field: 't2PayFundFxRate',
				header: 'T2 PAY FUND FX RATE',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '240px',
			},
			{
				field: 't2FundConsideration',
				header: 'T2 FUND CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '250px',
			},
			{
				field: 't2UnfilledQtyChg',
				header: 'T2 UNFILLED QTY CHG',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '240px',
			},
			{
				field: 't2UnfilledMvConsideration',
				header: 'T2 UNFILLED MV CONSIDERATION CHG',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '370px',
			},
			{
				field: 't2UnfilledPayMvConsideration',
				header: 'T2 UNFILLED PAY MV CONSIDERATION CHG',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '400px',
			},
			{
				field: 't2UnfilledFundMvConsideration',
				header: 'T2 UNFILLED FUND MV CONSIDERATION CHG',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '400px',
			},
			{
				field: 't2osUnfilledQty',
				header: 'T2 OS UNFILLED QTY',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '250px',
			},
			{
				field: 't2Price',
				header: 'T2PX',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '120px',
			},
			{
				field: 'adjustmentFactor',
				header: 'ADJ SUSP',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '140px',
			},
			{
				field: 'finalUnfilledMvConsideration',
				header: 'FINAL UNFILLED MV CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '400px',
			},
			{
				field: 'finalUnfilledPayMvConsideration',
				header: 'FINAL UNFILLED PAY MV CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '410px',
			},
			{
				field: 'finalUnfilledFundMvConsideration',
				header: 'FINAL UNFILLED FUND MV CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '420px',
			},
			{
				field: 't2osunfilledMvConsideration',
				header: 'T2 OS UNFILLED MV CONSIDERATION CHG',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '400px',
			},
			{
				field: 't2osunfilledPayMvConsideration',
				header: 'T2 OS UNFILLED PAY MV CONSIDERATION CHG',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '420px',
			},
			{
				field: 't2osunfilledFundMvConsideration',
				header: 'T2 OS UNFILLED FUND MV CONSIDERATION CHG',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '420px',
			},
		]
	},
	'trade_cost': {
		class: 'primary-p-table',
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 390px)',
		fields: [
			{
				field: 'pmaBasketId',
				header: 'PMA BASKET ID',
				filterType: 'dropdown',
				dataType: 'text',
				width: '400px',
				alignRight: false,
			},
			{
				field: 'orderType',
				header: 'ORDER TYPE',
				filterType: 'dropdown',
				dataType: 'text',
				width: '220px',
				alignRight: false,
			},
			{
				field: 'fund',
				header: 'FUND',
				filterType: 'dropdown',
				dataType: 'text',
				width: '200px',
				alignRight: false,
			},
			{
				field: 'bbdDaysPeriod',
				header: 'BBG DAYS IN PERIOD',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '220px',
			},
			{
				field: 'bbgCouponRate',
				header: 'BBG COUPON RATE',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '220px',
			},
			{
				field: 'accruedIntrestFactor',
				header: 'AI FACTOR',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '160px',
			},
			{
				field: 'executionFxDate',
				header: 'EXECUTION FX DATE',
				filterType: 'space',
				dataType: 'text',
				width: '220px',
			},
			{
				field: 'baskets',
				header: 'BASKETS',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '140px',
			},
			{
				field: 'crCCY',
				header: 'PAY CCY',
				filterType: 'dropdown',
				dataType: 'text',
				width: '140px',
			},
			{
				field: 'fundCCY',
				header: 'SHARE CLASS CCY',
				filterType: 'dropdown',
				dataType: 'text',
				width: '190px',
			},
			{
				field: 'toInkindQty',
				header: 'T0 INKIND QTY',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '170px',
			},
			{
				field: 't0inkindConsideration',
				header: 'T0 INKIND CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '280px',
			},
			{
				field: 't0UnderlyerFundFxRate',
				header: 'UNDERLIER FUND FX RATE',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '280px',
			},
			{
				field: 'T0inkindFundConsideration',
				header: 'To INKIND FUND CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '320px',
			},
			{
				field: 't0ExecQty',
				header: 'T0 EXEC QTY',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '140px',
			},
			{
				field: 'swapExecutionPrice',
				header: 'SWAP EXEC PX',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '160px',
			},
			{
				field: 't0ExecCcy',
				header: 'T0 EXEC CCY',
				filterType: 'space',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 't0ExecConsideration',
				header: 'T0 EXEC CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '250px',
			},
			{
				field: 't0PayFxRate',
				header: 'T0 PAY FX RATE',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '180px',
			},
			{
				field: 't0PayConsideration',
				header: 'T0 PAY CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '250px',
			},
			{
				field: 't0PayFundFxType',
				header: 'PAY FUND FX TYPE',
				filterType: 'space',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 't0PayFundFxRate',
				header: 'T0 PAY FUND FX RATE',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '220px',
			},
			{
				field: 't0FundConsiderationT1Fx',
				header: 'T0 FUND CONSIDERATION T1 FX',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '310px',
			},
			{
				field: 't0FundConsideration',
				header: 'T0 FUND CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '260px',
			},
			{
				field: 't0Price',
				header: 'T0 PX',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '120px',
			},
			{
				field: 'bondMVAIConsideration',
				header: 'BOND MV AI CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '300px',
			},
			{
				field: 't0MvConsideration',
				header: 'T0 MV CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '240px',
			},
			{
				field: 't0FundMvConsideration',
				header: 'T0 MV FUND CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '280px',
			},
			{
				field: 'tranCostUnderlyerccy',
				header: 'TRANSACTION COST SHARE CLASS CCY',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '340px',
			},
			{
				field: 'dcPrice',
				header: 'D&C PRICE',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '140px',
			},
			{
				field: 'dcAccruedIntrest',
				header: 'D&C AI',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '140px',
			},
			{
				field: 'dcT0',
				header: 'D&C TO',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '140px',
			},
			{
				field: 'dcT1Fxt0',
				header: 'D&C T1 FX T0',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '160px',
			},
			{
				field: 'orderedBaskets',
				header: 'ORDERED BASKET',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '200px',
			},
			{
				field: 'pcfAggTicker',
				header: 'PCFAGGTICKER',
				filterType: 'space',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 'isin',
				header: 'ISIN',
				filterType: 'space',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 'ticker',
				header: 'TICKER',
				filterType: 'space',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 'day',
				header: 'DAY',
				filterType: 'space',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 'actualTradeDate',
				header: 'ACTD',
				filterType: 'space',
				dataType: 'date',
				width: '160px',
			},
			{
				field: 'tradeDate',
				header: 'TRADE DATE',
				filterType: 'space',
				dataType: 'date',
				width: '160px',
			},
			{
				field: 'settleDate',
				header: 'SETTLES',
				filterType: 'space',
				dataType: 'date',
				width: '160px',
			},
			{
				field: 'omsSecID',
				header: 'OMS SEC ID',
				filterType: 'space',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 'country',
				header: 'COUNTRY',
				filterType: 'space',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 'type',
				header: 'TYPE',
				filterType: 'space',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 'quantity',
				header: 'QTY',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '140px',
			},
			{
				field: 'price',
				header: 'PRICE',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '140px',
			},
			{
				field: 'netPrice',
				header: 'NET PRICE',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '200px',
			},
			{
				field: 'consideration',
				header: 'CONSIDERATION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '200px',
			},
			{
				field: 'commission',
				header: 'COMMISSION',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '160px',
			},
			{
				field: 'otherCharges2',
				header: 'OTHER CHARGES 2',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '200px',
			},
			{
				field: 'accruedInterest',
				header: 'ACCRUED',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '130px',
			},
			{
				field: 'pointValue',
				header: 'POINT VALUE',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '160px',
			},
			{
				field: 'assetGroup',
				header: 'ASSET GROUP UDF',
				filterType: 'space',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 'accountParty',
				header: 'ACCOUNT',
				filterType: 'space',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 'crossCcyExecutionCcy',
				header: 'CROSS CCY EXECUTION CCY',
				filterType: 'space',
				dataType: 'text',
				width: '280px',
			},
			{
				field: 'crossCcyFxRate',
				header: 'CROSS CCY FX RATE',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '260px',
			},
			{
				field: 'crossCcyLocalPrice',
				header: 'CROSS CCY LOCAL PRICE',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '260px',
			},
		]
	},
	'dc_nav1': {
		class: 'primary-p-table',
		isResizableColumns: true,
		scrollHeight: 'calc(100vh - 390px)',
		fields: [
			{
				field: 'fund',
				header: 'FUND',
				filterType: 'dropdown',
				dataType: 'text',
				width: '200px',
				alignRight: false,
			},
			{
				field: 'pmaShareClass',
				header: 'SHARE CLASS NAME',
				filterType: 'dropdown',
				dataType: 'text',
				width: '320px',
			},
			{
				field: 'totalBaskets',
				header: 'TOTAL BASKETS',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '180px',
			},
			{
				field: 'shareClassCcy',
				header: 'SHARE CLASS CCY',
				filterType: 'dropdown',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 'payCcy',
				header: 'CR CCY',
				filterType: 'dropdown',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 'executionFxDay',
				header: 'EXECUTION FX DATE',
				filterType: 'space',
				dataType: 'text',
				width: '210px',
			},
			{
				field: 'executionFxIncludeCashComponent',
				header: 'EXEX FX CASH COMP INCLUDE',
				filterType: 'space',
				dataType: 'text',
				width: '300px',
			},
			{
				field: 'crCapitalFccy',
				header: 'CR CAPITAL FCCY',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '200px',
			},
			{
				field: 't0PayFx',
				header: 'T0 PAY FX',
				filterType: 'space',
				dataType: 'text',
				width: '200px',
			},
			{
				field: 't0PayFxRate',
				header: 'T0 PAY FX RATE',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '180px',
			},
			{
				field: 'totalPayAmountTo',
				header: 'T0TAL PAY AMT T0',
				filterType: 'space',
				dataType: 'hasNegativeNumber',
				width: '200px',
			},
		]
	},
	'extAum': {
		class: 'primary-p-table extAum-head-padding',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(100vh - 470px)',
		fields: [
			{
				field: 'navDate',
				header: 'Nav Date',
				filterType: 'date',
				dataType: 'date',
				width: '12%'
			},
			{
				field: 'fundName',
				header: 'FUND NAME',
				filterType: 'dropdown',
				dataType: 'text',
				width: '15%'
			},
			{
				field: 'ccy',
				header: 'FUND CURRENCY',
				filterType: 'dropdown',
				dataType: 'text',
				width: '15%'
			},
			{
				field: 'classIdName',
				header: 'ShareClass',
				filterType: 'dropdown',
				dataType: 'text',
				width: '12%'
			},
			{
				field: 'classCcy',
				header: 'ShareClass CURRENCY',
				filterType: 'dropdown',
				dataType: 'text',
				alignRight: false,
				width: '15%'
			},
			{
				field: 'nav',
				header: 'NAV',
				dataType: 'text',
				filterType: 'text',
				alignRight: true,
				width: '11%',
				style: {'float': 'right'}
			},
			{
				field: 'navPerUnit',
				header: 'NAV Per Unit',
				dataType: 'text',
				filterType: 'text',
				alignRight: true,
				width: '10%',
				style: {'float': 'right'}
			},
			{
				field: 'units',
				header: 'Units',
				dataType: 'text',
				filterType: 'text',
				alignRight: true,
				width: '10%',
				style: {'float': 'right'}
			},
		]
	},
	'aumQuery': {
		class: 'primary-p-table aumQuery-head-padding',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(100vh - 470px)',
		fields: [
			{
				field: 'queryDate',
				header: 'Query Date',
				filterType: 'date',
				dataType: 'date',
				width: '9%'
			},
			{
				field: 'navDate',
				header: 'Nav Date',
				filterType: 'date',
				dataType: 'date',
				inlineEdit: false,
				width: '9%'
			},
			{
				field: 'fundName',
				header: 'FUND NAME',
				filterType: 'dropdown',
				dataType: 'text',
				width: '17%'
			},
			{
				field: 'ccy',
				header: 'FUND CCY',
				filterType: 'dropdown',
				dataType: 'text',
				width: '5%'
			},
			{
				field: 'fundType',
				header: 'Fund Type',
				dataType: 'text',
				filterType: 'dropdown',
				width: '5%'
			},
			{
				field: 'classIdName',
				header: 'ShareClass',
				filterType: 'dropdown',
				dataType: 'text',
				width: '9%'
			},
			{
				field: 'classCcy',
				header: 'ShareClass CCY',
				filterType: 'dropdown',
				dataType: 'text',
				width: '7%'
			},
			{
				field: 'flexCode',
				header: 'Flex Code',
				filterType: 'dropdown',
				dataType: 'text',
				width: '8%'
			},
			{
				field: 'nav',
				header: 'NAV',
				dataType: 'text',
				filterType: 'text',
				alignRight: true,
				width: '10%',
				style: {'float': 'right'}
			},
			{
				field: 'navPerUnit',
				header: 'NAV Per Unit',
				dataType: 'text',
				filterType: 'text',
				alignRight: true,
				width: '10%',
				style: {'float': 'right'}
			},
			{
				field: 'units',
				header: 'Units',
				dataType: 'text',
				filterType: 'text',
				alignRight: true,
				width: '10%',
				style: {'float': 'right'}
			},
			{
				field: 'active',
				header: 'Active',
				dataType: 'text',
				filterType: 'dropdown',
				alignRight: false,
				width: '5%'
			},
		]
	},
	// ops-account-config
	'accountpostingdrivers': {
		class: 'primary-p-table',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(95vh - 370px)',
		fields: [
			{
				field: 'postingDriverDescription',
				header: 'ACCOUNT POSTING DRIVER',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '100px',
			},
			{
				field: 'holidayAdjustment_data',
				header: 'HOLIDAY DATE ADJUSTMENT',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '100px',
			},
			{
				field: 'actions',
				header: 'Actions',
				filterType: 'actions',
				buttonType: 'icon',
				edit: true,
				update: false,
				cancel: false,
				delete: true,
				isDisableSort: true,
				width: '100px'
			}
		]
	},
	'PostingDriverPaymentDateRule': {
		class: 'primary-p-table',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(95vh - 370px)',
		fields: [
			{
				field: 'fundCode',
				header: 'FUND',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '10%',
			},
			{
				field: 'postingDriverDescription',
				header: 'POSTING DRIVER',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '10%',
			},
			{
				field: 'dateRuleDescription',
				header: 'DATE RULE',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '10%',
			},
			{
				field: 'actions',
				header: 'Actions',
				filterType: 'actions',
				buttonType: 'icon',
				edit: true,
				update: false,
				cancel: false,
				delete: true,
				isDisableSort: true,
				width: '10%'
			}
		]
	},
	'accountPostings': {
		class: 'primary-p-table',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(95vh - 370px)',
		fields: [
			{
				field: 'recordTypeDescription',
				header: 'RECORD TYPE',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '280px',
			},
			{
				field: 'operationDescription',
				header: 'OPERATION',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'accountDriverDescription',
				header: 'ACCOUNTS DRIVER',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'postingDriverCodeDescription',
				header: 'ACCOUNT POSTING DRIVER',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'postingType',
				header: 'POSTING DESCRIPTION',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'condition1Description',
				header: 'CONDITION 1',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'condition1Value',
				header: 'CONDITION 1 VALUE',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'condition2Description',
				header: 'CONDITION 2',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'condition2value',
				header: 'CONDITION 2 VALUE',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'paymentDate',
				header: 'PAYMENT DATE',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'postingAmountDescription',
				header: 'POSTING AMOUNT',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'priceDescription',
				header: 'PRICE',
				filterType: 'dropdown',
				dataType: 'number',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'ccyDescription',
				header: 'CCY',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'toCcyDescription',
				header: 'TO CCY',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'postingTradeType',
				header: 'POSTING TRADE TYPE',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'accountCode',
				header: 'ACCOUNT CODE',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'toAccountCode',
				header: 'TO ACCOUNT CODE',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'counterParty',
				header: 'COUNTERPARTY',
				filterType: 'text',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'reportDateExclusion',
				header: 'REPORT DATE EXCLUSION',
				filterType: 'checkbox',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'notes',
				header: 'NOTES',
				filterType: 'dropdown',
				dataType: 'textTooltip',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'actions',
				header: 'Actions',
				filterType: 'actions',
				buttonType: 'icon',
				edit: true,
				update: false,
				cancel: false,
				delete: true,
				isDisableSort: true,
				width: '250px'
			}
		]
	},
	'capitalEntries': {
		class: 'primary-p-table',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(95vh - 370px)',
		fields: [
			{
				field: 'orderId',
				header: 'ORDER ID',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'entryStatus',
				header: 'STATUS',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'signOffStatus',
				header: 'Sign Off Status',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'entryDate',
				header: 'Entry Date',
				filterType: 'space',
				dataType: 'date',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'operaionDescription',
				header: 'OPERATION',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'postingDriverDescription',
				header: 'POSTING DRIVER',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'settlementCycle',
				header: 'SETTLEMENT CYCLE',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'holidayDateAdjustment',
				header: 'HOLIDAY DATE ADJ.',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'fund',
				header: 'FUND',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'shareClassAccount',
				header: 'SHARE CLASS ACCOUNT',
				filterType: 'space',
				dataType: 'textTooltip',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'transactionType',
				header: 'TRANSACTION TYPE',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'subscriptionAmount',
				header: 'SUBSCRIPTION AMOUNT',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'redemptionAmount',
				header: 'REDEMPTION AMOUNT',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'redemptionPrice',
				header: 'SUBSCRIPTION /REDEMPTION PRICE',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'redemptionDate',
				header: 'SUBSCRIPTION /REDEMPTION DATE',
				filterType: 'space',
				dataType: 'date',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'navEndingDate',
				header: 'NAV ENDING DATE',
				filterType: 'space',
				dataType: 'date',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'trader',
				header: 'TRADER',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'pbAccount',
				header: 'PB ACCOUNT',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'strategy',
				header: 'STRATEGY',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'fxType',
				header: 'FX RATE',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'notes',
				header: 'NOTES',
				filterType: 'space',
				dataType: 'textTooltip',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'reportDateExclusion',
				header: 'REPORT DATE EXCLUSION',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},

		]
	},
	'D&CEntries': {
		class: 'primary-p-table',
		isResizableColumns: true,
		paginator: true,
		scrollHeight: 'calc(95vh - 370px)',
		fields: [
			{
				field: 'orderId',
				header: 'ORDER ID',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'entryStatus',
				header: 'STATUS',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'signOffStatus',
				header: 'Sign Off Status',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'entryDate',
				header: 'Entry Date',
				filterType: 'space',
				dataType: 'date',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'operationDescription',
				header: 'OPERATION',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'crFeeFrom',
				header: 'CR FEE FROM',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'taOrderFeeFrom',
				header: 'TA ORDER FEE FROM',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'executionFxDate',
				header: 'EXECUTION FX DATE',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'preFund',
				header: 'PREFUND?',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'postingDriverDescription',
				header: 'POSTINGS DRIVER',
				filterType: 'dropdown',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'postingDiscription',
				header: 'POSTINGS DESC.',
				filterType: 'dropdown',
				dataType: 'textTooltip',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'settlementCycle',
				header: 'SETTLEMENT CYCLE',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'holidayDateAdjustment',
				header: 'HOLIDAY DATE ADJ.',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'rawPostingAmt',
				header: 'RAW POSTING AMT',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'fund',
				header: 'FUND',
				filterType: 'space',
				dataType: 'number',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'paymentDate',
				header: 'DATE',
				filterType: 'space',
				dataType: 'date',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'tradeType',
				header: 'TRADE TYPE',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'ccy',
				header: 'CCY',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'account',
				header: 'ACCOUNT CODE',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'strategy',
				header: 'STRATEGY',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'trader',
				header: 'TRADER',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'amount',
				header: 'AMOUNT',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'price',
				header: 'PRICE',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'toAccount',
				header: 'TO ACCOUNT CODE',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'notes',
				header: 'NOTES',
				filterType: 'space',
				dataType: 'textTooltip',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'security',
				header: 'SECURITY',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'settlementDate',
				header: 'SETTLEMENT DATE',
				filterType: 'space',
				dataType: 'date',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'counterParty',
				header: 'COUNTERPARTY',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'fxType',
				header: 'FX TYPE',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			},
			{
				field: 'reportDateExclution',
				header: 'REPORT DATE EXCLUSION',
				filterType: 'space',
				dataType: 'text',
				inlineEdit: false,
				width: '250px',
			}
		]
	},



};




