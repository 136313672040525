import { Injectable } from '@angular/core';
import { Privileges } from '../../assets/privileges';


@Injectable()
export class PrivilegeService {

  privs: {};

  constructor() {
    this.privs = this.getPrivileges();
  }

  // returns a boolean
  public isAuthorized(privilegeDetails: any): boolean {
    this.privs = this.getPrivileges();
    const screen = privilegeDetails['screenName'];
    const subModule = privilegeDetails['subModule'];
    const privilegeAsPerDB = Privileges[screen][subModule];
    return this.privs[privilegeAsPerDB] !== undefined;
  }

  private getPrivileges(): {} {
    let userPrivileges = JSON.parse(localStorage.getItem('userPrivileges'));
    if (!userPrivileges || Object.keys(userPrivileges).length === 0 ) {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      userPrivileges = {};
      if (!user || !user.privileges) {
        return userPrivileges;
      }
      user.privileges.forEach((element: any) => {
        userPrivileges[element.name] = element;
      });
      localStorage.setItem('userPrivileges', JSON.stringify(userPrivileges));
    }
    return userPrivileges;
  }

}
