import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';


@Injectable()
export class EventsService {
  public subject = new Subject<any>();

  sendMessage(data) {
    this.subject.next(data);
  }
}
