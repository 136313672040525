import { Component, OnInit, Injector, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { AuthService } from '../../shared/auth.service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Message } from 'primeng//api';
import { environment } from '../../../environments/environment';
import { AutoLogoutService } from '../../utils/auto-logout.service';
import { LocalStorageService } from '../../shared/local-storage.service';
import { ThemeingService } from '../../utils/themeing.service';
import { PrivilegeService } from '../../shared/privilege.service';
import { MessageService } from 'primeng/api';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';

declare let snowplow: Function;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  parentComponent: any;

  model: any = {};
  loading = false;
  sessionToken: any;
  isForgotPwd = false;
  captcha: any;
  logincaptcha: any;
  message: String = '';
  email: String = '';
  standPid: any;
  dataObj: any = {};
  stoken: any;
  selectpath: any;
  emailVerify = false;
  error = '';
  msgs: Message[] = [];
  resetPwd = false;
  confirmObj: any = {};
  pID: any;
  client = this.themeingService.getClientName();
  resetToken: any;
  logoURL = this.themeingService.getIcon('logoURL');
  @ViewChild('userName', { static: false }) private userName: ElementRef;
  emailRegex: any = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
  passwordform: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private location: Location,
    private injector: Injector,
    public privilegeService: PrivilegeService,
    private localStorage: LocalStorageService,
    private themeingService: ThemeingService,
    private messageService: MessageService
  ) {
    this.route.queryParams.subscribe(params => {
      if (params.resetToken) {
        this.showUpdatePwd(params);
        this.router.navigate(['/login']);
      }
    });
  }


  ngOnInit() {
    this.parentComponent = this.injector.get(AutoLogoutService);
    this.recaptchaKey();
    if (this.isForgotPwd === false && this.resetPwd === false) {
      this.message = environment[window.location.hostname].defaultTitle;
    }

    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/dashboard']);
    }
    if (this.parentComponent.showTimeoutMessage) {
      this.msgs = [];
      this.msgs.push({ severity: 'error', summary: 'Your session has expired, Please login again.' });
      this.parentComponent.isSessionTimeOut = false;
      this.parentComponent.showTimeoutMessage = false;
    }
    this.createForm()
  }

  createForm(): void {
    this.passwordform = this.fb.group({
      'newPassword': new FormControl('', Validators.required),
      'password': new FormControl('', Validators.required),
    });
  }


  ngAfterViewInit() {
    // this.userName.nativeElement.focus();
  }

  recaptchaKey() {
    return environment[window.location.hostname].siteKey;
  }

  resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
  }

  captchresolved(captchaResponse: string) {
    this.logincaptcha = captchaResponse;
  }

  showUpdatePwd(obj) {
    this.resetToken = obj['resetToken'];
    this.resetPwd = true;
    this.message = 'Change Password';
    this.createForm()
  }

  navigateToLogin() {
    this.isForgotPwd = false;
    this.message = environment[window.location.hostname].defaultTitle + ' ' + environment[window.location.hostname].envType;
  }

  loginClickHandler() {
    if (!this.model.username || !this.model.password) {
      this.msgs = [];
      this.msgs.push({ severity: 'error', summary: 'Please enter valid Username and Password.' });
    } else if (!this.model.username || !this.model.password) return;
    else if (this.model.username && this.model.password) {
      this.login();
    }
  }

  login() {
    this.loading = true;
    if (!this.model.username) {
      this.msgs = [];
      this.msgs.push({ severity: 'error', summary: 'Invalid credentials, Please try again' });
      this.loading = false;
      return;
    }
    this.authService.login(this.model.username.trim(), this.model.password).then((res) => {
      this.loading = false;
      this.msgs = [];
      if (res.status === "success") {
        res['roleCodes'][0] = atob(res['roleCodes'][0]);
        res['loginName'] = atob(res['loginName']);
        res['email'] = atob(res['email']);
        localStorage.setItem('currentUser', JSON.stringify(res));
        const currentUser = this.authService.currentUser();
        snowplow('setUserId', currentUser['_id']);
        if (!currentUser.resetPassword) {
          this.router.navigate(['/dashboard']);
          this.localStorage.initLocalStorageData();
          const sessionToken = currentUser['sessionToken'];
          // const sessionID = (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase()
          snowplow('trackStructEvent', 'user_session', 'login', sessionToken, 'SESSION_TOKEN', sessionToken);
        } else if (currentUser.resetPassword) {
          this.router.navigate(['/resetpassword']);
        }
      } else {
        this.msgs = [];
        if (res.status === "ERR_AUTH_FAILLED") {
          this.msgs.push({ severity: 'error', summary: 'Invalid credentials, Please try again' });
        } else {
          this.msgs.push({ severity: 'error', summary: res.status })
        }
      }

    }).catch(err => {
      this.msgs = [];
      this.msgs.push({ severity: 'error', summary: 'Invalid credentials, Please try again' });
      this.loading = false;
      this.error = err;
    });
  }

  sendResetLink() {
    if (!this.emailRegex.test(this.email)) {
      this.msgs = [];
      this.msgs.push({ severity: 'error', summary: "Please enter valid email." });
      return;
    }
    if (!this.email || !this.captcha) return;
    const query: any = {};
    query['emailID'] = this.email;
    this.authService.forgotPassword(query).then(data => {
      if (data['status'] === 'success') {
        this.isForgotPwd = false;
        this.msgs = [];
        this.message = environment[window.location.hostname].defaultTitle + ' ' + environment[window.location.hostname].envType;
        this.messageService.add({ severity: 'success', detail: 'Link has been sent to the registered email' });
        return;
      } else if (data['status'] == "ERR_INVALID_LOGIN") {
        this.msgs = [];
        this.messageService.add({ severity: 'error', detail: "Please enter valid email." });
      } else if (data['status'] == "ERR_PASSWORD_RESET_LIMIT_EXCEEDED") {
        this.messageService.add({ severity: 'error', detail: 'Password reset limit has been exceeded. Please try after sometime.' });
      }
      else if (data['status'] == "USER BLOCKED - MAX RESET ATTEMPTS EXCEEDED") {
        this.msgs = [];
        this.messageService.add({ severity: 'error', detail: 'Maximun password reset limit has been exceeded.User blocked' });
      }
      else {
        this.messageService.add({ severity: 'error', detail: data['status'] });
      }
    }).catch(err => {
      this.msgs = [];
      this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
    });
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.loginClickHandler();
    }
  }

  forgotPassword() {
    this.email = '';
    this.msgs = [];
    this.isForgotPwd = true;
    this.message = 'Forgot Password';
    this.emailVerify = true;
  }

  reset() {
    if (!this.passwordform.controls['password'].valid || !this.passwordform.controls['newPassword'].valid) return;
    if (this.confirmObj.password && this.confirmObj.newPassword && this.confirmObj.password != this.confirmObj.newPassword) {
      this.msgs = [];
      this.msgs.push({ severity: 'error', summary: 'Password mismatch' });
      return;
    }
    if (this.confirmObj.password && this.confirmObj.newPassword && this.confirmObj.password === this.confirmObj.newPassword) {
      const data = {
        'newPassword': window.btoa(this.confirmObj.newPassword),
        'resetToken': this.resetToken
      };
      this.resetPassword(data);
    }
  }

  resetPassword(data: any) {
    data.resetPassword = false;
    this.authService.updatePaswrd(data).then(res => {
      this.msgs = [];
      if (res['status'] === 'success') {
        this.msgs.push({ severity: 'success', summary: 'Password updated successfully' });
        this.resetPwd = false;
        this.message = environment[window.location.hostname].defaultTitle + ' ' + environment[window.location.hostname].envType;
        this.emailVerify = false;
        this.location.replaceState('login');
        return;
      } else if (res['status'] === 'ERR_PWD_COMPLEXITY_ADMIN') {
        this.msgs.push({ severity: 'error', summary: "Admin Password must be 15 characters including 1 uppercase letter, 1 lowercase letter and 1 numeric digit" });
      } else if (res['status'] === 'ERR_PWD_COMPLEXITY_OPS') {
        this.msgs.push({ severity: 'error', summary: "Operations Password must be 15 characters including 1 uppercase letter, 1 lowercase letter and 1 numeric digit" });
      } else if (res['status'] === 'ERR_PWD_COMPLEXITY') {
        this.msgs.push({ severity: 'error', summary: "Password must be 8 characters including 1 uppercase letter, 1 lowercase letter and 1 numeric digit" });
      } else if (res['status'] === 'ERR_USER_NOT_FOUND') {
        this.msgs.push({ severity: 'error', summary: "User not found" });
      } else {
        this.msgs.push({ severity: 'error', summary: res['status'] });
      }
    }).catch(err => {
      this.msgs = [];
      this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
    });
  }
}
