import { Component, Input, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import { seriesChartType } from '../../../../utils/util-interfaces';
import { formatNumberWithUnit } from '../../../../utils/util-functions';
import { formatNumber, formatDate } from '@angular/common';

@Component({
  selector: 'app-client-chart',
  templateUrl: './client-chart.component.html',
  styleUrls: ['./client-chart.component.scss']
})
export class ClientChartComponent {

  @Input() clientChartData: {x: string[], y: seriesChartType[]};
  @Input() startDate: Date;
  @Input() endDate: Date;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      const change = changes[propName];
      const curVal = change.currentValue;

      switch (propName) {
        case 'startDate':
          this.startDate = curVal;
          if (this.clientChartData) {this.plotGraph(this.clientChartData.x, this.clientChartData.y); }
          break;
        case 'endDate':
          this.endDate = curVal;
          if (this.clientChartData) {this.plotGraph(this.clientChartData.x, this.clientChartData.y); }
          break;
        case 'clientChartData':
          if (curVal) { this.plotGraph(curVal.x, curVal.y); }
          break;
        default:
          break;
      }
    }
  }

  plotGraph(xData: string[], seriesData: seriesChartType[]) {

    Highcharts.setOptions({
      lang: {
        decimalPoint: '.',
        thousandsSep: ','
      }
    });

    const options: any = {
      chart: {
        type: 'column',
        scrollablePlotArea: {
          minWidth: Math.round(seriesData[0]?.data.length * 125),
          scrollPositionX: 0
        }
      },

      title: {
        text: ''
      },

      xAxis: {
        categories: xData,
      },

      yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
          text: 'AUM ( In HKD )'
        },
        labels: {
          formatter: function() { return formatNumberWithUnit(this.value, '1.0-1'); }
        },
        gridLineDashStyle: 'LongDash'
      },

      tooltip: {
        backgroundColor: '#ffffff',
        borderRadius: 25,
        formatter: function() {
          return  '<span class="tooltip">' + this.x + '</span>' +
                  '<br><br><table><tr>' +
                  '<td style="color:#666666;margin-right:3rem;">Ticker</td>' +
                  '<td style="color:' + this.series.color + ';font-size:0.75rem;font-weight:bold;padding-left:0.5rem;">' +
                    this.series.name +
                  '</td></tr>' +
                  '<tr>' +
                  '<td style="color:#666666;margin-right:0.8rem">AUM ( HK$ )</td>' +
                  '<td><b style="color:' + this.series.color + ';font-size:1rem;padding-left:0.5rem;">' + formatNumber(this.y, 'en-US', '1.0-0') + '</b></td>' +
                  '</tr><tr>' +
                  '<td style="color:#666666;margin-right:3rem">Date</td>' +
                  '<td style="color:' + this.series.color + ';font-size:0.75rem;font-weight:bold;padding-left:0.5rem;">' +
                    formatDate(this.series.userOptions.date, 'd LLL yyyy', 'en-US') +
                  '</td></tr></table>';
        },
        shared: false,
        useHTML: true
      },

      plotOptions: {
        column: {
          stacking: 'normal'
        },
        series: {
          events: {
            legendItemClick: function() {
              const startTickerIndex = this.chart.series.findIndex(t => t.index == this.index && t.name == this.name);
              const endTickerIndex = this.chart.series.findIndex(t => t.index != this.index && t.name == this.name);
              !this.chart.series[startTickerIndex].visible ?
              this.chart.series[endTickerIndex].show() : this.chart.series[endTickerIndex].hide();
            }
          }
        }
      },

      credits: {
        enabled: false
      },

      legend: {
        layout: 'horizontal',
        align: 'right',
        verticalAlign: 'right'
      },
      series: seriesData
    };

    Highcharts.chart('container', options);
  }
}
