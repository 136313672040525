import { Injectable } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';

@Injectable()
export class SortParamsService {

    constructor() { }
    getParams(event: LazyLoadEvent): Object {
        const sortParams: Object = {};
        let sortOrder = 'asc';
        if (event.sortOrder == -1) {
            sortOrder = 'desc';
        }
        if (event.sortField) {
            const sortBy: Object = {};
            sortBy[event.sortField] = sortOrder;
            sortParams['sortBy'] = sortBy;
        }
        return sortParams;
    }
}
