import { formatDate, formatNumber } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { getApiFormattedDate } from '../../../../utils/util-functions';

@Component({
  selector: 'app-aum-chart',
  templateUrl: './aum-chart.component.html',
  styleUrls: ['./aum-chart.component.scss']
})
export class AumChartComponent implements OnInit {

  @Input() teamChartData: any;
  @Input() tickerChartData: any;
  @Input() startDate: Date;
  @Input() endDate: Date;

  @Input() chartUtilities: any;

  chartData: any;
  chartOptions: any;

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      const change = changes[propName];
      const curVal = change.currentValue;

      switch (propName) {
        case 'startDate':
          this.startDate = curVal;
          break;
        case 'endDate':
          this.endDate = curVal;
          break;
        case 'tickerChartData':
          this.getFormattedData(this.tickerChartData);
          break;
        default:
          break;
      }
    }
  }

  getFormattedData(data) {
    const startAumArray: number[] = [];
    const endAumArray: number[] = [];
    const participantArray: string[] = [];
    const startDate = getApiFormattedDate(this.startDate);
    const endDate = getApiFormattedDate(this.endDate);

    if (data) {
      data[startDate.split('T')[0]].forEach(element => {
        startAumArray.push(Math.round(element.aum));
        participantArray.push(element.tickerName);
      });
    }

    if (data) {
      data[endDate.split('T')[0]].forEach(element => {
        endAumArray.push(Math.round(element.aum));
      });
    }

    const chartdata = { startAumArray, endAumArray, participantArray };
    this.plotGraph(chartdata);
  }

  plotGraph(data) {
    const { startAumArray, endAumArray, participantArray } = data;

    this.chartData = {
      labels: participantArray,
      datasets: [
        {
          label: formatDate(this.startDate, 'dd LLL yyyy', 'en-US'),
          backgroundColor: this.chartUtilities.primaryColor,
          data: startAumArray,
          pointStyle: 'circle',
        },
        {
          label: formatDate(this.endDate, 'dd LLL yyyy', 'en-US'),
          backgroundColor: this.chartUtilities.secondaryColor,
          data: endAumArray,
          pointStyle: 'circle',
        }
      ]
    };

    this.chartOptions = {

      responsive: false,
      maintainAspectRatio: false,
      legend: {
        display: true,
        labels: {
          fontColor: '#040D1C',
          usePointStyle: true
        }
      },
      borderWidth: 10,
      scales: {
        padding: 1000,
        xAxes: [{
          gridLines: {
            drawBorder: false,
            display: false
          },
          // to change title label of X axis
          scaleLabel: {
            display: true,
            labelString: this.chartUtilities.labelText,
            fontColor: '#7d7d7d',
            font: { size: 25 }
          },
          ticks: {
            display: true,
            fontColor: '#4C5862',
            callback: (label) => (/\s/.test(label)) ? label.split(' ') : label,
          },
        }],
        yAxes: [{
          display: true,
          gridLines: {
            drawBorder: false,
            borderDash: [10, 10],
          },
          // to change title label of Y axis
          scaleLabel: {
            display: true,
            labelString: 'AUM ( In HKD )',
            fontColor: '#7d7d7d',
            font: { size: 20 }
          },
          label: function(tooltipItem, chartData) {
            const aum = formatNumber(tooltipItem.value, 'en-US', '1.0-0');
            return tooltipItem.label + ' : ' + aum;
          },
          ticks: {
            showDatapoints: true,
            barPercentage: 0.6,
            beginAtZero: true,
            fontColor: '#4C5862',
            callback: function (tick) {
              return (tick < 1000000 && tick != 0) ?
                tick / 1000000 + ' M' :
                tick / 10000000000 + ' B';
            }
          },
        }]
      }
    };
  }
}
