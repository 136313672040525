import { Component, OnInit, Input, Injector, Output, EventEmitter } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from '../../shared/auth.service';
import { ViewChild } from '@angular/core';
import { ApiService } from '../../shared/api.service';
import { PageService } from '../../shared/pages.service';
import { AppComponent } from '../../app.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile.modal.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileModalComponent implements OnInit {
  parentComponent: any;
  isLoading = false;
  loading = false;
  updatePwd = false;
  headerMessage: any;
  error = '';
  user: any;
  isDisable = false;
  profile: any = {};
  passwordModal = false;
  model: any = {};
  resetToken = '';
  imgUrl: any;
  profileDetails: any = {};
  @ViewChild('upload', { static: false }) myUploadVariable: any;
  @Input() isDisplay = false;
  @Output() isDisplayChange = new EventEmitter<boolean>();
  type = 'password';
  show = false;
  profileform: FormGroup;
  onClickDelete: boolean;
  resetImgUrl: any;
  pattern: any;
  minLength: any = 8

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    public injector: Injector,
    private apiService: ApiService,
    private pageService: PageService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.headerMessage = 'Edit Profile';
    this.pattern = this.user?.roleCodes && this.user?.roleCodes?.length > 0 && this.user?.roleCodes[0] == "ADMIN" ? /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z!@#$%&()-{}`~.+÷ß×¢£¤¥§©«µ¶₹€»,"'|\/d]{15,}$/ : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z!@#$%&()-{}`~.+÷ß×¢£¤¥§©«µ¶₹€»,"'|\/d]{8,}$/
    this.minLength = this.user?.roleCodes && this.user?.roleCodes?.length > 0 && this.user?.roleCodes[0] == "ADMIN" ? 15 : 8
    this.createForm();
    this.disable();
    this.getProfileDetails();
  }

  changePasswordClick() {
    this.model = {};
    this.createForm();
    this.headerMessage = 'Update Password';
    this.updatePwd = true;
  }

  cancelPwdUpdate() {
    this.headerMessage = 'Edit Profile';
    this.updatePwd = false;
  }

  createForm(): void {
    this.profileform = this.fb.group({
      'oldPassword': new FormControl('', Validators.required),
      'password': new FormControl('', Validators.required),
      'newPassword': new FormControl('', Validators.required),
      'firstName': new FormControl('', Validators.required),
      'lastName': new FormControl('', Validators.required),
    });
  }

  disable() {
    this.parentComponent = this.injector.get(AppComponent);
    this.parentComponent.topbarMenuActive = false;
  }

  getProfileDetails() {
    this.authService.myProfile().then(data => {
      this.profile.firstName = data.firstName;
      this.profile.lastName = data.lastName;
      this.profile.email = data.email;
      this.profileDetails = { ...this.profile };
      this.profile.role = data.roleCodes[0];
      this.imgUrl = data.imgUrl;
      this.resetImgUrl = data.imgUrl;
      this.user['firstName'] = data.firstName;
      this.user['lastName'] = data.lastName;
      this.user['imgUrl'] = data.imgUrl;
      this.authService.currentUser['imgUrl'] = data.imgUrl;
    }).catch(err => {
    });
  }

  resetNclose() {
    this.imgUrl = this.resetImgUrl;
    this.close();
  }

  pwdclose() {
    this.passwordModal = false;
  }

  updatePasswordClick() {
    if (!this.profileform.controls['password'].valid || !this.profileform.controls['oldPassword'].valid || !this.profileform.controls['newPassword'].valid) return;
    const loggeduser = this.authService.currentUser();
    this.resetToken = loggeduser.resetToken;
    const data = {
      'newPassword': window.btoa(this.model.newPassword),
      'oldPassword': window.btoa(this.model.oldPassword)
    };
    if (this.model.password === this.model.newPassword) {
      this.resetPwd(data);
    } else {
      this.messageService.add({ severity: 'error', detail: 'Password mismatch' });
    }
  }

  resetPwd(obj) {
    this.authService.updatePassword(obj).then(data => {
      if (data['status'] === 'success') {
        this.messageService.add({ severity: 'success', detail: 'Password changed successfully' });
        this.logout();
      } else {
        this.messageService.add({ severity: 'error', detail: data['status'] });
      }
    }).catch(err => {
          this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
    });
  }

  logout() {
    this.authService.logout();
  }

  uploadProfile(event) {
    this.isLoading = true;
    const aeFile = event.target.files[0];
    if (aeFile?.type === "image/jpeg" || aeFile?.type === "image/jpg" || aeFile?.type === "image/png") {
      const formData: any = new FormData();
      formData.append('file', aeFile);
      this.apiService.put_('commons/uploadfile', formData).then(data => {
        if (data.status == "success") this.imgUrl = data.imgUrl;
        else {
          this.messageService.add({ severity: 'error', detail: data.status });
          this.imgUrl = ""
        }
        this.myUploadVariable.files = [];
      }).catch(err => {
      });
    } else {
      this.messageService.add({ severity: 'error', detail: "Unsupported file Type" });
    }
  }

  updateProfileData() {
    if (!this.profile.firstName || !this.profile.lastName) return;
    let userData: any = {};
    userData = {
      firstName: this.profile.firstName,
      lastName: this.profile.lastName,
      imgUrl: this.imgUrl
    };
    this.update(userData);
    this.close();
    this.messageService.add({ severity: 'success', detail: 'Profile updated successfully' });
  }

  update(userData) {
    this.authService.updateimgUrl(userData.imgUrl);
    this.authService.updateUserData(userData);
    this.pageService.changeProfileData.next(userData);
    this.authService.update(userData).then(res => {
      this.profileDetails = this.profile;
      this.imgUrl = res['imgUrl'];
      this.isLoading = false;
    }).catch(err => {
      this.isLoading = false;
    });
  }

  close() {
    this.isDisplay = false;
    this.isDisplayChange.emit(this.isDisplay);
  }

  toggleShowPwd() {
    this.show = !this.show;
    if (this.show) {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }

  onClickRemove() {
    this.imgUrl = '';
    this.onClickDelete = false;
    this.messageService.add({ severity: 'success', detail: 'Profile image deleted successfully' });
  }

  onClickRemoveProfile() {
    this.onClickDelete = true;
  }

  onClickYes() {
    this.onClickRemove();
  }

}
