import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { WebsocketService } from './websocket.service';
import { environment } from '../../environments/environment';

export interface Message {
    message: string;
    firstName: string;
    caseName: string;
    caseID: string;
}

@Injectable()
  export class ChatService {
      public messages: Subject<Message>;
      constructor(wsService: WebsocketService, public injector: Injector) {

      if (!JSON.parse(localStorage.getItem('currentUser'))) {
        return;
      }
      const CHAT_URL = environment[window.location.hostname].notifUrl + '?room=' + JSON.parse(localStorage.getItem('currentUser'))['_id'];
      this.messages = <Subject<Message>>wsService
          .connect(CHAT_URL)
          .map((response: MessageEvent): Message => {
              return {
                message: JSON.parse(response.data).message,
                firstName: JSON.parse(response.data)['extras'].firstName,
                caseName: JSON.parse(response.data)['extras'].caseName,
                caseID: JSON.parse(response.data)['extras'].entityID,
              };

          });
    }
}
