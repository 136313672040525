import { formatNumber, formatPercent } from '@angular/common';
import { Component, Input } from '@angular/core';
import { processedSalesOverviewAum } from '../../../../utils/util-interfaces';

@Component({
  selector: 'app-sales-table',
  templateUrl: './sales-table.component.html',
  styleUrls: ['./sales-table.component.scss']
})
export class SalesTableComponent {

  @Input() salesStartDate: Date;
  @Input() salesEndDate: Date;
  @Input() data: processedSalesOverviewAum[];

  constructor() { }

  getValidAum(value: number) {
    if (isNaN(value) || value == null) {
      return 'NA';
    } else {
      return formatNumber(value, 'en-US', '1.0-0');
    }
  }

  getValidPercentage(value: number, checkValue: number) {
    if (isNaN(checkValue) || checkValue == null || isNaN(value) || value == null || checkValue == 0) {
      return 'NA';
    } else {
      return formatPercent(value, 'en-US', '1.2-2');
    }
  }
}
