import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { DEFAULT_TICKER_OBJECT, TICKER_TITLE, SIDE_NAVE_TITLES, ROLLING_PERIOD_TYPES, DEFAULT_PARTICIPANT_OBJECT, DEFAULT_SALESPERSON_OBJECT, DEFAULT_TEAM_OBJECT, ERROR_MSG, SHAREHOLDING_CHART_LABELS, DEFAULT_ISSUER_OBJECT } from '../../utils/util-constants';
import { tickerObject, shareHoldingResponse, rollingPeriodType, shareHoldingTickerResponse, teamObject, clientObject, teamShareholdingsResponse, shareholdingRollingType, chartShareholdingsData, salesPersonObject, shareholdingLocalStorage, issuerObj } from '../../utils/util-interfaces';
import { DatePipe } from '@angular/common';
import { convertToSnakeCase, getApiFormattedDate, setLocalStorageData } from '../../utils/util-functions';
import { CcassService } from '../../../ccass/ccass.service';

@Component({
  selector: 'app-shareholding-analysis',
  templateUrl: './shareholding-analysis.component.html',
  styleUrls: ['./shareholding-analysis.component.scss'],
  providers: [DatePipe]
})
export class ShareholdingAnalysisComponent implements OnInit {

  @Input() listOfTickers: tickerObject[];
  @Input() listOfTeams: teamObject[];
  @Input() listOfClient: clientObject[];
  @Input() listOfSalesperson: salesPersonObject[];
  @Input() listOfIssuer: issuerObj[];
  @Input() filteredListOfClient: clientObject[];
  @Input() startDate: Date;
  @Input() endDate: Date;

  @Output() setStartDate = new EventEmitter<Date>();
  @Output() setEndDate = new EventEmitter<Date>();
  @Output() titleHeading = new EventEmitter<string>();
  @Output() shouldDisplayTitle = new EventEmitter<boolean>();

  // Table data
  teamAnalysisData: teamShareholdingsResponse[];

  // Dropdown data
  clientAnalysisTicker: tickerObject = DEFAULT_TICKER_OBJECT;
  selectedListofClient: clientObject[] = [];
  selectedClient: clientObject[] = [DEFAULT_PARTICIPANT_OBJECT];
  selectedSalesPerson: salesPersonObject = DEFAULT_SALESPERSON_OBJECT;
  selectedTeam: teamObject = DEFAULT_TEAM_OBJECT;

  // ticker-card data
  baseCardTitle: string = TICKER_TITLE.baseTicker;
  compare1CardTitle: string = TICKER_TITLE.compareTicker1;
  compare2CardTitle: string = TICKER_TITLE.compareTicker2;
  baseTicker: tickerObject = DEFAULT_TICKER_OBJECT;
  compareTicker1: tickerObject = DEFAULT_TICKER_OBJECT;
  compareTicker2: tickerObject = DEFAULT_TICKER_OBJECT;
  baseIssuer: issuerObj;
  compareIssuer1: issuerObj;
  compareIssuer2: issuerObj;
  rollingPeriod: rollingPeriodType = ROLLING_PERIOD_TYPES[0];
  shareHoldingData: shareHoldingResponse[] = [];
  baseTickerShareHoldingData: shareHoldingTickerResponse;
  comp1TickerShareHoldingData: shareHoldingTickerResponse;
  comp2TickerShareHoldingData: shareHoldingTickerResponse;

  // Chart Data
  baseTickerChartData: chartShareholdingsData;
  comparisonTicker1ChartData: chartShareholdingsData;
  comparisonTicker2ChartData: chartShareholdingsData;
  clientAnalysisData: chartShareholdingsData;

  pageTitle: string = SIDE_NAVE_TITLES.shareholdingAnalysis;
  loadingArray: boolean[] = [false, false];
  teamTableLoader = true;
  showAlertPopup = false;
  message: string;
  customDates: {start: Date, isSelected: boolean} = {start: null, isSelected: true};

  baseTickerChartLabel = SHAREHOLDING_CHART_LABELS.base;
  compareTickerChartLabel = SHAREHOLDING_CHART_LABELS.compare;

  constructor(
    private datePipe: DatePipe,
    private ccassService: CcassService
  ) { }

  ngOnInit() {
    this.customDates = {start: this.startDate, isSelected: true};

    const localData: shareholdingLocalStorage = {
      baseTicker : this.baseTicker,
      compareTicker1 : this.compareTicker1,
      compareTicker2 : this.compareTicker2,
      selectedTeam : this.selectedTeam,
      selectedClient : this.selectedClient,
      selectedSalesPerson : this.selectedSalesPerson,
      selectedListofClient : this.selectedListofClient
    };
    localStorage.setItem(convertToSnakeCase(this.pageTitle), JSON.stringify(localData));

    this.titleHeading.emit(this.pageTitle);
    this.shouldDisplayTitle.emit(true);

    this.getAllData();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      const change = changes[propName];
      const {currentValue, firstChange} = change;

      switch (propName) {
        case 'startDate':
          if (this.customDates.isSelected) { this.customDates.start = currentValue; }
          this.checkAndSetPeriodOption();
          if (!firstChange) { this.getAllData(); }
          break;
        case 'endDate':
          this.checkAndSetPeriodOption();
          if (!firstChange) { this.getAllData(); }
          break;
        case 'listOfIssuer':
          if (currentValue && this.baseIssuer && this.baseIssuer.id != 0 && this.compareIssuer1.id != 0 && this.compareIssuer2.id != 0) {
            this.baseIssuer = currentValue.find(i => i.id == this.baseTicker.issuerId);
            this.compareIssuer1 = currentValue.find(i => i.id == this.compareTicker1.issuerId);
            this.compareIssuer2 = currentValue.find(i => i.id == this.compareTicker2.issuerId);
          } else {
            this.baseIssuer = DEFAULT_ISSUER_OBJECT;
            this.compareIssuer1 = DEFAULT_ISSUER_OBJECT;
            this.compareIssuer2 = DEFAULT_ISSUER_OBJECT;
          }
        default:
          break;
      }
    }
  }

  getAllData() {

    this.getShareholdingData();
    this.getClientAnalysisData();
    this.getTeamDataWithFilter();
  }

  getShareholdingData() {
    this.loadingArray[0] = true;

    const query = {};
    query['endDate'] = getApiFormattedDate(this.endDate);
    query['startDate'] = getApiFormattedDate(this.startDate);
    query['baseFundId'] = this.baseTicker.fundId;
    query['comp1FundId'] = this.compareTicker1.fundId;
    query['comp2FundId'] = this.compareTicker2.fundId;

    this.ccassService.ccassPost(query, 'v1/ticker-shareholding/').then(response => {
      this.loadingArray[0] = false;

      if (response['status'] && response['status'] === 'success') {
        this.baseTickerShareHoldingData = response['data']['baseTickerId'];
        this.comp1TickerShareHoldingData = response['data']['comp1TickerId'];
        this.comp2TickerShareHoldingData = response['data']['comp2TickerId'];

        this.calculateRollingChartData();

      } else {
        this.showWarningPopup();
      }
    }).catch(err => {
      this.loadingArray[0] = false;

      this.baseTickerShareHoldingData = null;
      this.comp1TickerShareHoldingData = null;
      this.comp2TickerShareHoldingData = null;
      this.calculateRollingChartData();

      this.showWarningPopup(ERROR_MSG.errorRecords);
    });
  }

  getClientAnalysisData() {
    this.loadingArray[1] = true;

    const query = {};
    query['endDate'] = getApiFormattedDate(this.endDate);
    query['startDate'] = getApiFormattedDate(this.startDate);
    query['fundId'] = this.clientAnalysisTicker.fundId;
    query['participantId'] = this.selectedListofClient && this.selectedListofClient.length != 0 ?
      this.selectedListofClient.map(value => value.id) : [0];

    this.ccassService.ccassPost(query, 'v1/client-analysis/').then(response => {
      this.loadingArray[1] = false;

      if (response['status'] && response['status'] === 'success') {
        this.clientAnalysisData = this.getFormattedData(response['data'], true);
      } else {
        this.showWarningPopup();
      }
    }).catch(err => {
      this.loadingArray[1] = false;
      this.showWarningPopup(ERROR_MSG.errorRecords + ' for Client Analysis');
      this.clientAnalysisData = { dates: [], shareholdings: [] };
    });
  }

  getTeamDataWithFilter() {
    this.teamTableLoader = true;

    const query = {};
    query['endDate'] = getApiFormattedDate(this.endDate);
    query['startDate'] = getApiFormattedDate(this.startDate);
    query['teamId'] = this.selectedTeam.id;
    query['salespersonId'] = this.selectedSalesPerson.id;
    query['participantId'] = this.selectedClient.map(value => value.id);
    query['baseFundId'] = this.baseTicker.fundId;
    query['comp1FundId'] = this.compareTicker1.fundId;
    query['comp2FundId'] = this.compareTicker2.fundId;

    this.ccassService.ccassPost(query, 'v1/participant-shareholding/').then(response => {
      this.teamTableLoader = false;
      if (response['status'] && response['status'] === 'success') {
        this.teamAnalysisData = response['data'];
      } else {
        this.teamAnalysisData = [];
        this.showWarningPopup();
      }
    }).catch(err => {

      this.teamAnalysisData = [];
      this.teamTableLoader = false;
      this.showWarningPopup(ERROR_MSG.errorRecords + ' for Teams');
    });
  }

  calculateRollingChartData() {

    this.baseTickerChartData = this.getFormattedData(this.baseTickerShareHoldingData.rolling, true);
    this.comparisonTicker1ChartData = this.getFormattedData(this.comp1TickerShareHoldingData.rolling, false);
    this.comparisonTicker2ChartData = this.getFormattedData(this.comp2TickerShareHoldingData.rolling, false);
  }

  // Format data for chart usage
  getFormattedData(data: shareholdingRollingType[], isBase: boolean) {
    const dates: string[] = [];
    const shareholdings: string[] = [];

    if (data) {
      data.forEach(value => {
        dates.push(this.datePipe.transform(new Date(value.recordDate), 'd MMM'));

        shareholdings.push(
          isBase ? value.shareholding.toString() : value.shareholdingInPercentage.toString()
        );
      });
    }

    return { dates, shareholdings};
  }

  // set ticker dropdowns
  setBaseTicker(key) {
    this.baseTicker = key;
    setLocalStorageData('baseTicker', key, this.pageTitle);
    this.getShareholdingData();
    this.getTeamDataWithFilter();
  }
  setComparing1Ticker(key) {
    this.compareTicker1 = key;
    setLocalStorageData('compareTicker1', key, this.pageTitle);
    this.getShareholdingData();
    this.getTeamDataWithFilter();
  }
  setComparing2Ticker(key) {
    this.compareTicker2 = key;
    setLocalStorageData('compareTicker2', key, this.pageTitle);
    this.getShareholdingData();
    this.getTeamDataWithFilter();
  }

  // set Issuer dropdowns
  setBaseIssuer(data: issuerObj) {
    this.baseIssuer = data;
    this.baseTicker = this.setTickerByIssuer(this.baseIssuer, data);

    // setLocalStorageData("baseTicker", data, this.pageTitle)
    this.getAllData();
  }
  setComparing1Issuer(data: issuerObj) {
    this.compareIssuer1 = data;
    this.compareTicker1 = this.setTickerByIssuer(this.compareIssuer1, data);

    // setLocalStorageData("compareTicker1", data, this.pageTitle)
    this.getShareholdingData();
    this.getTeamDataWithFilter();
  }
  setComparing2Issuer(data: issuerObj) {
    this.compareIssuer2 = data;
    this.compareTicker2 = this.setTickerByIssuer(this.compareIssuer2, data);

    // setLocalStorageData("compareTicker2", data, this.pageTitle)
    this.getShareholdingData();
    this.getTeamDataWithFilter();
  }
  setTickerByIssuer(issuer: issuerObj, data: issuerObj) {
    if (issuer.id != 0) { return this.listOfTickers.find(t => t.issuerId == data.id); } else { return DEFAULT_TICKER_OBJECT; }                     // when 'All' issuers are selected
  }

  // to get selected client from client analysis
  getSelectedParticipant(value: clientObject[]) {
    this.selectedListofClient = value;
    this.getClientAnalysisData();
  }
  selectedTickerEvent(value: tickerObject) {
    this.clientAnalysisTicker = value;
    this.getClientAnalysisData();
  }

  // to get selected client from Team
  getSelectedClient(value) {
    this.selectedClient = value;
    setLocalStorageData('selectedClient', value, this.pageTitle);
    this.getTeamDataWithFilter();
  }
  // to get selected team from Team
  getSelectedTeam(value) {
    this.selectedTeam = value;
    setLocalStorageData('selectedTeam', value, this.pageTitle);
    this.getTeamDataWithFilter();
  }
  // to get selected salesperson from Team
  getSelectedSalesperson(value) {
    this.selectedSalesPerson = value;
    setLocalStorageData('selectedSalesPerson', value, this.pageTitle);
    this.getTeamDataWithFilter();
  }

  onChangeRollingPeriod(e) {

    const tempEndDate = new Date(this.endDate.getTime());
    switch (e.value.name) {
      case 'Month':
        this.customDates.isSelected = false;
        tempEndDate.setMonth(tempEndDate.getMonth() - 1);
        this.startDate = tempEndDate;
        break;
      case 'Year':
        this.customDates.isSelected = false;
        tempEndDate.setUTCFullYear(tempEndDate.getUTCFullYear() - 1);
        this.startDate = tempEndDate;
        break;
      case 'Custom':
        this.customDates.isSelected = true;
        this.startDate = this.customDates.start;
        break;
      default:
        break;
    }
    this.setStartDate.emit(this.startDate);
    this.getShareholdingData();
    this.getClientAnalysisData();
  }

  checkAndSetPeriodOption() {

    const mDiff = this.endDate.getMonth() - this.startDate.getMonth();
    const monthDiff: number = mDiff < 0 ? mDiff + 12 : mDiff;
    const yearDiff: number = this.endDate.getFullYear() - this.startDate.getFullYear();

    if (this.startDate.getDate() == this.endDate.getDate()) {
      switch (true) {
        case monthDiff == 1:
          this.rollingPeriod = ROLLING_PERIOD_TYPES[1];   // Month option
          return;
        case yearDiff == 1:
          this.rollingPeriod = ROLLING_PERIOD_TYPES[2];   // Year option
          return;
        default:
          this.rollingPeriod = ROLLING_PERIOD_TYPES[0];    // Custom option
          return;
      }
    } else {
      this.rollingPeriod = ROLLING_PERIOD_TYPES[0];     // Custom option
    }
  }

  showWarningPopup(msg: string = ERROR_MSG.emptyRecords) {
    this.showAlertPopup = true;
    this.message = msg;
  }

  getLoadingStatus() {
    return !this.loadingArray.every(element => element == false);
  }
}
