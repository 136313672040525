import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import * as Chart from 'chart.js';

@Component({
	selector: 'app-nav-one-card',
	templateUrl: './nav-one-card.component.html',
	styleUrls: ['./nav-one-card.component.scss']
})
export class NavOneCardComponent implements OnInit {
	@Input() chartsDetails: any;
	defaultColors = ['#ACD679', '#FF8C5D'];

	constructor(
		private router: Router,
	) {
		Chart.pluginService.register({
			beforeDraw: function (chart) {
				if (chart.config.options.elements.center) {
					// Get ctx from string
					const ctx = chart.chart.ctx;
					// Get options from the center object in options
					const centerConfig = chart.config.options.elements.center;
					const fontStyle = centerConfig.fontStyle || 'Arial';
					const txt = centerConfig.text;
					const color = centerConfig.color || '#000';
					const maxFontSize = centerConfig.maxFontSize || 75;
					const sidePadding = centerConfig.sidePadding || 20;
					const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
					// Start with a base font of 30px
					// ctx.font = "10px " + fontStyle;


					// Get the width of the string and also the width of the element minus 10 to give it 5px side padding
					const stringWidth = ctx.measureText(txt).width;
					const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

					// Find out how much the font can grow in width.
					const widthRatio = elementWidth / stringWidth;
					// var newFontSize = Math.floor(30 * widthRatio);

					const elementHeight = (chart.innerRadius * 2);

					// Pick a new font size so it will not be larger than the height of label.
					const fontSizeToUse = Math.min(10, elementHeight, maxFontSize);
					const wrapText = false;

					// Set font settings to draw it correctly.
					ctx.textAlign = 'center';
					ctx.textBaseline = 'middle';
					const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
					const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
					ctx.font = 20 + 'px ' + fontStyle;
					ctx.fillStyle = color;

					if (!wrapText) {
						ctx.fillText(txt, centerX, centerY);
						return;
					}
				}
			}
		});
	}

	ngOnInit(): void {
		this.prepareDoughnutData();
	}

	onClickChart(event, chart, params, chartFor) {
		let routeParam: string;
		if (chart === 'doughnut') {
			if (chartFor === 'nav1signoff')
				routeParam = event === 1 ? 'nav1notsignedoff' + params : 'nav1signedoff' + params;
			else routeParam = event === 1 ? 'nav1notreconciled' + params : 'nav1reconciled' + params;
		} else {
			routeParam = event === 0 ? 'nav1metsla' : 'nav1unmetsla';
		}
		localStorage.removeItem('filtersObj');
		this.router.navigate(['/reconcilereport', routeParam]);
	}

	prepareDoughnutData() {
		var ctx = document.getElementById("signOffT0Data");
		let segment;
		let chartDetails = this.chartsDetails;
		let defaultColors = this.defaultColors;
		let _this = this;
		const DOUGHNUT_CHART_GREEN_COLOR = "#ACD679";
        const DOUGHNUT_CHART_ORANGE_COLOR = "#FF8C5D";
        const BAR_GRAPH_RED_COLOR = "#FF4646";
		const TEXT_ELEMENT_BLUE_COLOR = "#3b6ccc"
		const TEXT_BLACK_COLOR = "#000000";
        const ETF_TEXT_BLUE_COLOR = "#0C3062"


		var signOffT0Data = new Chart(ctx, {
			type: 'doughnut',
			data: {
				labels: ['Signed Off', 'Not Signed Off'],
				datasets: [{
					data: [Math.round(this.chartsDetails.signOffT0Count), Math.round(this.chartsDetails.unSignOffT0Count)],
					backgroundColor: [
						DOUGHNUT_CHART_GREEN_COLOR,
						DOUGHNUT_CHART_ORANGE_COLOR,
					],
					hoverBackgroundColor: [
						DOUGHNUT_CHART_GREEN_COLOR,
						DOUGHNUT_CHART_ORANGE_COLOR,
					],
					borderWidth: 1
				}]
			},
			options: {
				onClick: function (e) {
					var activePoints = signOffT0Data.getElementsAtEvent(e);
					const selectedIndex = activePoints[0]?._index;
					_this.onClickChart(selectedIndex, "doughnut", "t0", "nav1signoff")
				},
				elements: {
					center: {
						text: 'T0',
						color: TEXT_ELEMENT_BLUE_COLOR, // Default is #000000
						fontStyle: 'Arial', // Default is Arial
						sidePadding: 20, // Default is 20 (as a percentage)
						minFontSize: 10, // Default is 20 (in px), set to false and text will not wrap.
						lineHeight: 25 // Default is 25 (in px), used for when text wraps
					}
				},
				//cutoutPercentage: 40,
				usePointStyle: true,
				boxWidth: 4,
				responsive: false,
				cutoutPercentage: 70,
				legend: {
					display: true,
					position: 'left',
					labels: {
						fontSize: 10,
						fontWeight: 'bold',
						fontColor: TEXT_BLACK_COLOR,
						usePointStyle: true,
						boxWidth: 4,
						generateLabels: function (chart) {
							const d = chart;
							if (d) {
								return d.config.data.labels?.map(function (label, i) {
									const ds = d.config.data.datasets[0];
									return {
										text: ds.data[i] === 0 ? label + ' ' + 0 + ' ' + '%' : label + ' ' + Math.round((ds.data[i] / chartDetails.T0FundCount) * 100) + ' ' + '%',
										fillStyle: defaultColors[i],
									};
								});
							}
							return [];
						}
					}
				},
				onHover: function (evt, elements) {
					evt.target.style.cursor = elements[0] ? 'pointer' : 'default';
					if (elements && elements.length) {
						segment = elements[0];
						this.chart.update();
						segment._model.outerRadius += 3;
					} else {
						if (segment) {
							segment._model.outerRadius -= 3;
						}
						segment = null;
					}
				},
				tooltips: {
					callbacks: {
						label: function (tooltipItem, data) {
							var dataset = data.datasets[tooltipItem.datasetIndex];
							var currentValue = dataset.data[tooltipItem.index];
							var value = data['labels'][tooltipItem.index];
							return value + ' : ' + currentValue;
						}
					}
				},

			}
		});

		var ctx1 = document.getElementById("signOffT1Data");
		var signOffT1Data = new Chart(ctx1, {
			type: 'doughnut',
			data: {
				labels: ['Signed Off', 'Not Signed Off'],
				datasets: [{
					data: [Math.round(this.chartsDetails.signOffT1Count), Math.round(this.chartsDetails.unsignOffT1Count)],
					backgroundColor: [
						DOUGHNUT_CHART_GREEN_COLOR,
						DOUGHNUT_CHART_ORANGE_COLOR,
					],
					hoverBackgroundColor: [
						DOUGHNUT_CHART_GREEN_COLOR,
						DOUGHNUT_CHART_ORANGE_COLOR,
					],
					borderWidth: 1
				}]
			},
			options: {
				onClick: function (e) {
					var activePoints = signOffT1Data.getElementsAtEvent(e);
					const selectedIndex = activePoints[0]?._index;
					_this.onClickChart(selectedIndex, "doughnut", "t1", "nav1signoff")
				},
				elements: {
					center: {
						text: 'T1',
						color: TEXT_ELEMENT_BLUE_COLOR, // Default is #000000
						fontStyle: 'Arial', // Default is Arial
						sidePadding: 20, // Default is 20 (as a percentage)
						minFontSize: 10, // Default is 20 (in px), set to false and text will not wrap.
						lineHeight: 25 // Default is 25 (in px), used for when text wraps
					}
				},
				//cutoutPercentage: 40,
				usePointStyle: true,
				boxWidth: 4,
				responsive: false,
				cutoutPercentage: 70,
				legend: {
					display: true,
					position: 'left',
					labels: {
						fontSize: 10,
						fontWeight: 'bold',
						fontColor: TEXT_BLACK_COLOR,
						usePointStyle: true,
						boxWidth: 4,
						generateLabels: function (chart) {
							const d = chart;
							if (d) {
								return d.config.data.labels?.map(function (label, i) {
									const ds = d.config.data.datasets[0];
									return {
										text: ds.data[i] === 0 ? label + ' ' + 0 + ' ' + '%' : label + ' ' + Math.round((ds.data[i] / chartDetails.T1FundCount) * 100) + ' ' + '%',
										fillStyle: defaultColors[i],
									};
								});
							}
							return [];
						}
					}
				},
				onHover: function (evt, elements) {
					evt.target.style.cursor = elements[0] ? 'pointer' : 'default';
					if (elements && elements.length) {
						segment = elements[0];
						this.chart.update();
						segment._model.outerRadius += 3;
					} else {
						if (segment) {
							segment._model.outerRadius -= 3;
						}
						segment = null;
					}
				},
				tooltips: {
					callbacks: {
						label: function (tooltipItem, data) {
							var dataset = data.datasets[tooltipItem.datasetIndex];
							var currentValue = dataset.data[tooltipItem.index];
							var value = data['labels'][tooltipItem.index];
							return value + ' : ' + currentValue;
						}
					}
				},

			}
		});

		var ctx2 = document.getElementById("reconciledT0Data");
		var reconciledT0Data = new Chart(ctx2, {
			type: 'doughnut',
			data: {
				labels: ['Reconciled', 'Not Reconciled'],
				datasets: [
					{
						data: [Math.round(this.chartsDetails.reconciledT0Count), Math.round(this.chartsDetails.nonReconciledT0Count)],
						backgroundColor: [
							DOUGHNUT_CHART_GREEN_COLOR,
						    DOUGHNUT_CHART_ORANGE_COLOR,
						],
						hoverBackgroundColor: [
							DOUGHNUT_CHART_GREEN_COLOR,
						    DOUGHNUT_CHART_ORANGE_COLOR,
						]
					}
				]
			},
			options: {
				onClick: function (e) {
					var activePoints = reconciledT0Data.getElementsAtEvent(e);
					const selectedIndex = activePoints[0]?._index;
					_this.onClickChart(selectedIndex, "doughnut", "t0", "nav1recon")
				},
				elements: {
					center: {
						text: 'T0',
						color: TEXT_ELEMENT_BLUE_COLOR, // Default is #000000
						fontStyle: 'Arial', // Default is Arial
						sidePadding: 20, // Default is 20 (as a percentage)
						minFontSize: 10, // Default is 20 (in px), set to false and text will not wrap.
						lineHeight: 25 // Default is 25 (in px), used for when text wraps
					}
				},
				//cutoutPercentage: 40,
				usePointStyle: true,
				boxWidth: 4,
				responsive: false,
				cutoutPercentage: 70,
				legend: {
					display: true,
					position: 'left',
					labels: {
						fontSize: 10,
						fontWeight: 'bold',
						fontColor: TEXT_BLACK_COLOR,
						usePointStyle: true,
						boxWidth: 4,
						generateLabels: function (chart) {
							const d = chart;
							if (d) {
								return d.config.data.labels?.map(function (label, i) {
									const ds = d.config.data.datasets[0];
									return {
										text: ds.data[i] === 0 ? label + ' ' + 0 + ' ' + '%' : label + ' ' + Math.round((ds.data[i] / chartDetails.T0FundCount) * 100) + ' ' + '%',
										fillStyle: defaultColors[i],
									};
								});
							}
							return [];
						}
					}
				},
				onHover: function (evt, elements) {
					evt.target.style.cursor = elements[0] ? 'pointer' : 'default';
					if (elements && elements.length) {
						segment = elements[0];
						this.chart.update();
						segment._model.outerRadius += 3;
					} else {
						if (segment) {
							segment._model.outerRadius -= 3;
						}
						segment = null;
					}
				},
				tooltips: {
					callbacks: {
						label: function (tooltipItem, data) {
							var dataset = data.datasets[tooltipItem.datasetIndex];
							var currentValue = dataset.data[tooltipItem.index];
							var value = data['labels'][tooltipItem.index];
							return value + ' : ' + currentValue;
						}
					}
				},

			}
		});

		var ctx3 = document.getElementById("reconciledT1Data");
		var reconciledT1Data = new Chart(ctx3, {
			type: 'doughnut',
			data: {
				labels: ['Reconciled', 'Not Reconciled'],
				datasets: [
					{
						data: [Math.round(this.chartsDetails.reconciledT1Count), Math.round(this.chartsDetails.nonReconciledT1Count)],
						backgroundColor: [
							DOUGHNUT_CHART_GREEN_COLOR,
						    DOUGHNUT_CHART_ORANGE_COLOR,

						],
						hoverBackgroundColor: [
							DOUGHNUT_CHART_GREEN_COLOR,
						    DOUGHNUT_CHART_ORANGE_COLOR,
						]
					}
				]
			},
			options: {
				onClick: function (e) {
					var activePoints = reconciledT1Data.getElementsAtEvent(e);
					const selectedIndex = activePoints[0]?._index;
					_this.onClickChart(selectedIndex, "doughnut", "t1", "nav1recon")
				},
				elements: {
					center: {
						text: 'T1',
						color: TEXT_ELEMENT_BLUE_COLOR, // Default is #000000
						fontStyle: 'Arial', // Default is Arial
						sidePadding: 20, // Default is 20 (as a percentage)
						minFontSize: 10, // Default is 20 (in px), set to false and text will not wrap.
						lineHeight: 25 // Default is 25 (in px), used for when text wraps
					}
				},
				//cutoutPercentage: 40,
				usePointStyle: true,
				boxWidth: 4,
				responsive: false,
				cutoutPercentage: 70,
				legend: {
					display: true,
					position: 'left',
					labels: {
						fontSize: 10,
						fontWeight: 'bold',
						fontColor: TEXT_BLACK_COLOR,
						usePointStyle: true,
						boxWidth: 4,
						generateLabels: function (chart) {
							const d = chart;
							if (d) {
								return d.config.data.labels?.map(function (label, i) {
									const ds = d.config.data.datasets[0];
									return {
										text: ds.data[i] === 0 ? label + ' ' + 0 + ' ' + '%' : label + ' ' + Math.round((ds.data[i] / chartDetails.T1FundCount) * 100) + ' ' + '%',
										fillStyle: defaultColors[i],
									};
								});
							}
							return [];
						}
					}
				},
				onHover: function (evt, elements) {
					evt.target.style.cursor = elements[0] ? 'pointer' : 'default';
					if (elements && elements.length) {
						segment = elements[0];
						this.chart.update();
						segment._model.outerRadius += 3;
					} else {
						if (segment) {
							segment._model.outerRadius -= 3;
						}
						segment = null;
					}
				},
				tooltips: {
					callbacks: {
						label: function (tooltipItem, data) {
							var dataset = data.datasets[tooltipItem.datasetIndex];
							var currentValue = dataset.data[tooltipItem.index];
							var value = data['labels'][tooltipItem.index];
							return value + ' : ' + currentValue;
						}
					}
				},

			}
		});

		var ctx4 = document.getElementById("barChartData");
		const metCount = this.chartsDetails.metCount;
		const unMetCount = this.chartsDetails.unMetCount;
		var barChartData = new Chart(ctx4, {
			type: 'bar',
			data: {
				labels: ['Met SLA', 'Unmet SLA'],
				datasets: [
					{
						backgroundColor: [DOUGHNUT_CHART_GREEN_COLOR, BAR_GRAPH_RED_COLOR],
						data: [metCount, unMetCount],
						barPercentage: 0.4,
					}
				]
			},
			options: {
				onClick: function (e) {
					var activePoints = barChartData.getElementsAtEvent(e);
					const selectedIndex = activePoints[0]?._index;
					_this.onClickChart(selectedIndex, "bar", "", "nav1sla");
				},
				elements: {
					center: {
						text: '',
						color: TEXT_BLACK_COLOR, // Default is #000000
						fontStyle: 'Arial', // Default is Arial
						sidePadding: 20, // Default is 20 (as a percentage)
						minFontSize: 10, // Default is 20 (in px), set to false and text will not wrap.
						lineHeight: 25 // Default is 25 (in px), used for when text wraps
					}
				},
				//cutoutPercentage: 40,
				usePointStyle: true,
				boxWidth: 4,
				responsive: false,
				cutoutPercentage: 70,
				legend: {
					display: false,
					position: 'left',
					labels: {
						fontSize: 10,
						fontWeight: 'bold',
						fontColor: TEXT_BLACK_COLOR,
						usePointStyle: true,
						boxWidth: 4,
					}
				},
				onHover: function (evt, elements) {
					evt.target.style.cursor = elements[0] ? 'pointer' : 'default';
				},
				tooltips: {
					callbacks: {
						label: function (tooltipItem, data) {
							var dataset = data.datasets[tooltipItem.datasetIndex];
							var currentValue = dataset.data[tooltipItem.index];
							var value = data['labels'][tooltipItem.index];
							return value + ' : ' + currentValue;
						}
					}
				},
				animation: {
					onComplete: function () {
						const chartInstance = this.chart;
						const ctx = chartInstance.ctx;
						ctx.textBaseline = 'middle';
						ctx.textAlign = 'center';
						ctx.font = "10px \'Helvetica Neue\', Helvetica, Arial, sans-serif"
						this.data.datasets.forEach(function (dataset, i) {
							const meta = chartInstance.controller.getDatasetMeta(i);
							meta.data.forEach(function (bar, index) {
								const data = dataset.data[index];
								ctx.fillText(data, bar._model.x, bar._model.y - 10);
							});
						});
					}
				},
				scales: {
					yAxes: [{
						showDatapoints: true,
						// suggestedMin: 0,
						gridLines: {
							drawOnChartArea: false,
						},
						ticks: {
							max: this.chartsDetails.metCount + this.chartsDetails.unMetCount + 2,
							min: 0,
						},
						scaleLabel: {
							display: true,
							fontSize: (window.screen.width <= 1680) ? 9 : 10,
						}
					}],
					xAxes: [{
						showDatapoints: true,
						gridLines: {
							drawOnChartArea: false,
						},
						scaleLabel: {
							display: true,
							fontSize: (window.screen.width <= 1680) ? 9 : 10,
						},
						ticks: {
							padding: 0,
							fontSize: (window.screen.width <= 1680) ? 9 : 10,
							beginAtZero: true,
							fontColor: ETF_TEXT_BLUE_COLOR,
						}
					}]

				}
			}
		});
	}

}
