import { formatDate } from '@angular/common';
import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import { formatNumberWithUnit } from '../../../../utils/util-functions';
import { BAR_FIRST, BAR_SECOND } from '../../../../utils/util-colours';

import { aumGraphObject } from '../../../../utils/util-interfaces';

@Component({
  selector: 'app-sales-bar',
  templateUrl: './sales-bar.component.html',
  styleUrls: ['./sales-bar.component.scss']
})
export class SalesBarComponent implements OnChanges {

  @Input() aumGraphData: aumGraphObject;
  @Input() startDate: any;
  @Input() endDate: any;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      const change = changes[propName];
      const curVal = change.currentValue;

      switch (propName) {
        case 'aumGraphData':
          if (curVal) { this.plotGraph(curVal); }
          break;
        default:
          break;
      }
    }
  }

  plotGraph(data: aumGraphObject) {
    const { startAumArray, endAumArray, participantArray } = data;

    Highcharts.setOptions({
      lang: {
        decimalPoint: '.',
        thousandsSep: ','
      }
    });

    const options: any = {
      chart: {
        type: 'column',
        scrollablePlotArea: {
          minWidth: Math.round(participantArray.length * 150),
          scrollPositionX: 0
        },
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: participantArray,
      },
      yAxis: {
        title: {
          text: 'AUM ( In HKD )'
        },
        labels: {
          formatter: function() { return formatNumberWithUnit(this.value, '1.0-1'); }
        },
        gridLineDashStyle: 'LongDash',
      },
      credits: {
        enabled: false
      },

      legend: {
        layout: 'horizontal',
        align: 'right',
        verticalAlign: 'right',
        useHTML: true,
        labelFormatter: function() {
          return this.name;
        }
      },
      tooltip: {
        headerFormat: '<div style="background-color:{series.color};border-radius:25px;color:white; display:flex;align-items: center; justify-content: center; padding: 3px;">{series.name}</div><br>{point.key}',
        pointFormat: '<br><br><b>{point.y:,.0f} ( HK$ )</b>',
        shared: false,
        useHTML: true,
        outside: true,
        positioner: function(labelWidth, labelHeight, point) {
          const tooltipX = point.plotX + 325;
          const tooltipY = point.plotY + 300;
          return {
              x: tooltipX,
              y: tooltipY
          };
        }
      },
      plotOptions: {
        bar: {
          pointPadding: 1,
          groupPadding: 1,
          pointWidth: 10,
          maxPointWidth: 10,
          minPointLength: 10,
          borderRadius: 10
        }
      },

      series: [
        {
          type: 'column',
          name: formatDate(this.startDate, 'dd LLL yyyy', 'en-US'),
          data: startAumArray,
          color: BAR_FIRST
        },
        {
          type: 'column',
          name: formatDate(this.endDate, 'dd LLL yyyy', 'en-US'),
          data: endAumArray,
          color: BAR_SECOND
        }
      ]
    };

    Highcharts.chart('container', options);
  }
}
