import { Component, OnInit, Input, Output, EventEmitter, Injector } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FeeTypeToAccountMappingComponent } from './fee-type-to-account-mapping.component';
import { DefaultService } from '../../utils/default.service';

@Component({
  selector: 'add-fee-type',
  templateUrl: './add-fee-type.component.html',
  styleUrls: ['./fee-type-to-account-mapping.component.scss']
})
export class AddFeeTypeComponent implements OnInit {
  @Input() isDisplay = false;
  @Output() isDisplayChange = new EventEmitter<boolean>();
  @Input() selectedData: Object;
  feeForm: FormGroup;
  parentComponent: any;
  feeTypeLable: String;
  title: String;
  selectedFee: any = {};

  constructor(private fb: FormBuilder,
    private injector: Injector,
    private defaultservice: DefaultService) { }

  ngOnInit() {
    this.createForm();
    this.parentComponent = this.injector.get(FeeTypeToAccountMappingComponent);
    this.feeTypeLable = this.parentComponent.feeTypeLable;
    this.title = this.parentComponent.title;
  }

  createForm(): void {
    this.feeForm = this.fb.group({
      'feeType': new FormControl('', Validators.required),
      'tradeType': new FormControl('', Validators.required),
      'account': new FormControl('', Validators.required)
    });
  }

  createFee() {
    this.selectedFee['tradeType'] = 'Other Fee';
    this.selectedFee['isFixed'] = 'Y';
    this.defaultservice
      .create(this.selectedFee, 'quant/posting_def')
      .then(data => {
        if (data['status'] === 'success') {
          this.parentComponent.getFeeTypesData();
          this.parentComponent.messageService.add({
             severity: 'success', detail: 'Account added successfully' }
          );
          this.close();
        }
      })
      .catch(err => {
        this.parentComponent.messageService.add({
           severity: 'error', detail: err }
        );
      });
  }

  close() {
    this.isDisplay = false;
    this.isDisplayChange.emit(this.isDisplay);
  }
}
