
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DefaultService } from '../../utils/default.service';
import { TableSchema } from '../../utils/table-schema';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-account-maintainance',
  templateUrl: './account-maintainance.component.html',
  styleUrls: ['./account-maintainance.component.scss']
})
export class AccountMaintainanceComponent implements OnInit {
  @Input() isDisplay = false;
  @Output() isDisplayChange = new EventEmitter<boolean>();
  dayBasis: any[] = [];
  addFee: any = false;
  query: any = {};
  action: string;
  loading: boolean;
  accountMaintainanceList: any = [];
  onClickDelete: any = false;
  row: any;

  selectedAccountData: any = {};
  currencyTypes: any = [];
  accTableSchema = TableSchema['acc-maintanance'];
  constructor(private defaultService: DefaultService, private messageService: MessageService) {
  }
  ngOnInit() {
    this.getAccountMaintainanacedata();
  }
  addFeeData() {
    this.getCurrencies();
    this.addFee = true;
    this.action = 'ADD';
  }
  editFee(event) {
    this.getCurrencies();
    this.addFee = true;
    this.action = 'EDIT';
    this.selectedAccountData = event['row'];
  }
  getCurrencies() {
    if (this.currencyTypes.length > 0) {
      return;
    }
    this.currencyTypes = JSON.parse(localStorage.getItem('currencyWithSymbol'));
  }
  getAccountMaintainanacedata() {
    this.defaultService.find(this.query, 1, 100000, 'quant/account_def').then(data => {
      if (data['status'] === 'success') {
        this.accountMaintainanceList = data['rows'];
        this.accountMaintainanceList.forEach(element => {
          element['codeDayBasis'] = '(' + element.code + ')' + ' ' + '(' + element.dayBasis + 'basis)';
        });
      }
    }).catch(err => {
this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
    });
  }
  close() {
    this.isDisplay = false;
    this.isDisplayChange.emit(this.isDisplay);
  }
}
