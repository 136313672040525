import { Component, OnInit, Input, Output, EventEmitter, Injector } from '@angular/core';
import { DefaultService } from '../../utils/default.service';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-fee-type-to-account-mapping',
  templateUrl: './fee-type-to-account-mapping.component.html',
  styleUrls: ['./fee-type-to-account-mapping.component.scss']
})
export class FeeTypeToAccountMappingComponent implements OnInit {
  @Input() isDisplay = false;
  @Output() isDisplayChange = new EventEmitter<boolean>();
  selectedTab: any;
  selectedIndex: any;
  filterTabs = [];
  loading = true;
  variableFeeTypes: any = [];
  fixedFeeTypes: any = [];
  feeTypeLable: String;
  title: String;
  isAddFeeType = false;
  selectedFeeData = {};

  query: any = {};
  showAddFeeType: boolean;
  constructor(
    private defaultService: DefaultService,
    private messageService: MessageService,
    public injector: Injector) { }

  ngOnInit() {
    this.getFeeTypesData();
    this.filterTabs = [{
      label: 'Fixed Fee', value: 'FF'
    },
    {
      label: 'Variable Fee', value: 'VF'
    }];
    this.onClickFilterTabs({
      label: 'Fixed Fee', value: 'FF'
    }, 0);
    this.selectedTab = 'FF';
  }


  getFeeTypesData() {
    this.defaultService
      .find(this.query, 1, 100000, 'quant/posting_def')
      .then(data => {
        this.loading = false;
        if (data['status'] === 'success') {
          this.fixedFeeTypes = data['rows'].filter(field => field['fixedFeeAcc']);
          this.variableFeeTypes = data['rows'].filter(field => field['variableFeeAcc']);
        }
      })
      .catch(err => {
        this.loading = false;
      });
  }

  onClickFilterTabs(tab, index) {
    this.selectedTab = tab.value;
    this.selectedIndex = index;
    if (index === 0) {
      this.fixedFeeTypes.map(obj => {
        delete obj.canEdit;
        delete obj.vFee;
        delete obj.rAcc;
      });
    } else {
      this.variableFeeTypes.map(obj => {
        delete obj.canEdit;
        delete obj.vFee;
        delete obj.rAcc;
      });
    }
  }

  editAccount(index, row, type) {
    for (const obj of (type === 'FF') ? this.fixedFeeTypes : this.variableFeeTypes) {
      obj.canEdit = false;
      obj.vFee = false;
      obj.rAcc = false;
      if (row.feeType === obj.feeType) {
        if (index === 0) {
          obj.vFee = true;
        } else if (index === 1) {
          obj.rAcc = true;
        }
        obj.canEdit = true;
      }
    }
  }

  updateAccount(index, row, type) {
    this.loading = true;
    delete row.canEdit;
    delete row.vFee;
    delete row.rAcc;
    this.defaultService.update(row._id, row, 'quant/posting_def').then(data => {
      this.loading = false;
      if (data['status'] === 'success') {
        for (const obj of (type === 'FF') ? this.fixedFeeTypes : this.variableFeeTypes) {
          obj.canEdit = false;
        }
        this.messageService.add({ severity: 'success', detail: 'Account updated successfully' });
        return;
      }
      this.messageService.add({ severity: 'error', detail: data['status'] });
    }).catch(err => {
      this.loading = false;
this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
    });
  }

  addFeeType() {
    this.isAddFeeType = true;
    this.title = 'Add';
    if (this.selectedTab === 'FF') {
      this.feeTypeLable = 'Fixed Fee';
    } else {
      this.feeTypeLable = 'Variable Fee';
    }
  }

  cancelEdit() {
    this.getFeeTypesData();
  }

  close() {
    this.isDisplay = false;
    this.isDisplayChange.emit(this.isDisplay);
  }
}
