export const PageConfig = {
	'pages': [
		{
			'label': 'KYC SUMMARY',
			'routerLink': '/kycdashboard',
			'icon': 'fa fa-users',
			'priv': 'KYC_DASHBOARD'
		},
		{
			'label': 'CASE DETAILS',
			'routerLink': '/case/:id',
			'icon': 'fa fa-users',
			'priv': 'CASE_DETAILS_VIEW'
		},
		{
			'label': 'TRANSACTIONS',
			'routerLink': '/quantdashboard',
			'priv': 'TRANSACTION_SUMMARY'
		},
		{
			'label': 'USERS',
			'routerLink': '/users',
			'icon': 'fa fa-users',
			'priv': 'MANAGE_USERS',
		}, {
			'label': 'ROLES',
			'routerLink': '/roles',
			'icon': 'fa fa-users',
			'priv': 'MANAGE_ROLES',
		},
		{
			'label': 'AUDIT TRAIL',
			'routerLink': '/audittrail',
			'icon': 'fa fa-user',
			'priv': 'AUDIT_TRAIL'
		}, {
			'label': 'FUNDS',
			'routerLink': '/managefunds',
			'icon': 'fa fa-user',
			'priv': 'MANAGE_FUNDS'
		},
		{
			'label': 'USER GROUPS',
			'routerLink': '/groups',
			'icon': 'fa fa-user',
			'priv': 'MANAGE_GROUPS'
		},
		{
			'label': 'JOBS',
			'routerLink': '/jobs',
			'icon': 'fa fa-users',
			'priv': 'MANAGE_JOBS',
		},
		{
			'label': 'JOBS(New)',
			'routerLink': '/jobsConfig',
			'icon': 'fa fa-users',
			'priv': 'MANAGE_JOBS',
		},	
		{
			'label': 'SCHEDULER CONFIG',
			'routerLink': '/schedule',
			'icon': 'fa fa-users',
			'priv': 'SCHEDULER',
		},
		
		{
			'label': 'Reset Password',
			'routerLink': '/resetpassword',
			'icon': 'fa fa-user',
			'priv': 'RESET_PWD'
		},
		{
			'label': 'FIRM LEVEL LIQUIDITY',
			'routerLink': '/firmliquidation',
			'priv': 'FIRM_LEVEL_LIQUIDITY'
		},
		{
			'label': 'DATA MARKET PLACE',
			'routerLink': '/datamarketplace',
			'priv': 'DATA_MARKET_PLACE'
		},
		{
			'label': 'FIRM LEVEL SUSPENDED STOCKS',
			'routerLink': '/suspendedstocks',
			'priv': 'FIRM_LEVEL_SUSPENDED_STOCK'
		},
		{
			'label': 'UPLOADED FILES',
			'routerLink': '/uploadedfileslog',
			'priv': 'UPLOADED_FILES_LOG'
		},
		{
			'label': 'PREMIUM DISCOUNT SUMMARY',
			'routerLink': '/pdsummary',
			'priv': 'PREMIUM_DISCOUNT_SUMMARY'
		},
		{
			'label': 'PREMIUM DISCOUNT',
			'routerLink': '/premiumdiscount',
			'priv': 'PREMIUM_DISCOUNT'
		},
		{
			'label': 'DAILY C/R ORDERS MANAGEMENT',
			'routerLink': '/dailycrordrsmgmt',
			'priv': 'DAILY_CR_ORDERS_MGMT'
		},
		{
			'label': 'PORTFOLIO CODES',
			'routerLink': '/portfoliocodes',
			'priv': 'PORTFOLIOCODE_MAPPING'
		},
		{
			'label': 'Quant FX RATES',
			'routerLink': '/fxrate',
			'priv': 'FX_RATE'
		},
		{
			'label': 'FX RATES',
			'routerLink': '/opsfxrate',
			'priv': 'OPS_FX_RATES'
		},
		{
			'label': 'PRICES',
			'routerLink': '/pricemaintainance',
			'priv': 'PRICES'
		},
		// {
		// 	'label': 'HOLIDAY CALENDAR',
		// 	'routerLink': '/holidaycalender',
		// 	'priv': 'HOLIDAY_CALENDER'
		// },
		{
			'label': 'NEWSLETTERS',
			'routerLink': '/newsletters',
			'priv': 'NEWSLETTER_CREATE'
		},
		{
			'label': 'MAILS CONFIG',
			'routerLink': '/emailconfig',
			'priv': 'MAIL_CONFIG'
		},
		{
			'label': 'REQUESTED TEMPLATES FOR APPROVAL',
			'routerLink': '/requestedtemplates',
			'priv': 'NEWSLETTER_APPROVE'
		},
		{
			'label': 'PEER FUNDS',
			'routerLink': '/peerfunds',
			'priv': 'MANAGE_PEER_FUND'
		},
		{
			'label': 'EMAIL GENERATION',
			'routerLink': '/emailgeneration',
			'priv': 'EMAIL_GENERATION'
		},
		{
			'label': 'FINANCE AGGREGATE',
			'routerLink': '/finance',
			'priv': 'FINANCE_AGGREGATE'
		},
		{
			'label': 'USER STATISTICS',
			'routerLink': '/userstats',
			'priv': 'USER_STATS'
		},
		{
			'label': 'FLEX SYSTEM',
			'routerLink': '/flexfiles',
			'priv': 'FLEX_FILES'
		},
		{
			'label': 'RECON CONFIGURATION',
			'routerLink': '/reconconfig',
			'priv': 'RECON_CONFIGURATION'
		},
		{
			'label': 'ACCOUNT MAPPING',
			'routerLink': '/accountmapping',
			'priv': 'ACCOUNT_MAPPING'
		},
		{
			'label': 'Dashboard',
			'routerLink': '/ccass',
			'priv': 'DASHBOARD_CC_IFRAME'
		},
		{
			'label': 'D&C CONFIG',
			'routerLink': '/dc-config',
			'priv': 'DC_CONFIG'
		},
		{
			'label': 'ENUM CONFIG',
			'routerLink': '/enum-config',
			'priv': 'ENUM_CONFIG'
		},
		{
			'label': 'HOLIDAY CALENDAR',
			'routerLink': '/holidaycalender',
			'priv': 'HOLIDAY_CALENDER'
		},
		{
			'label': 'ACCOUNT CONFIG',
			'routerLink': '/account-config',
			'priv': 'ACCOUNT_CONFIG'
		},
		{
			'label': 'Transaction Cost CONFIG',
			'routerLink': '/transaction-cost-config',
			'priv': 'TRANSACTION_COST_CONFIG'
		},
		{
			'label': 'CA CONFIG',
			'routerLink': '/caconfig',
			'priv': 'CA_CONFIG'
		},



		

		// {
		// 	'label': 'FUND DETAILS',
		// 	'routerLink': '/opsfunddetails',
		// 	'priv': 'FLEX_FILES'
		// }

			// New Screens

			
	]
};