import { Injectable } from '@angular/core';
import { FormField } from './formfield';
@Injectable()
export class FormsUtilitiesService {

  constructor() {

  }

  static getEAMFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'EAM_CLNT_CLNT_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_CLNT_CLNT_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_CLNT_EAM_REP_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_CLNT_PVT_BK_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_PROD_INTEREST', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_PROD_INTEREST_OTHERS', value: '' } as FormField);
    formFields.push({ key: 'EAM_PROD_INTEREST_MUT_FND_NAME', value: '' } as FormField);
    formFields.push({ key: 'EAM_PROD_INTEREST_REMARKS' } as FormField);
    formFields.push({ key: 'EAM_CLNT_SIGN_BY_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_CLNT_SIGN_BY_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_CLNT_SIGN_BY_DATE2', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_CLNT_SIGN_BY_NAME2', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_CLNT_SIGN_BY_DATE3', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_CLNT_SIGN_BY_NAME3', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_CLNT_SIGN_BY_DATE4', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_CLNT_SIGN_BY_NAME4', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_CLNT_SIGN_BY_DATE5', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_CLNT_SIGN_BY_NAME5', required: true, value: '' } as FormField);
    // formFields.push({key: 'EAM_CLNT_SIGN_BY_SIGN', required: true,value:''} as FormField);
    return formFields;
  }


  static getCICORPFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'EAM_CLNT_CLNT_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_CLNT_CLNT_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_CLNT_EAM_REP_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_CLNT_PVT_BK_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_PROD_INTEREST', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_PROD_INTEREST_OTHERS', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_PROD_INTEREST_MUT_FND_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_CLNT_SIGN_BY_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_CLNT_SIGN_BY_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_CLNT_SIGN_BY_SIGN', required: true, value: '' } as FormField);
    return formFields;
  }

  static getCICORPCOMPPROFFormFields(): FormField[] {

    const formFields = [] as FormField[];
    formFields.push({ key: 'CLNT_CRP_P2_A_CMP_ENG_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_CLNT_CLNT_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_A_CMP_CHN_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_A_PIC', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_A_DIC', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_A_INC_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_A_REG_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_A_CNTCT', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_A_CNTCT_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_A_BUS_PHN', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_A_FAX_NUM', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_A_CORR_ADD', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_A_EMAIL_ADD', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_A_ADD_REG_IN_CNTRY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_A_BUS_NAT', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_A_REG_ENTY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_A_REG_ENTY_CMP', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_B_NET_AST_VAL', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_B_NO_OF_SHR', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_B_ORG_FND', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_B_ORG_FND_OTHRS', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_B_PAID_CAP', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_B_PROF_LOSS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_B_PROF_LOSS_OTHRS', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_B_SRC_FND', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_B_SRC_FND_OTHRS', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_B_SRC_WLTH', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_B_SRC_WLTH_OTHRS', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_B_CHK1', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_B_CHK1_YES_CMP_NAME', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_B_CHK2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_B_CHK3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_B_CHK3_YES_EMP_NAME', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_B_CHK3_YES_RLTN', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_B_CHK4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_B_CHK4_YES_CNTRY_PEP', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_B_CHK4_YES_NAME_POS', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_B_CHK4_YES_RLTN_POS', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_C_CRD', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_C_CRD_CCY', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_C_CHQ', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_C_CHQ_CCY', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_C_CHQ_RES', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_C_REG_ADDRESS', value: '' } as FormField); // added
    formFields.push({ key: 'CLNT_CRP_P2_D_DVD_CHQ', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_D_DVD_CRD_DVD_CCY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_D_DVD_CHQ', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_D_DVD_CHQ_CCY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_D_CHQ_OTHRS', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_D_DVD_CHQ_RES', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_C_CRD_OTHRS', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_C_CRD_BK_NAME', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_C_CRD_SWFT_CD', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_C_CRD_BK_ACC_NAME', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_C_CRD_BK_ACC_NUM', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_D_DVD_CRD_DVD_BK_NAME', required: true, value: '' } as FormField);
    // formFields.push({key: 'CLNT_CRP_P2_D_DVD_CRD_DVD_BK_NAME', required: true, value: ''} as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_D_DVD_CRD_DVD_SWFT_CD', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_D_DVD_CRD_DVD_BK_ACC_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_D_DVD_CRD_DVD_BK_ACC_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_C_CHQ_OTHRS', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_C_CHQ_RES_ADD', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_D_DVD_CRD_DVD_OTHRS', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_D_DVD_CHQ_RES_ADD', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_D_DVD_CHQ_OTHRS', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_D_DVD_CHQ_REG_ADDRESS', required: true, value: '' } as FormField);
    // formFields.push({key: 'CLNT_CRP_P2_CLNT_CHOP', required: true, value: ''} as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_CSOP_DEC', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_CSOP_TRD_DER', required: true, value: '' } as FormField);
    // formFields.push({key: 'CLNT_CRP_P2_CSOP_SIGN', required: true, value: ''} as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_CSOP_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_CSOP_CE_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_CSOP_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_D_DVD_NA', value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_C_NA', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_CLNT_SIGN', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_CLNT_DATE', required: true, value: '' } as FormField);

    formFields.push({ key: 'CLNT_CRP_P2_CLNT_SIGN2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_CLNT_DATE2', required: true, value: '' } as FormField);

    formFields.push({ key: 'CLNT_CRP_P2_CLNT_SIGN3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_CLNT_DATE3', required: true, value: '' } as FormField);

    formFields.push({ key: 'CLNT_CRP_P2_CLNT_SIGN4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_CLNT_DATE4', required: true, value: '' } as FormField);

    formFields.push({ key: 'CLNT_CRP_P2_CLNT_SIGN5', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P2_CLNT_DATE5', required: true, value: '' } as FormField);
    return formFields;
  }

  static getINVMANDFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'INV_ADV_ADD', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_ADDR', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_BK_ACC_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_PVT_BK_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_COMM', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_COMM_FAX', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_COMM_PHONE', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_COMM_EMAIL', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_COMM_POST', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_COMM_OTHRS', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_DEC_BY_STAFF_CE_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_DEC_BY_STAFF_DATE', required: true, value: '' } as FormField);
    // formFields.push({key: 'INV_ADV_INV_MDL_DEC_BY_STAFF_NAME', required: true, value: ''} as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_DEC_BY_STAFF_PLACE', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_INV_CAT_ALT_INV_N_OTH_INV_MAX', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_INV_CAT_ALT_INV_N_OTH_INV_MIN', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_INV_CAT_EQTY_SEC_MAX', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_INV_CAT_EQTY_SEC_MIN', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_INV_CAT_FIX_INC_MAX', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_INV_CAT_FIX_INC_MIN', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_INV_CAT_FWD_N_FUT_ON_FOR_EXCH_MAX', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_INV_CAT_FWD_N_FUT_ON_FOR_EXCH_MIN', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_INV_CAT_MNY_MKT_MAX', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_INV_CAT_MNY_MKT_MIN', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_INV_CCY_CHF_MAX', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_INV_CCY_CHF_MIN', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_INV_CCY_EUR_MAX', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_INV_CCY_EUR_MIN', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_INV_CCY_OTHERS_MAX', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_INV_CCY_OTHERS_MIN', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_INV_CCY_USD_MAX', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_INV_CCY_USD_MIN', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_INV_GUID_LNS', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_INV_RESTR', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_IS', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_REF_CCY', required: true, value: '' } as FormField);
    // formFields.push({key: 'INV_ADV_INV_MDL_SIGN_BY_CLNT_AUTH_SIGN', required: true, value: ''} as FormField);
    // formFields.push({key: 'INV_ADV_INV_MDL_SIGN_BY_CSOP_AUTH_SIGN', required: true, value: ''} as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_SIGN_BY_CSOP_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_SIGN_BY_CSOP_TITLE', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_MGMT_FEE', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_PRFM_FEE', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_REF_CCY', required: true, value: '' } as FormField);
    // OTHERS
    formFields.push({ key: 'INV_ADV_REF_CCY_OTHR', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_REF_CCY_OTHR', value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_SIGN_BY_CLNT_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_SIGN_BY_CLNT_TITLE', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_SIGN_BY_CLNT_NAME2', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_SIGN_BY_CLNT_TITLE2', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_SIGN_BY_CLNT_NAME3', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_SIGN_BY_CLNT_TITLE3', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_SIGN_BY_CLNT_NAME4', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_SIGN_BY_CLNT_TITLE4', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_SIGN_BY_CLNT_NAME5', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_SIGN_BY_CLNT_TITLE5', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_ACK_BY_CLNT_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_ACK_BY_CLNT_PLACE', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_ACK_BY_CLNT_DATE2', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_ACK_BY_CLNT_PLACE2', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_ACK_BY_CLNT_DATE3', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_ACK_BY_CLNT_PLACE3', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_ACK_BY_CLNT_DATE4', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_ACK_BY_CLNT_PLACE4', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_ACK_BY_CLNT_DATE5', required: true, value: '' } as FormField);
    formFields.push({ key: 'INV_ADV_INV_MDL_ACK_BY_CLNT_PLACE5', required: true, value: '' } as FormField);
    return formFields;
  }
  static getDISCMANDFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'DISC_MAND_ADD', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_ADDR', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_BK_ACC_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_PVT_BK_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_COMM', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_COMM_PHONE', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_COMM_EMAIL', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_COMM_POST', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_COMM_FAX', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_COMM_OTHRS', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_IS', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_DEC_BY_STAFF_CE_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_DEC_BY_STAFF_DATE', required: true, value: '' } as FormField);
    // formFields.push({key: 'DISC_MAND_INV_MDL_DEC_BY_STAFF_NAME', required: true, value: ''} as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_DEC_BY_STAFF_PLACE', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_INV_CAT_ALT_INV_N_OTH_INV_MAX', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_INV_CAT_ALT_INV_N_OTH_INV_MIN', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_INV_CAT_EQTY_SEC_MAX', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_INV_CAT_EQTY_SEC_MIN', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_INV_CAT_FIX_INC_MAX', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_INV_CAT_FIX_INC_MIN', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_INV_CAT_FWD_N_FUT_ON_FOR_EXCH_MAX', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_INV_CAT_FWD_N_FUT_ON_FOR_EXCH_MIN', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_INV_CAT_MNY_MKT_MAX', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_INV_CAT_MNY_MKT_MIN', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_INV_CCY_CHF_MAX', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_INV_CCY_CHF_MIN', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_INV_CCY_EUR_MAX', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_INV_CCY_EUR_MIN', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_INV_CCY_OTHERS_MIN', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_INV_CCY_OTHERS_MAX', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_INV_CCY_USD_MAX', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_INV_CCY_USD_MIN', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_INV_GUID_LNS', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_INV_RESTR', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_REF_CCY', value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_REF_CCY_OTHRS', value: '' } as FormField);
    // formFields.push({key: 'DISC_MAND_INV_MDL_SIGN_BY_CLNT_AUTH_SIGN', required: true, value: ''} as FormField);
    // formFields.push({key: 'DISC_MAND_INV_MDL_SIGN_BY_CSOP_AUTH_SIGN', required: true, value: ''} as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_SIGN_BY_CSOP_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_SIGN_BY_CSOP_TITLE', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_MGMT_FEE', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_PRFM_FEE', required: true, value: '' } as FormField);
    // formFields.push({key: 'DISC_MAND_INV_MDL_ACK_BY_CLNT_NAME', required: true, value: ''} as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_ACK_BY_CLNT_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_ACK_BY_CLNT_PLACE', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_ACK_BY_CLNT_DATE2', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_ACK_BY_CLNT_PLACE2', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_ACK_BY_CLNT_DATE3', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_ACK_BY_CLNT_PLACE3', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_ACK_BY_CLNT_DATE4', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_ACK_BY_CLNT_PLACE4', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_ACK_BY_CLNT_DATE5', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_ACK_BY_CLNT_PLACE5', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_SIGN_BY_CLNT_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_SIGN_BY_CLNT_TITLE', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_SIGN_BY_CLNT_NAME2', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_SIGN_BY_CLNT_TITLE2', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_SIGN_BY_CLNT_NAME3', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_SIGN_BY_CLNT_TITLE3', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_SIGN_BY_CLNT_NAME4', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_SIGN_BY_CLNT_TITLE4', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_SIGN_BY_CLNT_NAME5', required: true, value: '' } as FormField);
    formFields.push({ key: 'DISC_MAND_INV_MDL_SIGN_BY_CLNT_TITLE5', required: true, value: '' } as FormField);
    return formFields;
  }

  static getCICORPSELFAFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({key: 'CLNT_CRP_P3_A_S1_CNTRY_TAX_RES1', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S1_CNTRY_TAX_TIN1', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S1_CNTRY_TAX_RES2', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S1_CNTRY_TAX_TIN2', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S1_CNTRY_TAX_RES3', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S1_CNTRY_TAX_TIN3', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S1_CNTRY_TAX_RES4', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S1_CNTRY_TAX_TIN4', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S1_CNTRY_TAX_RES5', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S1_CNTRY_TAX_TIN5', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S1_US_TIN', value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S1_US_CTZN', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S2_FATCA_GIIN', value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S2_ACC_GIIN', value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S2_FATCA_CHK', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S2_FATCA_TRST_NAME', value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S2_FATCA_OTHRS',  value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S2_CRS', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S2_CRS_C_EST_NAM',  value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S2_CRS_C_REG_T_NAM', value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S2_CRS_OTHRS', value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S3_AUTH_PERS_DATE', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S3_AUTH_PERS_NAME', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S3_AUTH_PERS_DATE2', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S3_AUTH_PERS_NAME2', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S3_AUTH_PERS_DATE3', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S3_AUTH_PERS_NAME3', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S3_AUTH_PERS_DATE4', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S3_AUTH_PERS_NAME4', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S3_AUTH_PERS_DATE5', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_A_S3_AUTH_PERS_NAME5', required: true, value: ''} as FormField);
    // formFields.push({key: 'CLNT_CRP_P3_A_S3_AUTH_PERS1_SIGN', required: true, value: ''} as FormField);
    // formFields.push({key: 'CLNT_CRP_P3_A_S3_AUTH_PERS2_SIGN', required: true, value: ''} as FormField);
    // formFields.push({key: 'CLNT_CRP_P3_A_S3_AUTH_PERS1_CAP_SIGN', required: true, value: ''} as FormField);
    // formFields.push({key: 'CLNT_CRP_P3_A_S3_AUTH_PERS2_CAP_SIGN', required: true, value: ''} as FormField);
    return formFields;
  }
  static getCICORPSELFBFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({key: 'CLNT_CRP_P3_B_S1_ACC_NAME_TITLE', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S1_ACC_NAME_LAST', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S1_ACC_NAME_FIRST', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S1_ACC_NAME_MIDDLE', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S1_CURR_ADD_CITY', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S1_CURR_ADD_CNTRY', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S1_CURR_ADD_FLAT', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S1_CURR_ADD_STATE', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S1_CURR_ADD_STREET', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S1_CURR_ADD_ZIP', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S1_DOB', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S1_HK_PID', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S1_MAIL_ADD_CITY', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S1_MAIL_ADD_CNTRY', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S1_MAIL_ADD_FLAT', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S1_MAIL_ADD_STATE', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S1_MAIL_ADD_STREET', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S1_MAIL_ADD_ZIP', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S1_POB_CNTRY', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S1_POB_TOWN', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S1_PSPRT', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S1_CHK', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S1_NTNLTY', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S2_CHK', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S2_CHK_YES',  value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S3_CNTRY_TAX_RES1', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S3_CNTRY_TAX_RES2', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S3_CNTRY_TAX_RES3', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S3_CNTRY_TAX_RES4', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S3_CNTRY_TAX_RES5', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S3_CNTRY_TAX_TIN1', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S3_CNTRY_TAX_TIN2', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S3_CNTRY_TAX_TIN3', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S3_CNTRY_TAX_TIN4', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S3_CNTRY_TAX_TIN5', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_LGL_EXRC_CNTRL', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_LGL_EXRC_CNTRL_ENTY', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_LGL_HAS_CNTRL', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_LGL_HAS_CNTRL_ENTY', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_LGL_HLDS_POS', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_LGL_HLDS_POS_ENTY', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_TRST_BNFCRY_ENTY', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_TRST_BNFCRY_POS', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_TRST_OTHR_ENTY', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_TRST_OTHR_POS', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_TRST_PRTCTR_ENTY', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_TRST_PRTCTR_POS', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_TRST_STLR_ENTY', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_TRST_STLR_POS', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_TRST_TRSTE_ENTY', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_TRST_TRSTE_POS', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_LGL_ARNGMT_BNFCRY_ENTY', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_LGL_ARNGMT_BNFCRY_POS', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_LGL_ARNGMT_OTHR_ENTY', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_LGL_ARNGMT_OTHR_POS', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_LGL_ARNGMT_PRTCTR_ENTY', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_LGL_ARNGMT_PRTCTR_POS', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_LGL_ARNGMT_STLR_ENTY', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_LGL_ARNGMT_STLR_POS', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_LGL_ARNGMT_TRSTE_ENTY', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S4_LGL_ARNGMT_TRSTE_POS', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S5_ACC_HLDR_DEC_CPCTY', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S5_ACC_HLDR_DEC_DATE', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S5_ACC_HLDR_DEC_NAME', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S5_ACC_HLDR_DEC_DATE2', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S5_ACC_HLDR_DEC_NAME2', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S5_ACC_HLDR_DEC_DATE3', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S5_ACC_HLDR_DEC_NAME3', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S5_ACC_HLDR_DEC_DATE4', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S5_ACC_HLDR_DEC_NAME4', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S5_ACC_HLDR_DEC_DATE5', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S5_ACC_HLDR_DEC_NAME5', required: true, value: ''} as FormField);
    formFields.push({key: 'CLNT_CRP_P3_B_S5_ACC_HLDR_DEC_DATE', required: true, value: ''} as FormField);
    // formFields.push({key: 'CLNT_CRP_P3_B_S5_ACC_HLDR_DEC_NAME', required: true, value: ''} as FormField);
    // formFields.push({key: 'CLNT_CRP_P3_B_S5_ACC_HLDR_DEC_SIGN', required: true, value: ''} as FormField);
    return formFields;
  }

  static getBRDRESOLFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'BRD_RES_CMP_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'BRD_RES_ADDRESS', required: true, value: '' } as FormField);
    formFields.push({ key: 'BRD_RES_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'BRD_RES_DATE_TIME', required: true, value: '' } as FormField);
    formFields.push({ key: 'CHAIR_PERSON_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'ATTENDEE_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'CHAIRMAN_SIGN', required: true, value: '' } as FormField);
    formFields.push({ key: 'NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'PASSPORT_HKID_NO', required: true, value: '' } as FormField);
    formFields.push({ key: 'CONTACT_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CONTACT_EMAIL', required: true, value: '' } as FormField);
    formFields.push({ key: 'NAME2', required: true, value: '' } as FormField);
    formFields.push({ key: 'PASSPORT_HKID_NO2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CONTACT_NUM2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CONTACT_EMAIL2', required: true, value: '' } as FormField);
    formFields.push({ key: 'NAME3', required: true, value: '' } as FormField);
    formFields.push({ key: 'PASSPORT_HKID_NO3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CONTACT_NUM3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CONTACT_EMAIL3', required: true, value: '' } as FormField);
    formFields.push({ key: 'NAME4', required: true, value: '' } as FormField);
    formFields.push({ key: 'PASSPORT_HKID_NO4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CONTACT_NUM4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CONTACT_EMAIL4', required: true, value: '' } as FormField);
    formFields.push({ key: 'NAME5', required: true, value: '' } as FormField);
    formFields.push({ key: 'PASSPORT_HKID_NO5', required: true, value: '' } as FormField);
    formFields.push({ key: 'CONTACT_NUM5', required: true, value: '' } as FormField);
    formFields.push({ key: 'CONTACT_EMAIL5', required: true, value: '' } as FormField);
    formFields.push({ key: 'BRD_EFF_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'BRD_AUTH_NAME', required: true, value: '' } as FormField);
    return formFields;
  }
  static getCICORPCLNTDCLTNFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'CLNT_CRP_P4_SIGN', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P4_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P4_DATE2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P4_DATE3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P4_DATE4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P4_DATE5', required: true, value: '' } as FormField);
    return formFields;
  }
  static getTERMSANDCONDITIONSFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'CLNT_TERMS_CONDITIONS_P4__NA', required: false, value: '' } as FormField);
    return formFields;
  }
  static getCICORPDOCSFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'EAM_CLNT_CLNT_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_CLNT_CLNT_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P1_CI_HK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P1_CI_HK_CHK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P1_OVRS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P1_OVRS_CHK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P1_SP_HK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P1_SP_HK_CHK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P1_SP_OVRS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P1_SP_OVRS_CHK', required: true, value: '' } as FormField);
    return formFields;
  }

  static getINVKNWASSMTFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'EAM_CLNT_CLNT_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_CLNT_CLNT_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S1_MKT_DEVMKTS', required: false, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S1_MKT_EMRGMKTS', required: false, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S1_MKT_FRNTMKTS', required: false, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S1_MKT_HKNPRC', required: false, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S2_PA_ASSMTSCR', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S2_PA_EQTS', required: false, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S2_PA_FIXINCPRDCTS', required: false, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S2_PA_MUTFNDSUNTTRSTS', required: false, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S2_PB_ASSMTSCR', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S2_PB_FUTNOPTS', required: false, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S2_PB_HDGFNDS', required: false, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S2_PB_LEVFRX', required: false, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S2_PB_OTCSWP', required: false, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S2_PB_OTHDRVTSPRDCTS', required: false, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S2_PB_STKOPTS', required: false, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S2_PB_STRCTNOTES', required: false, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S2_PB_WRRNTS', required: false, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S3_DRVTS_DOUHAVGKINNATNRSK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S3_DRVTS_WHTISURSCRSOFDERKNW', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S3_DRVTS_WHTISURSCRSOFDERKNW_CPY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S4_CNFRMCHK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S4_CUSTDEC_AUTHSIGNBYCLNT', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_TYP_ASSMT', required: false, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S4_CUSTDEC_CMPNYNAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S4_CUSTDEC_DATE', required: false, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S4_CUSTDEC_DEPT', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S4_CUSTDEC_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S4_CUSTDEC_CMPNYNAME2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S4_CUSTDEC_DATE2', required: false, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S4_CUSTDEC_DEPT2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S4_CUSTDEC_NAME2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S4_CUSTDEC_CMPNYNAME3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S4_CUSTDEC_DATE3', required: false, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S4_CUSTDEC_DEPT3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S4_CUSTDEC_NAME3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S4_CUSTDEC_CMPNYNAME4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S4_CUSTDEC_DATE4', required: false, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S4_CUSTDEC_DEPT4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S4_CUSTDEC_NAME4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S4_CUSTDEC_CMPNYNAME5', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S4_CUSTDEC_DATE5', required: false, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S4_CUSTDEC_DEPT5', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P1_S4_CUSTDEC_NAME5', required: true, value: '' } as FormField);
    return formFields;
  }

  static getCICORPCSTINVFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'CLNT_CRP_P6_A_SIGN', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P6_B_SIGN', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P6_A_NA', required: true, value: '' } as FormField);

    formFields.push({ key: 'CLNT_CRP_P6_A_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P6_A_DATE2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P6_A_DATE3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P6_A_DATE4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P6_A_DATE5', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P6_B_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P6_B_DATE2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P6_B_DATE3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P6_B_DATE4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P6_B_DATE5', required: true, value: '' } as FormField);



    return formFields;
  }

  static getCICORPCSTMRKTNGFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'CLNT_CRP_P7_CNSNT_USG_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P7_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P7_SIGN', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P7_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P7_DATE2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P7_DATE3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P7_DATE4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P7_DATE5', required: true, value: '' } as FormField);


    return formFields;
  }

  static getCICORPPROFINVFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'CLNT_CRP_P5_ADQCY_TYPE1_DOC', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_ADQCY_TYPE1', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_ADQCY_NA', required: false, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_ADQCY_TYPE2_DOC', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_ADQCY_TYPE3_DOC', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_CRP_CHQ', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_CRP_EQTY_RMRK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_CRP_FUT_OPT_RMRK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_CRP_WRRNT_RMRK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_CRP_MUT_FND_RMRK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_CRP_STRCT_PROD_RMRK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_CRP_OTHRS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_CRP_OTHRS_RMRK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_Q1_ANS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_Q1_ANS_OTHRS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_Q2_ANS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_Q2_ANS_OTHRS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_Q2_ANS_TRNS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_Q2_ANS_YES', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_Q3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_CSOP_CHQ', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_CSOP_EQTY_RMRK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_CSOP_FIX_INC_RMRK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_CSOP_DEC_CHK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_CSOP_FUT_OPT_RMRK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_CSOP_MUT_FND_RMRK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_CSOP_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_CSOP_OTHRS_RMRK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_CSOP_OTHRS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_CSOP_SIGN', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_CSOP_STRCT_PROD_RMRK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_CSOP_WRRNT_RMRK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_CSOP_CE_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P5_CSOP_DATE', required: true, value: '' } as FormField);


    return formFields;
  }


  static getCUSTRSKPROFFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'CLNT_PROF_P2_SA_INV_KNW_EXP_LVL', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SB_ATCP_URREACTNOFSIGNFCNTPORTFALL', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SB_IKNE_URINVKNWNEXPLVL', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SB_INCF_STBLTYOFURCRNTANTCPTDSRCINC', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SB_INVHRZ_WHNDUNEEDACCSUBPRTOFINV', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SB_KOIMD_URFAMLRTYWTHINVMKTDEV', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SB_LOL_UREXPLVLOFBRWFORINV', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SB_ME_DOUHAVINEXPINMKTGRP', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SB_SCRINSECB', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SB_TNW_URTOTNETWRTH', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SB_TOMF_CHRTOFHIGHLOSSNGAIN', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SB_UROVRALLTOLLVL', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SC_DEC', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SC_RSK_TOL', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SC_STRTGY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SC_IF_CHECK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SC_SIGN_BY_CLNT', required: true, value: '' } as FormField);

    formFields.push({ key: 'CLNT_PROF_P2_SC_SIGN_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SC_SIGN_CMP_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SC_SIGN_DEPT', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SC_SIGN_DATE', required: false, value: '' } as FormField);

    formFields.push({ key: 'CLNT_PROF_P2_SC_SIGN_NAME2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SC_SIGN_CMP_NAME2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SC_SIGN_DEPT2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SC_SIGN_DATE2', required: false, value: '' } as FormField);

    formFields.push({ key: 'CLNT_PROF_P2_SC_SIGN_NAME3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SC_SIGN_CMP_NAME3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SC_SIGN_DEPT3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SC_SIGN_DATE3', required: false, value: '' } as FormField);

    formFields.push({ key: 'CLNT_PROF_P2_SC_SIGN_NAME4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SC_SIGN_CMP_NAME4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SC_SIGN_DEPT4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SC_SIGN_DATE4', required: false, value: '' } as FormField);

    formFields.push({ key: 'CLNT_PROF_P2_SC_SIGN_NAME5', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SC_SIGN_CMP_NAME5', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SC_SIGN_DEPT5', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P2_SC_SIGN_DATE5', required: false, value: '' } as FormField);
    return formFields;
  }
  static getGKONDRVTSFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'CLNT_PROF_P3_SIGN_BY_CLNT', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P3_SIGN_CMP_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P3_SIGN_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P3_SIGN_DEPT', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P3_SIGN_NAME', required: true, value: '' } as FormField);

    formFields.push({ key: 'CLNT_PROF_P3_SIGN_CMP_NAME2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P3_SIGN_DATE2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P3_SIGN_DEPT2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P3_SIGN_NAME2', required: true, value: '' } as FormField);

    formFields.push({ key: 'CLNT_PROF_P3_SIGN_CMP_NAME3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P3_SIGN_DATE3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P3_SIGN_DEPT3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P3_SIGN_NAME3', required: true, value: '' } as FormField);

    formFields.push({ key: 'CLNT_PROF_P3_SIGN_CMP_NAME4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P3_SIGN_DATE4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P3_SIGN_DEPT4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P3_SIGN_NAME4', required: true, value: '' } as FormField);

    formFields.push({ key: 'CLNT_PROF_P3_SIGN_CMP_NAME5', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P3_SIGN_DATE5', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P3_SIGN_DEPT5', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P3_SIGN_NAME5', required: true, value: '' } as FormField);
    return formFields;
  }
  static getRSKDISCLFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'CLNT_PROF_P5_CSOP_DEC_CE_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CSOP_DEC_CONFIRM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CSOP_DEC_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CSOP_DEC_DEPT', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CSOP_DEC_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CSOP_DEC_SIGN_BY_RM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CUST_DEC_CONFIRM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CUST_DEC_SIGN_BY_CLIENT', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CUST_DEC_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CUST_DEC_DEPT', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CUST_DEC_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CUST_DEC_CMP_NAME', required: true, value: '' } as FormField);

    formFields.push({ key: 'CLNT_PROF_P5_CUST_DEC_DATE2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CUST_DEC_DEPT2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CUST_DEC_NAME2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CUST_DEC_CMP_NAME2', required: true, value: '' } as FormField);

    formFields.push({ key: 'CLNT_PROF_P5_CUST_DEC_DATE3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CUST_DEC_DEPT3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CUST_DEC_NAME3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CUST_DEC_CMP_NAME3', required: true, value: '' } as FormField);

    formFields.push({ key: 'CLNT_PROF_P5_CUST_DEC_DATE4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CUST_DEC_DEPT4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CUST_DEC_NAME4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CUST_DEC_CMP_NAME4', required: true, value: '' } as FormField);

    formFields.push({ key: 'CLNT_PROF_P5_CUST_DEC_DATE5', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CUST_DEC_DEPT5', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CUST_DEC_NAME5', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_PROF_P5_CUST_DEC_CMP_NAME5', required: true, value: '' } as FormField);
    return formFields;
  }
  static getCICORPINTERNALRISKFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'CLNT_CRP_P8_CNTRY_RSK_A', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_CUST_RSK_A', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_CUST_RSK_B', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_CUST_RSK_C', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_CUST_RSK_D', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_CUST_RSK_E', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_CUST_RSK_F', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_CUST_RSK_G', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_CUST_RSK_H', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_CUST_RSK_I', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_CUST_RSK_J', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_CUST_RSK_K', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_CUST_RSK_L', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_CSOP_DEC_CE_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_CSOP_DEC_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_CSOP_DEC_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_CSOP_DEC_SIGN', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_DEL_TEST_A', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_DEL_TEST_B', required: true, value: '' } as FormField);
    // formFields.push({key: 'CLNT_CRP_P8_L_C_CMPL_DEPT', required: true, value: ''} as FormField);
    // formFields.push({key: 'CLNT_CRP_P8_L_C_DATE', required: true, value: ''} as FormField);
    // formFields.push({key: 'CLNT_CRP_P8_L_C_NAME', required: true, value: ''} as FormField);
    // formFields.push({key: 'CLNT_CRP_P8_L_C_TITLE', required: true, value: ''} as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_L_C_SIGN', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_SMPL_CUST_A', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_SMPL_CUST_B', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_SMPL_CUST_C', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_SMPL_CUST_D', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_SMPL_CUST_E', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_SMPL_CUST_F', required: true, value: '' } as FormField);
    // formFields.push({key: 'CLNT_CRP_P8_L_C_AML_RISK', required: true, value: ''} as FormField);
    // formFields.push({key: 'CLNT_CRP_P8_L_C_CHK_PERF', required: true, value: ''} as FormField);
    // formFields.push({key: 'CLNT_CRP_P8_L_C_CHK_RES', required: true, value: ''} as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_L_C_CMMNTS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_PROD_TEST_A', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_PROD_TEST_B', required: true, value: '' } as FormField);
    // formFields.push({key: 'CLNT_CRP_P8_L_C_PEP_BASE', required: true, value: ''} as FormField);
    // formFields.push({key: 'CLNT_CRP_P8_L_C_NEG_NOTE', required: true, value: ''} as FormField);
    // formFields.push({key: 'CLNT_CRP_P8_L_C_NEG_NOTE_DET',  value: ''} as FormField);
    return formFields;
  }
  static getCICORPLegalAndComplianceFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'CLNT_CRP_P8_L_C_CHK_PERF', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_L_C_PEP_BASE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_L_C_CHK_RES', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_L_C_NEG_NOTE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_L_C_AML_RISK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_L_C_CMPL_DEPT', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_L_C_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_L_C_TITLE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_L_C_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_CRP_P8_L_C_NEG_NOTE_DET', value: '' } as FormField);
    return formFields;
  }
  static getCIINDVDOCSFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'EAM_CLNT_CLNT_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'EAM_CLNT_CLNT_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P1_DOC_REQ_CHK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P1_DOC_RISK_CHK', required: true, value: '' } as FormField);
    return formFields;
  }
  static getCIINDVAPPLCNTFormFields(): FormField[] {
    const formFields = [] as FormField[];
    // Contact Information ((For oversea numbers, please add country and area codes):   done
    formFields.push({ key: 'CLNT_INF_P2_A_CORR_ADD', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_BUS_PHN_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_FAX_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_MOB_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_RES_ADD', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_RES_PHN_NUM', required: true, value: '' } as FormField);
    // Individual/Primary Account Holder     ----> done
    formFields.push({ key: 'CLNT_INF_P2_A_NTNLTY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_ID_CRD', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_ID_CRD_CNTRY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_NAME_CHN', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_NAME_ENG', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_POB', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_DOB', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_PSPRT', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_PSPRT_CNTRY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_PSPRT_EXP_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_EMAIL_ADD', required: true, value: '' } as FormField);
    // A. Background Information ----> DONE
    formFields.push({ key: 'CLNT_INF_P2_A_ANN_SAL', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_EDU_LVL', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_ORG_FND_SRC', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_ORG_FND_SPECIFY', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_RUEPC', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_RUEPC_CNTRY', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_RUEPC_N_P', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_RUEPC_PEP_RLTN', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_RUL', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_RUL_CMP', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_RURE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_RURE_EMP', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_RURE_RLTN', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_SRC_FNDS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_SRC_FNDS_SPECIFY', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_SRC_WLTH', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_SRC_WLTH_SPECIFY', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_A_TOT_ASST', required: true, value: '' } as FormField);
    // b. Contact Information ----> DONE
    formFields.push({ key: 'CLNT_INF_P2_B_CORR_ADD', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_BUS_PHN_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_FAX_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_MOB_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_RES_ADD', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_RES_PHN_NUM', required: true, value: '' } as FormField);
    // B -> Joint Account Holder ---> DONE
    formFields.push({ key: 'CLNT_INF_P2_B_DOB', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_ID_CRD', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_ID_CRD_CNTRY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_NTNLTY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_NAME_CHN', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_NAME_ENG', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_POB', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_PSPRT', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_PSPRT_CNTRY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_PSPRT_EXP_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_EMAIL_ADD', required: true, value: '' } as FormField);
    // B -> Background Information ----> DONE
    formFields.push({ key: 'CLNT_INF_P2_B_RUEPC', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_ANN_SAL', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_ORG_FND_SRC', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_EDU_LVL', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_RUEPC_CNTRY', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_RUEPC_N_P', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_RUEPC_PEP_RLTN', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_RUL', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_RUL_CMP', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_RURE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_RURE_EMP', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_RURE_RLTN', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_SRC_FNDS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_SRC_WLTH', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_TOT_ASST', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_ORG_FND_SPECIFY', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_SRC_WLTH_SPECIFY', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_B_SRC_FNDS_SPECIFY', value: '' } as FormField);
    // 2 -> CLIENT’S DECLARATION AND ACKNOWLEDGEMENT ---->DONE
    formFields.push({ key: 'CLNT_INF_P2_CLNT_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_CLNT_JNT_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_CLNT_JNT_SIGN', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_CLNT_SIGN', required: true, value: '' } as FormField);
    // DECLARATION BY CSOP SALES REPRESENTATIVE ----> DONE
    formFields.push({ key: 'CLNT_INF_P2_CSOP_CE_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_CSOP_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_CSOP_DEC', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_CSOP_NAME', required: true, value: '' } as FormField);
    // formFields.push({key: 'CLNT_INF_P2_CSOP_SIGN', required: true, value: ''} as FormField);
    formFields.push({ key: 'CLNT_INF_P2_CSOP_TRD_DER', required: true, value: '' } as FormField);
    // (C) Authorization of Joint Account (If applicable) --->DONE
    formFields.push({ key: 'CLNT_INF_P2_C_ACC_AUTH', required: true, value: '' } as FormField);
    // (D) Standing Instruction for Redemption Payment
    formFields.push({ key: 'CLNT_INF_P2_D_CHQ_CCY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_D_CHQ_OTHRS', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_D_CHQ_RES_ADD', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_D_PAY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_D_CRD_BK_ACC_NAME', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_D_CRD_BK_ACC_NUM', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_D_CRD_BK_NAME', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_D_CRD_BK_BRNCNTRY', value: '' } as FormField); // ADDED
    formFields.push({ key: 'CLNT_INF_P2_D_CRD_CCY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_D_CRD_SWFT_CD', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_D_CRD_OTHRS', value: '' } as FormField); // ADDED
    formFields.push({ key: 'CLNT_INF_P2_D_RES_CHQ_OTHERS', value: '' } as FormField); // ADDED
    formFields.push({ key: 'CLNT_INF_P2_D_CHQ_RES_REG_ADD', value: '' } as FormField); // ADDED
    formFields.push({ key: 'CLNT_INF_P2_D_NA', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_D_RES_CHQ_RSTRD', required: true, value: '' } as FormField); // ADDED
    // (E) Standing Instruction for Dividend Payment   (Applicable to CSOP fund unitholders)
    formFields.push({ key: 'CLNT_INF_P2_E_DVD_ALL_FUT', required: true, value: '' } as FormField);
    // formFields.push({key: 'CLNT_INF_P2_E_DVD_CHQ', required: true, value: ''} as FormField);
    // formFields.push({key: 'CLNT_INF_P2_E_DVD_CRD', required: true, value: ''} as FormField); // ADDED
    formFields.push({ key: 'CLNT_INF_P2_E_DVD_CHQ_CCY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_E_DVD_CHQ_OTHRS', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_E_DVD_CHQ_RES_ADD', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_E_DVD_CRD_DVD_BK_ACC_NAME', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_E_DVD_CRD_DVD_BK_ACC_NUM', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_E_DVD_CRD_DVD_BK_NAME', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_E_DVD_CRD_DVD_CCY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_E_DVD_CRD_DVD_SWFT_CD', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_E_CRD_OTHRS', value: '' } as FormField); // ADDED
    formFields.push({ key: 'CLNT_INF_P2_E_DVD_RES_CHQ_OTHERS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_E_DVD_RES_CHQ_ADDRESS', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_E_DVD_CHQ_RES_REG_ADD', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_E_DVD_RES_CHQ_RESRADDR', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_E_DVD_NA', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P2_E_DVD_CRD_DIVIDEND', required: true, value: '' } as FormField); // ADDED
    formFields.push({ key: 'CLNT_INF_P2_E_DVD_PAY', required: true, value: '' } as FormField); // ADDED
    formFields.push({ key: 'CLNT_INF_P2_E_DVD_CRD_DVD_BKCNTRY', required: true, value: '' } as FormField); // ADDED
    return formFields;
  }
  static getCIINDVSELFCERTIFFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'CLNT_INF_P3_S1_US_CTZN', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S1_US_TIN', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S2_CNTRY_TAX_RES1', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S2_CNTRY_TAX_RES2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S2_CNTRY_TAX_RES3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S2_CNTRY_TAX_RES4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S2_CNTRY_TAX_RES5', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S2_CNTRY_TAX_TIN1', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S2_CNTRY_TAX_TIN2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S2_CNTRY_TAX_TIN3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S2_CNTRY_TAX_TIN4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S2_CNTRY_TAX_TIN5', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_LGL_ARNGMT_BNFCRY_ENTY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_LGL_ARNGMT_BNFCRY_POS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_LGL_ARNGMT_OTHR_ENTY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_LGL_ARNGMT_OTHR_POS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_LGL_ARNGMT_PRTCTR_ENTY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_LGL_ARNGMT_PRTCTR_POS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_LGL_ARNGMT_STLR_ENTY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_LGL_ARNGMT_STLR_POS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_LGL_ARNGMT_TRSTE_ENTY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_LGL_ARNGMT_TRSTE_POS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_LGL_EXRC_CNTRL', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_LGL_EXRC_CNTRL_ENTY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_LGL_HAS_CNTRL', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_LGL_HAS_CNTRL_ENTY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_LGL_HLDS_POS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_LGL_HLDS_POS_ENTY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_TRST_BNFCRY_ENTY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_TRST_BNFCRY_POS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_TRST_OTHR_ENTY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_TRST_OTHR_POS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_TRST_PRTCTR_ENTY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_TRST_PRTCTR_POS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_TRST_STLR_ENTY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_TRST_STLR_POS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_TRST_TRSTE_ENTY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S3_TRST_TRSTE_POS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S4_DEC_CPCTY', required: true, value: '' } as FormField);

    formFields.push({ key: 'CLNT_INF_P3_S4_DEC_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S4_DEC_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S4_DEC_DATE2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S4_DEC_NAME2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S4_DEC_DATE3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S4_DEC_NAME3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S4_DEC_DATE4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S4_DEC_NAME4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S4_DEC_DATE5', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P3_S4_DEC_NAME5', required: true, value: '' } as FormField);
    // formFields.push({key: 'CLNT_INF_P3_S4_DEC_SIGN', required: true, value: ''} as FormField);
    return formFields;
  }
  static getCIINDVCLNTDECLFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'CLNT_INF_P4_PRMRY_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P4_PRMRY_NAME', required: true, value: '' } as FormField);
    // formFields.push({key: 'CLNT_INF_P4_PRMRY_SIGN', required: true, value: ''} as FormField);
    formFields.push({ key: 'CLNT_INF_P4_SCNDRY_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P4_SCNDRY_NAME', required: true, value: '' } as FormField);
    // formFields.push({key: 'CLNT_INF_P4_SCNDRY_SIGN', required: true, value: ''} as FormField);
    return formFields;
  }
  static getCIINDVASSMTFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'CLNT_INF_P5_ASST_ADQCY', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_ASST_ADQCY_PROOF', required: true, value: '' } as FormField);
    // formFields.push({key: 'CLNT_INF_P5_INV_EXP_Q1', required: true, value: ''} as FormField);
    // formFields.push({key: 'CLNT_INF_P5_INV_EXP_Q2', required: true, value: ''} as FormField);
    formFields.push({ key: 'CLNT_INF_P5_INV_EXP_Q3_OTHR', required: true, value: '' } as FormField);
    // formFields.push({key: 'CLNT_INF_P5_INV_EXP_Q3', required: true, value: ''} as FormField);
    // formFields.push({key: 'CLNT_INF_P5_INV_EXP_Q4', required: true, value: ''} as FormField);
    formFields.push({ key: 'CLNT_INF_P5_EQTY_SEC', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_EQTY_SEC_INV_EXP', required: true, value: '' } as FormField);
    // formFields.push({key: 'CLNT_INF_P5_INF_CHQ', required: true, value: ''} as FormField);
    formFields.push({ key: 'CLNT_INF_P5_EQTY_SEC_NO_OF_TRDS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_EQTY_SEC_Q1', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_EQTY_SEC_Q2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_EQTY_SEC_Q3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_EQTY_SEC_Q3_C', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_EQTY_SEC_Q4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_FIX_INC', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_FIX_INC_INV_EXP', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_FIX_INC_NO_OF_TRDS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_FIX_INC_Q1', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_FIX_INC_Q2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_FIX_INC_Q3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_FIX_INC_Q3_C', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_FIX_INC_Q4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_FUT_OPT', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_FUT_OPT_INV_EXP', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_FUT_OPT_NO_OF_TRDS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_FUT_OPT_Q1', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_FUT_OPT_Q2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_FUT_OPT_Q3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_FUT_OPT_Q3_C', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_FUT_OPT_Q4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_MUT_FND', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_MUT_FND_INV_EXP', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_MUT_FND_NO_OF_TRDS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_MUT_FND_Q1', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_MUT_FND_Q2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_MUT_FND_Q3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_MUT_FND_Q3_C', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_MUT_FND_Q4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_OTHRS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_OTHRS_INV_EXP', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_OTHRS_NO_OF_TRDS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_OTHRS_Q1', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_OTHRS_Q2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_OTHRS_Q3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_OTHRS_Q3_C', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_OTHRS_Q4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_STRUCT_PROD', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_STRUCT_PROD_INV_EXP', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_STRUCT_PROD_NO_OF_TRDS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_STRUCT_PROD_Q1', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_STRUCT_PROD_Q2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_STRUCT_PROD_Q3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_STRUCT_PROD_Q3_C', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_STRUCT_PROD_Q4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_WRRNT_STCK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_WRRNT_STCK_INV_EXP', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_WRRNT_STCK_NO_OF_TRDS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_WRRNT_STCK_Q1', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_WRRNT_STCK_Q2', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_WRRNT_STCK_Q3', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_WRRNT_STCK_Q3_C', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_WRRNT_STCK_Q4', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_CSOP_DEC_PRD_EQTY_REM', required: true, value: '' } as FormField);
    // formFields.push({key: 'CLNT_INF_P5_CSOP_DEC_PRD_CHQ', required: true, value: ''} as FormField);
    formFields.push({ key: 'CLNT_INF_P5_CSOP_DEC_PRD_CHQ_OTHR', value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_CSOP_DEC_PRD_FIX_INC_REM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_CSOP_DEC_PRD_FUT_REM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_CSOP_DEC_PRD_MUT_FND_REM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_CSOP_DEC_PRD_OTHRS_REM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_CSOP_DEC_PRD_STRCT_PRD_REM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_CSOP_DEC_PRD_WRRNT_REM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_CSOP_DEC_CHQ', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_CSOP_DEC_CE_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_CSOP_DEC_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_CSOP_DEC_DEPT', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P5_CSOP_DEC_NAME', required: true, value: '' } as FormField);
    // formFields.push({key: 'CLNT_INF_P5_CSOP_DEC_RM_SIGN', required: true, value: ''} as FormField);
    formFields.push({ key: 'CLNT_INF_P5_INF_CHQ_OTHR', value: '' } as FormField);


    return formFields;
  }
  static getCIINDVCSNSNTIPVFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'CLNT_INF_P6_PRMRY_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P6_PRMRY_NAME', required: true, value: '' } as FormField);
    // formFields.push({key: 'CLNT_INF_P6_PRMRY_SIGN', required: true, value: ''} as FormField);
    formFields.push({ key: 'CLNT_INF_P6_SCNDRY_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P6_SCNDRY_NAME', required: true, value: '' } as FormField);
    // formFields.push({key: 'CLNT_INF_P6_SCNDRY_SIGN', required: true, value: ''} as FormField);
    return formFields;
  }
  static getCIINDVCSNSNTDDMFormFields(): FormField[] {
    const formFields = [] as FormField[];
    formFields.push({ key: 'CLNT_INF_P7_CNSNT_USG_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P7_PRMRY_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P7_PRMRY_NAME', required: true, value: '' } as FormField);
    // formFields.push({key: 'CLNT_INF_P7_PRMRY_SIGN', required: true, value: ''} as FormField);
    formFields.push({ key: 'CLNT_INF_P7_SCNDRY_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P7_SCNDRY_NAME', required: true, value: '' } as FormField);
    // formFields.push({key: 'CLNT_INF_P7_SCNDRY_SIGN', required: true, value: ''} as FormField);
    return formFields;
  }
  // static getCIINDVIRAFormFields():  FormField[] {
  //   const formFields = [] as FormField[];
  //   formFields.push({key: 'CLNT_CRP_P7_CNSNT_USG_NAME', required: true, value: ''} as FormField);
  //   formFields.push({key: 'CLNT_CRP_P7_DATE', required: true, value: ''} as FormField);
  //   formFields.push({key: 'CLNT_CRP_P7_NAME', required: true, value: ''} as FormField);
  //   formFields.push({key: 'CLNT_CRP_P7_SIGN', required: true, value: ''} as FormField);

  //   return formFields;
  // }


  static getCIINDVIRAFormFields(): FormField[] {
    const formFields = [] as FormField[];
    // country risk part 1
    formFields.push({ key: 'CLNT_INF_P8_CNTRY_RSK_A', required: true, value: '' } as FormField);
    // customer risk part2
    formFields.push({ key: 'CLNT_INF_P8_CUST_RSK_A', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_CUST_RSK_B', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_CUST_RSK_C', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_CUST_RSK_D', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_CUST_RSK_E', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_CUST_RSK_F', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_CUST_RSK_G', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_CUST_RSK_H', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_CUST_RSK_I', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_CUST_RSK_J', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_CUST_RSK_K', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_CUST_RSK_L', required: true, value: '' } as FormField);
    // product and delivery distribution channel test part 3
    formFields.push({ key: 'CLNT_INF_P8_DEL_TEST_A', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_PROD_TEST_A', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_PROD_TEST_B', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_DEL_TEST_B', required: true, value: '' } as FormField);
    // SDD PART4
    formFields.push({ key: 'CLNT_INF_P8_SMPL_CUST_A', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_SMPL_CUST_B', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_SMPL_CUST_C', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_SMPL_CUST_D', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_SMPL_CUST_E', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_SMPL_CUST_F', required: true, value: '' } as FormField);
    // Sales representative Part5
    formFields.push({ key: 'CLNT_INF_P8_CSOP_DEC_CE_NUM', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_CSOP_DEC_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_CSOP_DEC_NAME', required: true, value: '' } as FormField);
    // formFields.push({key: 'CLNT_INF_P8_CSOP_DEC_SIGN', required: true, value: ''} as FormField);
    // Legal and compliance
    formFields.push({ key: 'CLNT_INF_P8_L_C_AML_RISK', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_L_C_CHK_PERF', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_L_C_CHK_RES', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_L_C_CMMNTS', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_L_C_CMPL_DEPT', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_L_C_DATE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_L_C_NAME', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_L_C_NEG_NOTE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_L_C_NEG_NOTE_DET', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_L_C_PEP_BASE', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_L_C_SIGN', required: true, value: '' } as FormField);
    formFields.push({ key: 'CLNT_INF_P8_L_C_TITLE', required: true, value: '' } as FormField);
    return formFields;
  }

}
