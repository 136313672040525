import { Injectable } from '@angular/core';
import { DefaultService } from '../utils/default.service';
import { SelectItem } from 'primeng/api';
@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(private defaultService: DefaultService) { }
  initLocalStorageData() {
    this.getRoles();
    this.getCurrencies();
    this.getCountries();
  }

  getRoles() {
    const filter = {};
    filter['sortBy'] = { 'name': 'asc' };
    this.defaultService.get(filter, 1, 100, 'auth/roles').then(data => {
      if (data['status'] === 'success') {
        localStorage.setItem('roles', JSON.stringify(data['rows']));
      }
    }).catch(err => {
    });
  }

  getCurrencies() {
    const filter = {};
    filter['sortBy'] = {
      'code': 'asc'
    };
    this.defaultService.get(filter, 1, 1000, 'commons/currencies').then(data => {
      if (data['status'] === 'success') {
        localStorage.setItem('currencies', JSON.stringify(data));
        const currencyTypes = [];
        data.rows.forEach(element => {
          let symbol = '';
          const symVal = element['symbol'];
          if (symVal !== undefined && symVal !== '') {
            symbol = ' (' + symVal + ')';
          }
          currencyTypes.push({ label: element['code'] + symbol, value: element['code'] } as SelectItem);
        });
        localStorage.setItem('currencyWithSymbol', JSON.stringify(currencyTypes));
      }
    }).catch(err => {
    });
  }

  getCountries() {
    const filter = {};
    filter['sortBy'] = {
      'countryName': 'asc'
    };
    this.defaultService.get(filter, 1, 1000, 'commons/countrystates/list').then(data => {
      if (data['status'] === 'success') {
        localStorage.setItem('countries', JSON.stringify(data));
      }
    }).catch(err => {
    });
  }
}
