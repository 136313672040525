import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { SelectItem } from 'primeng/api';

@Pipe({
  name: 'formatSelectItem'
})
export class FormatSelectItemPipe implements PipeTransform {

  selectItems: SelectItem[] = [];
  checkAndRemoveDups(map: SelectItem[]) {
    for (let i = 0; i < map.length; ++i) {
      if (!this.contains(map[i].label, map[i].value)) {
        this.selectItems.push(map[i]);
      }
    }
  }
  contains(label: any, value: any): boolean {
    if (this.selectItems.length > 0) {
      for (let i = 0; i < this.selectItems.length; ++i) {
        if (label === this.selectItems[i].label && value === this.selectItems[i].value) {
          return true;
        }
      }
    }
    return false;
  }
  transform(value: any[], valueProperty: string, labelProperty: string): SelectItem[] {
    if (value) {

      const map = value.map(function (item) {
        const data: any = {
          value: item[valueProperty],
          label: item[labelProperty]
        };
        if (valueProperty === 'OBJ') {
          data.value = item;
        }
        return data;
      });
      this.checkAndRemoveDups(map);
      return this.selectItems;
    }
  }
}
