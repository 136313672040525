import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { SIDE_NAVE_TITLES } from '../../../utils/util-constants';
import { convertToSnakeCase } from '../../../utils/util-functions';
import { salesPersonObject, teamObject, selectedRowData, clientObject, pdMappingObject, salesPersonMappingObject } from '../../../utils/util-interfaces';

@Component({
  selector: 'app-mapping-form',
  templateUrl: './mapping-form.component.html',
  styleUrls: ['./mapping-form.component.scss']
})
export class MappingFormComponent implements OnInit {

  @Input() openingMode = 'new';
  @Input() showPopup: string;
  @Input() selectedRowData: selectedRowData;
  @Input() listOfUpdatedSalesperson;
  @Input() listOfTeams: teamObject[];
  @Input() listOfClient: clientObject[];
  @Input() listOfSalesperson: salesPersonObject[];
  @Input() listOfPdMapping: pdMappingObject[];
  @Input() listOfSalespersonMapping: salesPersonMappingObject[];

  @Output() setPopup = new EventEmitter<string>();
  @Output() selectedClientData = new EventEmitter<any>();
  @Output() getUpdateClient = new EventEmitter<any>();
  @Output() getUpdateSalesperson = new EventEmitter<any>();
  @Output() selectedSalespersonData = new EventEmitter<any>();

  display: string;
  loading = true;

  selectedSalesperson: salesPersonObject;
  selectedClient: clientObject;
  selectedTeam: teamObject;

  formData: {};

  clientMappingKey = convertToSnakeCase(SIDE_NAVE_TITLES.clientMapping);
  salespersonMappingKey = convertToSnakeCase(SIDE_NAVE_TITLES.salespersonMapping);

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      const change = changes[propName];
      const curVal = change.currentValue;
      switch (propName) {
        case 'listOfUpdatedSalesperson':
          this.listOfUpdatedSalesperson = curVal;
          break;
        case 'listOfSalesperson':
          this.listOfSalesperson = curVal;
          break;
        case 'listOfTeams':
          this.listOfTeams = curVal;
          break;
        case 'selectedRowData':
          this.selectedRowData = curVal;
          this.openingModeCheck();
          break;
        case 'openingMode':
          this.openingMode = curVal;
          this.openingModeCheck();
          break;
        default:
          break;
      }
    }
  }

  openingModeCheck() {
    switch (this.openingMode) {
      case 'new':
        this.selectedClient = null;
        this.selectedTeam = null;
        this.selectedSalesperson = null;
        break;
      case 'edit':
        if (this.listOfClient) {
          this.selectedClient = this.listOfClient.find(c => c.pId == this.selectedRowData.participantId);
        }
        this.selectedTeam = this.listOfTeams.find(t => t.teamName == this.selectedRowData.teamName);
        this.selectedSalesperson = this.listOfSalesperson.find(s => s.id == this.selectedRowData.salespersonId);
        break;
      default:
        break;
    }
  }

  onClickClient(selectedKey) {
    switch (selectedKey) {
      case 'CANCEL':
        this.display = 'false';
        this.setPopup.emit(this.display);
        break;
      case 'SAVE':
        this.display = 'false';
        this.setPopup.emit(this.display);
        this.formData = {
          salesPersonId: this.selectedSalesperson.id,
          participantId: this.selectedClient.pId,
          participantName: this.selectedClient.participantName
        };
        this.selectedClientData.emit(this.formData);
        this.selectedClient = null;
        this.selectedTeam = null;
        break;
      case 'UPDATE':
        this.display = 'false';
        this.setPopup.emit(this.display);
        this.formData = {
          mappingId: this.selectedRowData.mappingId,
          participantId: this.selectedClient.id,
          participantName: this.selectedClient.participantName,
          salesPersonId: this.selectedSalesperson.id
        };
        this.getUpdateClient.emit(this.formData);
        break;
      default:
        break;
    }
  }

  onClickSalesPerson(selectedKey) {
    switch (selectedKey) {
      case 'CANCEL':
        this.display = 'false';
        this.setPopup.emit(this.display);
        break;
      case 'SAVE':
        this.display = 'false';
        this.setPopup.emit(this.display);
        this.formData = {
          teamId: this.selectedTeam.id,
          salespersonName: this.selectedSalesperson.salespersonName,
          salesPersonId: this.selectedSalesperson.id
        };
        this.selectedSalespersonData.emit(this.formData);
        this.selectedSalesperson = null;
        break;
      case 'UPDATE':
        this.display = 'false';
        this.setPopup.emit(this.display);
        this.formData = {
          mappingId: this.selectedRowData.mappingId,
          teamId: this.selectedTeam.id,
          teamName: this.selectedTeam.teamName,
          salespersonName: this.selectedSalesperson.salespersonName,
          salesPersonId: this.selectedSalesperson.id
        };
        this.getUpdateSalesperson.emit(this.formData);
        break;
      default:
        break;
    }
  }

  // to get the selected salesperson in CLIENT MAPPING screen
  getSelectedSalespersonInClient(e) {
    this.selectedSalesperson = e.value;
    const team = this.listOfPdMapping.find(pd => pd.salespersonName == this.selectedSalesperson.salespersonName);
    this.selectedTeam = this.listOfTeams.find(t => t.teamName == team.teamName);
  }

  getSelectedTeam(key) {
    this.selectedTeam = key.value;
  }

  // to get the selected client in CLIENT MAPPING screen
  getSelectedClientName(key) {
    this.selectedClient = key.value;
  }

  // to get the selected salesperson in SALESPERSON MAPPING screen
  getSelectedSalesperson(key) {
    this.selectedSalesperson = key.value;
  }
}
