import { Component, OnInit } from '@angular/core';
import { aumAnalysisStorage, clientObject, salesLocalStorage, salesPersonObject, shareholdingLocalStorage, teamObject, tickerObject, topCreatorsRedeemersStorage, issuerObj } from './utils/util-interfaces';
import { CcassService } from './ccass.service';
import { convertToSnakeCase, getApiFormattedDate, getLatestBusinessDates } from './utils/util-functions';
import { ApiService } from '../shared/api.service';
import { SIDE_NAVE_TITLES } from './utils/util-constants';

@Component({
  selector: 'app-ccass',
  templateUrl: './ccass.component.html',
  styleUrls: ['./ccass.component.scss'],
})
export class CcassComponent implements OnInit {

  startDate: Date;
  endDate: Date;

  selectedItem = localStorage.getItem('selectedItem') ?
    localStorage.getItem('selectedItem') : convertToSnakeCase(SIDE_NAVE_TITLES.salesOverview);

  displayTopbar = true;
  pageTitle: string = SIDE_NAVE_TITLES.salesOverview;

  listOfTickers: tickerObject[];
  listOfTeams: teamObject[];
  listOfClient: clientObject[];
  listOfSalesperson: salesPersonObject[];
  listOfIssuer: issuerObj[];
  filteredListOfClient: clientObject[];

  loading = false;
  growlMessage: {severity: string, detail: string}[] = [];

  shareholdingAnalysisKey = convertToSnakeCase(SIDE_NAVE_TITLES.shareholdingAnalysis);
  salesOverviewKey = convertToSnakeCase(SIDE_NAVE_TITLES.salesOverview);
  aumAnalysisKey = convertToSnakeCase(SIDE_NAVE_TITLES.aumAnalysis);
  topCreatorsRedeemersKey = convertToSnakeCase(SIDE_NAVE_TITLES.topCreatorsReedemers);
  salespersonMappingKey = convertToSnakeCase(SIDE_NAVE_TITLES.salespersonMapping);
  clientMappingKey = convertToSnakeCase(SIDE_NAVE_TITLES.clientMapping);

  constructor(
    private ccassService: CcassService,
    private apiService: ApiService
  ) { }

  ngOnInit() {
    const { start, end } = getLatestBusinessDates();
    this.startDate = start;
    this.endDate = end;

    this.getClientList();
    this.getTickersList();
    this.getSalesPersonList();
    this.getTeamList();
    this.getFilteredClientList();
    this.getIssuerList();

    localStorage.setItem('selectedItem', this.selectedItem);
  }

  getTickersList() {
    this.ccassService.ccassfindOne('', 'v1/tickers').then(response => {
      if (response['status'] && response['status'] === 'success') {
        this.listOfTickers = response['tickers'];
      } else {
        // handle failure
      }
    }).catch(err => {
      // handle error
    });
  }

  getTeamList() {
    this.ccassService.ccassfindOne('', 'v1/teams').then(response => {
      if (response['status'] && response['status'] === 'success') {
        this.listOfTeams = response['teams'];
      } else {
        // handle failure
      }
    }).catch(err => {
      // handle error
    });
  }

  getClientList() {
    this.ccassService.ccassfindOne('', 'v1/participants').then(response => {
      if (response['status'] && response['status'] === 'success') {
        this.listOfClient = response['participants'];
      } else {
        // handle failure
      }
    }).catch(err => {
      // handle error
    });
  }

  getFilteredClientList() {
    if (this.startDate && this.endDate) {
      const query = {};
      query['endDate'] = getApiFormattedDate(this.endDate);
      query['startDate'] = getApiFormattedDate(this.startDate);

      this.ccassService.ccassPost(query, 'v1/participants').then(response => {
        if (response['status'] && response['status'] === 'success') {
          this.filteredListOfClient = response['participants'];
        } else {
          // handle failure
        }
      }).catch(err => {
        // handle error
      });
    }
  }

  getSalesPersonList() {
    this.ccassService.ccassfindOne('', 'v1/salespersons').then(response => {
      if (response['status'] && response['status'] === 'success') {
        this.listOfSalesperson = response['salesPersons'];
      } else {
        // handle failure
      }
    }).catch(err => {
      // handle error
    });
  }

  getIssuerList() {
    this.ccassService.ccassfindOne('', 'v1/issuers').then(response => {
      if (response['status'] && response['status'] === 'success') {
        this.listOfIssuer = response['issuers'];
      } else {
        // handle failure
        this.listOfIssuer = [];
      }
    }).catch(err => {
      // handle error
      this.listOfIssuer = [];
    });
  }

  OnExportClick() {
    this.loading = true;

    const localItem = convertToSnakeCase(this.pageTitle);
    switch (this.pageTitle) {
      case SIDE_NAVE_TITLES.shareholdingAnalysis:

        const localShareholdingData: shareholdingLocalStorage = JSON.parse(localStorage.getItem(localItem));
        this.exportXLSX(
          'shareholding',
          {
            'endDate': getApiFormattedDate(this.endDate),
            'startDate': getApiFormattedDate(this.startDate),
            'teamId': localShareholdingData.selectedTeam.id,
            'salespersonId': localShareholdingData.selectedSalesPerson.id,
            'participantId': localShareholdingData.selectedClient.map(v => v.id),
            'baseFundId': localShareholdingData.baseTicker.fundId,
            'comp1FundId': localShareholdingData.compareTicker1.fundId,
            'comp2FundId': localShareholdingData.compareTicker2.fundId,
            'type': localItem
          }
        );
        break;
      case SIDE_NAVE_TITLES.salesOverview:

        const localSalesData: salesLocalStorage = JSON.parse(localStorage.getItem(localItem));
        this.exportXLSX(
          'sales',
          {
            'startDate': getApiFormattedDate(this.startDate),
            'endDate': getApiFormattedDate(this.endDate),
            'teamId': this.checkValidArray(localSalesData.selectedTeamList) ?
              localSalesData.selectedTeamList.map(v => v.id) : [0],
            'fundId': this.checkValidArray(localSalesData.selectedTickerList) ?
              localSalesData.selectedTickerList.map(v => v.fundId) : [0],
            'type': 'sales_overview'
          }
        );
        break;
      case SIDE_NAVE_TITLES.topCreatorsReedemers:

        const localTcrData: topCreatorsRedeemersStorage = JSON.parse(localStorage.getItem(localItem));
        this.exportXLSX(
          'top-creators-redeemers',
          {
            'startDate': getApiFormattedDate(this.startDate),
            'endDate': getApiFormattedDate(this.endDate),
            'teamId': localTcrData.selectedTeam.id,
            'fundId': localTcrData.selectedTicker.fundId,
            'salespersonId': localTcrData.selectedSalesperson.id,
            'type': 'top_creators_and_redeemers',
          }
        );
        break;
      case SIDE_NAVE_TITLES.aumAnalysis:

      const localAumData: aumAnalysisStorage = JSON.parse(localStorage.getItem(localItem));
      this.exportXLSX(
        'aum-analysis',
        {
          'startDate': getApiFormattedDate(this.startDate),
          'endDate': getApiFormattedDate(this.endDate),
          'teamId': this.checkValidArray(localAumData.selectedTeamList) ?
            localAumData.selectedTeamList.map(v => v.id) : [0],
          'baseFundId': localAumData.selectedBaseTicker.fundId,
          'compFundId': this.checkValidArray(localAumData.selectedComparingTickers) ?
            localAumData.selectedComparingTickers.map(v => v.fundId) : [],
          'participantId': this.checkValidArray(localAumData.selectedClientList) ?
            localAumData.selectedClientList.map(v => v.id) : [0],
          'fundId': this.checkValidArray(localAumData.selectedTickerList) ?
            localAumData.selectedTickerList.map(v => v.fundId) : [0],
          'type': localItem,
          'fundIdForTeam': 2
        }
      );
      default:
        break;
    }
  }

  exportXLSX(name: string, query: any) {

    const fileAsOfDate = this.startDate.toLocaleDateString() + '-' + this.endDate.toLocaleDateString() ;
    const fileName = name.toUpperCase() + '_' + fileAsOfDate;
    const url = 'ccass/v1/reports';

    this.apiService.ccassFileDownload(
      url,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      query,
      fileName,
      () => { this.loading = false; this.growlMessage = [{severity: 'success', detail: 'File download available!'}]; },
      () => { this.loading = false; this.growlMessage = [{severity: 'error', detail: 'Unable to export file!'}]; }
    );
  }

  // Event functions
  selectedMenu(key) {
    this.selectedItem = key;
    localStorage.setItem('selectedItem', key);
  }

  // Date functions
  updateStartDate(key) {
    this.startDate = key;
    this.getFilteredClientList();
  }
  updateEndDate(key) {
    this.endDate = key;
    this.getFilteredClientList();
  }

  // Title functions
  titleHeading(name) {
    this.pageTitle = name;
  }
  shouldDisplayTitle(value) {
    this.displayTopbar = value;
  }

  checkValidArray(data: any[]) {
    return data && data.length != 0;
  }
}
