import { Component, Inject, forwardRef, Injector, OnInit, ViewChild, ElementRef, Renderer2, Input } from '@angular/core';
import { AppComponent } from './app.component';
import { ApiService } from './shared/api.service';
import { Router } from '@angular/router';
import { PageService } from './shared/pages.service';
import { NotificationService } from './menu-popups/notifications/notification.service';
import { UtilitiesService } from './utils/utilities.service';
import { ChatService } from './ws-services/chat.service';
import { AutoLogoutService } from './utils/auto-logout.service';
import { environment } from '../environments/environment';
import { PrivilegeService } from './shared/privilege.service';
import { AuthService } from './shared/auth.service';
import { ThemeingService } from './utils/themeing.service';
import { PlatformLocation } from '@angular/common';
@Component({
	selector: 'app-topbar',
	templateUrl: './app.topbar.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppTopBarComponent implements OnInit {

	isApplicationFee = false;
	user: any = {};
	isOpsUser: boolean;
	title: string;
	parentComponent: any;
	nonifList: any;
	isAddNewCase = false;
	isEditProfile = false;
	isNotifications = false;
	loginUser: Object = {};
	defaultWelcomePage = '';
	matched = false;
	pages: any = [];
	filter: object = {};
	obj: object = {};
	loading = false;
	unReadCount = 0;
	totalCount = 0;
	time: any;
	time1: any;
	notifCreatedTime: any;
	currentDate: any;
	currentTime: any;
	hourDiff: any;
	diffDays: any;
	isExportedFilesLog = false;
	msg: any;
	displayExtFileLog = false;
	kycPages = [];
	riskPages = [];
	dmpPages = [];
	opsPages = [];
	manageStaticPages = [];
	quantPagescol1 = [];
	quantPagescol2 = [];
	quantPagescol3 = [];
	settingsPages = [];
	salesTeamPages = [];
	pAndLPages = [];
	fairvalue = false;
	accountmaintain = false;
	isRates = false;
	showAccMaping = false;
	isTradeDates = false;
	@Input() selectpath: any

	showSessionExpired = false;
	confirmDefaultHome = false;
	selectedPage: any;
	customHeader: any;
	isLoading = false;
	isSelectedWelcomePageAvailable = false;
	landingLogo = '';
	client = this.themeingService.getClientName();

	canCreateNewCase = { 'screenName': 'CaseCreate', 'subModule': 'canCreateNewCase' };
	canViewTradeDatesPriv = { 'screenName': 'Quant', 'subModule': 'canViewTradeDates' };
	canViewFxRatePriv = { 'screenName': 'Quant', 'subModule': 'canViewFxRate' };
	canAdjustFairValuePriv = { 'screenName': 'Quant', 'subModule': 'canAdjustFairValue' };
	canViewAccMapPriv = { 'screenName': 'Quant', 'subModule': 'canViewAccMap' };
	canViewBkIntrstMntncePriv = { 'screenName': 'Quant', 'subModule': 'canViewBkIntrstMntnce' };
	canViewFeeMntncePriv = { 'screenName': 'Quant', 'subModule': 'canViewFeeMntnce' };
	canViewExpFilesLogPriv = { 'screenName': 'Quant', 'subModule': 'canViewExpFilesLog' };

	@ViewChild('menuContainer', { static: false }) menuContainer: ElementRef;
	path: string;


	constructor(@Inject(forwardRef(() => AppComponent)) public app: AppComponent,
		public chatService: ChatService,
		public apiService: ApiService,
		private router: Router,
		public injector: Injector,
		private pageService: PageService,
		public autoLogoutService: AutoLogoutService,
		public privilegeService: PrivilegeService,
		private notificationService: NotificationService,
		private renderer: Renderer2,
		private authService: AuthService,
		private themeingService: ThemeingService,
		private location: PlatformLocation
	) {
		chatService.messages.subscribe(msg => {
			this.getNotifications(msg);
		});

		// Hiding Menu and Notif list on click browser back button.
		location.onPopState(() => {
			this.parentComponent.topbarMenuActive = false;
			this.parentComponent.notifMenuActive = false;
		});
	}



	logout() {
		this.authService.logoutS()
	}

	search(val) {
		if ((val == 'aum') || (val == 'nav') || (val == 'AUM') || (val == 'NAV') || (val == 'Aum') || (val == 'Nav')) {
			this.router.navigate(['/datamarketplacereports'])
		}
	}

	ngOnInit() {
		this.title = environment[window.location.hostname].title;
		this.parentComponent = this.injector.get(AppComponent);
		this.loginUser = this.parentComponent.authService.currentUser();
		if (this.loginUser) {
			this.defaultWelcomePage = this.loginUser['defaultWelcomePage'];
		}
		if (this.defaultWelcomePage === undefined || this.defaultWelcomePage === null) {
			this.defaultWelcomePage = '';
		}
		this.landingLogo = (this.client === 'CSOP' ? 'assets/icons/large-home.svg' : 'assets/icons/large-home-tec.svg')
		localStorage.setItem('defaultWelcomePage', this.defaultWelcomePage);
		this.handleNotifAndUsers();
		this.setWelcomePageWhenNA();
		this.getManageStaticData();
		const canAccessOpsDashboard = { 'screenName': 'OPS', 'subModule': 'canAccessOpsDashboard' };
		if (this.privilegeService.isAuthorized(canAccessOpsDashboard)) {
			this.isOpsUser = true;
		}
	}

	getTitle() {
		return environment[window.location.hostname].defaultTitle + ' ' + environment[window.location.hostname].envType;
	}

	getLogo() {
		return this.themeingService.getIcon('logoURL');
	}

	handleNotifAndUsers() {
		this.pages = this.pageService.pages();
		this.user = JSON.parse(localStorage.getItem('currentUser'));
		this.parentComponent = this.injector.get(AppComponent);
		this.msg = {};
		this.getNotifications(this.msg);
	}

	handleMenuClick(event: Event) {
		const domElement = this.menuContainer.nativeElement;
		this.app.onTopbarMenuButtonClick(event);
		setTimeout(() => {
			if (domElement.scrollTop > 0) {
				this.renderer.setProperty(domElement, 'scrollTop', 0);
			}
		}, 20);
	}

	onMenuClick(url: string) {
		if (url === '/newsletters') {
			localStorage.removeItem('newsletterAction');
		}
		this.parentComponent.topbarMenuActive = false;
		this.router.navigate([url]);
	}

	onClickFeeMaintenance() {
		this.isApplicationFee = true;
	}

	viewAll() {
		this.isNotifications = true;
	}

	addNewCase() {
		this.isAddNewCase = true;
	}

	getNotifications(msg: any) {
		this.filter['sortBy'] = { 'createdAt': 'desc' };
		this.notificationService.find(this.filter).then(data => {
			this.nonifList = data['notificationList'];
			this.nonifList.forEach(element => {
				this.time = new Date(element.createdAt);
				this.currentDate = new Date();
				this.time1 = new Date();
				this.notifCreatedTime = new Date(Date.UTC(this.time.getFullYear(),
					this.time.getMonth(),
					this.time.getDate(),
					this.time.getHours(),
					this.time.getMinutes(),
					this.time.getSeconds()));
				this.currentTime = new Date(this.time1.setTime(this.time1.getTime() + (30 * 60 * 1000) + (5 * 60 * 60 * 1000)));
				this.diffDays = this.currentTime.getDate() - this.notifCreatedTime.getDate();
				element.showTime = UtilitiesService.setLoginAndCaseUpdatedTime(this.notifCreatedTime, this.currentTime);
			});
			this.unReadCount = data['unread'];
			this.totalCount = data['totalRecords'];
			this.loading = false;
			if (msg.message) {
				this.formatAndReturnTheMessage(msg.message);
				this.parentComponent.messageService.add({
					severity: 'success', detail: '<span>' + msg.firstName +
						'</span>' + ' ' + this.formatAndReturnTheMessage(msg.message)
				});
			}
		}).catch(err => {
		});
	}

	onClickProfile() {
		this.parentComponent.topbarMenuActive = false;
		this.isEditProfile = true;
	}

	onClickExportFileLog() {
		this.parentComponent.topbarMenuActive = false;
		this.displayExtFileLog = true;
	}

	formatAndReturnTheMessage(message: string) {
		return message ? message.replace('USER ', '') : message;
	}

	readNotif(notifCase: any) {
		this.obj = { 'noticeId': notifCase['noticeId'] };
		this.notificationService.update(this.obj).then(data => {
			if (data['status'] === 'success') {
				this.nonifList.forEach(element => {
					if (element.noticeId === notifCase.noticeId) {
						element.readFlag = '1';
					}
				});
				if (this.unReadCount > 0) {
					this.unReadCount = this.unReadCount - 1;
				}
			} else {
				this.parentComponent.messageService.add({ severity: 'error', detail: data['status'] });
			}
		}).catch(err => {
			this.parentComponent.messageService.add({ severity: 'error', detail: err });
		});
	}

	ContinueWorking() {
		this.autoLogoutService.reset();
	}

	userLogout() {
		// this.apiService.logout();
		this.authService.logoutS()
		window.location.reload();
	}

	onClickLogo() {
		this.router.navigate(['/dashboard']);
	}
	OnClickAdvSearch() {
		this.router.navigate(['/datamarketplacedetails'])
	}

	getKYCPages(itemsToIterate) {
		this.kycPages = [];
		this.pages.forEach(element => {
			if (itemsToIterate.indexOf(element.priv) !== -1) {
				this.kycPages.push(element);
				this.setWelcomePage(element);
			}
		});
	}

	private setWelcomePage(element: any) {
		if (this.defaultWelcomePage === '') {
			this.defaultWelcomePage = element.routerLink;
			localStorage.setItem('defaultWelcomePage', this.defaultWelcomePage);
		} else if (this.defaultWelcomePage === element.routerLink) {
			this.isSelectedWelcomePageAvailable = true;
		}
	}

	private setWelcomePageWhenNA() {
		if (!this.isSelectedWelcomePageAvailable) {
			let itemsToCheckFrom = [];
			if (this.riskPages.length > 0) {
				itemsToCheckFrom = this.riskPages;
			}
			else if (this.dmpPages.length > 0) {
				itemsToCheckFrom = this.dmpPages
			}
			else if (this.opsPages.length > 0) {
				itemsToCheckFrom = this.opsPages;
			} else if (this.kycPages.length > 0) {
				itemsToCheckFrom = this.kycPages;
			} else if (this.quantPagescol1.length > 0) {
				itemsToCheckFrom = this.quantPagescol1;
			} else if (this.quantPagescol2.length > 0) {
				itemsToCheckFrom = this.quantPagescol2;
			} else if (this.quantPagescol3.length > 0) {
				itemsToCheckFrom = this.quantPagescol3;
			}
			if (itemsToCheckFrom.length > 0) {
				this.defaultWelcomePage = itemsToCheckFrom[0]?.routerLink;
				localStorage.setItem('defaultWelcomePage', this.defaultWelcomePage);
			}
		}
	}

	private getWelcomePage(currentPage: any): string {
		const defaultWelcomePage = localStorage.getItem('defaultWelcomePage');
		if (!defaultWelcomePage || (defaultWelcomePage === currentPage)) {
			return '/';
		}
		return defaultWelcomePage + '/';
	}

	isAllowedToViewMenu(menuHeader: string): boolean {
		let itemsToIterate = [];
		switch (menuHeader) {
			case 'KYC':
				itemsToIterate = ['CASE_CREATE', 'KYC_DASHBOARD', 'MANAGE_GROUPS'];
				this.getKYCPages(itemsToIterate);
				break;
			case 'OPS':
				itemsToIterate = ['OPS_DASHBOARD', 'ACCOUNT_MAPPING', 'RECON_CONFIGURATION'];
				this.getOpsPages(itemsToIterate);
				break;
			case 'RISK': itemsToIterate = ['PRICE_MAINTENANCE',
				'FIRM_LEVEL_LIQUIDITY', 'FIRM_LEVEL_SUSPENDED_STOCK', 'NEWSLETTER_APPROVE', 'FLEX_FILES'];
				this.getRiskPages(itemsToIterate);
				break;
			case 'DMP': itemsToIterate = ['DATA_MARKET_PLACE']
				this.getDmpPages(itemsToIterate);
				break;
			case 'QUANT': itemsToIterate = ['PREMIUM_DISCOUNT', 'PREMIUM_DISCOUNT_SUMMARY', 'DAILY_CR_ORDERS_MGMT', 'UPLOADED_FILES_LOG', 'TRANSACTION_SUMMARY',
				'QUANT_TRADE_DATES', 'QUANT_FX_RATE', 'QUANT_ADJUST_FAIR_VALUE', 'QUANT_ACC_MAPPING',
				'QUANT_BK_INTRST_MNTNCE', 'QUANT_FEE_MNTNCE', 'QUANT_EXP_FILES_LOG', 'PORTFOLIOCODE_MAPPING'];
				this.getQuantPages();
				break;
			case 'MANAGE': itemsToIterate = ['MANAGE_JOBS', 'SCHEDULER', 'AUDIT_TRAIL', 'MANAGE_USERS', 'USER_STATS',
				'MANAGE_ROLES', 'MANAGE_FUNDS', 'NEWSLETTER_CREATE', 'MAIL_CONFIG', 'EMAIL_GENERATION', 'FX_RATE', 'MANAGE_PEER_FUND', 'PRICES',
				'OPS_FX_RATES', 'ENUM_CONFIG', 'DC_CONFIG', 'HOLIDAY_CALENDER', 'ACCOUNT_CONFIG', 'TRANSACTION_COST_CONFIG',
				'CA_CONFIG', 'CODE_CONFIG', 'PARTIES', 'TD_THRESHOLD'];
				this.getSettingsPages(itemsToIterate);
				break;
			case 'P & L': itemsToIterate = ['FINANCE_AGGREGATE'];
				this.getPAndLPages(itemsToIterate);
				break;
			// CCASS-Sankey code
			case 'SALES_TEAM': itemsToIterate = ['DASHBOARD_CC_IFRAME']
				this.getSalesTeamPages();
				break;
			default:
				break;
		}
		const privs = this.privilegeService.privs;
		for (let index = 0; index < itemsToIterate.length; index++) {
			const respectivePrv = itemsToIterate[index];
			if (privs[respectivePrv] !== undefined) {
				return true;
			}
		}
		return false;
	}

	getRiskPages(itemsToIterate) {
		this.riskPages = [];
		this.pages.forEach(element => {
			if (itemsToIterate.indexOf(element.priv) !== -1) {
				this.riskPages.push(element);
				this.setWelcomePage(element);
			}
		});
	}
	getDmpPages(itemsToIterate) {
		this.dmpPages = [];
		this.pages.forEach(element => {
			if (itemsToIterate.indexOf(element.priv) !== -1) {
				this.dmpPages.push(element);
				this.setWelcomePage(element);
			}
		});
	}
	getOpsPages(itemsToIterate) {
		this.opsPages = [];

		this.pages.forEach(element => {
			if (itemsToIterate.indexOf(element.priv) !== -1) {
				this.opsPages.push(element);
				// this.setWelcomePage(element);
			}
		});



	}

	getManageStaticData() {
		const manageStaticPages = ['FX_RATE', 'HOLIDAY_CALENDER'];
		this.manageStaticPages = [];
		this.pages.forEach(element => {
			if (manageStaticPages.indexOf(element.priv) !== -1) {
				this.manageStaticPages.push(element);
				this.setWelcomePage(element);
			}
		});
	}

	getQuantPages() {
		const col1 = ['PREMIUM_DISCOUNT', 'PREMIUM_DISCOUNT_SUMMARY', 'DAILY_CR_ORDERS_MGMT'];
		const col2 = ['UPLOADED_FILES_LOG', 'PORTFOLIOCODE_MAPPING'];
		const col3 = ['TRANSACTION_SUMMARY'];
		this.quantPagescol1 = [];
		this.quantPagescol2 = [];
		this.quantPagescol3 = [];
		this.pages.forEach(element => {
			if (col1.indexOf(element.priv) !== -1) {
				this.quantPagescol1.push(element);
				this.setWelcomePage(element);
			}
		});
		this.pages.forEach(element => {
			if (col2.indexOf(element.priv) !== -1) {
				this.quantPagescol2.push(element);
				this.setWelcomePage(element);
			}
		});
		this.pages.forEach(element => {
			if (col3.indexOf(element.priv) !== -1) {
				this.quantPagescol3.push(element);
				this.setWelcomePage(element);
			}
		});
	}

	fairvalueAdjustment() {
		this.fairvalue = true;
	}

	accountmaintainance() {
		this.accountmaintain = true;
	}

	fxRates() {
		this.isRates = true;
	}

	showFeeTypeToAccMaping() {
		this.showAccMaping = true;
	}

	showTradeDates() {
		this.isTradeDates = true;
	}

	getSettingsPages(itemsToIterate) {
		this.settingsPages = [];
		this.pages.forEach(element => {
			if (itemsToIterate.indexOf(element.priv) !== -1) {
				this.settingsPages.push(element);
				this.setWelcomePage(element);
			}
		});
	}

	getPAndLPages(itemsToIterate) {
		this.pAndLPages = [];
		this.pages.forEach(element => {
			if (itemsToIterate.indexOf(element.priv) !== -1) {
				this.pAndLPages.push(element);
				this.setWelcomePage(element);
			}
		});
	}

	getSalesTeamPages() {
		const pages = ['DASHBOARD_CC_IFRAME']
		this.salesTeamPages = [];
		this.pages.forEach(element => {
			if (pages.indexOf(element.priv) !== -1) {
				this.salesTeamPages.push(element)
				this.setWelcomePage(element)
			}
		});
	}

	// Set Default Home Page
	setDefaultHomePage(page) {
		this.confirmDefaultHome = true;
		this.selectedPage = page;
		this.customHeader = `<div style='opacity:0.7'>Do you want to save <b style='opacity: 1 !important;'>&nbsp;`
			+ page.label + `</b>&nbsp;page as Default Landing Page ?</div>`;
	}

	cancel() {
		this.selectedPage = null;
	}

	update() {
		this.loginUser['defaultWelcomePage'] = this.selectedPage.routerLink;
		this.authService.updateUserData(this.loginUser);
		this.isLoading = true;
		this.pageService.changeProfileData.next(this.loginUser);
		const obj = {
			'defaultWelcomePage': this.selectedPage.routerLink
		};
		this.defaultWelcomePage = this.selectedPage.routerLink;
		this.authService.update(obj).then(res => {
			this.isLoading = false;
			this.parentComponent.messageService.add({
				severity: 'success', detail: 'Updated ' + '<b>' + this.selectedPage.label +
					'</b>' + ' ' + 'as default landing page successfully.'
			});
		}).catch(err => {
			this.parentComponent.messageService.add({ severity: 'error', detail: err });
			this.isLoading = false;
		});
	}

}