import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DefaultService } from '../../utils/default.service';
import * as XLSX from 'xlsx';
import { TableSchema } from '../../utils/table-schema';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-exported-files-log',
  templateUrl: './exported-files-log.component.html',
  styleUrls: ['./exported-files-log.component.scss']
})

export class ExportedFilesLogComponent implements OnInit {
  exportedFiles: any = [];

  loading: Boolean = true;
  tableSchema = TableSchema['exportedFilesLog'];

  @Input() isDisplay = false;
  @Output() isDisplayChange = new EventEmitter<boolean>();
  constructor(private defaultService: DefaultService, private messageService: MessageService) { }

  ngOnInit() {
    this.getExportfiles();
  }
  getExportfiles() {
    const query = {};
    this.defaultService.find(query, 1, 1000, 'quant/exportedfiles').then(data => {
      this.loading = false;
      if (data['status'] === 'success') {
        this.exportedFiles = data['rows'];
        return;
      }
      this.messageService.add({ severity: 'error', detail: data['status'] });
    }).catch(err => {
      this.loading = false;
this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
    });
  }
  downloadExtFile(file) {
    const link = document.createElement('a');
    link.download = 'a';
    link.href = file['file'];
    document.body.appendChild(link);
    link.click();
  }

  deleteExtFile(event) {
    const file = event;
    this.defaultService.delete(file['_id'], 'quant/exportedfiles').then(data => {
      if (data['status'] === 'success') {
        this.messageService.add({ severity: 'success', detail: 'File deleted successfully' });
        this.getExportfiles();
        return;
      }
      this.messageService.add({ severity: 'error', detail: data['status'] });
    }).catch(err => {
this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
    });
  }
  onClickDownload() {
    const colheaders = ['TIMESTAMP', 'EXPORTED FILE', 'FILE TYPE', 'TRADE TYPE'];
    const downloadjobsList = [];
    this.exportedFiles.forEach(file => {
      const date = new Date(file.updatedAt);
      let updatedDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      updatedDate = updatedDate + ' ' + date.toLocaleTimeString().replace(/:\d+ /, ' ');
      downloadjobsList.push({
        'TIMESTAMP': updatedDate,
        'EXPORTED FILE': file.fileName,
        'FILE TYPE': file.fileType,
        'TRADE TYPE': file.tradeType,
      });
    });
    const ws = XLSX.utils.json_to_sheet(downloadjobsList, { header: colheaders });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws);
    /* save to file */
    XLSX.writeFile(wb, 'ExportedFilesLog.xlsx');
    this.messageService.add({ severity: 'success', detail: 'File downloaded successfully' });
  }
  close() {
    this.isDisplay = false;
    this.isDisplayChange.emit(this.isDisplay);
  }
}

