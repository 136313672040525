import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { DEFAULT_TICKER_OBJECT, DEFAULT_TEAM_OBJECT, DEFAULT_SALESPERSON_OBJECT, SIDE_NAVE_TITLES, ERROR_MSG, DEFAULT_TCR_OBJECT, DEFAULT_PARTICIPANT_OBJECT, INIT_TICKER_OBJECT, TCR_KEY } from '../../utils/util-constants';
import { getApiFormattedDate, convertToSnakeCase, setLocalStorageData } from '../../utils/util-functions';
import { salesPersonObject, teamObject, tickerObject, creatorRedeemerResponse, clientObject, topCreatorsRedeemersStorage, salesPersonMappingObject } from '../../utils/util-interfaces';
import { CcassService } from '../../../ccass/ccass.service';

@Component({
  selector: 'app-top-creators-redeemers',
  templateUrl: './top-creators-redeemers.component.html',
  styleUrls: ['./top-creators-redeemers.component.scss']
})
export class TopCreatorsRedeemersComponent implements OnInit {

  @Input() listOfTickers: tickerObject[];
  @Input() listOfTeams: teamObject[];
  @Input() listOfSalesperson: salesPersonObject[];
  @Input() startDate: Date;
  @Input() endDate: Date;

  @Output() setStartDate = new EventEmitter<Date>();
  @Output() setEndDate = new EventEmitter<Date>();
  @Output() titleHeading = new EventEmitter<string>();
  @Output() shouldDisplayTitle = new EventEmitter<boolean>();

  selectedTicker: tickerObject = DEFAULT_TICKER_OBJECT;
  selectedTeam: teamObject = DEFAULT_TEAM_OBJECT;
  selectedSalesperson: salesPersonObject = DEFAULT_SALESPERSON_OBJECT;
  pageTitle: string = SIDE_NAVE_TITLES.topCreatorsReedemers;

  listOfTopCreators: creatorRedeemerResponse[];
  listOfTopRedeemers: creatorRedeemerResponse[];
  filteredTopCreators: creatorRedeemerResponse[];
  filteredTopRedeemers: creatorRedeemerResponse[];
  listOfSalespersonMapping: salesPersonMappingObject[];

  headingText: string = DEFAULT_TCR_OBJECT.heading_shareholdings;
  headingTicker: string = DEFAULT_TICKER_OBJECT.tickerName;
  toggleKey = true;

  minRange = 5;
  maxRange = 20;
  sliderValue = 5;

  loading = true;
  showAlertPopup = false;
  message: string;

  teamList: teamObject[] = [];
  salespersonList: salesPersonObject[] = [];
  tickerList: clientObject[] = [];

  creatorKey: string = TCR_KEY.creator;
  redeemerKey: string = TCR_KEY.redeemer;

  constructor(private ccassService: CcassService) { }

  ngOnInit() {

    const localData: topCreatorsRedeemersStorage = {
      selectedTicker : this.selectedTicker,
      selectedTeam: this.selectedTeam,
      selectedSalesperson: this.selectedSalesperson,
      sliderValue: this.sliderValue,
      toggleKey: this.toggleKey
    };
    localStorage.setItem(convertToSnakeCase(this.pageTitle), JSON.stringify(localData));

    this.titleHeading.emit(this.pageTitle);
    this.shouldDisplayTitle.emit(true);

    this.getCreatorRedeemer();
    this.getSalespersonMapping();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      const change = changes[propName];
      const { currentValue, firstChange } = change;

      switch (propName) {
        case 'startDate':
          if (!firstChange) { this.getCreatorRedeemer(); }
          break;
        case 'endDate':
          if (!firstChange) { this.getCreatorRedeemer(); }
          break;
        case 'listOfTickers':
          this.tickerList = currentValue ? [INIT_TICKER_OBJECT, ...currentValue] : [];
          break;
        case 'listOfTeams':
          this.teamList = currentValue ? [DEFAULT_TEAM_OBJECT, ...currentValue] : [];
          break;
        case 'listOfSalesperson':
          this.salespersonList = currentValue ? [DEFAULT_SALESPERSON_OBJECT, ...currentValue] : [];
          break;
        default:
          break;
      }
    }
  }

  getCreatorRedeemer() {
    this.loading = true;

    const query = {};
    query['endDate'] = getApiFormattedDate(this.endDate);
    query['startDate'] = getApiFormattedDate(this.startDate);
    query['fundId'] = this.selectedTicker.fundId;
    query['salespersonId'] = this.selectedSalesperson.id;
    query['teamId'] = this.selectedTeam.id;
    query['isShareholding'] = this.toggleKey;

    this.ccassService.ccassPost(query, 'v1/creators-redeemers/').then(response => {
      this.loading = false;
      if (response['status'] && response['status'] === 'success') {

        this.listOfTopCreators = response['data']['topCreators'];
        this.listOfTopRedeemers = response['data']['topRedeemers'];

        if (!this.listOfTopCreators && !this.listOfTopRedeemers) {
          this.showWarningPopup();
        }
        this.filteredTopCreators = this.getFilteredData(this.listOfTopCreators);
        this.filteredTopRedeemers = this.getFilteredData(this.listOfTopRedeemers);
      }
    }).catch(err => {

      this.listOfTopCreators = [];
      this.listOfTopRedeemers = [];
      this.filteredTopCreators = this.getFilteredData(this.listOfTopCreators);
      this.filteredTopRedeemers = this.getFilteredData(this.listOfTopRedeemers);

      this.loading = false;
      this.showWarningPopup();
    });
  }

  getFilteredData(data: creatorRedeemerResponse[]) {
    return data && data.length != 0 ? data.slice(0, this.sliderValue) : [];
  }

  onClickToggle(toggleKey) {
    if (toggleKey === 'Shareholdings') {
      this.headingText = DEFAULT_TCR_OBJECT.heading_shareholdings;
      this.toggleKey = true;
      this.getCreatorRedeemer();
    } else {
      this.headingText = DEFAULT_TCR_OBJECT.heading_aum;
      this.toggleKey = false;
      this.getCreatorRedeemer();
    }
    setLocalStorageData('toggleKey', this.toggleKey, this.pageTitle);
  }

  getSelectedTicker(key) {
    this.selectedTicker = key.value;
    setLocalStorageData('selectedTicker', this.selectedTicker, this.pageTitle);
    this.headingTicker = key.value.tickerName;
    this.getCreatorRedeemer();
  }
  getSelectedTeam(key) {
    this.selectedTeam = key.value;
    setLocalStorageData('selectedTeam', this.selectedTeam, this.pageTitle);

    let salespersonObj: salesPersonMappingObject[];

    if (key.value.id == 0) {
      salespersonObj = this.listOfSalespersonMapping;
    } else {
      salespersonObj = this.listOfSalespersonMapping.filter(salesperson => salesperson.teamId === key.value.id);
    }
    this.salespersonList = salespersonObj.map((salesperson) => {
      return {
        id: salesperson.salespersonId,
        salespersonName: salesperson.salespersonName
      };
    });

    this.salespersonList = [DEFAULT_SALESPERSON_OBJECT, ...this.salespersonList];
    this.selectedSalesperson = this.salespersonList[0];
    this.getCreatorRedeemer();
  }
  getSelectedSalesPerson(key) {
    this.selectedSalesperson = key.value;
    setLocalStorageData('selectedSalesperson', this.selectedSalesperson, this.pageTitle);
    this.getCreatorRedeemer();
  }

  handleSliderChange(sliderValue) {
    this.sliderValue = sliderValue.value;
    setLocalStorageData('sliderValue', this.sliderValue, this.pageTitle);
    this.filteredTopCreators = this.getFilteredData(this.listOfTopCreators);
    this.filteredTopRedeemers = this.getFilteredData(this.listOfTopRedeemers);
  }

  showWarningPopup(msg: string = ERROR_MSG.emptyRecords) {
    this.showAlertPopup = true;
    this.message = msg;
  }

  // api function call to get list of mapping records
  getSalespersonMapping() {
    this.ccassService.ccassfindOne('', 'v1/salesperson-mapping').then(response => {
      if (response['status'] && response['status'] === 'success') {
        this.listOfSalespersonMapping = response['salespersonMapping'];
      }
    }).catch(err => {
      this.listOfSalespersonMapping = [];
    });
  }
}
