import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { formatNumber } from '@angular/common';
import { SHAREHOLDING_CHART_LABELS } from '../../../../utils/util-constants';
import { tickerObject, rollingPeriodType, chartShareholdingsData } from '../../../../utils/util-interfaces';
import { calculateValueInMillion } from '../../../../utils/util-functions';
import { BAR_FIRST } from '../../../../utils/util-colours';

@Component({
  selector: 'app-shareholding-chart',
  templateUrl: './shareholding-chart.component.html',
  styleUrls: ['./shareholding-chart.component.scss']
})
export class ShareholdingChartComponent implements OnInit {

  chartProperties: any;
  chartOptions: any;

  @Input() baseTicker: tickerObject;
  @Input() compareTicker: tickerObject;
  @Input() rollingPeriod: rollingPeriodType;
  @Input() chartData: chartShareholdingsData = {dates: [], shareholdings: []};
  @Input() chartLabel: string;

  @Output() onChangeRollingPeriod = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
    this.setChartAttributes(this.chartData, this.isCompareTicker());
  }

  ngOnChanges(changes: SimpleChanges) {

    for (const propName in changes) {
      const change = changes[propName];
      const curVal = change.currentValue;

      switch (propName) {
        case 'chartData':
          this.setChartAttributes(curVal, this.isCompareTicker());
          break;
        default:
          break;
      }
    }
  }

  isCompareTicker() {
    return this.compareTicker != null;
  }

  setDuration(key: string) {
    this.onChangeRollingPeriod.emit(key);
  }

  setChartAttributes(chartCurVal: chartShareholdingsData, isCompare: boolean) {
    this.chartProperties = {
      labels: chartCurVal ? chartCurVal.dates : [],
      datasets: [
        {
          label: this.chartLabel,
          data: chartCurVal ? chartCurVal.shareholdings : [],
          fill: false,
          pointStyle: 'line', // changes legend to line from box
          borderColor: BAR_FIRST,
          borderWidth: 2,
        }
      ]
    };


    this.chartOptions = {

      legend: {
        maxHeight	: 200,
        pointStyle: 'line',
        labels: {
          fontColor: '#040D1C',
          usePointStyle: true,
          drawOnChartArea: true,
        }
      },
      layout: {
        padding: {
          left: 10,
          right: 20,
          top: 0,
          bottom: 0
        }
      },
      scales: {
        xAxes: [{
          ticks: {
            fontColor: '#4C5862',
            fontSize: 12,
            fontStyle: 'bold',
          },
          gridLines: {
            color: 'white'
          }
        }],
        yAxes: [{
          ticks: {
            fontColor: '#4C5862',
            fontSize: '12',
            fontStyle: 'bold',
            userCallback: tick => {
              if (this.chartLabel == SHAREHOLDING_CHART_LABELS.base) {
                const valueInM = parseFloat(calculateValueInMillion(tick, '', 1));
                return formatNumber(valueInM, 'en-US', '1.0-2') + ' M';
              } else {
                return formatNumber(tick, 'en-US', '1.0-2') + '%';
              }
            }

          },
          gridLines: {
            color: '#EBEFF2',
            borderDash: [5, 5],
          }
        }]
      },
      tooltips: {
        backgroundColor: 'white',
        titleFontColor: 'black',
        footerFontColor: 'black',
        borderColor: BAR_FIRST,
        borderWidth: 2,
        callbacks: {
          title: function(tooltipItem, chartData) {
            // returns formated date
            return '\n\t' + chartData.labels[tooltipItem[0]?.index] + '\n';
          },
          footer: function(tooltipItem) {
            const unit = isCompare ? ' %' : '';
            // returns shareholdings and comparison
            return '\t' + formatNumber(tooltipItem[0]?.value, 'en-US', '1.2-2') + unit + '\n';
          },
          label: function() {},    // Do not remove - mandatory function
        }
      }
    };
  }
}
