import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ROLLING_PERIOD_TYPES } from '../../../../utils/util-constants';
import { rollingPeriodType } from '../../../../utils/util-interfaces';

@Component({
  selector: 'app-rolling-period',
  templateUrl: './rolling-period.component.html',
  styleUrls: ['./rolling-period.component.scss']
})
export class RollingPeriodComponent implements OnInit {

  @Input() selectedRollingPeriod: rollingPeriodType;
  @Output() getSelectedDuration = new EventEmitter<string>();

  duration: rollingPeriodType[];

  constructor() { }

  ngOnInit() {
    this.duration = ROLLING_PERIOD_TYPES;
  }

  getSelected(e) {
    this.selectedRollingPeriod = e.value;
    this.getSelectedDuration.emit(e);
  }
}
