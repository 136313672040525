import { Component, AfterViewInit, Renderer2 } from '@angular/core';
import { AuthService } from './shared/auth.service';
import { Router, NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Event as RouterEvent } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { ThemeingService } from './utils/themeing.service';
// import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';

declare let snowplow: Function;
enum MenuMode {
  STATIC,
  OVERLAY,
  SLIM
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  menu: MenuMode = MenuMode.STATIC;



  layout = 'default';

  darkMenu: boolean;

  documentClickListener: Function;

  staticMenuInactive: boolean;

  overlayMenuActive: boolean;

  mobileMenuActive: boolean;

  menuClick: boolean;

  menuButtonClick: boolean;

  topbarMenuButtonClick: boolean;
  onClickMenu: boolean;
  onClickNotif: boolean;
  notifMenuButtonClick: boolean;

  topbarMenuClick: boolean;
  notifMenuClick: boolean;
  sessionToken: any;
  topbarMenuActive: boolean;
  notifMenuActive: boolean;
  activeTopbarItem: Element;
  projectID: any;
  selectpath: any;
  resetSlim: boolean;
  standPid: any;
  pCode: any;
  pName: any;
  projName: any;
  pID: any;
  stoken: any;
  docName: any;
  user: any;
  loginUser: any;
  showHeader = false;
  public isShowingRouteLoadIndicator: boolean;

  constructor(
    private router: Router,
    public renderer: Renderer2,
    public authService: AuthService,
    private swUpdate: SwUpdate,
    themeingService: ThemeingService
  ) {
    themeingService.setTheme();
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.isShowingRouteLoadIndicator = false;
    router.events.subscribe((event: RouterEvent): void => {
      if (event instanceof NavigationEnd) {
        const params = event.url.split('#');
        if (params) {
          this.selectpath = params[0]?.split('?')[0];
          localStorage.setItem('routePath', this.selectpath);
        }
      }
      if (event instanceof RouteConfigLoadStart) {
        this.isShowingRouteLoadIndicator = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.isShowingRouteLoadIndicator = false;
      }
    });
  }

  ngAfterViewInit() {
    // this.mScrollbarService.initScrollbar(document.body, { axis: 'y', theme: 'dark', scrollButtons: { enable: true } });
    this.documentClickListener = this.renderer.listen('body', 'click', (event) => {
      if (!this.menuClick && !this.menuButtonClick) {
        this.mobileMenuActive = false;
        this.overlayMenuActive = false;
        this.resetSlim = true;
      }

      if (!this.topbarMenuClick && !this.topbarMenuButtonClick) {
        this.topbarMenuActive = false;
      }
      if (!this.notifMenuClick && !this.notifMenuButtonClick) {
        this.notifMenuActive = false;
      }
      this.menuClick = false;
      this.menuButtonClick = false;
      this.topbarMenuClick = false;
      this.topbarMenuButtonClick = false;
      this.notifMenuButtonClick = false;
      this.notifMenuClick = false;
      this.notifMenuButtonClick = false;
    });

  }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm('New version is available. Load New Version?')) {
          window.location.reload();
        }
      });
    }
  }

  onMenuButtonClick(event: Event) {
    this.menuButtonClick = true;
    if (this.isMobile()) {
      this.mobileMenuActive = !this.mobileMenuActive;
    } else {
      if (this.staticMenu) {
        this.staticMenuInactive = !this.staticMenuInactive;
      } else if (this.overlayMenu) {
        this.overlayMenuActive = !this.overlayMenuActive;
      }
    }
    event.preventDefault();
  }

  onTopbarMenuButtonClick(event: Event) {
    this.topbarMenuButtonClick = true;
    this.onClickMenu = true;
    this.onClickNotif = false;
    this.topbarMenuActive = !this.topbarMenuActive;
    event.preventDefault();
  }
  onNotificationClick(event: Event) {
    this.onClickNotif = true;
    this.onClickMenu = false;
    this.notifMenuButtonClick = true;
    this.topbarMenuActive = false;
    this.notifMenuActive = !this.notifMenuActive;
    event.preventDefault();
  }

  onTopbarItemClick(event: Event, item: Element) {
    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }
    event.preventDefault();
  }

  onTopbarMenuClick(event: Event) {
    this.topbarMenuClick = true;
  }

  onMenuClick(event: Event) {
    this.menuClick = true;
    this.resetSlim = false;
  }

  get slimMenu(): boolean {
    return this.menu === MenuMode.SLIM;
  }

  get overlayMenu(): boolean {
    return this.menu === MenuMode.OVERLAY;
  }

  get staticMenu(): boolean {
    return this.menu === MenuMode.STATIC;
  }

  changeToSlimMenu() {
    this.menu = MenuMode.SLIM;
  }

  changeToOverlayMenu() {
    this.menu = MenuMode.OVERLAY;
  }

  changeToStaticMenu() {
    this.menu = MenuMode.STATIC;
  }

  isMobile() {
    return window.innerWidth < 640;
  }

}
