import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Injector
} from '@angular/core';
import { AccountMaintainanceComponent } from './account-maintainance.component';
import { DefaultService } from '../../utils/default.service';
@Component({
  selector: 'app-add-fee',
  templateUrl: './add-fee.component.html',
  styleUrls: ['./account-maintainance.component.scss']
})
export class AddFeeComponent implements OnInit {
  @Input()
  isDisplay = false;
  @Input()
  selectedData: Object;
  @Input() currencies: any;
  @Output()
  isDisplayChange = new EventEmitter<boolean>();
  @Input() actiontype: any;
  parentComponent: any;
  headerTitle: String;
  account: any = {};

  currencyTypes: any = [];
  accGroup: FormGroup;
  btnLable = 'Save';
  constructor(
    private injecor: Injector,
    private defaultservice: DefaultService,
    private fb: FormBuilder
  ) { }
  ngOnInit() {
    this.accGroup = this.fb.group({
      name: new FormControl('', Validators.required),
      code: new FormControl('', Validators.required),
      ccy: new FormControl('', Validators.required),
      dayBasis: new FormControl('', Validators.required),
      interestRate: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
      proportionateAmount: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
      postingAcc: new FormControl('', Validators.required),
    });
    this.currencyTypes = this.currencies;
    this.parentComponent = this.injecor.get(AccountMaintainanceComponent);
    if (this.actiontype === 'EDIT') {
      this.headerTitle = 'Edit Details';
      this.btnLable = 'Update';
      this.account = { ...this.selectedData };
    } else {
      this.headerTitle = 'Add Details';
    }
  }
  onlyDecimalNumberKey(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  saveFee() {
    if (this.actiontype === 'EDIT') {
      this.updateAccountFee();
    } else {
      this.addFeeData();
    }
  }
  addFeeData() {
    delete this.account['createdAt'];
    this.account['dayBasis'] = Number(this.account['dayBasis']);
    this.account['interestRate'] = Number(this.account['interestRate']);
    this.account['proportionateAmount'] = Number(
      this.account['proportionateAmount']
    );
    this.addData();
  }
  addData() {
      this.defaultservice
        .create(this.account, 'quant/create/accountdef')
        .then(data => {
          if (data['status'] === 'success') {
            this.parentComponent.messageService.add({
               severity: 'success', detail: 'Account added successfully' }
            );
            this.parentComponent.getAccountMaintainanacedata();
            this.close();
            return;
          }
          this.parentComponent.messageService.add({
             severity: 'error', detail: data['status'] }
          );
        })
        .catch(err => {
          this.parentComponent.messageService.add({
             severity: 'error', detail: err }
          );
        });
  }
  updateAccountFee() {
    delete this.account['createdAt'];
    this.account['dayBasis'] = Number(this.account['dayBasis']);
    this.account['interestRate'] = Number(this.account['interestRate']);
    this.account['proportionateAmount'] = Number(
      this.account['proportionateAmount']
    );
    this.defaultservice
      .update_(this.account._id, this.account, 'quant/update/accountdef')
      .then(data => {
        if (data['status'] === 'success') {
          this.parentComponent.messageService.add({
             severity: 'success', detail: 'Account updated successfully' }
          );
          this.parentComponent.getAccountMaintainanacedata();
          this.close();
          return;
        }
        this.parentComponent.messageService.add({
           severity: 'error', detail: data['status'] }
        );
      })
      .catch(err => {
        this.parentComponent.messageService.add({
           severity: 'error', detail: err }
        );
      });
  }
  close() {
    this.isDisplay = false;
    this.isDisplayChange.emit(this.isDisplay);
  }
}
