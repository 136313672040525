import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { ChartModule } from 'primeng/chart';
import { TableModule } from 'primeng/table';
import { BlockUIModule } from 'primeng/blockui';
import { DialogModule } from 'primeng/dialog';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
// import { GrowlModule } from 'primeng/primeng';
import { TooltipModule } from 'primeng/tooltip';

import {ScrollPanelModule} from 'primeng/scrollpanel';
import {TabViewModule} from 'primeng/tabview';
import {SelectButtonModule} from 'primeng/selectbutton';

import { CcassRoutingModule } from './ccass-routing.module';
import { CcassComponent } from './ccass.component';
import { ClientMappingComponent } from './admin/client-mapping/client-mapping.component';
import { SalesPersonMappingComponent } from './admin/sales-person-mapping/sales-person-mapping.component';
import { SalesOverviewComponent } from './capital/sales-overview/sales-overview.component';
import { ShareholdingAnalysisComponent } from './capital/shareholding-analysis/shareholding-analysis.component';
import { AumAnalysisComponent } from './capital/aum-analysis/aum-analysis.component';
import { AumChartComponent } from './capital/aum-analysis/utils/aum-chart/aum-chart.component';
import { TopCreatorsRedeemersComponent } from './capital/top-creators-redeemers/top-creators-redeemers.component';
import { SideNavComponent } from './utils/side-nav/side-nav.component';
import { TopNavComponent } from './utils/top-nav/top-nav.component';
import { TickerCardComponent } from './capital/shareholding-analysis/utils/ticker-card/ticker-card.component';
import { ShareholdingChartComponent } from './capital/shareholding-analysis/utils/shareholding-chart/shareholding-chart.component';
import { ClientAnalysisComponent } from './capital/shareholding-analysis/utils/client-analysis/client-analysis.component';
import { SalesBarComponent } from './capital/sales-overview/utils/sales-bar/sales-bar.component';
import { MappingFormComponent } from './admin/utils/mapping-form/mapping-form.component';
import { SalesTableComponent } from './capital/sales-overview/utils/sales-table/sales-table.component';
import { RollingPeriodComponent } from './capital/shareholding-analysis/utils/rolling-period/rolling-period.component';
import { ShareholdingTableComponent } from './capital/shareholding-analysis/utils/shareholding-table/shareholding-table.component';
import { MappingTablesComponent } from './admin/utils/mapping-tables/mapping-tables.component';
import { ConfirmationPopupModule } from '../menu-popups/confirmation-popup/confirmation-popup.module';
import { TcrChartComponent } from './capital/top-creators-redeemers/utils/tcr-chart/tcr-chart.component';
import { ClientChartComponent } from './capital/aum-analysis/utils/client-chart/client-chart.component';
import { TickerChartComponent } from './capital/aum-analysis/utils/ticker-chart/ticker-chart.component';
import { TeamChartComponent } from './capital/aum-analysis/utils/team-chart/team-chart.component';

@NgModule({
  declarations: [
    CcassComponent,
    ClientMappingComponent,
    SalesPersonMappingComponent,
    SalesOverviewComponent,
    ShareholdingAnalysisComponent,
    AumAnalysisComponent,
    AumChartComponent,
    TopCreatorsRedeemersComponent,
    SideNavComponent,
    TopNavComponent,
    TickerCardComponent,
    ShareholdingChartComponent,
    ClientAnalysisComponent,
    SalesBarComponent,
    MappingFormComponent,
    SalesTableComponent,
    RollingPeriodComponent,
    ShareholdingTableComponent,
    MappingTablesComponent,
    ClientChartComponent,
    TcrChartComponent,
    TickerChartComponent,
    TeamChartComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    CcassRoutingModule,
    CalendarModule,
    SliderModule,
    MultiSelectModule,
    ChartModule,
    BlockUIModule,
    DropdownModule,
    TableModule,
    FormsModule,
    DialogModule,
    ConfirmationPopupModule,
    // GrowlModule,
    TooltipModule,
    ScrollPanelModule,
		TabViewModule,
		MultiSelectModule,
		SelectButtonModule,
  ]
})
export class CcassModule { }
