import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DefaultService } from '../../utils/default.service';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-trade-dates',
  templateUrl: './trade-dates.component.html',
  styleUrls: ['./trade-dates.component.scss']
})
export class TradeDatesComponent implements OnInit {
  tradeDate = {};
  T1: Date;
  T2: Date;
  T3: Date;
  T4: Date;

  @Input() isDisplay = false;
  @Output() isDisplayChange = new EventEmitter<boolean>();
  newDates = [];
  i = 0;

  constructor(private defaultService: DefaultService, private messageService: MessageService) { }

  ngOnInit() {
    this.changeDate(new Date());
  }
  getTDates(date) {
    this.tradeDate['T_1'] = this.getPreviousDate(date, 1);
  }
  getT1Dates(date) {
    this.tradeDate['asOfDate'] = date;
    this.tradeDate['T1'] = this.getNextDay(date, 1);
    this.getT2Dates();
  }
  getT2Dates() {
    this.tradeDate['T2'] = this.getNextDay(this.tradeDate['T1'], 1);
    this.getT3Dates();
  }
  getT3Dates() {
    this.tradeDate['T3'] = this.getNextDay(this.tradeDate['T2'], 1);
    this.getT4Dates();
  }
  getT4Dates() {
    this.tradeDate['T4'] = this.getNextDay(this.tradeDate['T3'], 1);
  }
  changeDate(selectedDate) {
    const query = {};
    query['asOfDate'] = this.defaultService.getStartDate(selectedDate);
    this.defaultService.find(query, 1, 1000, 'quant/dc_tradeDates').then(data => {
      let tradeDates = {};
      if (data['status'] === 'success') {
        if (data.rows[0]) {
          tradeDates = data.rows[0];
          this.tradeDate['asOfDate'] = new Date(tradeDates['asOfDate']);
          this.tradeDate['T_1'] = new Date(tradeDates['T_1']);
          this.tradeDate['T1'] = new Date(tradeDates['T1']);
          this.tradeDate['T2'] = new Date(tradeDates['T2']);
          this.tradeDate['T3'] = new Date(tradeDates['T3']);
          this.tradeDate['T4'] = new Date(tradeDates['T4']);
        } else {
          this.getT1Dates(selectedDate);
          this.getTDates(selectedDate);
        }
      }
    }).catch(err => {
this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
    });
  }
  getPreviousDate(date, days) {
    let previousDay;
    let previousWorkingDay;
    previousDay = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
    const day = previousDay.getDay();
    if (day === 0 || day === 6) {
      previousDay = new Date(previousDay.getTime() - (2 * 24 * 60 * 60 * 1000));
    }
    previousWorkingDay = previousDay;
    return previousWorkingDay;
  }

  getNextDay(date, days) {
    let nextDay;
    nextDay = new Date(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const day = nextDay.getDay();
    if (day === 0) {
      nextDay = new Date(nextDay.getTime() + (1 * 24 * 60 * 60 * 1000));
    } else if (day == 6) {
      nextDay = new Date(nextDay.getTime() + (2 * 24 * 60 * 60 * 1000));
    }
    return nextDay;
  }

  saveTradeDatesInfo(tDates) {
    this.defaultService.create(tDates, 'quant/dc_tradeDates').then(data => {
      if (data['status'] === 'success') {
        this.close();
        return;
      }
      this.messageService.add({ severity: 'error', detail: data['status'] });
    }).catch(err => {
this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
    });
  }

  changeAsOfDate(selectedDate) {

    this.changeDate(selectedDate);
  }

  close() {
    this.isDisplay = false;
    this.isDisplayChange.emit(this.isDisplay);
  }
}
