import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, ViewChildren, QueryList } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import { formatNumberWithUnit } from '../../../../utils/util-functions';
import { chartShareholdingsData, clientObject, tickerObject } from '../../../../utils/util-interfaces';
import { BAR_SECOND } from '../../../../utils/util-colours';
import { DEFAULT_TICKER_OBJECT } from '../../../../utils/util-constants';

import { MultiSelect } from 'primeng/multiselect';

@Component({
  selector: 'app-client-analysis',
  templateUrl: './client-analysis.component.html',
  styleUrls: ['./client-analysis.component.scss']
})
export class ClientAnalysisComponent implements OnInit {

  @Input() listOfClient: clientObject[] = [];
  @Input() listOfTickers: tickerObject[] = [];
  @Input() chartData: chartShareholdingsData = {dates: [], shareholdings: []};

  @Output() selectedParticipantId = new EventEmitter<any>();
  @Output() selectedTickerEvent = new EventEmitter<tickerObject>();

  selectedTicker: tickerObject = DEFAULT_TICKER_OBJECT;

  selectedClientList: clientObject[] = [];

  isOverlayVisible = false;

  constructor() {}

  ngOnInit() {
    this.plotClientChart(this.chartData);
  }

  ngOnChanges(changes: SimpleChanges) {

    for (const propName in changes) {
      const change = changes[propName];
      const curVal = change.currentValue;

      switch (propName) {
        case 'chartData':
          this.isOverlayVisible = false;
          this.plotClientChart(curVal);
        default:
          break;
      }
    }
  }

  onMultiSelectClick() {
    this.isOverlayVisible = true;
  }

  onClientListSubmit() {
    if (this.selectedClientList.length > 0) {
      this.selectedParticipantId.emit(this.selectedClientList);
    }
  }

  onClientListClear() {
    this.selectedClientList = [];
  }

  getClientTooltip() {
    if (this.selectedClientList) {
      return this.selectedClientList.map(client =>
        client.participantName + (client.teamName ? ' (' + client.teamName + ')' : '')).join(',\r\n');
    }
  }

  getSelectedTicker(e) {
    this.selectedTickerEvent.emit(this.selectedTicker);
  }

  plotClientChart(chartCurVal: chartShareholdingsData) {
    if (chartCurVal) {

      // highcharts code
      Highcharts.setOptions({
        lang: {
          decimalPoint: '.',
          thousandsSep: ','
        }
      });

      const options: any = {
        chart: {
          type: 'line'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        xAxis: {
          categories: chartCurVal.dates
        },
        yAxis: {
          title: {
            text: 'Shareholding'
          },
          gridLineDashStyle: 'LongDash',
          labels: {
            formatter: function() { return formatNumberWithUnit(this.value, '1.0-2'); }
          },
          plotLines: [{
            value: 0,
            width: 2
          }]
        },
        credits: {
          enabled: false
        },
        legend: {
          layout: 'horizontal',
          align: 'right',
          verticalAlign: 'right',
          floating: false,
        },
        tooltip: {
          headerFormat: '<div style="background-color:{series.color};border-radius:25px;color:white; display:flex;align-items: center; justify-content: center">{point.x}</div><br>',
          pointFormat: '<b>{point.y:,.2f}</b>',
          shared: false,
          useHTML: true,
        },
        series: [{
          name: 'Client Analysis',
          data: chartCurVal.shareholdings.map(element => parseFloat(element)),
          color: BAR_SECOND
        }]
      };

      Highcharts.chart('client-analysis', options);
    }
  }

}
