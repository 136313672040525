import { Component, OnInit, Input, Output, EventEmitter, Injector } from '@angular/core';
import { DefaultService } from '../../utils/default.service';
import { AppComponent } from '../../app.component';
import { TableSchema } from '../../utils/table-schema';

@Component({
  selector: 'app-fairvalueadjustment',
  templateUrl: './fairvalueadjustment.component.html',
  styleUrls: ['./fairvalueadjustment.component.scss']
})
export class FairvalueadjustmentComponent implements OnInit {
  @Input()
  isDisplay = false;
  @Output()
  isDisplayChange = new EventEmitter<boolean>();
  fairvalueadjustment: any = [];
  canEdit: boolean;
  parentComponent: any;
  loading = true;
  query: any = {};
  tableSchema = TableSchema['fairvalueadjustment'];

  constructor(private defaultService: DefaultService,
    private injector: Injector) { }

  ngOnInit() {
    this.getFairValueData();
    this.parentComponent = this.injector.get(AppComponent);
  }

  edit(event) {
    for (const u of this.fairvalueadjustment) {
      u.canEdit = false;
      if (event['row']._id === u._id) {
        event['row'].canEdit = true;
      }
    }
  }

  getFairValueData() {
    this.query['asOfDate'] = this.defaultService.getStartDate(new Date());
    this.query['marketStatus'] = ['SUSP', 'HALT'];
    this.defaultService
      .find(this.query, 1, 100000, 'quant/pd_susp_stock')
      .then(data => {
        this.loading = false;
        if (data['status'] === 'success') {
          this.fairvalueadjustment = data['rows'];
        }
      })
      .catch(err => {
        this.loading = false;
      });
  }

  // update service will be called here
  update(row) {
    this.loading = true;
    row['priceOfficialClose'] = +row['priceOfficialClose'];
    delete row['canEdit'];
    this.defaultService
      .update(row._id, row, 'quant/pd_susp_stock')
      .then(data => {
        this.loading = false;
        if (data['status'] === 'success') {
          this.parentComponent.messageService.add({ severity: 'success', detail: 'Account updated successfully' });
          this.getFairValueData();
          return;
        }
        this.parentComponent.messageService.add({ severity: 'error', detail: data['status'] });
      })
      .catch(err => {
        this.loading = false;
        this.parentComponent.messageService.add({ severity: 'error', detail: err });
      });
  }

  close() {
    this.isDisplay = false;
    this.isDisplayChange.emit(this.isDisplay);
  }
}
