import { DefaultService } from './../../utils/default.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.scss']
})
export class RatesComponent implements OnInit {
  rateForm: FormGroup;
  fxRate: any = {};

  isDisableInput = false;
  currDate = new Date();
  asOfDate: Date;

  @Input() isDisplay = false;
  @Output() isDisplayChange = new EventEmitter<boolean>();

  constructor(
    private defaultService: DefaultService,
    private fb: FormBuilder,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.asOfDate = new Date();
    this.changeDate(this.asOfDate);
    this.loadRateForm();
  }

  loadRateForm() {
    this.rateForm = this.fb.group({
      'asOfDateValidation': new FormControl(''),
      'HKDtoCNY': new FormControl('', Validators.required),
      'HKDtoUSD': new FormControl('', Validators.required),
      'CNYtoUSD': new FormControl('', Validators.required),
      'excutedFXUSD': new FormControl('', Validators.required)
    });
  }

  close() {
    this.isDisplay = false;
    this.isDisplayChange.emit(this.isDisplay);
  }

  changeDate(selectedDate) {
    this.asOfDate = selectedDate;
    const query = {};
    query['asOfDate'] = this.defaultService.getStartDate(selectedDate);
    this.defaultService.find(query, 1, 1000, 'quant/dc_fxRate').then(data => {
      if (data['status'] === 'success') {
        if (data.rows[0]) {
          this.isDisableInput = true;
          this.fxRate = data.rows[0];
        } else {
          this.fxRate = {};
          this.isDisableInput = false;
        }
      }
    }).catch(err => {
        this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
    });
  }

  saveRates() {
    this.fxRate['asOfDate'] = this.asOfDate;
    this.fxRate['CNY_USD'] = +this.fxRate['CNY_USD'];
    this.fxRate['HKD_CNY'] = +this.fxRate['HKD_CNY'];
    this.fxRate['HKD_USD'] = +this.fxRate['HKD_USD'];
    this.fxRate['excutedFXUSD'] = +this.fxRate['excutedFXUSD'];
    this.defaultService.create(this.fxRate, 'quant/dc_fxRate').then(data => {
      if (data['status'] === 'success') {
        this.close();
        this.messageService.add({ severity: 'success', detail: 'Fx-Rates added successfully' });
        return;
      }
      this.messageService.add({ severity: 'error', detail: data['status'] });
    }).catch(err => {
          this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
    });
  }

  onlyDecimalNumberKey(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) { return false; } return true;
  }

}
