import { Injectable } from '@angular/core';
import { ApiService, PagedRecords } from '../shared/api.service';
import { Subject } from 'rxjs/Subject';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ItemService {
	serviceName = 'item/';
	public subject = new Subject<any>();
	constructor(private apiService: ApiService) {
	}

	find(itemType: string, filter: Object, page: number = 1, size: number = 100): Promise<PagedRecords> {
		return this.apiService.getWithFilter(this.serviceName + itemType + '/list', filter, page, size)
			.then(response => response as PagedRecords);
	}

	create(itemType: string, data: Object): Promise<Object> {
		let body = new HttpParams();
		body = body.set('body', JSON.stringify(data));
		return this.apiService.post(this.serviceName + itemType + '/create', data);
	}

	update(itemType: string, id: string, data: Object): Promise<Object> {
		const url = `${this.serviceName}/${itemType}/${id}`;
		return this.apiService.put(url, data);
	}

	update_(itemType: string, id: string, data: Object): Promise<Object> {
		const url = `${this.serviceName}${itemType}/${id}/update`;
		return this.apiService.post(url, data);
	}

	delete(itemType: string, id: string): Promise<Object> {
		const url = `${this.serviceName}/${itemType}/${id}`;
		return this.apiService.delete(url);
	}

	delete_(itemType: string, id: string): Promise<Object> {
		const url = `${this.serviceName}/${itemType}/${id}/delete`;
		return this.apiService.post(url);
	}

	sendMessage(data) {
		this.subject.next(data);
	}
}
