import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import * as Chart from 'chart.js';

@Component({
	selector: 'app-dc-calculation-card',
	templateUrl: './dc-calculation.component.html',
	styleUrls: ['./dc-calculation.component.scss']
})
export class DCCalculationComponent implements OnInit {
	@Input() chartsDetails: any;
	defaultColors = ['#ACD679', '#FF8C5D'];
	t0ChartData: boolean;
	t1ChartData: boolean;
	t2ChartData: boolean;
	signOffT1: any 
	signOffT2: any;
	signOffT0: any;
	highest: number;

	constructor(
		private router: Router,
	) {
		Chart.pluginService.register({
			beforeDraw: function (chart) {
				if (chart.config.options.elements.center) {
					// Get ctx from string
					const ctx = chart.chart.ctx;
					// Get options from the center object in options
					const centerConfig = chart.config.options.elements.center;
					const fontStyle = centerConfig.fontStyle || 'Arial';
					const txt = centerConfig.text;
					const color = centerConfig.color || '#000';
					const maxFontSize = centerConfig.maxFontSize || 75;
					const sidePadding = centerConfig.sidePadding || 20;
					const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
					// Start with a base font of 30px
					// ctx.font = "10px " + fontStyle;


					// Get the width of the string and also the width of the element minus 10 to give it 5px side padding
					const stringWidth = ctx.measureText(txt).width;
					const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

					// Find out how much the font can grow in width.
					const widthRatio = elementWidth / stringWidth;
					// var newFontSize = Math.floor(30 * widthRatio);

					const elementHeight = (chart.innerRadius * 2);

					// Pick a new font size so it will not be larger than the height of label.
					const fontSizeToUse = Math.min(10, elementHeight, maxFontSize);
					const wrapText = false;

					// Set font settings to draw it correctly.
					ctx.textAlign = 'center';
					ctx.textBaseline = 'middle';
					const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
					const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
					ctx.font = 20 + 'px ' + fontStyle;
					ctx.fillStyle = color;

					if (!wrapText) {
						ctx.fillText(txt, centerX, centerY);
						return;
					}
				}
			}
		});
	}

	ngOnInit(): void {
		this.prepareDoughnutData();
	}


	  onClickChart(event, chart, params, chartFor) {
		let routeParam: string;
		if (chart === 'doughnut') {
			routeParam = event === 1 ? 'd&cnotsignedoff' + params : 'd&csignedoff' + params;
		} else {
			routeParam = event === 0 ? 'd&cequityetf' : event === 1 ? 'd&cmmfetf' : event === 2 ? 'd&cbondetf' : 'd&clandifetf'
		}
		localStorage.removeItem('filtersObj');
		this.router.navigate(['/reconcilereport', routeParam]);
	}

	prepareDoughnutData() {

		let segment;
		let chartDetails = this.chartsDetails;
		let defaultColors = this.defaultColors;
		let _this = this;
		const DOUGHNUT_CHART_GREEN_COLOR = "#ACD679";
		const DOUGHNUT_CHART_ORANGE_COLOR = "#FF8C5D";
		const BAR_GRAPH_BLUE_COLOR = "#366CCCB5";
		const TEXT_ELEMENT_BLUE_COLOR = "#3b6ccc"
		const TEXT_BLACK_COLOR = "#000000";
		const ETF_TEXT_BLUE_COLOR = "#0C3062"

		

		const t0ChartData = this.chartsDetails.chartData.some(elememt => {
			if(elememt.timeline == 'T0') {
                return true
			}
			else {
				return false
			}
		})
		const t1ChartData = this.chartsDetails.chartData.some(elememt => {
			if(elememt.timeline == 'T1') {
                return true
			}
			else {
				return false
			}
		})
		const t2ChartData = this.chartsDetails.chartData.some(elememt => {
			if(elememt.timeline == 'T2') {
                return true
			}
			else {
				return false
			}
		})


			if (t0ChartData == true) {
				const objData = this.chartsDetails.chartData.filter(obj => obj.timeline === 'T0');
				this.signOffT0 = objData[0]
			}
			else {
				this.signOffT0 = {
							 "timeline": 'T0',
							 "unsignedOff": null,
							 "signedOff": null
							} 
			}
            if (t1ChartData == true) {
				const objData = this.chartsDetails.chartData.filter(obj => obj.timeline === 'T1');
				this.signOffT1 = objData[0]
			} else {
				this.signOffT1 = {
								"timeline": 'T1',
								"unsignedOff": null,
								"signedOff": null
							} 
			}
			if (t2ChartData == true) {
				const objData = this.chartsDetails.chartData.filter(obj => obj.timeline === 'T2');
				this.signOffT2 = objData[0]
			} else {
				this.signOffT2 = {
								"timeline": 'T2',
								"unsignedOff": null,
								"signedOff": null
							} 
			}

		this.chartsDetails.chartData[0] = this.signOffT0
		this.chartsDetails.chartData[1] = this.signOffT1
		this.chartsDetails.chartData[2] = this.signOffT2

		var ctx = document.getElementById("signOffT0DataDC");
		var signOffT0DataDC = new Chart(ctx, {
			type: 'doughnut',
			data: {
				labels: ['Signed Off', 'Not Signed Off'],
				datasets: [{
					data: [Math.round(this.chartsDetails.chartData[0]?.signedOff), Math.round(this.chartsDetails.chartData[0]?.unsignedOff)],
					backgroundColor: [
						DOUGHNUT_CHART_GREEN_COLOR,
						DOUGHNUT_CHART_ORANGE_COLOR,
					],
					hoverBackgroundColor: [
						DOUGHNUT_CHART_GREEN_COLOR,
						DOUGHNUT_CHART_ORANGE_COLOR,
					],
					borderWidth: 1
				}]
			},
			options: {
				onClick: function (e) {
					var activePoints = signOffT0DataDC.getElementsAtEvent(e);
					const selectedIndex = activePoints[0]?._index;
					_this.onClickChart(selectedIndex, "doughnut", "t0", "d&csignoff")
				},
				elements: {
					center: {
						text: 'T0',
						color: TEXT_ELEMENT_BLUE_COLOR, // Default is #000000
						fontStyle: 'Arial', // Default is Arial
						sidePadding: 20, // Default is 20 (as a percentage)
						minFontSize: 10, // Default is 20 (in px), set to false and text will not wrap.
						lineHeight: 25 // Default is 25 (in px), used for when text wraps
					}
				},
				//cutoutPercentage: 40,
				usePointStyle: true,
				boxWidth: 4,
				responsive: false,
				cutoutPercentage: 70,
				legend: {
					display: true,
					position: 'bottom',
					labels: {
						fontSize: 10.5,
						fontWeight: 'bold',
						fontColor: TEXT_BLACK_COLOR,
						usePointStyle: true,
						boxWidth: 4,
						generateLabels: function (chart) {
							const d = chart;
							if (d) {
								return d.config.data.labels?.map(function (label, i) {
									const ds = d.config.data.datasets[0];
									return {
										text: ds.data[i] === 0 ? label + ' ' + 0 + ' ' + '%' : label + ' ' + Math.round((ds.data[i] / (chartDetails.chartData[0]?.signedOff + chartDetails.chartData[0]?.unsignedOff)) * 100) + ' ' + '%',
										fillStyle: defaultColors[i],
									};
								});
							}
							return [];
						}
					}
				},
				onHover: function (evt, elements) {
					evt.target.style.cursor = elements[0] ? 'pointer' : 'default';
					if (elements && elements.length) {
						segment = elements[0];
						this.chart.update();
						segment._model.outerRadius += 1;
					} else {
						if (segment) {
							segment._model.outerRadius -= 1;
						}
						segment = null;
					}
				},
				tooltips: {
					callbacks: {
						label: function (tooltipItem, data) {
							var dataset = data.datasets[tooltipItem.datasetIndex];
							var currentValue = dataset.data[tooltipItem.index];
							var value = data['labels'][tooltipItem.index];
							return value + ' : ' + currentValue;
						}
					}
				},

			}
		});

		var ctx1 = document.getElementById("signOffT1DataDC");
		var signOffT1DataDC = new Chart(ctx1, {
			type: 'doughnut',
			data: {
				labels: ['Signed Off', 'Not Signed Off'],
				datasets: [{
					data: [Math.round(this.chartsDetails.chartData[1].signedOff), Math.round(this.chartsDetails.chartData[1].unsignedOff)],
					backgroundColor: [
						DOUGHNUT_CHART_GREEN_COLOR,
						DOUGHNUT_CHART_ORANGE_COLOR,
					],
					hoverBackgroundColor: [
						DOUGHNUT_CHART_GREEN_COLOR,
						DOUGHNUT_CHART_ORANGE_COLOR,
					],
					borderWidth: 1
				}]
			},
			options: {
				onClick: function (e) {
					var activePoints = signOffT1DataDC.getElementsAtEvent(e);
					const selectedIndex = activePoints[0]?._index;
					_this.onClickChart(selectedIndex, "doughnut", "t1", "d&csignoff")
				},
				elements: {
					center: {
						text: 'T1',
						color: TEXT_ELEMENT_BLUE_COLOR, // Default is #000000
						fontStyle: 'Arial', // Default is Arial
						sidePadding: 20, // Default is 20 (as a percentage)
						minFontSize: 10, // Default is 20 (in px), set to false and text will not wrap.
						lineHeight: 25 // Default is 25 (in px), used for when text wraps
					}
				},
				//cutoutPercentage: 40,
				usePointStyle: true,
				boxWidth: 4,
				responsive: false,
				cutoutPercentage: 70,
				legend: {
					display: true,
					position: 'bottom',
					labels: {
						fontSize: 10.5,
						fontWeight: 'bold',
						fontColor: TEXT_BLACK_COLOR,
						usePointStyle: true,
						boxWidth: 4,
						generateLabels: function (chart) {
							const d = chart;
							if (d) {
								return d.config.data.labels?.map(function (label, i) {
									const ds = d.config.data.datasets[0];
									return {
										text: ds.data[i] === 0 ? label + ' ' + 0 + ' ' + '%' : label + ' ' + Math.round((ds.data[i] / (chartDetails.chartData[1].signedOff + chartDetails.chartData[1].unsignedOff)) * 100) + ' ' + '%',
										fillStyle: defaultColors[i],
									};
								});
							}
							return [];
						}
					}
				},
				onHover: function (evt, elements) {
					evt.target.style.cursor = elements[0] ? 'pointer' : 'default';
					if (elements && elements.length) {
						segment = elements[0];
						this.chart.update();
						segment._model.outerRadius += 1;
					} else {
						if (segment) {
							segment._model.outerRadius -= 1;
						}
						segment = null;
					}
				},
				tooltips: {
					callbacks: {
						label: function (tooltipItem, data) {
							var dataset = data.datasets[tooltipItem.datasetIndex];
							var currentValue = dataset.data[tooltipItem.index];
							var value = data['labels'][tooltipItem.index];
							return value + ' : ' + currentValue;
						}
					}
				},

			}
		});

		var ctx2 = document.getElementById("signOffT2DataDC");
		var signOffT2DataDC = new Chart(ctx2, {
			type: 'doughnut',
			data: {
				labels: ['Signed Off', 'Not Signed Off'],
				datasets: [{
					data: [Math.round(this.chartsDetails.chartData[2].signedOff), Math.round(this.chartsDetails.chartData[2].unsignedOff)],
					backgroundColor: [
						DOUGHNUT_CHART_GREEN_COLOR,
						DOUGHNUT_CHART_ORANGE_COLOR,
					],
					hoverBackgroundColor: [
						DOUGHNUT_CHART_GREEN_COLOR,
						DOUGHNUT_CHART_ORANGE_COLOR,
					],
					borderWidth: 1
				}]
			},
			options: {
				onClick: function (e) {
					var activePoints = signOffT2DataDC.getElementsAtEvent(e);
					const selectedIndex = activePoints[0]?._index;
					_this.onClickChart(selectedIndex, "doughnut", "t2", "d&csignoff")
				},
				elements: {
					center: {
						text: 'T2',
						color: TEXT_ELEMENT_BLUE_COLOR, // Default is #000000
						fontStyle: 'Arial', // Default is Arial
						sidePadding: 20, // Default is 20 (as a percentage)
						minFontSize: 10, // Default is 20 (in px), set to false and text will not wrap.
						lineHeight: 25 // Default is 25 (in px), used for when text wraps
					}
				},
				//cutoutPercentage: 40,
				usePointStyle: true,
				boxWidth: 4,
				responsive: false,
				cutoutPercentage: 70,
				legend: {
					display: true,
					position: 'bottom',
					labels: {
						fontSize: 10.5,
						fontWeight: 'bold',
						fontColor: TEXT_BLACK_COLOR,
						usePointStyle: true,
						boxWidth: 4,
						generateLabels: function (chart) {
							const d = chart;
							if (d) {
								return d.config.data.labels?.map(function (label, i) {
									const ds = d.config.data.datasets[0];
									return {
										text: ds.data[i] === 0 ? label + '  ' + 0 + ' ' + '%' : label + ' ' + Math.round((ds.data[i] / (chartDetails.chartData[2].signedOff + chartDetails.chartData[2].unsignedOff)) * 100) + ' ' + '%',
										fillStyle: defaultColors[i],
									};
								});
							}
							return [];
						}
					}
				},
				onHover: function (evt, elements) {
					evt.target.style.cursor = elements[0] ? 'pointer' : 'default';
					if (elements && elements.length) {
						segment = elements[0];
						this.chart.update();
						segment._model.outerRadius += 1;
					} else {
						if (segment) {
							segment._model.outerRadius -= 1;
						}
						segment = null;
					}
				},
				tooltips: {
					callbacks: {
						label: function (tooltipItem, data) {
							var dataset = data.datasets[tooltipItem.datasetIndex];
							var currentValue = dataset.data[tooltipItem.index];
							var value = data['labels'][tooltipItem.index];
							return value + ' : ' + currentValue;
						}
					}
				},

			}
		});



		var ctx4 = document.getElementById("barChartDataDC");
		const equityEtf = this.chartsDetails?.fundData?.['EQUITY ETF'] ? this.chartsDetails.fundData['EQUITY ETF'] : 0
		const mmfEtf = this.chartsDetails?.fundData?.['MMF ETF'] ? this.chartsDetails.fundData['MMF ETF'] : 0
		const bondEtf = this.chartsDetails?.fundData?.['BOND ETF'] ? this.chartsDetails.fundData['BOND ETF'] : 0
		const landiEtf = this.chartsDetails?.fundData?.['L&I ETF'] ? this.chartsDetails.fundData['L&I ETF'] : 0
		const highest = Math.max(equityEtf,mmfEtf,bondEtf,landiEtf)
		if(highest < 7) {
			this.highest = highest + 4
		} else {
			this.highest = highest
		}
		var barChartDataDC = new Chart(ctx4, {
			type: 'bar',
			data: {
				labels: ['Equity ETF', 'MMF ETF', 'Bond ETF','L&I ETF'],
				datasets: [
					{
						backgroundColor: BAR_GRAPH_BLUE_COLOR,
						data: [equityEtf, mmfEtf, bondEtf, landiEtf],
						barPercentage: 0.3,
					}
				]
			},
			options: {
				onClick: function (e) {
					var activePoints = barChartDataDC.getElementsAtEvent(e);
					const selectedIndex = activePoints[0]?._index;
					_this.onClickChart(selectedIndex, "bar", "", "dcbar");
				},
				elements: {
					center: {
						text: '',
						color: TEXT_BLACK_COLOR, // Default is #000000
						fontStyle: 'Arial', // Default is Arial
						sidePadding: 20, // Default is 20 (as a percentage)
						minFontSize: 10, // Default is 20 (in px), set to false and text will not wrap.
						lineHeight: 25 // Default is 25 (in px), used for when text wraps
					}
				},
				//cutoutPercentage: 40,
				usePointStyle: true,
				boxWidth: 4,
				responsive: false,
				cutoutPercentage: 70,
				legend: {
					display: false,
					position: 'left',
					labels: {
						fontSize: 10,
						fontWeight: 'bold',
						fontColor: TEXT_BLACK_COLOR,
						usePointStyle: true,
						boxWidth: 4,
					}
				},
				onHover: function (evt, elements) {
					evt.target.style.cursor = elements[0] ? 'pointer' : 'default';
				},
				tooltips: {
					callbacks: {
						label: function (tooltipItem, data) {
							var dataset = data.datasets[tooltipItem.datasetIndex];
							var currentValue = dataset.data[tooltipItem.index];
							var value = data['labels'][tooltipItem.index];
							return value + ' : ' + currentValue;
						}
					}
				},
				animation: {
					onComplete: function () {
						const chartInstance = this.chart;
						const ctx = chartInstance.ctx;
						ctx.textBaseline = 'middle';
						ctx.textAlign = 'center';
						ctx.font = "10px \'Helvetica Neue\', Helvetica, Arial, sans-serif"
						this.data.datasets.forEach(function (dataset, i) {
							const meta = chartInstance.controller.getDatasetMeta(i);
							meta.data.forEach(function (bar, index) {
								const data = dataset.data[index];
								ctx.fillText(data, bar._model.x, bar._model.y - 10);
							});
						});
					}
				},
				scales: {
					yAxes: [{
						showDatapoints: true,
						// suggestedMin: 0,
						gridLines: {
							drawOnChartArea: false,
						},
						ticks: {
							max: this.highest + 2,
							min: 0,
						},
						scaleLabel: {
							display: true,
							fontSize: (window.screen.width <= 1680) ? 9 : 10,
						}
					}],
					xAxes: [{
						showDatapoints: true,
						gridLines: {
							drawOnChartArea: false,
						},
						scaleLabel: {
							display: true,
							fontSize: (window.screen.width <= 1680) ? 9 : 10,
						},
						ticks: {
							padding: 0,
							fontSize: (window.screen.width <= 1680) ? 9 : 10,
							beginAtZero: true,
							fontColor: ETF_TEXT_BLUE_COLOR,
						}
					}]

				}
			}
		});
	}

}
