import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlockUIModule } from 'primeng/blockui';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ButtonModule } from 'primeng/button';
import { AppPrimengModule } from './app.primeng.module';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { ItemService } from './shared/item.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { GridsterModule } from 'angular-gridster2';
import { UserService } from './users/users.service';
import { WorkflowService, EntityService } from './kyc/case/case-submit/workflow.service';
import { SortParamsService } from './shared/sortParams.service';
import { CKEditorModule } from 'ng2-ckeditor';
import { AvatarModule } from "primeng/avatar";

import { TableModule } from 'primeng/table';
// import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { RatingModule } from 'primeng/rating';
import { TabViewModule } from 'primeng/tabview';
import {TreeModule} from 'primeng/tree';
import {CheckboxModule} from 'primeng/checkbox';




@NgModule({
	declarations: [
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		AngularSvgIconModule,
		BlockUIModule,
		CardModule,
		PdfViewerModule,
		RecaptchaModule,
		RecaptchaFormsModule,
		AppPrimengModule,
		GridsterModule,
		ButtonModule,
		DialogModule,
		TableModule,
		
		// ToastModule,
		CalendarModule,
		SliderModule,
		MultiSelectModule,
		ContextMenuModule,
		DropdownModule,
		ProgressBarModule,
		InputTextModule,
		RatingModule,
		TabViewModule,
		CKEditorModule,
		TreeModule,
		CheckboxModule,
		AvatarModule
	],
	providers: [
		ItemService,
		WorkflowService,
		EntityService,
		SortParamsService,
		UserService
	]
})
export class SharedModule { }
