import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, PagedRecords } from '../shared/api.service';

export interface User {
	_id: string;
	loginName: string;
	firstName: string;
	lastName: string;
	email: string;
	roleCodes: string[];
}

@Injectable()
export class UserService {
	serviceName = 'auth/user';
	postService = 'auth/myaccount/signup';
	FindService = 'auth/users';
	constructor(private apiService: ApiService) {
	}

	find(filter: Object, page: number = 1, size: number = 10): Promise<PagedRecords> {
		let query = new HttpParams();
		query = query.set('page', page.toString());
		query = query.set('limit', size.toString());

		if (Object.keys(filter).length !== 0) {
			for (const key of Object.keys(filter)) {
				query = query.set(key, filter[key]);
				if (typeof (filter[key]) === 'object') {
					query = query.set(key, JSON.stringify(filter[key]));
				}
			}
		}
		return this.apiService.get(this.FindService, query)
			.then(response => response as PagedRecords);
	}

	findByService(filter: Object, page: number = 1, size: number = 10, serviceName: string): Promise<PagedRecords> {
		let query = new HttpParams();
		query = query.set('page', page.toString());
		query = query.set('limit', size.toString());

		if (Object.keys(filter).length !== 0) {
			for (const key of Object.keys(filter)) {
				query = query.set(key, filter[key]);
				if (typeof (filter[key]) === 'object') {
					query = query.set(key, JSON.stringify(filter[key]));
				}
			}
		}
		return this.apiService.get('auth/' + serviceName, query)
			.then(response => response as PagedRecords);
	}

	create(data: Object): Promise<Object> {
		let body = new HttpParams();
		body = body.set('body', JSON.stringify(data));
		return this.apiService.post(this.postService, data);
	}

	update(id: string, data: Object): Promise<Object> {
		const url = `${this.serviceName}/${id}/update`;
		let body = new HttpParams();
		body = body.set('body', JSON.stringify(data));
		return this.apiService.post_(url, data);
	}

	delete(id: string): Promise<Object> {
		const url = `${this.serviceName}/${id}/delete`;
		return this.apiService.post_(url);
	}
}
