import { formatNumber } from '@angular/common';

const calculateValueInMillion = (value: number, invalidString: string = '', decimalPlaces: number = 2) => {
    if (!isNaN(value)) {
        const valueInMillion = (value / Math.pow(10, 6));
        return Math.round(valueInMillion) === valueInMillion ? valueInMillion.toString() : valueInMillion.toFixed(decimalPlaces);
    } else {
        return invalidString;
    }
};

const decrementDate = (mondate: Date, factor: number) => {
    const newDate = new Date(mondate.getTime());

    switch (newDate.getDay()) {
        case 1:                            // MONDAY
            newDate.setDate(newDate.getDate() - factor  - 2);
            break;
        default:
            newDate.setDate(newDate.getDate() - factor);
            break;
    }
    return newDate;
};

const getLatestBusinessDates = (date: Date = new Date(), factor: number = 2) => {
    const end = decrementDate(date, factor);
    switch (end.getDay()) {
        case 0:                           // SUNDAY
            end.setDate(end.getDate() - 2);
            break;
        case 6:                           // SATURDAY
            end.setDate(end.getDate() - 1);
            break;
        default:                          // WEEKDAYS
            break;
    }

    const start = factor > 1 ? decrementDate(end, (factor - 1)) : decrementDate(end, 1);

    return { end, start };
};

const getApiFormattedDate = (date: Date) => {
    if (date) {
        const timezoneOffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
        return (new Date(date.getTime() - timezoneOffset)).toISOString();
    }
    return null;
};

const convertToSnakeCase = (value: string = '') => {
    return value.replace(/\W+/g, ' ')
      .split(/ |\B(?=[A-Z])/)
      .map(word => word.toLowerCase())
      .join('_');
};

const calculateValueForRange = (rangeStart: number, rangeEnd: number, currentValueInPercentage: number) => {
    if (isNaN(rangeEnd) || isNaN(rangeStart) || isNaN(currentValueInPercentage)) {
        return null;
    }
    const diff = rangeEnd - rangeStart;
    return ( diff * (currentValueInPercentage / 100) ) + rangeStart;
};

const setLocalStorageData = (key: string, value: any, screenKey: string) => {
    const item = convertToSnakeCase(screenKey);

    const data = JSON.parse(localStorage.getItem(item));
    data[key] = value;
    localStorage.setItem(item , JSON.stringify(data));
};

const formatNumberWithUnit = (value: number, digitsInfo: string = '1.0-0') => {
    // if value greater than or equal to 1 trillion
    if (value >= Math.pow(10, 12)) {
        return formatNumber(value / Math.pow(10, 12), 'en-US', digitsInfo) + ' T';
      } else if (value >= Math.pow(10, 9)) {
        return formatNumber(value / Math.pow(10, 9), 'en-US', digitsInfo) + ' B';
      } else if (value >= Math.pow(10, 6)) {
        return formatNumber(value / Math.pow(10, 6), 'en-US', digitsInfo) + ' M';
      } else {
        return formatNumber(value, 'en-US', '1.0-0');
      }
};

export {
    calculateValueInMillion,
    getLatestBusinessDates,
    getApiFormattedDate,
    convertToSnakeCase,
    calculateValueForRange,
    setLocalStorageData,
    formatNumberWithUnit
};
