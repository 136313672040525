import { Component, OnInit } from '@angular/core';
import { DefaultService } from '../../utils/default.service';
import { ApiService } from '../../shared/api.service';
import { ThemeingService } from '../../utils/themeing.service';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { MessageService } from 'primeng/api';


@Component({
	selector: 'app-form-level-liquidation',
	templateUrl: './form-level-liquidation.component.html',
	styleUrls: ['./form-level-liquidation.component.scss'],
	providers: [DashboardComponent]
})
export class FormLevelLiquidationComponent implements OnInit {
	LiquidityDistribution = [];
	liquidityStocks = [];
	loading = true;
	asOfDate: any;
	Math: any;
	currentDate = new Date();
	liquidityGross: any = [];
	barChartOptions: any = [];
	barChartData: any = [];
	chartDataLabels = [];
	chartData: any = [];
	previousWorkingDay: Date;
	themeObj: any;
	maxDate = new Date();
	constructor(
		private defaultService: DefaultService,
		private messageService: MessageService,
		private apiService: ApiService,
		private themeService: ThemeingService, private DashboardComponent: DashboardComponent
	) {
		this.Math = Math;
	}

	ngOnInit() {
		this.chartDataLabels = ['1', '3', '5', '7', '30'],
			this.getPreviousDate(this.currentDate, 2);
		this.themeObj = this.themeService.getThemeObj();
		this.setMaxDate();
	}

	getLiquidityStockList() {
		const query = {};
		this.chartData = ['0', '0', '0', '0', '0'];
		const colors = [];
		query['asOfDate'] = this.defaultService.getStartDate(this.previousWorkingDay);
		this.defaultService.find(query, 1, 1000, 'csoprisk/firmliquidation').then(data => {
			this.loading = false;
			if (data['status'] === 'success') {
				this.liquidityStocks = data['rows'];
				this.liquidityGross = data['gross'];
				this.prepareBarChartData(data);
				return;
			}
			this.getBarChartData(colors);
			this.messageService.add({ severity: 'error', detail: data['status'] });
			this.liquidityStocks = [];
			const liquidityGross = [];
			this.LiquidityDistribution = [];
		}).catch(err => {
			this.messageService.add({ severity: 'error', detail: err });
			this.liquidityStocks = [];
			const liquidityGross = [];
			this.LiquidityDistribution = [];
		});
	}

	prepareBarChartData(data) {
		const daysToTrade = [];
		const colors = [];
		data['gross'].forEach(trade => {
			daysToTrade.push((Math.abs(trade['value'] * 100).toFixed(2)));
		});
		this.chartData = daysToTrade;
		this.getBarChartData(colors);
	}
	exportLiquidityXLSX() {
		const query = 'asOfDate=' + this.defaultService.getStartDate(this.previousWorkingDay);
		this.apiService.getFileDownload('cop/csoprisk/firmlevel/report',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', query, 'LiquidityStocks'
		);
		this.messageService.add(
			{ severity: 'success', detail: 'XLSX downloaded successfully' });
	}
	getPreviousDate(date, days) {
		let previousDay;
		if (localStorage.getItem('asOfDate')) {
			this.previousWorkingDay = new Date(localStorage.getItem('asOfDate'));
		} else {
			previousDay = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
			const day = previousDay.getDay();
			if (day === 0 || day === 6) {
				previousDay = new Date(previousDay.getTime() - (2 * 24 * 60 * 60 * 1000));
			}
			this.previousWorkingDay = previousDay;
		}
		this.getLiquidityStockList();
	}

	getBarChartData(colors) {
		// Calculate Max & min Values because of overlapping
		let minVal = 0;
		let maxVal = Math.max.apply(Math, this.chartData.map(function (count) { return count; }));
		maxVal = parseInt(maxVal);
		const lastIndex = 10 - (maxVal.toString().split('').pop());
		maxVal = (maxVal + lastIndex) + 20;
		let minValue = Math.min.apply(Math, this.chartData.map(function (count) { return count; }));
		if (minValue < 0) {
			minValue = parseInt(minValue);
			const lastVal = 10 - (minValue.toString().split('').pop());
			minVal = (minValue - lastVal) - 10;
		}
		this.barChartData = {
			labels: this.chartDataLabels,
			datasets: [
				{
					label: 'Liquidity',
					data: this.chartData,
					backgroundColor: [
						'#add573',
						'#add573',
						'#f8db66',
						'#f8db66',
						'#ff7f7a'
					],
					hoverBackgroundColor: [
						'#add573',
						'#add573',
						'#f8db66',
						'#f8db66',
						'#ff7f7a'
					]
				}
			]
		};
		this.barChartOptions = {
			events: false,
			maintainAspectRatio: false,
			animation: {
				onComplete: function () {
					const chartInstance = this.chart;
					const ctx = chartInstance.ctx;
					ctx.canvas.parentNode.style.height = '18em';
					// ctx.canvas.parentNode.style.height = '280px';
					// ctx.canvas.parentNode.style.height = '30vh';
					ctx.textBaseline = 'middle';
					ctx.textAlign = 'center';
					this.data.datasets.forEach(function (dataset, i) {
						const meta = chartInstance.controller.getDatasetMeta(i);
						meta.data.forEach(function (bar, index) {
							const data = dataset.data[index] + '%';
							ctx.fillText(data, bar._model.x, bar._model.y - 7);
						});
					});
				}
			},
			legend: {
				display: false,
				labels: {
					fontSize: 14,
					fontColor: '#114591',
				}
			},
			borderWidth: 10,
			scales: {
				yAxes: [{
					showDatapoints: true,
					barPercentage: 0.4,
					gridLines: {
						drawOnChartArea: false,
					},
					scaleLabel: {
						display: true,
						labelString: 'Exposure',
						fontSize: 16,
						fontColor: this.themeObj['primaryColor'],
					},
					ticks: {
						fontSize: 14,
						beginAtZero: true,
						fontColor: '#0C3062',
						max: Math.round(maxVal),
						min: minVal,
					},
				}],
				xAxes: [{
					showDatapoints: true,
					barPercentage: 0.4,
					gridLines: {
						drawOnChartArea: false,
					},
					scaleLabel: {
						display: true,
						labelString: 'Days to Trade',
						fontSize: 16,
						fontColor: this.themeObj['primaryColor']
					},
					ticks: {
						fontSize: 14,
						beginAtZero: true,
						fontColor: '#0C3062',
					}
				}]
			},
			tooltips: {
				callbacks: {
					label: function (tooltipItem, chartData) {
						return chartData.labels[tooltipItem.index] + ': ' + chartData.datasets[0].data[tooltipItem.index] + '%';
					}
				}
			}
		};
	}
	getAsOfDate(selectedDate) {
		this.loading = true;
		localStorage.setItem('asOfDate', selectedDate);
		this.getLiquidityStockList();
	}

	setMaxDate() {
		this.maxDate = this.DashboardComponent.setMaxDate(this.currentDate, 2);
	}
}
