const SERIES_COLORS = [
    '#ffa782',
    '#acd679',
    '#21bea2',
    '#f6db6e',
    '#b7a8e9',
    '#0067bf',
    '#002f57'
];

const BAR_FIRST = '#4aa3e6';
const BAR_SECOND = '#366ccc';
const PRIMARY_COLOR = '#114591';
const SECONDARY_COLOR = '#366ccc';
const UNIQUE_PRIMARY = '#ff8c5d';
const UNIQUE_SECONDARY = '#eb5f24';

export {
    SERIES_COLORS,
    BAR_FIRST,
    BAR_SECOND,
    PRIMARY_COLOR,
    SECONDARY_COLOR,
    UNIQUE_PRIMARY,
    UNIQUE_SECONDARY
};
