import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-sign-off-popup',
	templateUrl: './sign-off-popup.component.html',
	styleUrls: ['./sign-off-popup.component.scss']
})
export class SignOffPopupComponent implements OnInit {
	@Input() header: any;
	@Input() message: any;
	@Input() isDisplay = false;
	@Output() isDisplayChange = new EventEmitter<boolean>();
	@Output() doSignOff = new EventEmitter();
	disableSignOff: boolean;
	@Input() reconType:any;
	comments:string;

	constructor() { }
	ngOnInit(): void {
	}

	clickYes() {
		this.disableSignOff = true;
		this.doSignOff.emit({comments:this.comments});
	}

	clickNo() {
		this.isDisplay = false;
		this.isDisplayChange.emit(this.isDisplay);
	}

}
