import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { DEFAULT_TICKER_OBJECT, SIDE_NAVE_TITLES, ERROR_MSG, DEFAULT_COMPARING_OBJECT, INIT_TICKER_OBJECT } from '../../utils/util-constants';
import { convertToSnakeCase, getApiFormattedDate, setLocalStorageData } from '../../utils/util-functions';
import { clientObject, teamObject, tickerObject, aumAnalysisTeamResponse, aumAnalysisTickerResponse, aumTickerObect, aumTeamObect, aumAnalysisClientResponse, aumAnalysisStorage, seriesChartType } from '../../utils/util-interfaces';
import { SERIES_COLORS } from '../../utils/util-colours';
import { CcassService } from '../../../ccass/ccass.service';

@Component({
  selector: 'app-aum-analysis',
  templateUrl: './aum-analysis.component.html',
  styleUrls: ['./aum-analysis.component.scss']
})
export class AumAnalysisComponent implements OnInit {

  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() listOfTickers: tickerObject[];
  @Input() filteredListOfTickers: tickerObject[]; // list of tickers without 'All'
  @Input() listOfTeams: teamObject[];
  @Input() listOfClient: clientObject[];

  @Output() setStartDate = new EventEmitter<Date>();
  @Output() setEndDate = new EventEmitter<Date>();
  @Output() titleHeading = new EventEmitter<string>();
  @Output() shouldDisplayTitle = new EventEmitter<boolean>();

  selectedTeamList: teamObject[];
  selectedTicker: tickerObject = DEFAULT_TICKER_OBJECT;
  selectedByClientTeamList: teamObject[];
  selectedBaseTicker: tickerObject = DEFAULT_TICKER_OBJECT;
  selectedComparingTickers: tickerObject[] = DEFAULT_COMPARING_OBJECT;
  selectedTickerList: tickerObject[] = [DEFAULT_TICKER_OBJECT];
  selectedClientList: clientObject[];

  teamChartData: aumTeamObect;
  tickerChartData: aumTickerObect;
  clientChartData: {x: string[], y: seriesChartType[]};

  teamsList: teamObject[];

  pageTitle: string = SIDE_NAVE_TITLES.aumAnalysis;
  loadingArray: boolean[] = [false, false, false];
  isOverlayVisible = false;
  showAlertPopup = false;
  message: string;

  constructor(private ccassService: CcassService) { }

  ngOnInit() {

    this.titleHeading.emit(this.pageTitle);
    this.shouldDisplayTitle.emit(true);

    this.setStartDate.emit(this.startDate);
    this.setEndDate.emit(this.endDate);

    const localData: aumAnalysisStorage = {
      selectedTeamList: this.selectedTeamList,
      selectedBaseTicker: this.selectedBaseTicker,
      selectedComparingTickers: this.selectedComparingTickers,
      selectedClientList: this.selectedClientList,
      selectedTickerList: this.selectedTickerList,
      selectedByClientTeamList: this.selectedByClientTeamList
    };
    localStorage.setItem(convertToSnakeCase(this.pageTitle), JSON.stringify(localData));

    this.getAllData();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      const change = changes[propName];
      const { currentValue, firstChange } = change;

      switch (propName) {
        case 'startDate':
          if (!firstChange) { this.getAllData(); }
          break;
        case 'endDate':
          if (!firstChange) { this.getAllData(); }
          break;
        case 'listOfTeams':
          this.teamsList = currentValue ? [...currentValue] : [];
          break;
        case 'listOfTickers':
          this.listOfTickers = currentValue ? [INIT_TICKER_OBJECT, ...currentValue] : [];
          break;
        case 'filteredListOfTickers':
          if (currentValue) {
            this.filteredListOfTickers = this.sortBasedOnSelected(currentValue, this.selectedTickerList, 'tickerName');
          }
        default:
          break;
      }
    }
  }

  getAllData() {
    this.getTeamChartData();
    this.getTickerChartData();
    this.getClientChartData();
  }

  getTeamChartData() {
    this.loadingArray[0] = true;

    const queryStartDate = getApiFormattedDate(this.startDate);
    const queryEndDate = getApiFormattedDate(this.endDate);

    const query = {};
    query['secondDate'] = getApiFormattedDate(this.endDate);
    query['firstDate'] = getApiFormattedDate(this.startDate);
    query['teamId'] = this.selectedTeamList && this.selectedTeamList.length != 0 ?
      this.selectedTeamList.map(value => value.id) : [0];
    query['fundIdForTeam'] = this.selectedTicker ? this.selectedTicker.fundId : [0];

    // API call
    this.ccassService.ccassPost(query, 'v1/aum-analysis-team/').then(response => {
      this.loadingArray[0] = false;
      this.isOverlayVisible = false;

      if (response['status'] && response['status'] === 'success') {
        const startDateObj: aumAnalysisTeamResponse = response['data'][queryStartDate.split('T')[0]];
        const endDateObj: aumAnalysisTeamResponse = response['data'][queryEndDate.split('T')[0]];
        this.teamChartData = { startDateObj, endDateObj };
      } else {
        this.showWarningPopup();
      }
    }).catch(err => {
      this.loadingArray[0] = false;
      this.teamChartData = null;
      this.showWarningPopup(ERROR_MSG.errorRecords);
    });
  }

  getTickerChartData() {
    this.loadingArray[1] = true;

    const queryStartDate = getApiFormattedDate(this.startDate);
    const queryEndDate = getApiFormattedDate(this.endDate);

    const query = {};
    query['secondDate'] = getApiFormattedDate(this.endDate);
    query['firstDate'] = getApiFormattedDate(this.startDate);
    query['baseFundId'] = this.selectedBaseTicker.fundId;
    query['compFundId'] = this.selectedComparingTickers && this.selectedComparingTickers.length != 0 ?
      this.selectedComparingTickers.map(value => value.fundId) : [];

    this.ccassService.ccassPost(query, 'v1/aum-analysis-ticker/').then(response => {
      this.loadingArray[1] = false;
      this.isOverlayVisible = false;

      if (response['status'] && response['status'] === 'success') {
        const startDateObj: aumAnalysisTickerResponse = response['data'][queryStartDate.split('T')[0]];
        const endDateObj: aumAnalysisTickerResponse = response['data'][queryEndDate.split('T')[0]];
        this.tickerChartData = { startDateObj, endDateObj };
      } else {
        this.showWarningPopup();
      }
    }).catch(err => {
      this.loadingArray[1] = false;
      this.isOverlayVisible = false;

      this.tickerChartData = null;
      this.showWarningPopup(ERROR_MSG.errorRecords);
    });
  }

  getClientChartData() {
    this.loadingArray[2] = true;
    const queryStartDate = getApiFormattedDate(this.startDate);
    const queryEndDate = getApiFormattedDate(this.endDate);

    const query = {};
    query['secondDate'] = getApiFormattedDate(this.endDate);
    query['firstDate'] = getApiFormattedDate(this.startDate);
    query['fundId'] = this.selectedTickerList ? this.selectedTickerList.map(value => value.fundId) : [0];
    query['participantId'] = this.selectedClientList ? this.selectedClientList.map(value => value.id) : [0];
    query['teamId'] = this.selectedByClientTeamList && this.selectedByClientTeamList.length != 0 ?
    this.selectedByClientTeamList.map(value => value.id) : [0];
    this.ccassService.ccassPost(query, 'v1/aum-analysis-client/').then(response => {
      this.loadingArray[2] = false;
      this.isOverlayVisible = false;

      if (response['status'] && response['status'] === 'success') {
        const startDateObj: aumAnalysisClientResponse[] = response['data'][queryStartDate.split('T')[0]];
        const endDateObj: aumAnalysisClientResponse[] = response['data'][queryEndDate.split('T')[0]];

        this.clientChartData = this.generateClientChartData(startDateObj, endDateObj);

      } else {
        this.showWarningPopup();
      }
    }).catch((err: Error) => {
      this.loadingArray[2] = false;
      this.isOverlayVisible = false;
      this.clientChartData = null;
      this.showWarningPopup(ERROR_MSG.emptyRecords);
    });
  }

  generateClientChartData(startDateObj: aumAnalysisClientResponse[], endDateObj: aumAnalysisClientResponse[]) {

    const seriesData: seriesChartType[] = [];

    this.selectedTickerList.forEach((value, index) => {
      const startTicker: seriesChartType = {
        name: value.tickerName,
        data: [],
        stack: 'start',
        date: this.startDate,
        color: SERIES_COLORS[index],
        showInLegend: true
      };
      const endTicker: seriesChartType = {
        name: value.tickerName,
        data: [],
        stack: 'end',
        date: this.endDate,
        color: SERIES_COLORS[index],
        showInLegend: false
      };

      seriesData.push(startTicker);
      seriesData.push(endTicker);
    });

    const participantNames: string[] = [];

    // Loop through response data and map start with end
    startDateObj.forEach((startValue, index) => {
      const endValue = endDateObj[index];

      if (startValue.participantId == endValue.participantId) {
        // X-Axis label calculation
        participantNames.push(startValue.participantName || endValue.participantName);

        // Series Calculation
        this.selectedTickerList.map(tickerValue => {

          const sIndex = seriesData.findIndex(v => v.name == tickerValue.tickerName && v.stack == 'start');
          if (startValue.listOfTickers) {
            const startIndex = startValue.listOfTickers.findIndex(v => v.tickerName == tickerValue.tickerName);
            if (startIndex == -1) {
              seriesData[sIndex].data.push(0);
            } else {
              seriesData[sIndex].data.push(startValue.listOfTickers[startIndex].aum);
            }
          } else {
            seriesData[sIndex].data.push(0);
          }

          const eIndex = seriesData.findIndex(v => v.name == tickerValue.tickerName && v.stack == 'end');
          if (endValue.listOfTickers) {
            const endIndex = endValue.listOfTickers.findIndex(v => v.tickerName == tickerValue.tickerName);
            if (endIndex == -1) {
              seriesData[eIndex].data.push(0);
            } else {
              seriesData[eIndex].data.push(endValue.listOfTickers[endIndex].aum);
            }
          } else {
            seriesData[eIndex].data.push(0);
          }
        });
      }
    });

    return {x: participantNames, y: seriesData};
  }

  onMultiSelectClick() {
    this.isOverlayVisible = true;
  }

  // team multiselect dropdown
  getSelectedTeamList() {
    setLocalStorageData('selectedTeamList', this.selectedTeamList, this.pageTitle);
    if (this.selectedTeamList.length > 0) { this.getTeamChartData(); }
  }

  // team multiselect in BY CLIENT section
  getSelectedByClientTeamList() {
    setLocalStorageData('selectedByClientTeamList', this.selectedByClientTeamList, this.pageTitle);

    if (this.selectedByClientTeamList.length > 0) {
      this.getClientChartData();
    }
  }

  // base ticker dropdown
  getSelectedBaseTicker(key: tickerObject) {
    setLocalStorageData('selectedBaseTicker', this.selectedBaseTicker, this.pageTitle);
    this.getTickerChartData();
  }

  // ticker dropdown in BY TEAM section
  getSelectedByTeamTicker(key: tickerObject) {
    this.getTeamChartData();
  }

  // comparing ticker multiselect dropdown
  getSelectedComparingTickers() {
    setLocalStorageData('selectedComparingTickers', this.selectedComparingTickers, this.pageTitle);
    if (this.selectedComparingTickers.length > 0) { this.getTickerChartData(); }
  }

  // ticker multiselect dropdown from client chart
  getSelectedByClientTicker() {
    setLocalStorageData('selectedTickerList', this.selectedTickerList, this.pageTitle);
    if (this.selectedTickerList.length > 0) { this.getClientChartData(); }
  }

  // client multiselect dropdown
  getSelectedClient() {
    setLocalStorageData('selectedClientList', this.selectedClientList, this.pageTitle);
    if (this.selectedClientList.length > 0) { this.getClientChartData(); }
  }

  clearSelection(value: string) {
    switch (value) {
      case 'clients':
        this.selectedClientList = [];
        setLocalStorageData('selectedClientList', this.selectedClientList, this.pageTitle);
        break;
      case 'compareTickers':
        this.selectedComparingTickers = [];
        setLocalStorageData('selectedComparingTickers', this.selectedComparingTickers, this.pageTitle);
      case 'tickers':
        this.selectedTickerList = [];
        setLocalStorageData('selectedTickerList', this.selectedTickerList, this.pageTitle);
      case 'teams':
        this.selectedTeamList = [];
        setLocalStorageData('selectedTeamList', this.selectedTeamList, this.pageTitle);
    }
  }

  getLoadingStatus() {
    return !this.loadingArray.every(element => element == false);
  }

  showWarningPopup(msg: string = ERROR_MSG.emptyRecords) {
    this.showAlertPopup = true;
    this.message = msg;
  }

  // tooltip for Team multiselect in BY CLIENT section
  getTeamTooltip() {
    if (this.selectedByClientTeamList) {
      return ' ' + this.selectedByClientTeamList.map(team => team.teamName).join(', ') + ' ';
    }
  }

  modalChange(data: tickerObject[]) {
    this.filteredListOfTickers = this.sortBasedOnSelected(this.filteredListOfTickers , data, 'tickerName');
  }

  sortBasedOnSelected(list: any[], selected: any, key: string) {

    list.sort(function (a, b) {
      if (selected.includes(a)) { return -1; }
      if (selected.includes(b)) { return 1; }

      if (a[key] < b[key]) { return -1; }
      if (a[key] > b[key]) { return 1; }
      return 0;
    });

    return list;
  }

}
