import { Component, OnInit, Input, Injector, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
	selector: 'app-profile-bar',
	templateUrl: './profile-bar.component.html',
	styleUrls: ['./profile-bar.component.scss']
})
export class ProfileBarComponent implements OnInit {
	title: string;
	subTitle: string;
	@Input() routeParam: string;
	@Input() showAETitle: string
	@Input() hideSubTable: string
	currDate: Date;
	@Output() dateSelection = new EventEmitter();
	@Output() setBackTable = new EventEmitter();
	@Input() showETF: any = false;
	@Input() selectedRows: any;
	selectedFundIds: any = [];
	exportSelectedFundId: any;
	selectedFundCodes: any = [];
	@Input() showFundTitle: any;
	fundId: any;
	@Input() bbg: any;
	@Input() opsFundType: any;
	currentDate: Date;

	constructor(
		private router: Router,
		public injector: Injector,
		private location: Location,
		private route: ActivatedRoute,
	) { }

	ngOnInit(): void {
		this.currentDate = new Date();
		this.route.params.subscribe(params => {
			this.fundId = params['id'];
		});
		this.title = this.routeParam.includes('nav1') ? 'NAV1 Reconciliation' : this.routeParam.includes('nav2') ? 'NAV2 Reconciliation' : this.routeParam.includes('D & C') ? 'D & C CONFIG' : this.routeParam.includes('holidayCalendarMaster') ? 'HOLIDAY CALENDAR' : this.routeParam.includes('datamarketplace') ? 'Data MarketPlace' : this.routeParam.includes('d&c') ? 'ORDER SUMMARY' : this.routeParam.includes('accountConfig') ? 'ACCOUNT POSTING' : this.routeParam.includes('accountingEntries') ? 'ACCOUNTING ENTRIES' : this.routeParam.includes('caConfig') ? 'CA CONFIG' : 'Premium Discount & Cash Component';
		if (this.title == "D & C CONFIG" || this.title == "HOLIDAY CALENDAR" || this.title == "Data MarketPlace" || this.title == "ORDER SUMMARY" || this.title == 'ACCOUNT POSTING' || this.title == 'ACCOUNTING ENTRIES' || this.title == 'CA CONFIG')
			this.subTitle = null
		else
			this.subTitle =
				this.routeParam.includes('signedoff') && this.routeParam.includes('t0') ? 'Signed Off vs Not Signed Off Funds : T0' :
					this.routeParam.includes('signedoff') && this.routeParam.includes('t1') ? 'Signed Off vs Not Signed Off Funds : T1' :
						this.routeParam.includes('reconcile') && this.routeParam.includes('t1') ? 'Reconciled vs Not Reconciled : T1' :
							this.routeParam.includes('reconcile') && this.routeParam.includes('t0') ? 'Reconciled vs Not Reconciled : T0' :
								this.routeParam.includes('exception') && this.routeParam.includes('t0') ? 'Exceptions vs No Exceptions : T0' :
									this.routeParam.includes('exception') && this.routeParam.includes('t1') ? 'Exceptions vs No Exceptions : T1' : 'Met SLA vs Unmet SLA';
		this.currDate = new Date(localStorage.getItem('opsAsOfDate'));
		if (this.fundId) {
			this.selectedFundIds.push(this.fundId);
		} else {
			this.selectedRows = JSON.parse(localStorage.getItem('selectedRows'));
		}

		if (this.selectedRows?.length > 0) {
			this.fundCodesToShow();
		}
	}

	fundCodesToShow() {
		this.selectedRows?.forEach((d: any) => {
			this.selectedFundIds?.push(d.fund_id);
			this.exportSelectedFundId?.push(d.fund_id.toString());
			this.selectedFundCodes?.push(d.tradar_code);
			this.selectedFundCodes = this.selectedFundCodes.filter((item, i, ar) => ar.indexOf(item) === i);
			this.selectedFundIds = [...this.selectedFundIds.filter((item, i, ar) => ar.indexOf(item) === i)];
		});
	}

	onClickBack(param: any) {
		if (this.hideSubTable) {
			this.setBackTable.emit(false)
		} else if (param === 'pdcc' || this.showFundTitle) {
			this.location.back();
		} else if (this.showAETitle) {
			localStorage.removeItem("selectedRows")
			this.location.back();
		} else {
			localStorage.removeItem('filtersObj');
			this.router.navigate(['dashboard']);
		}
	}

	onChangeDate(date: any) {
		localStorage.setItem('opsAsOfDate', date);
		localStorage.setItem('asOfDate', date);
		this.dateSelection.emit(date);
	}

}
