import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';
import { NotificationComponent } from './notifications/notification.component';
import { NotificationService } from './notifications/notification.service';
import { ExportedFilesLogComponent } from './exported-files-log/exported-files-log.component';
import { SharedServiceModule } from '../shared/sharedservice.module';
import { TradeDatesComponent } from './trade-dates/trade-dates.component';
import { RatesComponent } from './rates/rates.component';
import { FairvalueadjustmentComponent } from './fairvalueadjustment/fairvalueadjustment.component';
import { AccountMaintainanceComponent } from './account-maintainance/account-maintainance.component';
import { ApplicationFeeMaintenanceComponent } from './application-fee-maintenance/application-fee-maintenance.component';
import { AddFeeComponent } from './account-maintainance/add-fee.component';
import { FeeTypeToAccountMappingComponent } from './fee-type-to-account-mapping/fee-type-to-account-mapping.component';
import { AddFeeTypeComponent } from './fee-type-to-account-mapping/add-fee-type.component';
import { DatePopupComponent } from '../newsletters/date-popup/date-popup.component';

@NgModule({
  declarations: [
    NotificationComponent,
    ExportedFilesLogComponent,
    TradeDatesComponent,
    RatesComponent,
    FairvalueadjustmentComponent,
    AccountMaintainanceComponent,
    ApplicationFeeMaintenanceComponent,
    AddFeeComponent,
    FeeTypeToAccountMappingComponent,
    AddFeeTypeComponent,
    DatePopupComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedServiceModule
  ],
  exports: [
    NotificationComponent,
    ExportedFilesLogComponent,
    TradeDatesComponent,
    RatesComponent,
    FairvalueadjustmentComponent,
    AccountMaintainanceComponent,
    ApplicationFeeMaintenanceComponent,
    AddFeeComponent,
    FeeTypeToAccountMappingComponent,
    AddFeeTypeComponent,
    DatePopupComponent
  ],
  providers : [
    NotificationService
  ]
})
export class MenuPopupsModule { }
