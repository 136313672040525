export const themeschema = {
  CSOP: {
    THEME: {
      primaryColor: '#114591',
      secondaryColor: '#366ccc',
      primaryTextColor: '#ffffff',
      secondaryTextColor: '#ffffff9c',
      primaryDarkenColor: '#104189',
      primaryLightenColor: '#bbcded',
      loginBg: 'url(/assets/images/csop_bg.jpg)',
      leftMenu: '#0b306385',
      barOne: '#356bcc12',
      barTwo: '#356bcc33',
      barThree: '#27509945',
      barFour: '#305fb366',
      barFive: '#356bcca8',
      header: '#366ccc',
      dashboardCardColor: '#366ccc',
      dCardValColor: '#114591',
      dCardHeader: '#366ccc',
      menuScroll: '#c2c2c2'
    },
    URL: {
      logoURL: 'assets/icons/csop_logo.svg',
      newsletter_thumbnail: 'assets/csop/Thumbnail.png',
      add_icon: 'assets/csop/add.svg',
      overview_icon: 'assets/csop/overview-blue.png',
      fund_info_icon: 'assets/csop/piggy-bank-blue.png',
      performance_icon: 'assets/csop/perf-blue.png',
      holdings_icon: 'assets/csop/holding-blue.png',
      tdte_icon: 'assets/csop/tdte-blue.png',
      historical_reports_icon: 'assets/csop/perf-blue.png',
      liquidity_icon: 'assets/csop/liquidity-blue.png',
      fair_value_icon: 'assets/csop/tdte-blue.png',
      retreat_from_peak_icon: 'assets/csop/tdte-blue.png',
      stop_loss_icon: 'assets/csop/tdte-blue.png',
      stress_testing_icon: 'assets/csop/tdte-blue.png',
      analytics_icon: 'assets/csop/tdte-blue.png',
      finance_icon: 'assets/csop/finance.svg',
      audit_icon: 'assets/csop/Audit.png',
      peer_fund: 'assets/csop/peer-fund.svg',
      barchart: 'assets/csop/barchart-blue.png',
      piechart: 'assets/csop/piechart-blue.png',
      header_logo_EN: 'assets/csop/csop_logo_EN.png',
      header_logo_TCH: 'assets/csop/csop_logo_CHI.png',
      bottom_logo: 'assets/csop/bottom_logo.png',
      asset_class_ENG: 'assets/csop/asset_class_ENG.png',
      asset_class_CHI: 'assets/csop/asset_class_CHI.png'
    }
  },

  TECTORO: {
    THEME: {
      primaryColor: '#1496A7',
      secondaryColor: '#0dc0c0',
      primaryTextColor: '#ffffff',
      secondaryTextColor: '#ffffff9c',
      primaryDarkenColor: '#128e9e',
      primaryLightenColor: '#b5edf5',
      loginBg: 'url(/assets/images/tectoro_bg.jpg)',
      leftMenu: '#90b8bd',
      header: '#0dc0c0',
      barOne: '#d6faff',
      barTwo: '#b5edf5',
      barThree: '#72e7f7',
      barFour: '#24b6c9',
      barFive: '#1496A7',
      dashboardCardColor: '#5e6772',
      dCardValColor:  '#5e6772',
      dCardHeader: '#5e6772',
      menuScroll: '#e3165b87'
    },
    URL: {
      logoURL: 'assets/icons/tectoro-logo.svg',
      newsletter_thumbnail: 'assets/tectoro/Thumbnail.png',
      add_icon: 'assets/tectoro/add-tec.svg',
      overview_icon: 'assets/tectoro/overview-blue-tec.png',
      fund_info_icon: 'assets/tectoro/piggy-bank-blue-tec.png',
      performance_icon: 'assets/tectoro/perf-blue-tec.png',
      holdings_icon: 'assets/tectoro/holding-blue-tec.png',
      tdte_icon: 'assets/tectoro/tdte-blue-tec.png',
      historical_reports_icon: 'assets/tectoro/perf-blue-tec.png',
      liquidity_icon: 'assets/tectoro/liquidity-blue-tec.png',
      fair_value_icon: 'assets/tectoro/tdte-blue-tec.png',
      retreat_from_peak_icon: 'assets/tectoro/tdte-blue-tec.png',
      stop_loss_icon: 'assets/tectoro/tdte-blue-tec.png',
      stress_testing_icon: 'assets/tectoro/tdte-blue-tec.png',
      analytics_icon: 'assets/tectoro/tdte-blue-tec.png',
      finance_icon: 'assets/tectoro/finance-tec.png',
      audit_icon: 'assets/tectoro/Audit-tec.png',
      peer_fund: 'assets/tectoro/peer-fund-tec.png',
      barchart: 'assets/tectoro/barchart-blue-tec.png',
      piechart: 'assets/tectoro/pie-chart-tec.png',
      header_logo_EN: 'assets/tectoro/AssertManagment.png',
      header_logo_TCH: 'assets/tectoro/AssertManagment.png',
      bottom_logo: 'assets/tectoro/bottom_logo.png',
      asset_class_ENG: 'assets/tectoro/english_etf.png',
      asset_class_CHI: 'assets/tectoro/china_etf.png'
    }
  }
};
