import { Injectable } from '@angular/core';
import { ApiService, PagedRecords } from '../../../shared/api.service';
import { Subject } from 'rxjs/Subject';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class WorkflowService {
	serviceName = 'wf-api-v2/workflow/';
	serviceNameAct = 'wf-api-v2/workflowact/';
	serviceActivity = 'wf-api-v2/activity/';
	findAllServiceName = 'cop/case';
	getWorkflowService = 'wf-api-v2/list/';
	public subject = new Subject<any>();
	constructor(private apiService: ApiService) {
	}

	find(filter: Object, page: number = 1, size: number = 100): Promise<PagedRecords> {
		let query = new HttpParams();
		query = query.set('page', page.toString());
		query = query.set('limit', size.toString());

		if (Object.keys(filter).length !== 0) {
			for (const key of Object.keys(filter)) {
				query = query.set(key, filter[key]);
			}
		}
		return this.apiService.get(this.serviceName, query)
			.then(response => response);
	}

	findActivity(filter: Object): Promise<PagedRecords> {
		let query = new HttpParams();

		if (Object.keys(filter).length !== 0) {
			for (const key of Object.keys(filter)) {
				query = query.set(key, filter[key]);
			}
		}
		return this.apiService.get(this.serviceActivity, query)
			.then(response => response);
	}

	findWorkflow(filter: Object, page: number = 1, size: number = 100): Promise<PagedRecords> {
		return this.apiService.getWithFilter(this.getWorkflowService, filter, page, size)
			.then(response => response);
	}

	findall(filter: Object, page: number = 1, size: number = 100): Promise<PagedRecords> {
		return this.apiService.getWithFilter(this.findAllServiceName, filter, page, size)
			.then(response => response);
	}
	update(id: string, data: Object): Promise<Object> {
		const url = `${this.serviceName}${id}`;
		return this.apiService.put(url, data);

	}

	createNew(data: Object): Promise<Object> {
		return this.apiService.post(this.findAllServiceName, data);
	}

	create(data: Object): Promise<Object> {
		return this.apiService.post(this.serviceNameAct, data);
	}

	delete(id): Promise<Object> {
		const url = `${this.serviceName}/${id}`;
		return this.apiService.delete(url);
	}

}

@Injectable()
export class ActivityService {
	serviceName = 'wf-api-v2/activity';
	summaryServieName = 'cop/activity';
	constructor(private apiService: ApiService) {
	}
	update(id: string, data: Object): Promise<Object> {
		const url = `${this.serviceName}/${id}`;
		const body = new HttpParams();
		body.set('body', JSON.stringify(data));
		return this.apiService.put(url, data);
	}

	summary(filter: Object, page: number = 1, size: number = 100): Promise<PagedRecords> {
		let query = new HttpParams();
		query = query.set('page', page.toString());
		query = query.set('limit', size.toString());

		if (Object.keys(filter).length !== 0) {
			for (const key of Object.keys(filter)) {
				query = query.set(key, filter[key]);
			}
		}
		return this.apiService.get(this.summaryServieName, query)
			.then(response => response);
	}


}


@Injectable()
export class EntityService {
	serviceName = 'cop/case';
	constructor(private apiService: ApiService) {
	}
	find(filter: Object, page: number = 1, size: number = 100): Promise<any> {
		return this.apiService.getWithFilter(this.serviceName, filter, page, size)
			.then(response => response);
	}

	create(data: Object): Promise<Object> {
		return this.apiService.post(this.serviceName, data);
	}

	delete(id): Promise<Object> {
		const url = `${this.serviceName}/${id}/delete`;
		return this.apiService.post(url);
	}

	findOne(id: string): Promise<Object> {
		const url = `${this.serviceName}/${id}`;
		return this.apiService.get(url);
	}

	update(id: string, data: Object): Promise<Object> {
		const url = `${this.serviceName}/${id}/update`;
		return this.apiService.post(url, data);
	}
}
