import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChildren, QueryList } from '@angular/core';
import { formatNumber } from '@angular/common';
import { DEFAULT_PARTICIPANT_OBJECT, DEFAULT_SALESPERSON_OBJECT, DEFAULT_TEAM_OBJECT } from '../../../../utils/util-constants';
import { clientObject, salesPersonObject, teamObject, teamShareholdingsResponse } from '../../../../utils/util-interfaces';

import { MultiSelect } from 'primeng/multiselect';

@Component({
  selector: 'app-shareholding-table',
  templateUrl: './shareholding-table.component.html',
  styleUrls: ['./shareholding-table.component.scss']
})
export class ShareholdingTableComponent {

  @Input() listOfTeams: teamObject[] = [];
  @Input() listOfClient: clientObject[];
  @Input() listOfSalesperson: salesPersonObject[] = [];
  @Input() teamAnalysisData: teamShareholdingsResponse;
  @Input() loading: boolean;

  @Output() setSelectedClient = new EventEmitter<clientObject[]>();
  @Output() setSelectedSalesperson = new EventEmitter<string>();
  @Output() setSelectedTeam = new EventEmitter<string>();

  selectedClientList: clientObject[] = [];
  teamData: teamShareholdingsResponse;

  teamList: teamObject[] = [];
  salespersonList: salesPersonObject[] = [];

  isClientPanelVisible = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      const change = changes[propName];
      const curVal = change.currentValue;

      switch (propName) {
        case 'listOfTeams':
          this.teamList = curVal ? [DEFAULT_TEAM_OBJECT, ...curVal] : [];
          break;
        case 'listOfSalesperson':
          this.salespersonList = curVal ? [DEFAULT_SALESPERSON_OBJECT, ...curVal] : [];
          break;
        case 'teamAnalysisData':
          this.teamAnalysisData = curVal;
          this.isClientPanelVisible = false;
          break;

        default:
          break;
      }
    }
  }

  getSelectedClient(key) {
    this.setSelectedClient.emit(key.value);
  }
  getSelectedTeam(key) {
    this.setSelectedTeam.emit(key.value);
  }
  getSelectedSalesPerson(key) {
    this.setSelectedSalesperson.emit(key.value);
  }

  // to get proper table data
  getTickerDiff(tickerDiff: number, dp: number = 2) {
    if (!isNaN(tickerDiff)) {
      return {value: formatNumber(tickerDiff, 'en-US', '1.0-' + dp.toString()), isNegative: tickerDiff < 0};
    } else {
      return {value: 'NA', isNegative: false};
    }
  }

  getTickerDiffPercent(tickerDiffPercent: number) {
    if (!isNaN(tickerDiffPercent)) {
      return {value: formatNumber(tickerDiffPercent, 'en-US', '1.3-3') + '%', isNegative: tickerDiffPercent < 0};
    } else {
      return {value: 'NA', isNegative: false};
    }
  }

  onClientClick() {
    this.isClientPanelVisible = true;
  }

  onClientSubmit() {
    this.isClientPanelVisible = false;
    this.setSelectedClient.emit(this.selectedClientList);
  }

  // when clear button is clicked in Client multiselect
  onClientClear() {
    this.selectedClientList = [];
  }

  getClientTooltip() {
    if (this.selectedClientList) {
      return this.selectedClientList.map(client => client.participantName).join(', ');
    }
  }
}
