import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(html) {
    if (html) {
      return this.sanitizer.bypassSecurityTrustHtml(html);
    }
    // switch (html) {
    //   case 'style':
    //     return this.sanitizer.bypassSecurityTrustStyle(html);
    //   case 'script':
    //     return this.sanitizer.bypassSecurityTrustScript(html);
    //   case 'url':
    //     return this.sanitizer.bypassSecurityTrustUrl(html);
    //   case 'resourceUrl':
    //     return this.sanitizer.bypassSecurityTrustResourceUrl(html);
    //   default: return this.sanitizer.bypassSecurityTrustHtml(html);
    // }
  }

}
