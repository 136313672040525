import {Component, OnInit} from '@angular/core';
import {AlertService} from './alert.service';
import {Message} from 'primeng//api';

// TODO: Support display of multiple alerts
@Component({
  selector: 'alert',
  template: `
    <p-messages [value]="msgs"></p-messages>
  `
})

export class Alert implements OnInit {
  msgs: Message[] = [];

  constructor(private alertService: AlertService) {
  }

  ngOnInit() {
    this.alertService.alert.subscribe((msg: Message) => {
      this.msgs.push(msg);

      if (msg.severity == 'success' || msg.severity == 'info') {
        setTimeout(() => this.msgs.pop(), 3000);
      }
    });
  }

  closeAlert() {
    this.msgs.pop();
  }
}
