import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppPrimengModule } from '../app.primeng.module';

import { Alert } from './alert';
import { AlertService } from './alert.service';
import { ApiService } from './api.service';
import { AuthGuard, AuthService } from './auth.service';
import { FormatSelectItemPipe } from './dropdown.service';
import { EventsService } from './events.service';
import { ItemService } from './item.service';
import { PageService } from './pages.service';
import { SortParamsService } from './sortParams.service';
import { PrivilegeService } from './privilege.service';
import { DynamicTableComponent } from '../dynamic-table/dynamic-table.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SafeHtmlPipe } from '../safe-html.pipe';
import { ExpandableTableComponent } from '../expandable-table/expandable-table.component';
import { HttpClientModule } from '@angular/common/http';
import { MergeTableComponent } from '../ops/merge-table/merge-table.component';
import { SignOffPopupComponent } from '../ops/reconcil-report/sign-off-popup/sign-off-popup.component';
import { ProfileBarComponent } from '../ops/reconcil-report/profile-bar/profile-bar.component';
import { RerunComponent } from '../ops/reconcil-report/rerun/rerun.component';

@NgModule({
	declarations: [
		Alert,
		FormatSelectItemPipe,
		SafeHtmlPipe,
		DynamicTableComponent,
		ExpandableTableComponent,
		MergeTableComponent,
		SignOffPopupComponent,
		ProfileBarComponent,
		RerunComponent
	],
	imports: [
		CommonModule,
		AppPrimengModule,
		AngularSvgIconModule,
		HttpClientModule
	],
	providers: [AlertService, ApiService, AuthGuard, AuthService, EventsService, ItemService, PageService, SortParamsService, FormatSelectItemPipe, PrivilegeService,
		SafeHtmlPipe
	],
	exports: [
		Alert,
		FormatSelectItemPipe,
		SafeHtmlPipe,
		DynamicTableComponent,
		ExpandableTableComponent,
		MergeTableComponent,
		SignOffPopupComponent,
		ProfileBarComponent,
		RerunComponent
	]
})
export class SharedServiceModule { }
