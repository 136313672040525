import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DefaultService } from '../../utils/default.service';
import { TableSchema } from '../../utils/table-schema';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-application-fee-maintenance',
  templateUrl: './application-fee-maintenance.component.html',
  styleUrls: ['./application-fee-maintenance.component.scss']
})
export class ApplicationFeeMaintenanceComponent implements OnInit {
  loading = false;
  feeMaintenanceData: any = [];
  canEdit = false;
  query: any = {};

  tableSchema = TableSchema['appFeeMaintenance'];
  @Input() isApplicationFee;
  @Input() isDisplay = false;
  @Output() isDisplayChange = new EventEmitter<boolean>();
  selectedIndex: any;
  selectedRow: any;

  constructor(private defaultService: DefaultService, private messageService: MessageService) { }

  ngOnInit() {
    this.loading = true;
    this.getFeeMaintenance();
  }

  getFeeMaintenance() {
    this.defaultService.find({}, 1, 1000, 'csoprisk/fund').then(data => {
      if (data['status'] === 'success') {
        if (data['status'] === 'success') {
          this.feeMaintenanceData = data.rows;
          this.feeMaintenanceData.forEach(fundElement => {
            fundElement['references'].forEach(refElement => {
              if (refElement['type'] === 'TRADAR_CODE') {
                fundElement['tradarCode'] = refElement['value'];
              }
            });
            if (fundElement['applicationFee']) {
              fundElement['creationFee'] = fundElement['applicationFee']['creationFee'];
              fundElement['redemptionFee'] = fundElement['applicationFee']['redemptionFee'];
              fundElement['dcBuffer'] = fundElement['applicationFee']['dcBuffer'];
            }
          });
          this.loading = false;
          return;
        }
      }
      this.messageService.add({ severity: 'error', detail: data['status'] });
    }).catch(err => {
      this.loading = false;
this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
    });
  }

  onClickUpdate(row) {
    const applicationFee = {};
    if (row['creationFee']) { applicationFee['creationFee'] = +row['creationFee']; }
    if (row['redemptionFee']) { applicationFee['redemptionFee'] = +row['redemptionFee']; }
    if (row['dcBuffer']) { applicationFee['dcBuffer'] = +row['dcBuffer']; }
    row['applicationFee'] = applicationFee;
    if (row.canEdit) {
      delete row.canEdit;
      delete row.tradarCode;
    }
    this.defaultService.update_(row._id, row, 'csoprisk/fund').then(data => {
      this.loading = true;
      if (data['status'] === 'success') {
        this.getFeeMaintenance();
        // const temp = this.feeMaintenanceData;
        // const index = this.feeMaintenanceData.indexOf(row);
        // temp[index] = temp[0];
        // temp[0] = row;
        // this.feeMaintenanceData = temp;
        this.messageService.add({ severity: 'success', detail: 'Fee updated successfully' });
        return;
      }
      this.messageService.add({ severity: 'error', detail: data['status'] });
    }).catch(err => {
      this.loading = false;
this.messageService.add({ severity: 'error', detail: typeof err === 'object' ? err['statusText'] : err });
    });
  }

  close() {
    this.isDisplay = false;
    this.isDisplayChange.emit(this.isDisplay);
  }

  cancelEdit() {
    delete this.selectedRow['canEdit'];
    const obj = [...this.feeMaintenanceData];
    obj[this.selectedIndex] = this.selectedRow;
    this.feeMaintenanceData = obj;
  }

  onClickEdit(event) {
    const row = event['row'];
    this.selectedIndex = event['index'];
    for (const u of this.feeMaintenanceData) {
      u.canEdit = false;
      if (row._id === u._id) {
        row.canEdit = true;
        this.selectedRow = row;
      }
    }
  }
}
