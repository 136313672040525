import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationPopupComponent } from './confirmation-popup.component';
import { SharedModule } from '../../shared.module';



@NgModule({
  declarations: [
    ConfirmationPopupComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports : [
    ConfirmationPopupComponent
  ]
})
export class ConfirmationPopupModule { }
