import { formatDate, formatNumber } from '@angular/common';
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { aumTickerObect, tickerObject } from '../../../../utils/util-interfaces';
import { BAR_FIRST, BAR_SECOND, UNIQUE_PRIMARY, UNIQUE_SECONDARY } from '../../../../utils/util-colours';

@Component({
  selector: 'app-ticker-chart',
  templateUrl: './ticker-chart.component.html',
  styleUrls: ['./ticker-chart.component.scss']
})
export class TickerChartComponent implements OnInit {

  @Input() tickerChartData: aumTickerObect;
  @Input() startDate: Date;
  @Input() endDate: Date;

  @Input() selectedBaseTicker: tickerObject;

  chartData: any;
  chartOptions: any;
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      switch (propName) {
        case 'tickerChartData':
          const data = this.getFormattedData(this.tickerChartData);
          this.plotGraph(data);
          break;
        default:
          break;
      }
    }
  }

  getFormattedData(data) {
    const startAumArray: number[] = [];
    const endAumArray: number[] = [];
    const participantArray: string[] = [];

    if (data) {
      if (data['startDateObj']) {
        data['startDateObj'].forEach(element => {
          if (element.tickerName == this.selectedBaseTicker.tickerName) {
            startAumArray.splice(0, 0, element.aum);
          } else {
            startAumArray.push(Math.round(element.aum));
          }
        });
      }

      if (data['endDateObj']) {
        data['endDateObj'].forEach(element => {
          if (element.tickerName == this.selectedBaseTicker.tickerName) {
            endAumArray.splice(0, 0, element.aum);
            participantArray.splice(0, 0, element.tickerName);
          } else {
            endAumArray.push(Math.round(element.aum));
            participantArray.push(element.tickerName);
          }
        });
      }
    }

    return { startAumArray, endAumArray, participantArray };
  }

  plotGraph(data) {
    const { startAumArray, endAumArray, participantArray } = data;

    // primeng code
    this.chartData = {
      labels: participantArray,
      datasets: [
        {
          label: '',
          backgroundColor: [
            UNIQUE_PRIMARY,
            BAR_FIRST, BAR_FIRST, BAR_FIRST, BAR_FIRST, BAR_FIRST,
          ],
          data: startAumArray,
          pointStyle: 'circle',
        },
        {
          label: formatDate(this.startDate, 'dd LLL yyyy', 'en-US') + '    ',
          backgroundColor: [
             BAR_FIRST,
          ],
          // data: startAumArray,
          pointStyle: 'circle',
        },
        {
          label: '',
          backgroundColor: [
            UNIQUE_SECONDARY,
            BAR_SECOND, BAR_SECOND, BAR_SECOND, BAR_SECOND, BAR_SECOND
          ],
          data: endAumArray,
          pointStyle: 'circle'
        },
        {
          label: formatDate(this.endDate, 'dd LLL yyyy', 'en-US'),
          backgroundColor: [
            BAR_SECOND,
          ],
          // data: endAumArray,
          pointStyle: 'circle',
        }
      ]
    };

    // highcharts code
    this.chartOptions = {

      responsive: false,
      maintainAspectRatio: false,
      legend: {
        display: true,
        labels: {
          fontColor: '#040D1C',
          usePointStyle: true
        }
      },
      borderWidth: 10,
      scales: {
        padding: 1000,
        xAxes: [{
          barPercentage: 1.8,
          gridLines: {
            drawBorder: false,
            display: false
          },
          // to change title label of X axis
          scaleLabel: {
            display: true,
            labelString: 'TICKER',
            fontColor: '#7d7d7d',
            font: { size: 25 }
          },
          ticks: {
            display: true,
            fontColor: '#4C5862',
            callback: (label) => (/\s/.test(label)) ? label.split(' ') : label,
          },
        }],
        yAxes: [{
          display: true,
          gridLines: {
            drawBorder: false,
            borderDash: [10, 10],
          },
          // to change title label of Y axis
          scaleLabel: {
            display: true,
            labelString: 'AUM ( In HKD )',
            fontColor: '#7d7d7d',
            font: { size: 20 }
          },
          label: function (tooltipItem, chartData) {
            const aum = formatNumber(tooltipItem.value, 'en-US', '1.0-0');
            return tooltipItem.label + ' : ' + aum;
          },
          ticks: {
            showDatapoints: true,
            barPercentage: 0.6,
            beginAtZero: true,
            fontColor: '#4C5862',
            callback: function (tick) {
              // if value greater than or equal to 1 trillion
            if (tick >= Math.pow(10, 12)) {
              return formatNumber(tick / Math.pow(10, 12), 'en-US', '1.0-2') + ' T';
            } else if (tick >= Math.pow(10, 9)) {
              return formatNumber(tick / Math.pow(10, 9), 'en-US', '1.0-2') + ' B';
            } else if (tick >= Math.pow(10, 6)) {
              return formatNumber(tick / Math.pow(10, 6), 'en-US', '1.0-2') + ' M';
            } else {
              return formatNumber(tick, 'en-US', '1.0-2');
            }
            }
          },
        }]
      },
      tooltips: {
        backgroundColor: 'white',
        titleFontColor: 'black',
        bodyFontColor: 'black',
        footerFontColor: 'black',
        borderColor: 'black',
        borderWidth: 2,
        custom: function(tooltipModel) {
          // if the user hovers on bars
          if (tooltipModel.dataPoints) {
            tooltipModel.borderColor = tooltipModel.labelColors[0]?.backgroundColor;
          }
        },
        callbacks: {
          beforeTitle: function(tooltipItem, chartData) {
            // when user hovers over first bar for a specific ticker
            if (tooltipItem[0]?.datasetIndex === 0) {
              return '\n\t' + chartData.datasets[1].label.trim() + '\n';
            } else {
              return '\n\t' + chartData.datasets[3].label.trim() + '\n';
            }
          },
          // Do not remove - causes unwanted behavior
          title: function(tooltipItem, chartData) {},
          afterTitle: function(tooltipItem, chartData) {
            return '\t' + chartData.labels[tooltipItem[0]?.index] + '\n';
          },
          beforeBody: function(tooltipItem, chartData) {
            return '\t' + formatNumber(tooltipItem[0]?.value, 'en-US', '1.0-0') + '\n';
          },
          // Do not remove - causes unwanted behavior
          label: function() {},
        }
      }
    };
  }
}
