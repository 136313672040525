import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthService } from '../shared/auth.service';
import { ApiService } from '../shared/api.service';

const hostName = window.location.hostname;
const MINUTES_UNITL_AUTO_LOGOUT =  environment[hostName].autoLogoutInMinutes;
const CHECK_INTERVAL = environment[hostName].checkIntervalInMilliSec;
const SHOW_MODAL = environment[hostName].ssnExpryCnfrmMdlTimeInMilliSec;
const STORE_KEY =  'lastAction';

@Injectable()
export class AutoLogoutService {
 isSessionTimeOut: boolean;
 showTimeoutMessage  = false;
 public getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY), 10);
  }
 public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  constructor(private router: Router, private authService: AuthService, private apiService: ApiService) {
    this.check();
    this.initListener();
    this.initInterval();
    localStorage.setItem(STORE_KEY, Date.now().toString());
    this.isSessionTimeOut = false;
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover', () => this.reset());
    document.body.addEventListener('mouseout', () => this.reset());
    document.body.addEventListener('keydown', () => this.reset());
    document.body.addEventListener('keyup', () => this.reset());
    document.body.addEventListener('keypress', () => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    if (this.authService.isLoggedIn()) {
      if (diff <= SHOW_MODAL && diff >= 0) {
        this.isSessionTimeOut = true;
        this.showTimeoutMessage = false;
      }
      if (diff < 0)   {
       this.apiService.logout();
       this.showTimeoutMessage = true;
       this.isSessionTimeOut = false;
      }
    } else {
      this.showTimeoutMessage = false;
      this.isSessionTimeOut = false;
    }
  }
}
