import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { ThemeingService } from './utils/themeing.service';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html'
})
export class AppFooterComponent {
  user: any;
  routePath: any;
  title: String;
  client: String;
  constructor(private themeingService: ThemeingService) {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.routePath = localStorage.getItem('routePath');
    this.client = this.themeingService.getClientName();
    this.title = this.client + environment[window.location.hostname].title;
  }
  getTitle() {
    // if (this.user['roleCodes'][0] === 'RA' || this.user['roleCodes'][0] === 'PM') {
    //   return environment[window.location.hostname].title + ' ' + environment[window.location.hostname].envType;
    // }
    return environment[window.location.hostname].defaultTitle + ' ' + environment[window.location.hostname].envType;
  }

}
