import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
declare var $: any;
import * as FileSaver from 'file-saver';

@Injectable()
export class UtilitiesFuncion {
	static defaultColors = [
		'#4472c4',
		'#ed7c31',
		'#a5a5a5',
		'#fdc101',
		'#5a9bd5',
		'#70ad46',
		'#294476',
		'#8e4b1d',
		'#987301',
		'#607d8b',
		'#1cb8cc',
		'#D2C1AD',
		'#547762',
		'#496c6a',
		'#8b5632'
	];

	constructor() { }
	// pie chart options
	static getPieChartData(labels, data, chartLabel, fundType, legendPos) {
		const _this = this;
		let pieChartOptions = {};
		const pieChartData = {
			labels: labels,
			datasets: [
				{
					data: data,
					backgroundColor: this.defaultColors,
					hoverBackgroundColor: this.defaultColors,
				}],
		};
		pieChartOptions = {
			legend: {
				display: false
			},
			tooltips: {
				callbacks: {
					label: function (tooltipItem, chartData) {
						return chartData.labels[tooltipItem.index] + ': ' + chartData.datasets[0].data[tooltipItem.index] + '%';
					}
				}
			}
		};
		let tempVar = 0;
		const legends = [];
		labels.forEach(element => {
			const obj = {};
			if (chartLabel === 'Country-wise Exposure' && element !== 'Cash and Others') {
				obj['label'] = (fundType === 'BE' || fundType === 'BM') ?
					element + ' ' + ':' + ' ' + data[tempVar] + ' ' + '%' :
					element + ' ' + ' ' + ':' + ' ' + data[tempVar] + ' ' + '%';
			} else {
				obj['label'] = element + ' ' + ':' + ' ' + data[tempVar] + ' ' + '%';
			}
			obj['color'] = this.defaultColors[tempVar];
			tempVar++;
			legends.push(obj);
		});
		return [pieChartOptions, pieChartData, legends];
	}

	// bar chart options
	static getBarChartData(chartLabels, data, chartLabel, fundType, fontSize) {
		// Calculate Max & min Values because of overlapping
		let minVal = 0;
		let maxVal = Math.max.apply(Math, data.map(function (count) { return count; }));
		maxVal = parseInt(maxVal);
		const lastIndex = 10 - (maxVal.toString().split('').pop());
		maxVal = (maxVal + lastIndex) + 30;
		let minValue = Math.min.apply(Math, data.map(function (count) { return count; }));
		if (minValue < 0) {
			minValue = parseInt(minValue);
			const lastVal = 10 - (minValue.toString().split('').pop());
			minVal = (minValue - lastVal) - 30;
		}
		if (chartLabel === 'Country-wise Exposure') {
			const labels = [];
			chartLabels.forEach(element => {
				if (element !== 'Cash and Others') {
					element = (fundType === 'BE' || fundType === 'BM') ? element :
						element;
				}
				labels.push(element);
			});
			chartLabels = labels;
		}
		let barChartOptions = {};
		const barChartData = {
			labels: chartLabels,
			datasets: [
				{
					label: chartLabel,
					backgroundColor: '#4472c4',
					borderColor: '#4472c4',
					data: data
				}
			]
		};
		barChartOptions = {
			events: false,
			hover: {
				animationDuration: 0
			},
			animation: {
				duration: 1,
				onComplete: function () {
					const chartInstance = this.chart;
					const ctx = chartInstance.ctx;
					ctx.textBaseline = 'middle';
					this.data.datasets.forEach(function (dataset, i) {
						const meta = chartInstance.controller.getDatasetMeta(i);
						meta.data.forEach(function (bar, index) {
							const obj = dataset.data[index] + '%';
							if (dataset.data[index] >= 0) {
								ctx.fillText(obj, bar._model.x + 10, bar._model.y);
							} else {
								ctx.fillText(obj, bar._model.x - 45, bar._model.y);
							}
						});
					});
				}
			},
			legend: {
				display: false,
				position: 'bottom',
				labels: {
					usePointStyle: true,
					fontSize: fontSize,
					padding: 14,
					fontColor: '#5F6772',
				}
			},
			borderWidth: 10,
			scales: {
				yAxes: [{
					showDatapoints: true,
					barPercentage: 0.6,
					ticks: {
						fontSize: fontSize,
					}
				}],
				xAxes: [{
					ticks: {
						beginAtZero: true,
						fontSize: fontSize,
						max: Math.round(maxVal),
						min: minVal,
						callback: function (value) {
							return value + '%';
						},
					}
				}]
			},
			tooltips: {
				callbacks: {
					label: function (tooltipItem, chartData) {
						return chartData.labels[tooltipItem.index] + ': ' + chartData.datasets[0].data[tooltipItem.index] + '%';
					}
				}
			}
		};
		return [barChartOptions, barChartData];
	}
	// Risk Chart Utility Services


	static chartOptions() {
		const options = {};
		const time = {};
		time['time'] = false;
		options['colors'] = [
			'#9aa3ed',
			'#ef2b34',
			'#4bef2a',
			'#9d67db',
			'#67dbd7',
			'#d88338',
			'#a7ed61',
			'#966363',
			'#99ccaa',
			'#dfed49',
			'#f9b8f7',
			'#560d3d',
			'#564c0d'
		];
		options['time'] = time;
		return options;
	}
	static getPeridAndRange(period, range) {
		switch (range) {
			case '1m':
				range = 1;
				break;
			case '3m':
				range = 3;
				break;
			case '6m':
				range = 6;
				break;
			case '1y':
				range = 1;
				period = 'year';
				break;
			case 'YTD':
				range = '';
				period = 'ytd';
				break;
			case 'All':
				range = '';
				period = 'All';
				break;
		}
		const rangeAndPeriod = {};
		rangeAndPeriod['range'] = range;
		rangeAndPeriod['period'] = period;
		return rangeAndPeriod;
	}
	static setStockSeriesData(data, period, range, fund) {
		const self = this;
		const stockData = {
			chart: {
				height: 200
			},
			tooltip: {
				formatter: function () {
					let s = '<b>' + 'Date' + ':' + new Date(this.x).toLocaleDateString() + '</b>';
					$.each(this.points, function (i, p) {
						s += '<br/>' + '<b style="color: {p.color}">' + this.series.name + '</b>' + ': '
							+ '<b>' + this.y + '</b>';
					});
					return s;
				},
				shared: true
			},
			rangeSelector: {
				selected: range,
				inputDateFormat: '%d-%m-%Y'
			},
			navigator: {
				enabled: false
			},
			scrollbar: {
				enabled: false
			},
			title: {
				style: {
					fontWeight: 'bold',
					opacity: '0.6'
				}
			},
			legend: {
				enabled: false
			},
			exporting: {
				buttons: {
					contextButton: {
						menuItems: [
							'downloadPDF',
							'separator',
							'downloadXLS',
							'downloadCSV']
					}
				},
				filename: fund.tradarCode + '_Fund-Performance_Chart',
			},
			xAxis: {
				type: 'datetime',
				title: {
					text: 'Date'
				},
				// events: {
				//   setExtremes: function (e) {
				//     if (e && e.rangeSelectorButton) {
				//       switch (e.rangeSelectorButton.text) {
				//         case '1m':
				//           self.getLineChartData('month', e.rangeSelectorButton.text);
				//           // self.rangeSelecter = 0;
				//           break;
				//         case '3m':
				//           self.getLineChartData('month', e.rangeSelectorButton.text);
				//           // self.rangeSelecter = 1;
				//           break;
				//         case '6m':
				//           self.getLineChartData('month', e.rangeSelectorButton.text);
				//           // self.rangeSelecter = 2;
				//           break;
				//         case 'YTD':
				//           self.getLineChartData('YTD', e.rangeSelectorButton.text);
				//           // self.rangeSelecter = 3;
				//           break;
				//         case '1y':
				//           self.getLineChartData('year', e.rangeSelectorButton.text);
				//           // self.rangeSelecter = 4;
				//           break;
				//         case 'All':
				//           self.getLineChartData('All', e.rangeSelectorButton.text);
				//           // self.rangeSelecter = 5;
				//           break;
				//       }
				//     }
				//   }
				// }
			},
			series: this.getChartData(data)
		};
		return stockData;
	}
	static getChartData(data) {
		const series = [];
		Object.keys(data).forEach(function (key) {
			series.push({ name: key, data: data[key] });
		});
		return series;
	}
	// XLSX Download
	static exportXlsx(selectedFund, apiService, tab) {
		const selectedTab = tab;
		const fileName = selectedFund.tradarCode;
		const query = 'asOfDate=' + selectedFund['asOfDate'] + '&holdingDate=' + selectedFund.inceptionDate + '&tabs='
			+ selectedTab.toString();

		const url = 'cop/csoprisk/fund/' + selectedFund['fundID'] + '/report';
		apiService.getFileDownload(url, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', query, fileName);
		const growlMessage = [
			{ severity: 'success', detail: 'XLSX downloaded successfully' }
		];
		return growlMessage;
	}
}

export function noSpace(event: any) {
	if (event.target.selectionStart === 0 && event.code === 'Space') {
		event.preventDefault();
	}
}

export function noWhiteSpace(control: AbstractControl): { [key: string]: any } | null {
	const isEmpty = control.value && control.value.length > 0 && control.value?.trim().length == 0 ? true : false;
	return isEmpty ? { 'noWhiteSpace': { value: control.value } } : null;
}

export function tolPercentage(control: AbstractControl): { [key: string]: any } | null {
	const isNotValid = control.value > 100.00 ? true : false;
	return isNotValid ? { 'tolPrec': { value: control.value } } : null;
}
export function tolValue(control: AbstractControl): { [key: string]: any } | null {
	const isNotValid = control.value > 100.00 || control.value < -100.00 ? true : false;
	return isNotValid ? { 'tolValue': { value: control.value } } : null;
}

export function thousands_separators(num) {
	const num_parts = num && typeof num === 'string' ? num.split('.') : num && typeof num === 'number' ? num.toString().split('.') : [];
	if (num_parts.length > 0) { num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); }
	return num_parts.length > 0 ? num_parts.join('.') : '';
}

export function noZero(control: AbstractControl): { [key: string]: any } | null {
	const isNotValid = control.value == 0 || control.value == 0.00 ? true : false;
	return isNotValid ? { 'fxValue': { value: control.value } } : null;
}

export function saveAsExcelFile(buffer: any, fileName: string): void {
	const EXCEL_TYPE =
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	const EXCEL_EXTENSION = '.xlsx';
	const data: Blob = new Blob([buffer], {
		type: EXCEL_TYPE
	});
	FileSaver.saveAs(
		data,
		fileName + EXCEL_EXTENSION
	);
}
export function saveAsCSVFile(buffer: any, fileName: string): void {
	const EXCEL_TYPE =
		'text.csv';
	const EXCEL_EXTENSION = '.csv';
	const data: Blob = new Blob([buffer], {
		type: EXCEL_TYPE
	});
	FileSaver.saveAs(
		data,
		fileName + EXCEL_EXTENSION
	);
}