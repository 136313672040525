import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ERROR_MSG, SIDE_NAVE_TITLES } from '../../utils/util-constants';
import { calculateValueForRange, convertToSnakeCase, getApiFormattedDate, setLocalStorageData } from '../../utils/util-functions';
import { aumGraphObject, processedSalesOverviewAum, salesLocalStorage, salesOverviewAumResponse, teamObject, tickerObject } from '../../utils/util-interfaces';
import { CcassService } from '../../../ccass/ccass.service';

@Component({
  selector: 'app-sales-overview',
  templateUrl: './sales-overview.component.html',
  styleUrls: ['./sales-overview.component.scss']
})

export class SalesOverviewComponent implements OnInit {
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() listOfTickers: tickerObject;
  @Input() listOfTeams: teamObject;
  @Input() pageTitle: string = SIDE_NAVE_TITLES.salesOverview;

  @Output() setStartDate = new EventEmitter<any>();
  @Output() setEndDate = new EventEmitter<any>();
  @Output() titleHeading = new EventEmitter<string>();
  @Output() shouldDisplayTitle = new EventEmitter<boolean>();

  showAlertPopup = false;
  message: string;
  rangeValues: number[] = [0, 100];
  maxAum: number;
  minAum: number;

  // Chart data and table data
  aumGraphData: aumGraphObject;
  aumData: processedSalesOverviewAum[];

  // dropdown lists
  selectedTickerList: tickerObject[] = [];
  selectedTeamList: teamObject[] = [];

  // text which toggles between APPRAISAL and ANALYSIS
  toggleText: string;
  btnAppraisalStyle = 'btn-default';
  btnAnalysisStyle = 'btn-change';

  loading = false;
  isTickerPanelVisible = false;
  isTeamPanelVisible = false;

  DEFAULT_SLIDER_RANGE_VALUE = 10000000;
  appraisalKey = 'APPRAISAL';
  analysisKey = 'ANALYSIS';

  constructor(private ccassService: CcassService) {}

  ngOnInit() {
    this.toggleText = this.appraisalKey;

    this.titleHeading.emit(this.pageTitle);
    this.shouldDisplayTitle.emit(true);

    const localData: salesLocalStorage = {
      rangeValues: this.rangeValues,
      selectedTickerList: this.selectedTickerList,
      selectedTeamList: this.selectedTeamList,
      toggleText: this.toggleText
    };

    localStorage.setItem(convertToSnakeCase(this.pageTitle), JSON.stringify(localData));

    this.getSalesOverviewData();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      const change = changes[propName];
      const { firstChange } = change;

      switch (propName) {
        case 'startDate':
          if (!firstChange) { this.getSalesOverviewData(); }
          break;
        case 'endDate':
          if (!firstChange) { this.getSalesOverviewData(); }
          break;
        default:
          break;
      }
    }
  }

  getSalesOverviewData() {
    this.loading = true;
    const queryStartDate = getApiFormattedDate(this.startDate);
    const queryEndDate = getApiFormattedDate(this.endDate);

    const reqData = {
      firstDate: queryStartDate,
      secondDate: queryEndDate,
      teamId: this.selectedTeamList && this.selectedTeamList.length != 0 ? this.selectedTeamList.map(v => v.id) : [0],
      fundId: this.selectedTickerList && this.selectedTickerList.length != 0 ? this.selectedTickerList.map(v => v.fundId) : [0],
      isAppraisalMode: this.toggleText === this.appraisalKey ? true : false
    };

    this.ccassService.ccassPost(reqData, 'v1/sales-overview/')
      .then(response => {
        this.loading = false;
        this.isTickerPanelVisible = false;
        this.isTeamPanelVisible = false;
        if (response['status'] && response['status'] === 'success') {
          const startDateObj: salesOverviewAumResponse[] = response['data'][queryStartDate.split('T')[0]];
          const endDateObj: salesOverviewAumResponse[] = response['data'][queryEndDate.split('T')[0]];

          this.aumData = this.calculateAumData(startDateObj, endDateObj);

          this.setInitialSlider(this.aumData);
        } else {
          this.showWarningPopup();
        }
      }).catch(err => {
        this.aumData = [];
        this.minAum = null;
        this.maxAum = null;
        this.rangeValues = [0, 100];
        setLocalStorageData('rangeValues', this.rangeValues, this.pageTitle);
        this.aumGraphData = this.getGraphData(this.aumData);

        this.loading = false;
        this.isTickerPanelVisible = false;
        this.isTeamPanelVisible = false;

        this.showWarningPopup();
      });
  }

  calculateAumData(startDateObj: salesOverviewAumResponse[], endDateObj: salesOverviewAumResponse[]) {
    // Merge start and end data into one for unique participant
    const result: processedSalesOverviewAum[] = startDateObj.map((startValue, index) => {
      const endValue = endDateObj[index];
      if (startValue.participantId == endValue.participantId) {
        const validEndAum = endValue.aum ? endValue.aum : 0;
        const validStartAum = startValue.aum ? startValue.aum : 0;
        return {
          participantId: startValue.participantId,
          participantName: startValue.participantName ? startValue.participantName : endValue.participantName,
          startAum: startValue.aum,
          startSalespersonId: startValue.salespersonId,
          startSalespersonName: startValue.salespersonName,
          startTeamId: startValue.teamId,
          startTeamName: startValue.teamName,
          endAum: endValue.aum,
          endSalespersonId: endValue.salespersonId,
          endSalespersonName: endValue.salespersonName,
          endTeamId: endValue.teamId,
          endTeamName: endValue.teamName,
          changeInAum: validEndAum - validStartAum,
          changeInAumPercent: ((endValue.aum - startValue.aum) / startValue.aum)  // Percent value(i.e 100) is added by PercentPipe, thus a change here needs change in the condition on table.html
        };
      }
    });

    // Sort merged data based on AUM value
    result.sort((b, a) => a.endAum - b.endAum);

    return result;
  }

  setInitialSlider(data: processedSalesOverviewAum[]) {
    // Calculate min and max from response
    let count = data.length - 1;
    while (this.minAum == null) {
      this.minAum = data[count].endAum;
      count -= 1;
    }
    this.maxAum = data[0]?.endAum;

    // Set rangevalue initial knob to default_slider_range
    if (this.maxAum > this.DEFAULT_SLIDER_RANGE_VALUE) {
      this.rangeValues = [
        (this.DEFAULT_SLIDER_RANGE_VALUE / (this.maxAum - this.minAum)) * 100,
        100
      ];
    } else {
      this.rangeValues = [0, 100];
    }
    setLocalStorageData('rangeValues', this.rangeValues, this.pageTitle);

    this.setGraphData(this.rangeValues);
  }

  getGraphData(data: processedSalesOverviewAum[]) {
    const startAumArray: number[] = [];
    const endAumArray: number[] = [];
    const participantArray: string[] = [];

    data.forEach(value => {
      startAumArray.push(value.startAum);
      endAumArray.push(value.endAum);
      participantArray.push(value.participantName);
    });

    return { startAumArray, endAumArray, participantArray };
  }

  onTickerClick() {
    this.isTickerPanelVisible = true;
  }

  onTeamClick() {
    this.isTeamPanelVisible = true;
  }

  onTickerSubmit() {
    setLocalStorageData('selectedTickerList', this.selectedTickerList, this.pageTitle);
    this.getSalesOverviewData();
  }

  onTeamSubmit() {
    setLocalStorageData('selectedTeamList', this.selectedTeamList, this.pageTitle);
    this.getSalesOverviewData();
  }

  setSelectedStartDate(value) {
    this.startDate = value;
    this.getSalesOverviewData();
  }
  setSelectedEndDate(value) {
    this.endDate = value;
    this.getSalesOverviewData();
  }

  // callback when APPRAISAL/ANALYSIS button is clicked
  onToggleClick(value) {
    this.toggleText = value;
    setLocalStorageData('toggleText', this.toggleText, this.pageTitle);
    this.getSalesOverviewData();
  }

  showWarningPopup(msg: string = '') {
    this.showAlertPopup = true;
    this.message = ERROR_MSG.emptyRecords + msg;
  }

  getSliderLabel(range: number) {
    if (this.minAum == null || isNaN(this.minAum) || this.maxAum == null || isNaN(this.maxAum)) {
      return '';
    }
    const result: number = calculateValueForRange(this.minAum, this.maxAum, range);
    return !isNaN(result) ? result : '';
  }

  handleSliderChange(event) {
    const values: number[] = event.values;
    setLocalStorageData('rangeValues', this.rangeValues, this.pageTitle);

    this.setGraphData(values);
  }

  setGraphData(values: number[]) {
    const minLimit: number = calculateValueForRange(this.minAum, this.maxAum, values[0]);
    const maxLimit: number = calculateValueForRange(this.minAum, this.maxAum, values[1]);

    if (!isNaN(minLimit) && !isNaN(maxLimit)) {
      const filteredData = this.aumData.filter(value => value.endAum <= maxLimit && value.endAum >= minLimit);
      filteredData.sort((b, a) => a.endAum - b.endAum);
      this.aumGraphData = this.getGraphData(filteredData);
    }
  }

  getTickerTooltip() {
    if (this.selectedTickerList) {
      return ' ' + this.selectedTickerList.map(ticker => ticker.tickerName).join(', ') + ' ';
    }
  }

  getTeamTooltip() {
    if (this.selectedTeamList) {
      return ' ' + this.selectedTeamList.map(team => team.teamName).join(', ') + ' ';
    }
  }

  // to clear Ticker and Team multiselects
  clearSelection(value: string) {
    switch (value) {
      case 'teams':
        this.selectedTeamList = [];
        setLocalStorageData('selectedTeamList', this.selectedTeamList, this.pageTitle);
        break;
      case 'tickers':
        this.selectedTickerList = [];
        setLocalStorageData('selectedTickerList', this.selectedTickerList, this.pageTitle);
        break;
    }
  }
}
