import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';

import { SharedModule } from '../shared.module';
import { ProfileModalComponent } from './profile/profile.modal.component';
import { MenuPopupsModule } from '../menu-popups/menu-popups.module';
import { ConfirmationPopupModule } from '../menu-popups/confirmation-popup/confirmation-popup.module';


@NgModule({
  declarations: [LoginComponent, ProfileModalComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    MenuPopupsModule,
    ConfirmationPopupModule

  ],
  exports : [LoginComponent, ProfileModalComponent, ConfirmationPopupModule]
})
export class AuthModule { }
