import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { formatNumber } from '@angular/common';
import { calculateValueInMillion } from '../../../../utils/util-functions';
import { tickerObject, shareHoldingTickerResponse, issuerObj } from '../../../../utils/util-interfaces';
import { NULL_VALUE_STRING, DEFAULT_ISSUER_OBJECT } from '../../../../utils/util-constants';

@Component({
  selector: 'app-ticker-card',
  templateUrl: './ticker-card.component.html',
  styleUrls: ['./ticker-card.component.scss']
})
export class TickerCardComponent implements OnInit {

  @Input() cardTitle: string;
  @Input() listOfTickers: tickerObject[];
  @Input() listOfIssuer: issuerObj[];
  @Input() tickerShareHoldingData: shareHoldingTickerResponse;
  @Input() selectedTicker: tickerObject;
  @Input() selectedIssuer: issuerObj = DEFAULT_ISSUER_OBJECT;

  @Output() setTickerEvent = new EventEmitter<tickerObject>();
  @Output() setIssuerEvent = new EventEmitter<issuerObj>();

  displayName: string;
  shareholdingValue: string;
  wtdValue: string;
  mtdValue: string;

  defaultValueString: string = NULL_VALUE_STRING;

  filteredListOfTickers: tickerObject[];

  positiveTextColor = '#FF3951';
  negativeTextColor = '#040D1C';

  constructor() { }

  ngOnInit() {

    this.displayName = this.cardTitle;
    this.updateCardData(this.tickerShareHoldingData);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      const change = changes[propName];
      const curVal = change.currentValue;

      switch (propName) {
        case 'tickerShareHoldingData':
          this.updateCardData(curVal);
          break;
        case 'listOfIssuer':
          this.listOfIssuer = curVal ? [DEFAULT_ISSUER_OBJECT, ...curVal] : [];
          break;
        case 'selectedIssuer':
          if (this.selectedIssuer.id != 0) {
            this.filteredListOfTickers = this.listOfTickers.filter(t => t.issuerId == this.selectedIssuer.id);
          } else {                          // when 'All' issuers are selected
            this.filteredListOfTickers = this.listOfTickers;
          }
          break;
        case 'listOfTickers':
          this.filteredListOfTickers = curVal;
        default:
          break;
      }
    }
  }

  getSelectedTicker(e) {
    this.setTickerEvent.emit(e.value);
    this.updateCardData(this.tickerShareHoldingData);
  }

  getSelectedIssuer(e) {
    this.setIssuerEvent.emit(e.value);
  }

  updateCardData(data: shareHoldingTickerResponse) {
    if (data) {
      this.wtdValue = calculateValueInMillion(data.wtd);
      this.mtdValue = calculateValueInMillion(data.mtd);
      this.shareholdingValue = calculateValueInMillion(data.shareholdingEndDate);
    } else {
      this.wtdValue = this.defaultValueString;
      this.mtdValue = this.defaultValueString;
      this.shareholdingValue = this.defaultValueString;
    }
  }

  displayValue(value: string) {

    const floatParse = parseFloat(value);
    if (isNaN(floatParse)) {
      return this.defaultValueString;
    } else {
      return floatParse < 0 ? `(${formatNumber(Math.abs(floatParse), 'en-US', '1.2-2')})`
        : formatNumber(floatParse, 'en-US', '1.2-2');
    }
  }

  checkValueForNegative(value: string) {
    return parseFloat(value) < 0;
  }
}
