import { Component, Input, SimpleChanges } from '@angular/core';
import { calculateValueInMillion, formatNumberWithUnit } from '../../../../utils/util-functions';
import { creatorRedeemerResponse } from '../../../../utils/util-interfaces';
import { TCR_KEY, DEFAULT_TCR_OBJECT } from '../../../../utils/util-constants';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../../../utils/util-colours';
import { formatDate, formatNumber } from '@angular/common';

@Component({
  selector: 'app-tcr-chart',
  templateUrl: './tcr-chart.component.html',
  styleUrls: ['./tcr-chart.component.scss']
})
export class TcrChartComponent {

  @Input() chartId: string;
  // @Input() chartLabel: string
  @Input() chartData: creatorRedeemerResponse[];
  // @Input() barColor: string
  @Input() sliderValue: number;
  @Input() headingText: string;
  @Input() endDate: Date;

  chartProperties: any;
  chartOptions: any;

  barWidthFactor = 5;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      const change = changes[propName];
      const curVal = change.currentValue;

      switch (propName) {
        case 'chartData' :
          if (curVal) {
            const {participantArray, participantValues} = this.getGraphData(curVal);
            this.plotGraph(participantArray, participantValues);
          }
          break;
        default:
          break;
      }
    }
  }

  getGraphData(data: creatorRedeemerResponse[]) {
    if (data) {
      const participantArray: string[] = [];
      const participantValues: number[] = [];

      data.forEach(element => {
        participantArray.push(element.participantName);
        participantValues.push(Math.abs(Math.round(element.value)));
      });

      return { participantArray, participantValues };
    }
  }

  plotGraph(participantArray: string[], participantValues: number[]) {

    this.chartProperties = {
      labels: participantArray,
      datasets: [
        {
          label: formatDate(this.endDate, 'dd LLL yyyy', 'en-US'),
          backgroundColor: this.chartId == TCR_KEY.creator ?
            PRIMARY_COLOR : SECONDARY_COLOR,
          data: participantValues,
        },
      ]
    };

    const chartStyles: {font: number, width: string} = this.getChartStyles();
    this.chartOptions = {
      options: {
        cornerRadius: 20,
        layout: {
          padding: 10,
        },
      },
      barRoundness: 10,
      legend: {
        display: true,
        labels: {
          fontColor: '#040D1C',
          usePointStyle: true
        }
      },
      cornerRadius: 20,
      scales: {
        padding: 7,
        xAxes: [{
          barPercentage: 0.5,
          gridLines: {
            drawBorder: false,
            // this will remove all the x-axis grid lines
            display: false
          },
          afterFit: function (scaleInstance: any) { },
          ticks: {
            display: true,
            fontColor: '#4C5862',
            fontSize: chartStyles.font,
            callback: function (label) {
              if (/\s/.test(label)) {
                return label.split(' ');
              } else {
                return label;
              }
            }
          },
        }],
        yAxes: [{
          display: true,
          title: {
            display: true,
            text: 'Title',
          },
          gridLines: {
            drawBorder: false,
            // adds dashed grid line instead of straight grid line
            borderDash: [10, 10],
          },
          // to change title label of Y axis
          scaleLabel: {
            display: true,
            labelString: this.headingText === DEFAULT_TCR_OBJECT.heading_shareholdings ? 'Shareholdings' : 'AUM (in HKD)',
            fontColor: '#7d7d7d',
            font: {
              size: 20
            }
          },
          ticks: {
            fontColor: '#4C5862',
            callback: function (tick) {
            return formatNumberWithUnit(tick, '1.0-1');
            }
          },
        }]
      },
      tooltips: {
        backgroundColor: 'white',
        titleFontColor: 'black',
        footerFontColor: 'black',
        borderColor: this.chartId == TCR_KEY.creator ? PRIMARY_COLOR : SECONDARY_COLOR,
        borderWidth: 2,
        callbacks: {​
            title: function(tooltipItem, chartData) {
              // returns client name
              return '\n\t' + chartData.labels[tooltipItem[0]?.index] + '\n\t';
            },
            footer: function(tooltipItem, chartData) {
              // returns shareholding or AUM
              return  '\t' + formatNumber(chartData.datasets[0]?.data[tooltipItem[0]?.index], 'en-US', '1.0-0') + '\t';
            },
            label: function() {}​,   // Do not remove - mandatory function
          }​
      }
    };
  }

  getChartStyles() {
    const dataSize = this.chartData ? this.chartData.length : 0;
    const widthThreshold = 5;

    if (dataSize > widthThreshold) {
      return {font: 7.5, width: (this.barWidthFactor * dataSize).toString() + 'rem'};
    } else {
      const [maxLowFont, minLowFont] = [4.5, 7.5];

      return {
        font: dataSize > 5 ? ((maxLowFont - minLowFont) * (dataSize - 5) / 5) + minLowFont : 8 ,
        width: 'auto'
      };
    }
  }
}
