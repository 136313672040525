import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-rerun',
  templateUrl: './rerun.component.html',
  styleUrls: ['./rerun.component.scss']
})
export class RerunComponent implements OnInit {
  @Input() isDisplay = false;
  @Output() isDisplayChange = new EventEmitter<boolean>();
  @Output() doRerun = new EventEmitter();
  @Input() reconType: any;
  selectedFiles = [];
  isRerunClicked: boolean;
  constructor() { }

  ngOnInit() {
  }

  onClickRerun() {
    this.isRerunClicked = true;
    this.isDisplay = false;
    this.isDisplayChange.emit(this.isDisplay);
    this.doRerun.emit(this.selectedFiles);
  }

  onClickClose() {
    this.isDisplay = false;
    this.isDisplayChange.emit(this.isDisplay);
  }

}