import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import {Message} from 'primeng//api';

@Injectable()
export class AlertService {
  public alert: Subject<Message> = new Subject<Message>();

  _showAlert(msg: string, level: string) {
    const d = new Date();
    const mesg: Message = { severity: level, summary: '', detail: msg, id: String(d.getTime()) } as Message;
    this.alert.next(mesg);
  }

  success(msg: string) {
    this._showAlert(msg, 'success');
  }

  info(msg: string) {
    this._showAlert(msg, 'info');
  }

  warn(msg: string) {
    this._showAlert(msg, 'warn');
  }

  error(msg: string) {
    this._showAlert(msg, 'error');
  }

}
