import { Injectable } from '@angular/core';
import { ApiService } from '../shared/api.service';

@Injectable()
export class CcassService {
	serviceName = 'ccass';
	constructor(private apiService: ApiService) { }

	ccassPost(data: Object, table: string): Promise<Object> {
		const url = `${this.serviceName}/${table}`;
		return this.apiService.post(url, data);
	}

	ccassfindOne(id: string, table: string): Promise<Object> {
		const url = `${this.serviceName}/${table}`;
		return this.apiService.get(url);
	}

	ccassUpdate(code: string, data: Object, table: string): Promise<Object> {
		const url = `${this.serviceName}/${table}`;
		return this.apiService.put(url, data);
	}

	ccassDelete(code: string, table: string): Promise<Object> {
		const url = `${this.serviceName}/${table}/${code}`;
		return this.apiService.delete(url);
	}
}
