import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CardModule } from 'primeng/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';

import { AuthService } from './shared/auth.service';
import { ApiService } from './shared/api.service';
import { AlertService } from './shared/alert.service';
import { PageService } from './shared/pages.service';
import { DefaultService } from './utils/default.service';
import { AutoLogoutService } from './utils/auto-logout.service';
import { ChatService } from './ws-services/chat.service';
import { WebsocketService } from './ws-services/websocket.service';
import { NotificationService } from './menu-popups/notifications/notification.service';
import { PrivilegeService } from './shared/privilege.service';
import { MenuPopupsModule } from './menu-popups/menu-popups.module';
import { AddNewCaseComponent } from './kyc/add-new-case/add-new-case.component';
import { SharedModule } from './shared.module';
import { AuthModule } from './auth/auth.module';
import { FormLevelLiquidationModule } from './risk/form-level-liquidation/form-level-liquidation.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ThemeingService } from './utils/themeing.service';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { MessageService } from 'primeng/api';
import { DecimalPipe } from '@angular/common';

// ccass import
import { ChartModule } from 'primeng/chart';
import { CcassModule } from './ccass/ccass.module';
import { CcassService } from './ccass/ccass.service';
import { TwoDecimalDirective } from './two-decimal.directive';
//

@NgModule({
	declarations: [
		AppComponent,
		AddNewCaseComponent,
		AppTopBarComponent,
		AppFooterComponent,
        TwoDecimalDirective,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		CardModule,
		FormsModule, ReactiveFormsModule, DialogModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AngularSvgIconModule.forRoot(),
		MenuPopupsModule,
		SharedModule,
		AuthModule,
		FormLevelLiquidationModule,
		ButtonModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
		// ccass
		ChartModule,
		CcassModule
		//
	],
	providers: [
		AuthService,
		ApiService,
		AlertService,
		PageService,
		DefaultService,
		AutoLogoutService,
		ChatService,
		WebsocketService,
		NotificationService,
		PrivilegeService,
		ThemeingService,
		MessageService,
		CcassService,
		DecimalPipe
	],
	bootstrap: [AppComponent],
	exports: [
		AppFooterComponent, AddNewCaseComponent, AppTopBarComponent

	]
})
export class AppModule { }
