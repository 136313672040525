import { Component, OnInit, Input, Injector, Output, EventEmitter, forwardRef, Inject } from '@angular/core';
import { AuthService } from '../../shared/auth.service';
import { AlertService } from '../../shared/alert.service';
import { ApiService } from '../../shared/api.service';
import { AppTopBarComponent } from '../../app.topbar.component';
import { NotificationService } from './notification.service';
import { UtilitiesService } from '../../utils/utilities.service';
import { MessageService} from 'primeng/api';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  notifList: any;
  user: any;
  showTime: any;
  filter: Object = {};
  obj: object = {};
  loading = false;
  time: any;
  time1: any;
  notifCreatedTime: any;
  currentDate: any;
  currentTime: any;
  hourDiff: any;
  diffDays: any;
  parentComponent: any;

  @Input() isDisplay = false;
  @Output() isDisplayChange = new EventEmitter<boolean>();

  constructor( @Inject(forwardRef(() => AppTopBarComponent)) public app: AppTopBarComponent,
    private authService: AuthService,
    public injector: Injector,
    private apiService: ApiService,
    private alertService: AlertService,
    private messageService: MessageService,
    private notificationService: NotificationService) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.parentComponent = this.injector.get(AppTopBarComponent);
    this.getNotifications();

  }

  getNotifications() {
    this.filter['sortBy'] = {'createdAt' : 'desc' };
    this.notificationService.find(this.filter, 1, 1000).then(data => {
      this.notifList = data['notificationList'];
      this.notifList.forEach(element => {
        this.time = new Date(element.createdAt);
        this.currentDate = new Date();
        this.time1 = new Date();
         // eslint-disable-next-line max-len
         this.notifCreatedTime = new Date(Date.UTC(this.time.getFullYear(), this.time.getMonth(), this.time.getDate(),  this.time.getHours(), this.time.getMinutes(), this.time.getSeconds()));
         this.currentTime = new Date(this.time1.setTime(this.time1.getTime() + (30 * 60 * 1000) + (5 * 60 * 60 * 1000)));
        this.diffDays =  this.currentTime.getDate() - this.notifCreatedTime.getDate();
        element.showTime = UtilitiesService.setLoginAndCaseUpdatedTime(this.notifCreatedTime, this.currentTime);
      });
      this.loading = false;
    }).catch(err => {
    });
  }

  loadData(event) {
    // initialize
    // if(!this.notifList) {
    //     this.carService.getCarsSmall().then(cars => this.cars = cars);
    // }
    // in real application, newArray should be loaded from a remote datasource
    // else {
    //     let newArray = this.cars.slice(0);
    //     for(let i = 0; i < newArray.length; i++) {
    //         this.cars.push(newArray[i]);
    //     }
    //     this.msgs = [];
    //     this.msgs.push({severity:'info', summary:'Data Loaded', detail:'Between ' + event.first + ' and ' + (event.first + event.rows)});
    // }
}

  close() {
    this.isDisplay = false;
    this.isDisplayChange.emit(this.isDisplay);
    this.parentComponent.handleNotifAndUsers();
  }


  formatAndReturnTheMessage(message: string) {
    return message ?  message.replace('USER ', '') : message ;
  }

  readNotif(notifCase: any) {
      this.obj = {'noticeId': notifCase['noticeId']};
      this.notificationService.update(this.obj).then(data => {
        if (data['status'] === 'success') {
          this.notifList.forEach(element => {
            if (element.noticeId === notifCase.noticeId) {
              element.readFlag = '1';
            }
          });
          if (this.parentComponent.unReadCount > 0) {
            this.parentComponent.unReadCount = this.parentComponent.unReadCount - 1;
          }
        } else {
          this.parentComponent.messageService.add({severity: 'error', detail: data['status']});
        }
  }).catch(err => {
      this.parentComponent.messageService.add({severity: 'error', detail: err});
  });
}
}
