import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SIDE_NAVE_TITLES } from '../../utils/util-constants';
import { convertToSnakeCase } from '../../utils/util-functions';
import { updateSalesperson, pdMappingObject, clientObject, teamObject, salesPersonObject } from '../../utils/util-interfaces';
import { CcassService } from '../../../ccass/ccass.service';

@Component({
  selector: 'app-client-mapping',
  templateUrl: './client-mapping.component.html',
  styleUrls: ['./client-mapping.component.scss']
})
export class ClientMappingComponent implements OnInit {

  @Input() listOfClient: clientObject[];
  @Input() listOfTeams: teamObject[];
  @Input() listOfSalesperson: salesPersonObject[];

  @Output() titleHeading = new EventEmitter<string>();
  @Output() shouldDisplayTitle = new EventEmitter<boolean>();

  pageTitle: string = SIDE_NAVE_TITLES.clientMapping;
  loading = true;

  listOfPdMapping: pdMappingObject[];
  listOfUpdatedSalesperson: updateSalesperson;

  showAlertPopup = false;
  message: string;

  clientMappingKey = convertToSnakeCase(SIDE_NAVE_TITLES.clientMapping);

  constructor(private ccassService: CcassService) { }

  ngOnInit() {
    this.titleHeading.emit(this.pageTitle);
    this.shouldDisplayTitle.emit(false);
    this.getLists();
  }

  getLists() {
    this.getUpdatedSalesperson();
    this.getPdMapping();
  }

  // api function call to add new record
  addPdMappingData(data) {
    this.loading = true;
    const query = {};
    query['participantId'] = data.participantId;
    query['participantName'] = data.participantName;
    query['salespersonId'] = data.salesPersonId;
    this.ccassService.ccassPost(query, 'v1/client-mapping/').then(response => {
      if (response['status'] && response['status'] === 'success') {
        this.loading = false;
        this.showWarningPopup('Successfully Added');
        this.getPdMapping();
      } else {
        this.showWarningPopup('Invalid Request');
      }
    }).catch(err => {
      this.loading = false;
      const error = JSON.parse(err._body);
      // TODO: Change the way error msg is displayed
      if (error['status'] && error['status'] === 'pd mapping already exists' ) {
        this.showWarningPopup('Mapping Already Exists');
      } else {
        this.showWarningPopup('Invalid request');
      }
    });
  }

  // api function call to get list of mapping records
  getPdMapping() {
    this.loading = true;
    this.ccassService.ccassfindOne('', 'v1/client-mapping').then(response => {
      if (response['status'] && response['status'] === 'success') {
        this.listOfPdMapping = response['pdMapping'];
        this.loading = false;
      } else {
        this.showWarningPopup('Records not found');
      }
    }).catch(err => {
      this.showWarningPopup('Records not found');
      this.loading = false;
    });
  }

  // api function call to remove record from list
  removePdMapping(data) {
    this.loading = true;
    this.ccassService.ccassDelete(data.mappingId, 'v1/client-mapping').then(response => {
      if (response['status'] && response['status'] === 'success') {
        this.loading = false;
        this.getPdMapping();
        this.showWarningPopup('Success');
      } else {
        this.showWarningPopup('Invalid request');
      }
    }).catch(e => {
      this.loading = false;
      this.showWarningPopup('Invalid request');
    });
  }

  // api function call to update records
  updatePdMappingData(data) {
    this.loading = true;
    const query = {};
    query['mappingId'] = data.mappingId;
    query['participantId'] = data.participantId;
    query['participantName'] = data.participantName;
    query['salespersonId'] = data.salesPersonId;
    this.ccassService.ccassUpdate('', query, 'v1/client-mapping/').then(response => {
      if (response['status'] && response['status'] === 'success') {
        this.loading = false;
        this.getPdMapping();
        this.showWarningPopup('Successfully Updated');
      } else {
        this.showWarningPopup('Invalid request');
      }
    }).catch(err => {
      this.loading = false;
      this.showWarningPopup('Invalid request');
    });
  }

  // get updated salesperson list
  getUpdatedSalesperson() {
    this.loading = true;
    this.ccassService.ccassfindOne('', 'v1/salesperson-mapping').then(response => {
      if (response['status'] && response['status'] === 'success') {
        this.listOfUpdatedSalesperson = response['salespersonMapping'];
        this.loading = false;
      } else {
        this.showWarningPopup('Data not found');
      }
    }).catch(err => {
      this.loading = false;
      this.showWarningPopup('Data not found');
    });
  }

  selectedRecordToDelete(value) {
    this.removePdMapping(value);
  }

  updateClient(value) {
    this.updatePdMappingData(value);
  }

  saveClient(value) {
    this.addPdMappingData(value);
  }

  showWarningPopup(message) {
    this.showAlertPopup = true;
    this.message = message;
  }
}
