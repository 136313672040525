import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { FormLevelLiquidationRoutingModule } from './form-level-liquidation-routing.module';
import { FormLevelLiquidationComponent } from './form-level-liquidation.component';

@NgModule({
	declarations: [FormLevelLiquidationComponent],
	imports: [
		CommonModule,
		SharedModule,
		FormLevelLiquidationRoutingModule
	],
	providers: [
		DatePipe
	]
})
export class FormLevelLiquidationModule { }
