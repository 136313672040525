import { Component, OnInit, Output, Input, EventEmitter, Injector } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import { WorkflowService, EntityService } from '../case/case-submit/workflow.service';
import { AuthService } from '../../shared/auth.service';
import { MessageService} from 'primeng/api';


@Component({
  selector: 'app-add-new-case',
  templateUrl: './add-new-case.component.html',
  styleUrls: ['./add-new-case.component.scss']
})
export class AddNewCaseComponent implements OnInit {
  user: any;
  loading: boolean;

  @Input() isDisplay = false;
  @Output() isDisplayChange = new EventEmitter<boolean>();
  profiles: SelectItem[];
  mandatoryTypes: SelectItem[];
  caseForm: FormGroup;
  selectedProfile = 'Corporate';
  caseActivity: any = {};
  case: any = {};

  constructor(private formBuilder: FormBuilder, private router: Router,
    private workflowService: WorkflowService,
    private entityService: EntityService,
    private authService: AuthService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.loading = false;
    this.profiles = [
      { label: 'Corporate', value: 'CORPORATE' },
      { label: 'Individual', value: 'INDIVIDUAL' },
    ];
    this.mandatoryTypes = [
      { label: 'Discrete Advisory Mandate', value: 'DISCRETIONARY_MANDATE' },
      { label: 'Investment Advisory Mandate', value: 'INVESTMENT_ADVISORY_MANDATE' }
    ];
    this.user = this.authService.currentUser();
    this.createForm();
  }

  close() {
    this.isDisplay = false;
    this.isDisplayChange.emit(this.isDisplay);
  }
  createForm(): any {
    this.caseForm = this.formBuilder.group({
      'caseName': new FormControl('', Validators.required),
      'profileType': new FormControl('', Validators.required),
      'mandatoryType': new FormControl('', Validators.required)
    });

    this.case = {
      name: '',
      profileType: 'CORPORATE',
      mandatoryType: 'DISCRETIONARY_MANDATE',
      group: this.user['extras']['group']
    };
  }

  createNewCase(formData) {
    this.loading = true;
    this.entityService.create(formData).then(data => {
      if (data['status'] === 'success') {
        this.router.navigate(['/case', data['case']._id]);
        this.close();
        this.loading = false;
      }
    }).catch(err => {
      this.messageService.add({ severity: 'warn', detail: 'Error occured while creating the case, Please try again' });
      this.loading = false;
      this.close();
    });
  }

  submitCase() {
    this.caseActivity = {
      'type': 'ACTIVITY',
      'pType': 'CASE_APPROVAL',
      'pStatus': 'DRAFT',
      'status': 'SUBMISSION_PENDING',
      'title': 'USER CREATED THE CASE',
      'privilege': 'CASE_CREATE',
      'actionTitle': 'CASE APPROVAL',
      'entityID': this.case._id,
      'data': this.case,
      'actionType': 'API',
      'actionDef': {
        'form': 'CASE_APPROVAL'
      }
    };
    this.createProcess();
  }

  createProcess() {
    this.workflowService.create(this.prepareActivityData()).then(data => {
      if (data['status'] === 'success') {
        this.messageService.add({ severity: 'success', detail: 'Case created successfully' });
        this.close();
      }
    }).catch(err => {
    });
  }

  prepareActivityData(): any {
    const inputData = this.prepareData();
    const data: any = {
      'pType': this.caseActivity.pType,
      'status': this.caseActivity.status, // Process status to be created initially
      'title': this.caseActivity.title,
      'processID': this.caseActivity.processID,
      'activityInfo': this.getActivityInfo(inputData),
      'pStatus': this.caseActivity.nextStatus
    };
    data['entityID'] = this.case._id;
    return data;
  }

  prepareData(): any {
    let data: any = {};
    if (this.caseActivity.data) {
      data = this.caseActivity.data;
    }
    return data;
  }

  getActivityInfo(data: any): any {
    this.caseActivity.data = data;
    return this.caseActivity;
  }


}
