import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SIDE_NAVE_TITLES } from '../../../utils/util-constants';
import { convertToSnakeCase } from '../../../utils/util-functions';
import { pdMappingObject, teamObject, salesPersonMappingObject, clientObject, salesPersonObject } from '../../../utils/util-interfaces';

@Component({
  selector: 'app-mapping-tables',
  templateUrl: './mapping-tables.component.html',
  styleUrls: ['./mapping-tables.component.scss']
})
export class MappingTablesComponent implements OnInit {

  @Input() tableDataShow: string;
  @Input() listOfUpdatedSalesperson;
  @Input() listOfTeams: teamObject;
  @Input() listOfPdMapping: pdMappingObject;
  @Input() listOfSalespersonMapping: salesPersonMappingObject;
  @Input() listOfClient: clientObject[];
  @Input() listOfSalesperson: salesPersonObject[];

  @Output() updateClient = new EventEmitter<any>();
  @Output() saveClient = new EventEmitter<any>();
  @Output() saveSalesperson = new EventEmitter<any>();
  @Output() updateSalesperson = new EventEmitter<any>();
  @Output() selectedRecordToDelete = new EventEmitter<string>();

  display = false;
  showAlertPopup = false;
  message: string ;
  check = false;

  selectedRowData: any;
  openingMode = 'new';
  formTitle = '';
  removeClientData: any;

  clientMappingKey = convertToSnakeCase(SIDE_NAVE_TITLES.clientMapping);
  salespersonMappingKey = convertToSnakeCase(SIDE_NAVE_TITLES.salespersonMapping);

  constructor() { }

  ngOnInit() {
  }

  // add button of client-mapping
  onClickAddButton() {
    this.openingMode = 'new';
    this.display = true;
    this.formTitle = 'ADD CLIENT MAPPING';
  }

  // add button of salesperson-mapping
  onClickSalespersonAdd() {
    this.openingMode = 'new';
    this.display = true;
    this.formTitle = 'ADD SALESPERSON MAPPING';
  }
  setPopup(key) {
    if (key == 'false') {
      this.display = false;
    }
  }

  // to update client records
  updateClientData(value) {
    this.formTitle = 'UPDATE CLIENT';
    this.openingMode = 'edit';
    this.display = true;
    this.selectedRowData = value;
  }

  // to delete client records
  deleteClientData(value) {
    this.formTitle = 'DELETE CLIENT';
    this.removeClientData = value;
    this.showAlertPopup = true;
    this.message = 'Are you sure you want to delete?';
    this.check = false;
  }

  // to update salesperson records
  updateSalespersonData(data) {
    this.formTitle = 'UPDATE SALESPERSON';
    this.openingMode = 'edit';
    this.selectedRowData = data;
    this.display = true;
  }

  // to delete salesperson records
  deleteSalespersonData(data) {
    this.formTitle = 'DELETE SALESPERSON';
    this.removeClientData = data;
    this.showAlertPopup = true;
    this.message = 'Are you sure you want to delete?';
    this.check = false;
  }
  selectedClientData(value) {
    this.saveClient.emit(value);
  }

  selectedSalespersonData(value) {
    this.saveSalesperson.emit(value);
  }

  // input from client-mapping-form and emit outout to salesperson-mapping
  getUpdateSalesperson(value) {
    this.updateSalesperson.emit(value);
  }
  // input from client-mapping-form and emit outout to client-mapping
  getUpdateClient(value) {
    this.updateClient.emit(value);
  }

  // getSelectedPopup(key){
  //   switch(key){
  //     case 'NO':
  //       // this.confirmation = false
  //       // this.deletePopupSalesperson = false
  //       break;
  //     case 'YES':
  //       // this.confirmation = false
  //       // this.deletePopupSalesperson = false
  //       this.selectedRecordToDelete.emit(this.removeClientData);
  //       break
  //     default:
  //       break
  //   }
  // }

  onClickYes(key) {
    this.selectedRecordToDelete.emit(this.removeClientData);
  }
}
