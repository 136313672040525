import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor() { }
  static getFiltersOnSelect(filterName: string, selectedFilters: any): any[] {
    let filters = [];
    switch (filterName) {
      case 'Equity':
        filters = this.prepareAssetFiltersonSelectEquity(selectedFilters);
        break;
      case 'Fixed Income':
        filters = this.prepareAssetFiltersonSelectFixedIncome(selectedFilters);
        break;
      case 'Derivatives':
        filters = this.prepareAssetFiltersonSelectDerivative(selectedFilters);
        break;
      case 'ETF':
        filters = this.prepareAssetFiltersonSelectETF(selectedFilters);
        break;
      case 'Active Fund':
        filters = this.prepareAssetFiltersonSelectActiveFunds(selectedFilters);
        break;
      case 'Commodities':
        filters = this.prepareAssetFiltersonSelectCommodities(selectedFilters);
        break;
    }
    return filters;
  }

  static getFilterAfterRemove(filterName: string, selectedFilters: any): any[] {
    let filters: any;
    switch (filterName) {
      case 'Equity':
        filters = this.prepareAssetFiltersAfterRemoveEquity(selectedFilters);
        break;
      case 'Fixed Income':
        filters = this.prepareAssetFiltersAfterRemoveFixedIncome(selectedFilters);
        break;
      case 'Derivatives':
        filters = this.prepareAssetFiltersAfterRemoveDerivatives(selectedFilters);
        break;
      case 'ETF':
        filters = this.prepareAssetFiltersAfterRemoveETF(selectedFilters);
        break;
      case 'Active Fund':
        filters = this.prepareAssetFiltersAfterRemoveActiveFunds(selectedFilters);
        break;
      case 'Commodities':
        filters = this.prepareAssetFiltersAfterRemoveCommodities(selectedFilters);
        break;
    }
    return filters;
  }

  private static prepareAssetFiltersonSelectEquity(selectedFilters: any[]) {
    let filters: any;
    if ((selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) ||
      (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
        && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
        && selectedFilters.indexOf('Commodities') !== -1)) {
      filters = ['BE', 'LE', 'EM', 'LM', 'EE', 'BM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'EM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'BM', 'EE', 'EM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'EM', 'LE', 'LM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'EM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'EM', 'BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'EM', 'LE', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'EM', 'BE', 'BM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BM', 'EM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LM', 'EM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BM', 'EM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BM', 'EM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BM', 'EM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EM', 'LM', 'BM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'EE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE', 'EE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'EE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'EE', 'LE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LE', 'EE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'EE', 'LE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BE', 'EE', 'LE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'BM', 'EE', 'EM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'LM', 'EE', 'EM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'EM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'LM', 'EE', 'EM', 'BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LE', 'LM', 'EE', 'EM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BE', 'BM', 'EE', 'EM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE'];
    } else {
      filters = ['EE', 'EM'];
    }
    return filters;

  }
  private static prepareAssetFiltersonSelectCommodities(selectedFilters) {
    let filters: any;
    if ((selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1) ||
      (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
        && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
        && selectedFilters.indexOf('Equity') !== -1)) {
      filters = ['BE', 'LE', 'EM', 'LM', 'EE', 'BM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['BE', 'BM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['CE', 'LE', 'LM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EE', 'EM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['CE', 'BE', 'BM', 'LE', 'LM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EE', 'EM', 'CE', 'LE', 'LM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EE', 'EM', 'CE', 'BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['BM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EM', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EM', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EM', 'BM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['EM', 'BM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EM', 'BM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['LE', 'EE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['BE', 'EE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['LE', 'BE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['LE', 'BE', 'CE', 'EE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['BE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['LE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['BE', 'BM', 'LE', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EE', 'EM', 'LE', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EE', 'EM', 'BE', 'BM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['LE', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EE', 'EM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['BE', 'BM', 'CE'];
    } else {
      filters = ['CE'];
    }
    return filters;
  }
  private static prepareAssetFiltersonSelectFixedIncome(selectedFilters) {
    let filters: any;
    if ((selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) ||
      (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
        && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
        && selectedFilters.indexOf('Commodities') !== -1)) {
      filters = ['EE', 'BE', 'LE', 'EM', 'BM', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BE', 'BM', 'EE', 'EM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'LM', 'EE', 'EM', 'BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BE', 'BM', 'EE', 'EM', 'LE', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'BM', 'EE', 'EM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BE', 'BM', 'LE', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BM', 'EM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BM', 'EM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EM', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EM', 'BM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LE', 'BE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'BE', 'EE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'BE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'BE', 'CE', 'LE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'BE', ];

    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'BE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'EM', 'BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'LM', 'BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE', 'BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'LM', 'BE', 'BM', 'EE', 'EM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LE', 'LM', 'BE', 'BM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'EM', 'BE', 'BM', 'CE'];
    } else {
      filters = ['BE', 'BM'];
    }
    return filters;
  }
  private static prepareAssetFiltersonSelectDerivative(selectedFilters: any[]) {
    let filters: any;
    if ((selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1)
      || (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
        && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
        && selectedFilters.indexOf('Commodities') !== -1)) {
      filters = ['EE', 'BE', 'LE', 'EM', 'LM', 'BM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'LM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'LM', 'EE', 'EM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'LM', 'BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LE', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LE', 'LM', 'CE', 'EE', 'EM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'LM', 'EE', 'EM', 'BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LE', 'LM', 'CE', 'BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EM', 'LM', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EM', 'LM', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'LE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE', 'LE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'LE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'LE', 'EE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BE', 'LE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'LE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'LE', 'CE', 'BE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'EM', 'LE', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'EM', 'BE', 'BM', 'LE', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BE', 'BM', 'LE', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LE', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'BM', 'LE', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'EM', 'LE', 'LM'];
    } else {
      filters = ['LE', 'LM'];
    }
    return filters;
  }


  private static prepareAssetFiltersonSelectETF(selectedFilters: any[]) {
    let filters: any;
    if ((selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) ||
      (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') === -1
        && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
        && selectedFilters.indexOf('Commodities') === -1)) {
      filters = ['BM', 'EM', 'LM', 'EE', 'BE', 'LE', 'CE'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EM', 'BM', 'EE', 'BE', 'CE'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BM', 'LM', 'BE', 'LE', 'CE'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EM', 'LM', 'EE', 'LE', 'CE'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'BE', 'LE', 'CE'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'BE', 'LE'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LE', 'CE'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'BE', 'LE'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') == -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'CE'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'BE', 'CE'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'EM', 'CE'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BE', 'LE', 'CE'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'CE', 'BE'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1
    ) {
      filters = ['CE'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'EE'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = [];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'LE', 'CE'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'EM'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'EM', 'BE', 'BM'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'LM', 'BE', 'BM'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LE', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'EM', 'CE'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'EM', 'LE', 'LM'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE', 'BE', 'BM'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'EM', 'BE', 'BM', 'LE', 'LM'];
    } else {
      filters = ['EE', 'BE', 'LE', 'CE'];
    }
    return filters;
  }
  private static prepareAssetFiltersonSelectActiveFunds(selectedFilters: any[]) {
    let filters: any;
    if ((selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) ||
      (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') === -1
        && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
        && selectedFilters.indexOf('Commodities') === -1)) {
      filters = ['BE', 'EE', 'LE', 'EM', 'BM', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'BE', 'EM', 'BM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BE', 'LE', 'BM', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'LE', 'EM', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'LE', 'EM', 'LM', 'BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EM', 'BM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BM', 'EM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'EM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LE', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = [];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = [];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EM', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LM', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'EM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'EM', 'BM', 'BM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'BM', 'LE', 'LM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LE', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'EM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'EM', 'LE', 'LM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BE', 'BM', 'CE'];
    } else {
      filters = ['EM', 'BM', 'LM'];
    }
    return filters;
  }
  private static prepareAssetFiltersAfterRemoveEquity(selectedFilters: any[]) {
    let filters: any;
    if ((selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) ||
      (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
        && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
        && selectedFilters.indexOf('Commodities') !== -1)) {
      filters = ['BE', 'LE', 'LM', 'BM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'LE', 'EM', 'LM', 'EE', 'BM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'LM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'BM', 'LE', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LE', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BE', 'BM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LM', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LM', 'BM', 'EM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'BE', 'LE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'LE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'LE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BE', 'LE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EM', 'BM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'LM', 'BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LE', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BE', 'BM', 'CE'];
    } else {
      filters = [];
    }
    return filters;
  }
  private static prepareAssetFiltersAfterRemoveFixedIncome(selectedFilters: any[]) {
    let filters: any;
    if ((selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) ||
      (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
        && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
        && selectedFilters.indexOf('Commodities') !== -1)) {
      filters = ['EE', 'LE', 'EM', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'BE', 'LE', 'EM', 'BM', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'EM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'LM', 'EE', 'EM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'EM', 'LE', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'EM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LE', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LM', 'BM', 'EM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LM', 'EM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LM', 'EM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'EE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE'];

    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'LE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'EM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'LM', 'EE', 'EM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LE', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'EM', 'CE'];
    } else {
      filters = [];
    }
    return filters;
  }
  private static prepareAssetFiltersAfterRemoveDerivatives(selectedFilters: any[]) {
    let filters: any;
    if ((selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1)
      || (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
        && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
        && selectedFilters.indexOf('Commodities') !== -1)) {
      filters = ['EE', 'BE', 'EM', 'BM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'BE', 'LE', 'EM', 'LM', 'BM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'EM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE', 'EE', 'EM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'EM', 'BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE', 'BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EM', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EM', 'BM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EM', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EM', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EM', 'BM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'EE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'BE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'EM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'EM', 'BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BE', 'BM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'EM'];
    } else {
      filters = [];
    }
    return filters;
  }
  private static prepareAssetFiltersAfterRemoveETF(selectedFilters: any[]) {
    let filters: any;
    if ((selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) ||
      (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') === -1
        && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
        && selectedFilters.indexOf('Commodities') === -1)) {
      filters = ['BM', 'EM', 'LM'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EM', 'BM'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BM', 'LM'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EM', 'LM'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EM', 'LM', 'BM'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'BE', 'LE', 'CE', 'EM', 'BM', 'LM'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'BE', 'CE', 'EM', 'BM'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EM'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LM', 'EM'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BE', 'BM', 'LE', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE', 'BE', 'BM'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1
    ) {
      filters = ['EM'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LM'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BM', 'BE', 'EE', 'EM'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EM', 'BM', 'LM'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'LM'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'EM'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'BM'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE', 'EE', 'EM'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'BM', 'EE', 'EM', 'LE', 'LM'];
    } else if (selectedFilters.indexOf('Active Fund') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE', 'EE', 'EM', 'LE', 'LM'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EM'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EM', 'BM'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LM', 'BM'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LM'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EM', 'LM'];
    } else if (selectedFilters.indexOf('Active Fund') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BM'];
    } else {
      filters = [];
    }
    return filters;
  }
  private static prepareAssetFiltersAfterRemoveActiveFunds(selectedFilters: any[]) {
    let filters: any;
    if ((selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) ||
      (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') === -1
        && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
        && selectedFilters.indexOf('Commodities') !== -1)) {
      filters = ['EE', 'BE', 'LE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'BE', 'LE', 'EM', 'BM', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'BE', 'EM', 'BM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BE', 'LE', 'BM', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'LE', 'EM', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'BE', 'LE', 'EM', 'BM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'LE', 'EM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'BE', 'EM', 'BM', ];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BE', 'BM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LE', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'BM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'EM', ];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'EM', 'BE', 'BM', 'LE', 'LM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'EM', 'BE', 'BM', 'LE', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'EM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'BE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['LE', 'BE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['LE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['CE', 'BE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['EE', 'LE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['BE', 'LE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') === -1) {
      filters = ['BE', 'LE', 'EE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') === -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'LE', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1 && selectedFilters.indexOf('Fixed Income') !== -1
      && selectedFilters.indexOf('Commodities') !== -1) {
      filters = ['EE', 'BE', 'CE'];
    } else {
      filters = [];
    }
    return filters;
  }
  private static prepareAssetFiltersAfterRemoveCommodities(selectedFilters: any) {
    let filters: any;
    if ((selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1) ||
      (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
        && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
        && selectedFilters.indexOf('Equity') !== -1)) {
      filters = ['BE', 'LE', 'EM', 'LM', 'EE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['BE', 'LE', 'EM', 'LM', 'EE', 'BM', 'CE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['LE', 'LM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EE', 'EM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['BE', 'BM', 'LE', 'LM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EE', 'EM', 'LE', 'LM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EE', 'EM', 'BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['BM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EM', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EM', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EM', 'BM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') !== -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['EM', 'BM', 'LM'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['LE', 'EE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['BE', 'EE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['LE', 'BE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['LE', 'BE', 'EE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['BE'];
    } else if (selectedFilters.indexOf('ETF') !== -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['LE'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['BE', 'BM', 'LE', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EE', 'EM', 'LE', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EE', 'EM', 'BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['LE', 'LM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') === -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['EE', 'EM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') === -1
      && selectedFilters.indexOf('Equity') === -1) {
      filters = ['BE', 'BM'];
    } else if (selectedFilters.indexOf('ETF') === -1 && selectedFilters.indexOf('Active Fund') === -1
      && selectedFilters.indexOf('Fixed Income') !== -1 && selectedFilters.indexOf('Derivatives') !== -1
      && selectedFilters.indexOf('Equity') !== -1) {
      filters = ['BE', 'BM', 'EE', 'EM', 'LE', 'LM'];
    } else {
      filters = [];
    }
    return filters;
  }
}
