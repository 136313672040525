import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DefaultService } from '../../utils/default.service';

@Component({
  selector: 'app-date-popup',
  templateUrl: './date-popup.component.html',
  styleUrls: ['./date-popup.component.scss']
})
export class DatePopupComponent implements OnInit {
  @Input() isDisplay = false;
  @Input() label: any;
  @Input() message: any;
  @Input() newsletter: any;
  @Output() callEventFromPopup = new EventEmitter();
  asOfDate = new Date();
  @Output() isDisplayChange = new EventEmitter<boolean>();
  today = new Date();
  constructor(
    private defaultService: DefaultService
  ) { }

  ngOnInit() {

  }

  close() {
    this.isDisplay = false;
    this.isDisplayChange.emit(this.isDisplay);
  }

  action(actionType: any) {
    this.close();
    const obj = {
      actionType: actionType,
      asOfDate: this.defaultService.getStartDate(this.asOfDate),
      auditAction: 'REQUESTED FOR APPROVAL'
    };
    this.callEventFromPopup.emit(obj);
  }

}
