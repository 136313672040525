import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {

  @Input() pageTitle: string;
  @Input() parentStartDate: Date;
  @Input() parentEndDate: Date;
  @Input() displayTopbar: boolean;

  @Output() setStartDate = new EventEmitter<Date>();
  @Output() setEndDate = new EventEmitter<Date>();
  @Output() onClickExport = new EventEmitter<string>();

  todaysDate: Date = new Date();

  constructor() {}

  ngOnInit() {
  }

  handleExportClick() {
    this.onClickExport.emit();
  }

  onStartDateSelect(e: Date) {
    this.setStartDate.emit(e);
  }

  onEndDateSelect(e: Date) {
    this.setEndDate.emit(e);
  }

}
