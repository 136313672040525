import { Injectable, Injector, RendererFactory2, Renderer2 } from '@angular/core';
import { themeschema } from './theme-schema';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ThemeingService {
  static injector: Injector;
  private clientName: any;
  private themeObj: any;
  private urlObj: any;

  constructor(private factory: RendererFactory2) {
    this.clientName = environment[window.location.hostname].client;
    this.themeObj = themeschema[environment[window.location.hostname].client.toUpperCase()].THEME;
    this.urlObj = themeschema[environment[window.location.hostname].client.toUpperCase()].URL;
    ThemeingService.injector = Injector.create(
      Object.keys(services).map(key => ({
        provide: services[key].provide,
        useClass: services[key].provide,
        deps: services[key].deps
      }))
    );
  }

  public setTheme() {
    let themeStyles = '';
    const renderer2 = this.factory.createRenderer(null, null);
    Object.keys(this.themeObj).map(style => {
      themeStyles = `${themeStyles}--${style}:${this.themeObj[style]};`;
    });
    renderer2.setAttribute(document.documentElement, 'style', themeStyles);
  }

  public getIcon(iconName: string) {
    return this.urlObj[iconName];
  }

  public getClientName() {
    return this.clientName;
  }

  public getThemeObj() {
    return this.themeObj;
  }

  public getUrlObj() {
    return this.urlObj;
  }

}

export const services: { [key: string]: { provide: any, deps: any[], useClass?: any } } = {
  'ThemeingService': {
    provide: ThemeingService,
    deps: []
  }
};

