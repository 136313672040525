import { Injectable } from '@angular/core';
import { PageConfig } from '../../assets/pages';
export { MenuItem } from './menuitem';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PageService {
  userPages: any = [];
  changeProfileData: BehaviorSubject<any> = new BehaviorSubject(null);

  pages() {
    this.userPages = [];
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (user) {
      const privs = user.privileges;
      for (const page of PageConfig.pages) {
        const hasAccess = privs.filter(priv => priv.name === page.priv)[0];
        if (hasAccess && 'submenu' in page) {
          this.userPages.push(this.computeSubmenu(page, privs));
          continue;
        }
        if (hasAccess && !('submenu' in page)) {
          this.userPages.push(page);
        }
      }
    }
    return this.userPages;
  }

  computeSubmenu(page: any, privs: any) {
    page.items = [];
    for (const subpage of page.submenu) {
      const hasAccess = privs.filter(priv => priv.name === subpage.priv)[0];
      if (hasAccess) {
        page.items.push(subpage);
      }
    }
    return page;
  }
}
