import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, PagedRecords } from '../../shared/api.service';


@Injectable()
export class NotificationService {
	ServiceName = 'commons/notification/list';
	updateService = 'commons/notification/markAsRead';

	constructor(private apiService: ApiService) { }

	find(filter: Object, page: number = 1, size: number = 10): Promise<PagedRecords> {
		let query = new HttpParams().set('page', page.toString()).set('limit', size.toString());
		if (Object.keys(filter).length !== 0) {
			for (const key of Object.keys(filter)) {
				query = query.set(key, JSON.stringify(filter[key]));
			}
		}
		return this.apiService.get(this.ServiceName, query)
			.then(response => response as PagedRecords);
	}
	update(data: Object): Promise<Object> {
		return this.apiService.put_(this.updateService, data);
	}


}
