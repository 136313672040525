import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment) {
  const title = document.createElement('title');
  const appName = environment[window.location.hostname].defaultTitle;
  const defaultTitle = document.createTextNode(appName);
  title.appendChild(defaultTitle);
  document.head.appendChild(title);
  const favicon = document.createElement('link');
  const faviconUrl = `/assets/images/favicon_${environment[window.location.hostname].client.toUpperCase()}.png`;
  favicon.setAttribute('rel', 'icon');
  favicon.setAttribute('type', 'image/x-icon');
  favicon.setAttribute('href', faviconUrl);
  document.head.appendChild(favicon);
}
if (environment.production) {
  enableProdMode();
  const script = document.createElement('script');
  const trackerURL = environment[window.location.hostname].trackerURL;
  script.setAttribute('async', '1');
  script.innerHTML = `
  ;(function(p,l,o,w,i,n,g){if(!p[i]){p.GlobalSnowplowNamespace=p.GlobalSnowplowNamespace||[];
  p.GlobalSnowplowNamespace.push(i);p[i]=function(){(p[i].q=p[i].q||[]).push(arguments)
  };p[i].q=p[i].q||[];n=l.createElement(o);g=l.getElementsByTagName(o)[0];n.async=1;
  n.src=w;g.parentNode.insertBefore(n,g)}}(window,document,"script","//d1fc8wv8zag5ca.cloudfront.net/2.10.2/sp.js","snowplow"));
  window.snowplow('newTracker', 'cf', '` + trackerURL + `', { // Initialise a tracker
    appId: 'CSOPRISK'
  });
  window.snowplow('trackPageView');
  `;
  document.head.appendChild(script);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
